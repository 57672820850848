import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Box, FormControlLabel, Switch, Typography} from "@mui/material";
import UserContext from "../../../store/user/user-context";
import {LineChart} from "@mui/x-charts/LineChart";
import tables from "../../UI/Tables/table.module.css";
import {Price} from "../../UI/Convertors/Price";
import {useGetEstimateElementsQuery} from "../../SendRequests/api/WorkJournal/queries/useGetEstimateElementsQuery";
import styles from './ContractExecutionStatus.module.css'

const series = [
    {
        id: 'plan',
        label: 'План',
        dataKey: 'plan',
        showMark: true,
        color: '#555f78'
    },
    {
        id: 'fact',
        label: 'Факт',
        dataKey: 'fact',
        showMark: true,
        color: '#5f7855'
    },
];

const tableNames = [
    {
        name: "№",
        type: {span: 2},
        key: "number",
    },
    {
        name: "Наименование работ и затрат",
        type: {span: 2},
        key: "name_works",
    },
]

const months = [
    "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
    "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
];

const ContractExecutionStatus = () => {

    const userSettings = useContext(UserContext);
    const subcompany_id = +window.localStorage.getItem('company');
    const tender_id = +window.localStorage.getItem('tender');

    const [approved, setApproved] = useState(false);

    const {data: estimateData, isSuccess} = useGetEstimateElementsQuery({userSettings, subcompany_id, tender_id})

    const works = useMemo(() => {
        if (!isSuccess) return []
        return estimateData.data
            .map(w => {
                const periods = w.periods.map(({date, value}) => ({
                    date: new Date(date).toISOString().slice(0, 7) + '-01',
                    value
                }));
                const work_log = approved ?
                    w.work_log.filter(l => l.certificates_completed_work.length > 0) :
                    w.work_log
                ;

                const fact = Object.entries(
                        work_log
                            .map(({date, value}) => {
                                const firstDay = new Date(date).toISOString().slice(0, 7) + '-01';
                                return {firstDay, value: Number(value)};
                            })
                            .reduce((acc, {firstDay, value}) => {
                                acc[firstDay] = (acc[firstDay] || 0) + value;
                                return acc;
                            }, {})
                    ).map(([date, value]) => ({date, value: value * (+w.unit_cost_materials + +w.unit_cost_smr)}))
                ;

                return {
                    ...w,
                    work_log,
                    periods,
                    fact,
                };
            })
            .filter(w => w.work_log.length > 0);
    }, [isSuccess, approved])

    const periods = useMemo(() => {
        return [...new Set(
            works.flatMap(work => [
                ...work.periods.map(p => p.date),
                ...work.fact.map(f => f.date)
            ])
        )].sort();
    }, [works])

    const sumPeriods = useMemo(() => {
        return periods.map(date => {
            const planSum = works
                .reduce((sum, work) => {
                    const period = work.periods.find(p => p.date === date);
                    return sum + (period ? period.value : 0);
                }, 0);

            const factSum = works
                .reduce((sum, work) => {
                    const fact = work.fact.find(f => f.date === date);
                    return sum + (fact ? fact.value : 0);
                }, 0)
            ;

            return {date, plan: planSum || null, fact: factSum || null};
        });
    }, [periods])

    const tableNamesWithPeriods = useMemo(() => {
        const newTableNames = [...tableNames];
        periods.forEach(date => {
            const month = months[new Date(date).getMonth()];
            const year = new Date(date).getFullYear();

            newTableNames.push({
                name: (<React.Fragment>
                    <div>{month}</div>
                    <div>{year}</div>
                </React.Fragment>),
                type: {
                    plan: "План",
                    fact: "Факт",
                },
                date
            });
        });
        return newTableNames
    }, [periods])

    const handleSwitchApproved = (event) => {
        setApproved(event.target.checked);
    };

    return (
        <Box>
            <Typography variant="h1">Статус исполнения договора</Typography>
            {/*<Alert className='w-fit' severity="error">?</Alert>*/}
            <FormControlLabel
                control={<Switch checked={approved} onChange={handleSwitchApproved}/>}
                label={approved ? "Согласованные" : "Журнал работ"}
            />
            <Box className={`flex flex-col gap-2 h-full relative`}>
                <Box>
                    <LineChart
                        dataset={sumPeriods.map(p => {
                            return ({
                                ...p,
                                date: new Date(p.date)
                            })
                        })}
                        xAxis={[
                            {
                                id: 'Years',
                                dataKey: 'date',
                                scaleType: 'time',
                                valueFormatter: (date) => date.toLocaleDateString("default", {
                                    year: "numeric",
                                    month: "short"
                                })
                            },
                        ]}
                        series={series}
                        height={300}
                        margin={{left: 70}}
                    />
                </Box>
                <Box className={`grow ${tables.container} ${styles.table}`}>
                    <table className={tables.table}>
                        <thead>
                        <tr>
                            {tableNamesWithPeriods.map((n, index) => (
                                <th
                                    key={index}
                                    rowSpan={n.type?.span}
                                    colSpan={!n.type?.span ? Object.keys(n.type).length : 0}
                                >
                                    {n.name}
                                </th>
                            ))}
                        </tr>
                        <tr>
                            {tableNamesWithPeriods
                                .filter(n => !n.type?.span)
                                .flatMap((n, index) =>
                                    Object.values(n.type).map((name, id) => (
                                        <th key={`${id}-${index}`}>
                                            {name}
                                        </th>
                                    ))
                                )
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {works?.map((w, index) => {
                            return (
                                <tr key={index}>
                                    {tableNamesWithPeriods.map((n, id) => {
                                        const plan = (n.date && w.periods) && w.periods.find(p => p.date === n.date)?.value;
                                        const fact = (n.date && w.fact) && w.fact.find(p => p.date === n.date)?.value;

                                        return (
                                            n.date ?
                                                <React.Fragment key={id}>
                                                    <td className={`text-center whitespace-nowrap`}>
                                                        {plan && Price(plan)}
                                                    </td>
                                                    <td className={`text-center whitespace-nowrap`}>
                                                        {fact && Price(fact)}
                                                    </td>
                                                </React.Fragment> :
                                                <td key={id}
                                                    className={`
                                                    ${n.key !== "name_works" ? "text-center" : ""}
                                                    ${(index % 2 === 0) ? "bg-white" : "bg-[#E7E8EB]"}
                                                    `}>
                                                    {w[n.key]}
                                                </td>
                                        );
                                    })}
                                </tr>
                            )
                        })}
                        <tr key={`all`}>
                            <td colSpan={2} className={"sticky left-0 bg-[#E7E8EB]"}>Итого за месяц</td>
                            {tableNamesWithPeriods
                                .filter(n => n.date)
                                .map((n, id) => {
                                        const sumBydate = sumPeriods.find(p => p.date === n.date);
                                        return (
                                            <React.Fragment key={id}>
                                                <td className={`text-center whitespace-nowrap`}>{sumBydate?.plan > 0 &&
                                                    Price(sumBydate?.plan)
                                                }</td>
                                                <td className={`text-center whitespace-nowrap`}>{sumBydate?.fact > 0 &&
                                                    Price(sumBydate?.fact)
                                                }</td>
                                            </React.Fragment>
                                        )
                                    }
                                )
                            }
                        </tr>
                        </tbody>
                    </table>
                </Box>
            </Box>
        </Box>
    );
};

export default ContractExecutionStatus;
