import React from "react";
import {Document, Font, Page, StyleSheet, Text, View} from "@react-pdf/renderer";

Font.register({
    family: "Roboto", src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"
});

const styles = StyleSheet.create({
    body: {
        fontFamily: "Roboto", fontSize: 10, padding: 20, display: "flex", flexDirection: "column", gap: "12px"
    }, page: {
        display: "flex", flexDirection: "column", padding: 10,
    }, block: {
        margin: "10px 0", display: "flex", flexDirection: "column",
    }, row: {
        display: "flex", flexDirection: "row", gap: "4px"
    }, spaceBetween: {
        display: "flex", justifyContent: "space-between"
    }, end: {
        alignItems: "flex-end",
    }, horizontalBreak: {
        width: "100%", borderBottom: "1px solid black"
    }, underline: {
        textDecoration: "underline"
    }, textCenter: {
        textAlign: "center",
    }
});

const BuildAct = (props) => {
    return (
        <Document title='АКТ ПРИЕМА-ПЕРЕДАЧИ СТРОИТЕЛЬНОЙ ПЛОЩАДКИ'>
            <Page style={styles.body}>
                <View style={[styles.block, styles.textCenter]}>
                    <Text>АКТ ПРИЕМА-ПЕРЕДАЧИ СТРОИТЕЛЬНОЙ ПЛОЩАДКИ</Text>
                    <Text>(ФРОНТА РАБОТ)</Text>
                </View>

                <View>
                    <Text>г. Санкт-Петербург</Text>
                    <Text>
                        Объект: Жилой комплекс со встроено-пристроенными помещениями, подземной автостоянкой (в том
                        числе
                        ДОУ семейного типа не менее чем на 20 мест), с реставрацией и приспособлением объектов
                        культурного наследия для современного использования (объектов культурного наследия
                        регионального значения: «Дом Корлякова» (СПб, наб. р. Карповки, 27, лит. А; «Здание
                        кинематографа «Гранд-Палас» (СПб, наб. р. Карповки, 27, лит. Г); «Постройки
                        мебельно-столярной фабрики Ф.А. Мельцера» (СПб, наб. р. Карповки, 27, лит. Б
                        (Производственный корпус литера Б8, литера Б4, Дымовая Труба литера Б1)», расположенного по
                        адресу: г. Санкт-Петербург, набережная реки Карповки, дом 27, литера А, общей площадью 35
                        150,1 кв.м., кадастровый номер земельного участка 78:07:0003125:2
                    </Text>
                    <Text style={styles.horizontalBreak}>{" "}</Text>
                    <Text style={styles.textCenter}>(должность, номер и дата доверенности, ФИО.)</Text>
                </View>

                <View>
                    <Text style={styles.horizontalBreak}>{" "}</Text>
                    <Text style={styles.textCenter}>(должность, номер и дата доверенности, ФИО.)</Text>
                </View>

                {/*<Text style={styles.underline}>{" "}</Text>*/}

                <View>
                    <Text>составили настоящий Акт о нижеследующем:</Text>
                    <Text>
                        1. Генподрядчик передал, а Субподрядчик принял строительную площадку для выполнения работ
                        по Договору подряда № ________ от ________ (далее по тексту Договор) по адресу
                    </Text>
                    <Text>
                        2. Участки для выполнения строительно-монтажных работ указаны в Приложении № 1 настоящего Акта
                        (далее по тексту Участки);
                    </Text>
                    <Text>
                        3. Места производства работ соответствует условиям Договора и действующему законодательству.
                        Субподрядчик подтверждает, что каких-либо препятствий для исполнения им обязательств из Договора
                        не имеется
                    </Text>
                    <Text>
                        4. Субподрядчику предоставлена точка подключения к электроснабжению. Дополнительный силовой щит,
                        приборы учёта электроэнергии должны быть установлены силами и за счёт средств Субподрядчика;
                    </Text>
                    <Text>
                        5. Субподрядчику предоставлено точка подключения к водоснабжению с условием устройства
                        дополнительного крана;
                    </Text>
                    <Text>
                        6. Субподрядчик проинформирован о наличии инженерных сетей на Объекте;
                    </Text>
                    <Text>
                        7. Восстановление Участков до исходного состояния выполняется силами и за счёт средств
                        Субподрядчика;
                    </Text>
                    <Text>
                        8. Дополнительные комментарии:
                    </Text>
                    <Text style={{marginLeft: "20px"}}>
                        a.
                    </Text>
                    <Text>
                        9. Настоящий акт составлен в 2 (двух) экземплярах, имеющих одинаковую юридическую силу, по
                        одному – для каждой из Сторон.
                    </Text>
                    <Text style={[styles.horizontalBreak]}>{" "}</Text>
                    <Text style={styles.textCenter}>(оси, координаты, участки, дополнительная информация)</Text>
                </View>

                <View>
                    <Text>
                        Приложения:
                    </Text>
                    <Text>
                        Приложение № 1 –
                    </Text>
                </View>

                <View style={{alignItems: "center"}}>
                    <View style={[styles.textCenter, {width: "200px"}]}>
                        <Text style={styles.horizontalBreak}>{" "}</Text>
                        <Text>Подпись, дата</Text>
                    </View>
                    <View style={[styles.textCenter, {width: "200px"}]}>
                        <Text style={styles.horizontalBreak}>{" "}</Text>
                        <Text style={styles.textCenter}>Подпись, дата</Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

export default BuildAct;