import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UserContext from "../../../store/user/user-context";
import plan_styles from "../../UI/Plan/panel.module.css";
import UserSection from "../../UI/Sections/UserSection";
import axios from "axios";
import { ReactComponent as BuildingIcon } from "../../UI/Icons/ControlPanel/project.svg";
import { ReactComponent as CreateOrder } from "../../UI/Icons/Plan/create.svg";
import ViewMap from "../../UI/Plan/ViewMap";
import Warnings from "../../UI/Sections/Warnings";
import { ErrorOccurred } from "../../UI/Sections/ErrorOccurred";
import { getSubcompanies } from "../../UI/GetInfo/getSubcompanies";
import { Alert } from "@mui/material";

export default function QualityControlBuilding() {
  const navigate = useNavigate();
  const role = +window.localStorage.getItem("role");
  if (role !== 3 && role !== 4) navigate("/work/");
  const userSettings = useContext(UserContext);
  const company_id = +window.localStorage.getItem("company");
  const project_id = +window.localStorage.getItem("project");
  const mapSettings = new URLSearchParams(useLocation().search);
  const building_id = +mapSettings.get("building");
  const floor_id = +mapSettings.get("floor");
  const section = +mapSettings.get("section");

  const [answers, setAnswers] = useState(null);
  const [sectionsList, setSectionsList] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [newCoordinate, setNewCoordinate] = useState(null);
  const section_id = selectedSection && selectedSection.id;

  const allIdForQueries = {
    company_id,
    project_id,
    section_id,
    building_id,
  };

  const [subcompanies, setSubcompanies] = useState(null);

  const [orders, setOrders] = useState(null);
  const selectedOrder =
    orders && orders.find((o) => o.id === +mapSettings.get("order"));
  const [isMoveOrder, setIsMoveOrder] = useState(null);
  const [isCreateOrder, setIsCreateOrder] = useState(null);
  const [gipOrders, setGipOrders] = useState(null);
  const [isCreateGipOrder, setIsCreateGipOrder] = useState(null);
  const [isMoveGipOrder, setIsMoveGipOrder] = useState(null);

  const setSectionHandler = (section_id) => {
    if (selectedSection) {
      const section =
        sectionsList.find((s) => s.id === +section_id) || sectionsList[0];
      if (building_id && section.id)
        navigate(
          `/work/technical-control/quality-control/building/?building=${building_id}&section=${section.id}&floor=${selectedFloor}`,
        );

      setIsMoveOrder(null);
      setIsCreateOrder(null);
      setIsCreateGipOrder(null);
      setIsMoveGipOrder(null);
      setNewCoordinate(null);
    }
  };
  const setFloorHandler = (id) => {
    if (selectedSection) {
      const section = selectedSection || sectionsList[0];
      const floor = id || section.floorList[0];
      if (section.id && floor)
        navigate(
          `/work/technical-control/quality-control/building/?building=${building_id}&section=${
            section.id
          }&floor=${
            floor === "Кровля" ? selectedSection.max_floor + 1 : floor
          }`,
        );
    }
  };
  const createOrderHandler = async (data) => {
    const tender_id = +data.tender_id;
    const subcompany_id = subcompanies?.find(
      (s) => s.tender.id === +data.tender_id,
    ).subcompany;

    data["floor"] = selectedFloor;

    delete data.tender_id;
    delete data.subcompany_id;

    try {
      setIsCreateOrder(null);
      const form = new FormData();
      Object.keys(data).forEach((d) => {
        if (d !== "photos_to_upload") form.append(d, data[d]);
      });
      for (let i = 0; i < data.photos_to_upload.length; i++) {
        const file = data.photos_to_upload[i];
        form.append("photos_to_upload", file);
      }

      const createOrder = await axios.post(
        `${userSettings.api}/company/${company_id}/projects/${project_id}/tenders/${tender_id}/buildings/${building_id}/sections/${section_id}/orders/?subcompany_id=${subcompany_id}`,
        form,
        { headers: userSettings.headers.postForm },
      );

      if (createOrder) window.location.reload();
    } catch (error) {
      setAnswers(
        <Warnings>
          Ошибка создания предписания -{" "}
          {error.response.data.errors
            ? error.response.data.errors.map((e, id) => (
                <p key={id}>{e.detail}</p>
              ))
            : error.response.status}
        </Warnings>,
      );
      console.error(`\n createOrderHandler error`, error.response);
    }
  };
  const editOrderHandler = useCallback(
    async (order) => {
      try {
        const subcompany = subcompanies?.find(
          (subcompany) => subcompany.id === order.contract,
        );
        const formData = new FormData();
        formData.append("coordinate_x", order.coordinate_x);
        formData.append("coordinate_y", order.coordinate_y);
        formData.append("category", order.category);
        formData.append("floor", order.floor);
        formData.append("description", order.description);
        formData.append("standard", order.standard);
        formData.append("measures", order.measures);

        const updateOrder = await axios.put(
          `${userSettings.api}/company/${company_id}/projects/${project_id}/tenders/${subcompany.tender.id}/buildings/${building_id}/sections/${section_id}/orders/${order.id}/`,
          formData,
          { headers: userSettings.headers.postForm },
        );

        if (updateOrder.status === 200) window.location.reload();
      } catch (error) {
        console.error("error 3", error);

        const responseError = ErrorOccurred(
          error.response,
          "Ошибка изменений предписания",
          "editOrderHandler",
        );
        setAnswers(responseError.jsx);
      }
    },
    [
      subcompanies,
      building_id,
      company_id,
      project_id,
      section_id,
      floor_id,
      userSettings,
    ],
  );
  const editOrder = (order_id) => {
  };
  const sendOrder = async (data) => {
    const section_id = selectedSection.id;
    const tender_id = data.tender_id;
    const order_id = data.order_id;

    try {
      const form = new FormData();
      form.append("order_file", data.order_file[0]);

      const sendOrder = await axios.post(
        `${userSettings.api}/company/${company_id}/projects/${project_id}/tenders/${tender_id}/buildings/${building_id}/sections/${section_id}/orders/${order_id}/send-by-company/`,
        form,
        { headers: userSettings.headers.postForm },
      );

      if (sendOrder) window.location.reload();
    } catch (error) {
      setAnswers(
        <Warnings>
          Ошибка предписания -{" "}
          {error.response.data.errors
            ? error.response.data.errors.map((e, id) => (
                <p key={id}>{e.detail}</p>
              ))
            : error.response.status}
        </Warnings>,
      );
      console.error(`\n createOrderHandler error`, error.response);
    }
  };
  const rejectOrder = async (data) => {

    try {
      const section_id = data.section_id;
      const tender_id = data.tender_id;
      const order_id = data.order_id;

      // const form = new FormData();
      // form.append('order_file', data.order_file[0]);
      const postOrder = await axios.post(
        `${userSettings.api}/company/${company_id}/projects/${project_id}/tenders/${tender_id}/buildings/${building_id}/sections/${section_id}/orders/${order_id}/reject/`,
        "",
        { headers: userSettings.headers.postForm },
      );
      if (postOrder) window.location.reload();
    } catch (error) {
      setAnswers(
        <Warnings>
          Ошибка предписания -{" "}
          {error.response.data.errors
            ? error.response.data.errors.map((e, id) => (
                <p key={id}>{e.detail}</p>
              ))
            : error.response.status}
        </Warnings>,
      );
    }
  };
  const acceptOrder = async (data) => {
    try {
      const section_id = data.section_id;
      const tender_id = data.tender_id;
      const order_id = data.order_id;

      const postOrder = await axios.post(
        `${userSettings.api}/company/${company_id}/projects/${project_id}/tenders/${tender_id}/buildings/${building_id}/sections/${section_id}/orders/${order_id}/accept/`,
        "",
        { headers: userSettings.headers.postForm },
      );
      if (postOrder) window.location.reload();

      // `/company/${company_id}/projects/${project_id}/tenders/${tender_id}/buildings/${building_id}/sections/${section_id}/orders/${order_id}/accept/`
    } catch (error) {
      setAnswers(
        <Warnings>
          Ошибка предписания -{" "}
          {error.response.data.errors
            ? error.response.data.errors.map((e, id) => (
                <p key={id}>{e.detail}</p>
              ))
            : error.response.status}
        </Warnings>,
      );
      console.error(`\n createOrderHandler error`, error.response);
    }
  };
  const sendGipOrderHandler = async (data) => {

    try {
      data.floor = selectedFloor;

      setIsCreateGipOrder(null);
      const form = new FormData();
      Object.keys(data).forEach((d) => {
        if (d !== "photos_to_upload") form.append(d, data[d]);
      });
      for (let i = 0; i < data.photos_to_upload.length; i++) {
        const file = data.photos_to_upload[i];
        form.append("photos_to_upload", file);
      }

      const createOrder = await axios.post(
        `${userSettings.api}/building-plans/companies/${company_id}/projects/${project_id}/buildings/${building_id}/sections/${section_id}/plans/`,
        form,
        { headers: userSettings.headers.postForm },
      );

      const newGipOrder = createOrder.data;

      setGipOrders((prev) => [...prev, newGipOrder]);
      // if (createOrder) window.location.reload();
    } catch (error) {
      setAnswers(
        <Warnings>
          Ошибка создания предписания ГИП -{" "}
          {error.response.data.errors
            ? error.response.data.errors.map((e, id) => (
                <p key={id}>
                  {e.detail} {e.attr && e.attr}
                </p>
              ))
            : error.response.status}
        </Warnings>,
      );
    }
  };
  const editGipOrderHandler = useCallback(
    async (data) => {

      try {
        const building_plan_id = data.id;

        setIsCreateGipOrder(null);
        const form = new FormData();
        Object.keys(data).forEach((d) => {
          if (d !== "photos_to_upload") form.append(d, data[d]);
        });
        // for (let i = 0; i < data.photos_to_upload.length; i++) {
        //     const file = data.photos_to_upload[i];
        //     form.append('photos_to_upload', file);
        // }
        const editOrder = await axios.put(
          `${userSettings.api}/building-plans/companies/${company_id}/projects/${project_id}/buildings/${building_id}/sections/${section_id}/plans/${building_plan_id}/`,
          form,
          { headers: userSettings.headers.postForm },
        );

        if (editOrder) window.location.reload();
      } catch (error) {
        setAnswers(
          <Warnings>
            Ошибка редактирования предписания ГИП -{" "}
            {error.response.data.errors
              ? error.response.data.errors.map((e, id) => (
                  <p key={id}>
                    {e.detail} {e.attr && e.attr}
                  </p>
                ))
              : error.response.status}
          </Warnings>,
        );
      }
    },
    [userSettings, company_id, project_id, building_id, section_id],
  );
  const moveOrderHandler = (id, type) => {
    if (type && type === "gip") setIsMoveGipOrder(id);
    else {
      setIsMoveOrder(id);
    }
  };

  useEffect(() => {
    const getSections = async () => {
      try {
        const getCurrentBuildings = await axios.get(
          `${userSettings.api}/tep-building/companies/${company_id}/projects/${project_id}/buildings/`,
          userSettings.headers.get,
        );
        if (
          getCurrentBuildings.data.data.filter((b) => b.id === building_id)
            .length === 0
        ) {
          window.location.replace(`/work/technical-control/quality-control/`);
        } else {
          const getSectionsList = await axios.get(
            `${userSettings.api}/plans-configurator/companies/${company_id}/projects/${project_id}/building/${building_id}/sections/`,
            userSettings.headers.get,
          );
          const updateSectionsList = getSectionsList.data
            .map((s) => {
              const ranges = s.ranges.filter((r) => r.plan) || [];
              // s[`floorList`] = ranges.length > 0 && ranges
              //     .map(r => r.floors)
              //     .flat()
              //     .sort((a, b) => a - b)
              // ;

              if (ranges.length > 0) return s;
              else return null;
            })
            .filter((s) => s);
          setSectionsList(updateSectionsList);
        }
      } catch (error) {
        console.error("error 9", error);

        const responseError = ErrorOccurred(
          error.response,
          "Ошибка получения зданий и секций",
          "getSections",
        );
        setAnswers(responseError.jsx);
      }
    };

    if (building_id && !isNaN(building_id)) getSections();
  }, [building_id, company_id, project_id, userSettings]);
  useEffect(() => {
    if (sectionsList && sectionsList.length > 0) {
      const currentSection = sectionsList.find((s) => s.id === section);
      const userSection = currentSection || sectionsList[0];
      setSelectedSection(userSection);

      const floorList = [
        ...new Set(
          userSection?.ranges?.filter((r) => r.plan).flatMap((r) => r.floors),
        ),
      ]
        .filter((floor) => floor !== 0)
        .sort((a, b) => a - b);
      userSection["floors"] = floorList;

      const userFloor = floorList.includes(floor_id)
        ? floor_id
        : floorList?.length > 0 && floorList[0];
      setSelectedFloor(
        userFloor === "Кровля" ? selectedSection.max_floor + 1 : userFloor,
      );
    }
  }, [sectionsList, floor_id, section]);

  useEffect(() => {
    const getOrders = async () => {
      try {
        setOrders(null);
        const getSubcompaniesData = await getSubcompanies(
          userSettings,
          company_id,
          project_id,
        );
        setSubcompanies(getSubcompaniesData);

        if (role === 3) {
          const getOrders = getSubcompaniesData.map(async (s) => {
            const response = await axios.get(
              `${userSettings.api}/company/${company_id}/projects/${project_id}/tenders/${s.tender.id}/buildings/${building_id}/sections/${section_id}/orders/`,
              userSettings.headers.get,
            );
            return response.data.data;
          });
          const updateOrders = (await Promise.all(getOrders)).flat();
          setOrders(updateOrders);
        }
      } catch (error) {
        console.error("error 10", error);

        const responseError = ErrorOccurred(
          error.response,
          "Ошибка получения предписаний",
          "getOrders",
        );
        setAnswers(responseError.jsx);
      }
    };

    const getGipOrders = async () => {
      try {
        const getGipOrders = await axios.get(
          `${userSettings.api}/building-plans/companies/${company_id}/projects/${project_id}/buildings/${building_id}/sections/${section_id}/plans/`,
          userSettings.headers.get,
        );

        setGipOrders(getGipOrders.data);
      } catch (err) {
      }
    };

    if (building_id && company_id && project_id && section_id && role === 3)
      getOrders();
    if (building_id && company_id && project_id && section_id && role === 4)
      getGipOrders();
  }, [building_id, company_id, project_id, section_id, userSettings, role]);

  useEffect(() => {
    if (!newCoordinate) return;
    if (isMoveOrder) {
      const coordinate = newCoordinate.map((c) => Math.round(c));

      const order = orders?.find((g) => g.id === isMoveOrder);

      if (order) {
        order.coordinate_x = coordinate[0];
        order.coordinate_y = coordinate[1];

        editOrderHandler(order);
      }
    } else if (isMoveGipOrder) {

      const order = gipOrders?.find((g) => g.id === isMoveGipOrder);

      const data = {
        comment: order.comment,
        coordinate_x: Math.round(newCoordinate[0]),
        coordinate_y: Math.round(newCoordinate[1]),
        floor: order.floor,
        id: order.id,
        section: order.section,
      };

      editGipOrderHandler(data);
    }
  }, [
    isMoveGipOrder,
    isMoveOrder,
    newCoordinate,
    gipOrders,
    orders,
    editGipOrderHandler,
    editOrderHandler,
  ]);

  return (
    <UserSection>
      {
        <div className={plan_styles.nav}>
          <Link
            to={`/work/technical-control/quality-control/`}
            className={`my-auto`}
          >
            <div className={plan_styles.btn} title={`К выбору корпуса`}>
              <BuildingIcon />
              <span>К выбору корпуса</span>
            </div>
          </Link>
          {(subcompanies || role === 4) && (
            <div className={`mx-1 md:mx-2 my-auto h-max`}>
              {isCreateOrder ||
              isMoveOrder ||
              isCreateGipOrder ||
              isMoveGipOrder ? (
                <div className={`flex`}>
                  <div className={`text-sm text-center`}>
                    Установите
                    <br />
                    предписание
                  </div>
                </div>
              ) : (
                <div
                  className={plan_styles.btn}
                  title={`Добавить предписание`}
                  onClick={() => {
                    if (subcompanies?.length > 0 && role === 3)
                      setIsCreateOrder(true);
                    else if (role === 4) setIsCreateGipOrder(true);
                  }}
                >
                  <CreateOrder />
                  <span>Добавить предписание</span>
                </div>
              )}
            </div>
          )}
          {sectionsList && (
            <React.Fragment>
              <div className={plan_styles.select}>
                <label>Секция</label>
                <select
                  onChange={(e) => setSectionHandler(e.target.value)}
                  value={selectedSection?.id}
                >
                  {sectionsList.map((s) => (
                    <option key={s.id} value={s.id}>
                      {s.name}
                    </option>
                  ))}
                </select>
              </div>

              {selectedSection && (
                <div className={plan_styles.select}>
                  <label>Этаж</label>
                  <select
                    onChange={(e) =>
                      setFloorHandler(
                        e.target.value === "Кровля"
                          ? selectedSection.max_floor + 1
                          : e.target.value,
                      )
                    }
                    value={
                      selectedFloor > selectedSection.max_floor
                        ? "Кровля"
                        : selectedFloor
                    }
                  >
                    {selectedSection.floors?.map((f, index) => (
                      <option key={`${selectedSection.id}-${index}`}>
                        {f > selectedSection.max_floor ? "Кровля" : f}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      }
      {answers && (
        <Alert
          severity="error"
          className={`w-max absolute top-14 right-1 z-[9999999]`}
        >
          Отсутствует подрядчик, невозможно создать предписание
        </Alert>
      )}
      {selectedSection && selectedFloor && (
        <ViewMap
          acceptOrder={acceptOrder}
          createOrderHandler={createOrderHandler}
          editGipOrderHandler={editGipOrderHandler}
          fileServer={userSettings.fileServer}
          gipOrders={gipOrders}
          isCreateGipOrder={isCreateGipOrder}
          isCreateOrder={isCreateOrder}
          isMoveGipOrder={isMoveGipOrder}
          isMoveOrder={isMoveOrder}
          moveOrderHandler={moveOrderHandler}
          sendOrder={sendOrder}
          sendGipOrderHandler={sendGipOrderHandler}
          selectedFloor={selectedFloor}
          selectedOrder={selectedOrder}
          selectedSection={selectedSection}
          setIsCreateGipOrder={setIsCreateGipOrder}
          setIsCreateOrder={setIsCreateOrder}
          setNewCoordinate={setNewCoordinate}
          subcompanies={subcompanies}
          orders={orders}
          setOrders={setOrders}
          setGipOrders={setGipOrders}
          rejectOrder={rejectOrder}
          editOrder={editOrder}
          allIdForQueries={allIdForQueries}
        />
      )}
    </UserSection>
  );
}
