import React, {useContext, useEffect, useState} from "react";
import ReactDom from "react-dom";
import {FormProvider, useForm} from "react-hook-form";
import styles from "./popup.module.css";
import inputs from "../Inputs/inputs.module.css";
import buttonsStyles from "../Buttons/buttons.module.css";
import dashStyles from "../../Work/dash.module.css";
import Btn from "../Buttons/Btn";
import Grids from "../Grids/Grids";
import RegistrationInput from "../Inputs/RegistrationInput";
import Button from "../Buttons/Button";
import {ReactComponent as CloseModal} from "../Icons/close.svg";
import CheckBoxChoice from "../Inputs/CheckBoxChoice";
import WorkInput from "../Inputs/WorkInput";
import axios from "axios";
import InputFile from "../Inputs/InputFile";
import "react-range-slider-input/dist/style.css";
import "../Inputs/range.css";
import {Link} from "react-router-dom";
import Warnings from "../Sections/Warnings";
import HTMLReactParser from "html-react-parser";
import UserContext from "../../../store/user/user-context";
import {ToggleButton, ToggleButtonGroup} from "@mui/material";

const Backdrop = (props) => {
    return <div className={styles.backdrop} onClick={props.onCloseModal}></div>;
};

const Popup = (props) => {

    const methods = useForm();
    const userSettings = useContext(UserContext);
    const onSubmitHandler = (data, e) => {
        props.onSubmit(data, e);
        props.onCloseModal();
    };

    const formFields = (form) => {
        if (form?.type === "checkbox") return (form.fields.map(field =>
            <CheckBoxChoice
                key={field.name}
                id={field.name}
                type={field.type}
                name={field.name}
                label={field.label}
                checked={field.checked}
            />
        ));
        else return (form.fields.map(field =>
            <RegistrationInput
                key={field.name}
                type={field.type}
                name={field.name}
                label={field.label}
                notRequired={field.notRequired}
                placeholder={field.placeholder}
                options={field.options}
                defaultValue={field.defaultValue}
                min={field.min}
                max={field.max}
                step={field.step}
            />
        ));
    };

    const [ownership, setOwnership] = useState();
    const [innError, setInnError] = useState();
    const [innData, setInnData] = useState();
    const checkInnHandler = (data) => {
        const options = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Token 0081d13554403402faae0bd889c3275bf36e1dd1'
            }
        };

        axios.post(`https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party`, {query: data.inn_check}, options)
            .then(response => {
                const result = response.data.suggestions[0].data;

                if (result.type === "INDIVIDUAL") setOwnership('2');

                if (result.state.status === 'ACTIVE') {


                    methods.reset();

                    setInnData(result);
                } else setInnError('Введите ИНН существующего лица');
            })
            .catch(error => {
                setInnError('Не верно указан ИНН')
            })

    };

    // buildingInfo
    const buildingNamesCoordinator = {
        "building": [
            {
                "name": "address",
                "label": "Адрес",
                "sort": 9,
                "type": "text",
                "min": 1
            },
            {
                "name": "area",
                "label": "Общая площадь",
                "sort": 2,
                "type": "decimal",
                "unit": "м<sup>2</sup>",
                "min": 0.01
            },
            {
                "name": "cadastral_number",
                "label": "Кадастровый номер",
                "sort": 10,
                "type": "text",
                "min": 1
            },
            {
                "name": "floors",
                "label": "Количество этажей",
                "sort": 4,
                "type": "integer",
                "min": 1
            },
            {
                "name": "height",
                "label": "Высота",
                "sort": 8,
                "type": "decimal",
                "unit": "м",
                "min": 0.01
            },
            {
                "name": "name",
                "label": "Наименование корпуса",
                "sort": 1,
                "type": "text",
                "min": 1
            },
            {
                "name": "underground_floors",
                "label": "Количество подземных этажей",
                "sort": 5,
                "type": "integer",
                "min": 0,
            },
            {
                "name": "urban_plan",
                "label": "Градостроительный план",
                "sort": 11,
                "type": "text",
                "min": 1
            },
            {
                "name": "volume",
                "label": "Объем",
                "sort": 3,
                "type": "decimal",
                "unit": "м<sup>3</sup>",
                "min": 0.01
            },
            {
                "name": "built_up_area",
                "label": "Площадь застройки",
                "sort": 6,
                "type": "decimal",
                "unit": "м<sup>2</sup>",
                "min": 0.01
            },
            {
                "name": "land_area",
                "label": "Площадь участка",
                "sort": 7,
                "type": "decimal",
                "unit": "м<sup>2</sup>",
                "min": 0.01
            }
        ],
        "areas": [
            {
                "name": "commercial_area",
                "label": "Встроенные (коммерческие)",
                "sort": 2,
                "type": "decimal",
                "unit": "м<sup>2</sup>",
            },
            {
                "name": "commercial_number",
                "label": "Встроенные (коммерческие)",
                "sort": 2,
                "type": "integer"
            },
            {
                "name": "common_area",
                "label": "Места общего пользования",
                "sort": 7,
                "type": "decimal",
                "unit": "м<sup>2</sup>",
            },
            {
                "name": "common_number",
                "label": "Места общего пользования",
                "sort": 7,
                "type": "integer"
            },
            {
                "name": "moto_area",
                "label": "Мотоместа",
                "sort": 6,
                "type": "decimal",
                "unit": "м<sup>2</sup>",
            },
            {
                "name": "moto_number",
                "label": "Мотоместа",
                "sort": 6,
                "type": "integer"
            },
            {
                "name": "office_area",
                "label": "Офисы",
                "sort": 4,
                "type": "decimal",
                "unit": "м<sup>2</sup>",
            },
            {
                "name": "office_number",
                "label": "Офисы",
                "sort": 4,
                "type": "integer"
            },
            {
                "name": "other_area",
                "label": "Иные",
                "sort": 9,
                "type": "decimal",
                "unit": "м<sup>2</sup>",
            },
            {
                "name": "other_number",
                "label": "Иные",
                "sort": 9,
                "type": "integer"
            },
            {
                "name": "parking_area",
                "label": "Паркинг",
                "sort": 5,
                "type": "decimal",
                "unit": "м<sup>2</sup>",
            },
            {
                "name": "parking_number",
                "label": "Паркинг",
                "sort": 5,
                "type": "integer"
            },
            {
                "name": "rooms_area",
                "label": "Квартиры",
                "sort": 1,
                "type": "decimal",
                "unit": "м<sup>2</sup>",
            },
            {
                "name": "rooms_number",
                "label": "Квартиры",
                "sort": 1,
                "type": "integer"
            },
            {
                "name": "storehouse_area",
                "label": "Кладовые",
                "sort": 3,
                "type": "decimal",
                "unit": "м<sup>2</sup>",
            },
            {
                "name": "storehouse_number",
                "label": "Кладовые",
                "sort": 3,
                "type": "integer"
            },
            {
                "name": "technical_area",
                "label": "Технические",
                "sort": 8,
                "type": "decimal",
                "unit": "м<sup>2</sup>",
            },
            {
                "name": "technical_number",
                "label": "Технические",
                "sort": 8,
                "type": "integer"
            },
        ],
    };

    const [buildInfoModel, setBuildInfoModel] = useState(Object.keys(buildingNamesCoordinator)[0]);
    const [viewModel, setViewModel] = useState('view');
    const fieldsBuildInfoModel = () => {

        let answer;

        if (buildInfoModel === 'building') answer = buildingNamesCoordinator.building.sort((a, b) => a.sort - b.sort).map(parameter => (
            <Grids cols={2} key={parameter.name} className={styles.rows}>
                <div className={styles.labels}>{parameter.label}</div>
                {viewModel === 'view' ?
                    <div
                        className={`text-center ${(parameter.type === 'decimal' || parameter.type === 'integer') ? styles.number : ''}`}>
                        {props.buildingInfo[parameter.name]}
                        <span className={styles.unit}>{parameter.unit && HTMLReactParser(parameter.unit)}</span>
                    </div>
                    :
                    <div className='flex'>
                        <WorkInput
                            name={parameter.name}
                            type={(parameter.type === 'decimal' || parameter.type === 'integer') ? 'number' : 'text'}
                            defaultValue={props.buildingInfo[parameter.name]}
                            step={parameter.type === 'decimal' ? 0.01 : parameter.type === 'integer' && 1}
                            min={parameter.type === 'decimal' ? 0.01 : parameter.type === 'integer' && 1}
                        />
                        <span className={styles.unit}>{parameter.unit && HTMLReactParser(parameter.unit)}</span>
                    </div>
                }
            </Grids>
        ));
        else if (buildInfoModel === 'areas') {

            const rows = buildingNamesCoordinator.areas.sort((a, b) => a.sort - b.sort).reduce((labels, obj) => {
                if (!labels.includes(obj.label)) {
                    labels.push(obj.label);
                }
                return labels;
            }, []);

            const buildPrint = rows.map(row => {

                const fields = buildingNamesCoordinator[buildInfoModel].filter(f => f.label === row);

                return (
                    <Grids cols={3} key={row} className={styles.rows}>
                        <div className={styles.labels}>{row}</div>
                        {viewModel === 'view' ?
                            <React.Fragment>
                                <div className={styles.number}>{props.buildingInfo[fields[1].name]}</div>
                                <div className={styles.number}>
                                    {props.buildingInfo[fields[0].name]}

                                    {(fields[0].unit && props.buildingInfo[fields[0].name]) &&
                                        <span
                                            className={styles.unit}>{fields[0].unit && HTMLReactParser(fields[0].unit)}</span>}
                                </div>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <WorkInput
                                    name={fields[1].name}
                                    type={(fields[1].type === 'decimal' || fields[1].type === 'integer') ? 'number' : 'text'}
                                    defaultValue={props.buildingInfo[fields[1].name]}
                                    step={1}
                                    min={1}
                                    notrequired={true}
                                />
                                <div className={`flex`}>
                                    <WorkInput
                                        name={fields[0].name}
                                        type={(fields[0].type === 'decimal' || fields[0].type === 'integer') ? 'number' : 'text'}
                                        defaultValue={props.buildingInfo[fields[0].name]}
                                        step={0.01}
                                        min={0.01}
                                        notrequired={true}
                                    />
                                    {(fields[0].unit && props.buildingInfo[fields[0].name]) &&
                                        <span className={styles.unit}
                                              dangerouslySetInnerHTML={{__html: fields[0].unit}}></span>}
                                </div>
                            </React.Fragment>
                        }
                    </Grids>
                )
            })

            const header = (
                <Grids cols={3} key="header" className={styles.titles}>
                    <div>Тип помещения</div>
                    <div>Количество</div>
                    <div>Площадь</div>
                </Grids>
            );

            buildPrint.unshift(header);

            answer = buildPrint;
        }

        return (answer);
    };

    // PlanConfigurator
    const [selectedSection, setSelectedSection] = useState('');
    const [fileName, setFileName] = useState('Обзор');
    const [fileMessage, setFileMessage] = useState(props.message || null);
    const [fileKey, setFileKey] = useState();
    const [existNumbers, setExistNumbers] = useState([]);
    const [addRange, setAddRange] = useState([]);
    const [pdfArray, setPdfArray] = useState([]);
    const [imgActive, setImageActive] = useState();
    const [rangeImageValues, setRangeImageValues] = useState([]);
    const [PDFValues, setPDFValues] = useState([]);
    const [imageRange, setImageRange] = useState();
    const regName = methods.register("name");
    const updateExistNumbers = (startRange) => {
        if (startRange === null) {
            setExistNumbers([]);
            return;
        }

        const startIndex = existNumbers.indexOf(startRange);

        if (startIndex === -1) {
            setExistNumbers([]);
            return;
        }

        let endIndex = startIndex;

        while (
            endIndex < existNumbers.length - 1 &&
            existNumbers[endIndex] + 1 === existNumbers[endIndex + 1]
            ) {
            endIndex++;
        }

        const resultNumbers = existNumbers.slice(startIndex, endIndex + 1);

        setExistNumbers(resultNumbers);
    };
    const handleSectionChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedSection(selectedValue);
        regName.onChange(event); // Обновляем значение поля имени
        methods.setValue("name", selectedValue);
    };
    const [isUpload, setIsUpload] = useState(false);
    const uploadPdf = (e) => {

        setIsUpload(true);

        const file = e.target.files[0];
        const allowedTypes = ['application/pdf'];

        if (file && allowedTypes.includes(file.type)) {

            const PDFJS = require("pdfjs-dist/webpack");

            const fileReader = new FileReader();
            fileReader.onload = async (e) => {
                const arrayBuffer = e.target.result;
                const pdf = await PDFJS.getDocument(new Uint8Array(arrayBuffer)).promise;

                const numPages = pdf.numPages;
                const pageImages = [];

                for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
                    const page = await pdf.getPage(pageNumber);
                    const viewport = page.getViewport({scale: 3.53});
                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    canvas.width = viewport.width;
                    canvas.height = viewport.height;

                    const renderContext = {
                        canvasContext: context,
                        viewport: viewport,
                    };

                    await page.render(renderContext).promise;
                    const image = canvas.toDataURL('image/jpeg');
                    pageImages.push(image);
                }

                setPdfArray(pageImages);
            };

            fileReader.readAsArrayBuffer(file);
        } else setFileMessage("Допустимые формат PDF");
    }
    const setFileNameHandler = (e) => {
        const file = e.target.files[0];
        const allowedTypes = ['image/jpeg', 'image/png'];

        if (file && allowedTypes.includes(file.type)) {
            setFileName(file.name);
            setFileMessage(null);
        } else {
            setFileName('Обзор');
            setFileMessage("Допустимые форматы: JPG и PNG");
            setFileKey(prevKey => prevKey + 1);
        }
    };
    const createRangeHandler = (num, img_id) => {
        let startIndex = PDFValues.indexOf(num);
        if (startIndex === -1) return;

        let updatePDFValues = [num];
        let currentIndex = startIndex;

        while (currentIndex < PDFValues.length - 1 && PDFValues[currentIndex + 1] - PDFValues[currentIndex] <= 1) {
            updatePDFValues.push(PDFValues[currentIndex + 1]);
            currentIndex++;
        }

        setPDFValues(updatePDFValues);

        if (!imageRange) setImageRange(num);
        else setRangeImageValues(prevRangeImageValues => ({...prevRangeImageValues, [img_id]: [imageRange, num]}))
    };
    const saveSchemePdf = () => {
        props.onSubmit(Object.keys(rangeImageValues).map(r => ({
            "min": rangeImageValues[r][0],
            "max": rangeImageValues[r][1],
            "plan": pdfArray[r],
        })));
    };
    useEffect(() => {
        if (props.addRange) {
            const filteredNumbers = [];
            const {min, max, exist} = props.addRange;

            for (let currentNumber = min; currentNumber <= max; currentNumber++) {
                const shouldExclude = exist.some(range => {
                    const [start, end] = range;
                    return currentNumber >= start && currentNumber <= end;
                });

                if (!shouldExclude) {
                    if (currentNumber === 0) continue;

                    filteredNumbers.push(currentNumber);
                }
            }

            if (!exist.flat().includes(max + 1)) filteredNumbers.push("Кровля")

            setExistNumbers(filteredNumbers);
        }
    }, [props.addRange]);
    useEffect(() => {
        if (props.addPdfRange && rangeImageValues) {
            const minLimit = props.addPdfRange.min;
            const maxLimit = props.addPdfRange.max;
            const existingNumbers = new Set(Object.values(rangeImageValues).flatMap(range => [...Array(range[1] - range[0] + 1).keys()].map(i => range[0] + i)));

            // Создайте массив исключений из диапазонов props.addPdfRange.exist
            const exclusionRanges = props.addPdfRange.exist;
            // Создайте функцию, которая проверяет, является ли число частью исключения
            const isExcluded = (num) => {
                for (const [start, end] of exclusionRanges) {
                    if (num >= start && num <= end) {
                        return true;
                    }
                }
                return false;
            };

            const newPDFValues = Array.from({length: maxLimit - minLimit + 1}, (_, index) => minLimit + index)
                .filter(num => !existingNumbers.has(num) && !isExcluded(num));

            if (!exclusionRanges.flat().includes(maxLimit + 1)) newPDFValues.push("Кровля")

            setImageActive();
            setImageRange();
            setPDFValues(newPDFValues);
        }
    }, [rangeImageValues, props.addPdfRange]);

    // ProductionCalendar
    const calendar = {
        "reason": ["Отпуск", "Болезнь", "Форс Мажор", "Иное"]
    }
    const [is_workday, setIs_workday] = useState();
    const [userReason, setUserReason] = useState();
    const [workHours, setWorkHours] = useState();
    const [otherReason, setOtherReason] = useState();

    // tenders
    const [lotDocs, setLotDocs] = useState([]);
    const randomId = () => {
        return Math.random().toString(36).substring(2, 10);
    }

    // subcompanyDocumentsUpload
    // const [fieldList, setFieldList] = useState(false)
    // const [addedFieldList, setAddedFieldList] = useState([]);
    // const [newDocumentData, setNewDocumentData] = useState({});
    // const fieldsTemplate = props.subcompanyDocumentsUpload.docFields.fields || null;
    // const addSubcompanyDocumentHandler = () => {
    //     const newDocument = (
    //         <div
    //             key={addedFieldList.length + 1}
    //             className={dashStyles.card}
    //         >
    //             <div className={dashStyles.label}>doc.label</div>
    //             <div className={dashStyles.area}>
    //                 {fieldsTemplate && fieldsTemplate.map(field => (
    //                     <RegistrationInput
    //                         key={field.name}
    //                         type={field.type}
    //                         name={field.name}
    //                         label={field.label}
    //                         options={field.options}
    //                         defaultValue={field.defaultValue}
    //                         min={field.min}
    //                         max={field.max}
    //                         step={field.step}
    //                     />
    //                 ))}
    //             </div>
    //         </div>
    //     );
    //
    //     setAddedFieldList((prevDocuments) => [...prevDocuments, newDocument]);
    //     setNewDocumentData({});
    // };
    // useEffect(() => {
    //
    //     if (props.subcompanyDocumentsUpload) {
    //
    //         const updateFieldList = props.subcompanyDocumentsUpload.docFields.template.map(doc => {
    //
    //             return (
    //                 <div
    //                     key={doc.name}
    //                     className={dashStyles.card}
    //                 >
    //                     <div className={dashStyles.label}>{doc.label}</div>
    //                     <div className={dashStyles.area}>
    //                         {fieldsTemplate && fieldsTemplate.map(field =>
    //                             <RegistrationInput
    //                                 key={field.name}
    //                                 type={field.type}
    //                                 name={field.name}
    //                                 label={field.label}
    //                                 options={field.options}
    //                                 defaultValue={field.defaultValue}
    //                                 min={field.min}
    //                                 max={field.max}
    //                                 step={field.step}
    //                             />
    //                         )}
    //                     </div>
    //                 </div>
    //             );
    //         });
    //
    //         setFieldList(updateFieldList);
    //     }
    // },[props.subcompanyDocumentsUpload,fieldsTemplate]);

    const handleAlignment = (event, newAlignment) => {
        if (newAlignment === "Иное") setOtherReason(true);
        else setOtherReason(false)
        setUserReason(newAlignment);
    };
    return (<React.Fragment>
        {ReactDom.createPortal(
            <Backdrop onCloseModal={props.onCloseModal}/>,
            document.getElementById("backdrop")
        )}
        {ReactDom.createPortal(
            <div className={`${styles.modal} ${props.wmax ? styles['modal-w-max'] : ''}`}>
                <header className={styles.header}>
                    <h2>
                        {
                            props.buildingInfo ?
                                `Информация по корпусу "${props.buildingInfo.name}"`
                                :
                                props.representatives ?
                                    `Добавить лицо`
                                    :
                                    props.edit_representatives ?
                                        `Редактировать лицо`
                                        :
                                        props.title
                        }
                    </h2>
                    <button onClick={props.onCloseModal} type="button">
                        <Btn color="white" icononly={true}><CloseModal/></Btn>
                    </button>
                </header>
                <div className={styles.content}>
                    {props.form && <FormProvider {...methods} >
                        <form onSubmit={methods.handleSubmit(onSubmitHandler)}>
                            {props.tariffHeader && <p>{props.tariffHeader}</p>}
                            {props.grid ?
                                <Grids cols={props.grid}>{formFields(props.form)}</Grids>
                                :
                                <div>{formFields(props.form)}</div>
                            }

                            {props.form.addTender && (
                                <React.Fragment>
                                    <Btn method={`view`} color={`link`}>
                                        <Link to={'/uploads/docs/contractors_estimate.xlsx'} target="_blank">Шаблон
                                            сметы</Link>
                                    </Btn>
                                    {lotDocs.length > 0 &&
                                        lotDocs.map((doc) => (
                                            <div key={`doc-${randomId()}`} className={`flex`}>
                                                {doc.content} {}
                                                <div className={`ml-2 pt-4`}>
                                                    <Btn
                                                        method={`remove`}
                                                        icononly={true}
                                                        onClick={() => {
                                                            const updatedLotDocs = lotDocs.filter((item) => item.id !== doc.id);
                                                            setLotDocs(updatedLotDocs);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    <Btn
                                        method={`add`}
                                        color={`button`}
                                        className={`mx-auto my-2`}
                                        onClick={() => {
                                            const newDoc = (
                                                <Grids cols={`auto`} className={`place-content-center`}
                                                       id={`doc-${randomId()}`} key={`doc-${randomId()}`}>
                                                    <RegistrationInput
                                                        type={`text`}
                                                        name={`doc-name-${randomId()}`}
                                                        label={`Наименование документа`}
                                                    />
                                                    <RegistrationInput
                                                        type={`file`}
                                                        name={`doc-file-${randomId()}`}
                                                        label={`Шаблон документа`}
                                                    />
                                                </Grids>
                                            );
                                            setLotDocs([...lotDocs, {id: `doc-${randomId()}`, content: newDoc}]);
                                        }}
                                    >
                                        Добавить документ для претендентов
                                    </Btn>
                                </React.Fragment>
                            )}
                            <div className={styles.submit}>
                                <Button type="submit">{props.form.submit}</Button>
                            </div>
                        </form>
                    </FormProvider>}
                    {props.buildingInfo && <FormProvider {...methods} >
                        <form onSubmit={methods.handleSubmit(props.onSubmit)}>
                            {fieldsBuildInfoModel()}
                            {viewModel === 'view' ?
                                <Grids cols={props.editable ? 2 : 1}>
                                    <Button
                                        color="button"
                                        onClick={() => setBuildInfoModel(Object.keys(buildingNamesCoordinator).filter(n => n !== buildInfoModel)[0])}
                                    >
                                        Информация по {buildInfoModel === "building" ? "помещениям" : "корпусу"}
                                    </Button>
                                    {props.editable && <Button color="button"
                                                               onClick={() => setViewModel('edit')}>Редактировать</Button>}
                                </Grids>
                                :
                                <div className={`mt-4 mx-auto w-max`}>
                                    <Button color="button" type="submit">Сохранить</Button>
                                </div>
                            }
                        </form>
                    </FormProvider>}
                    {props.addBuilding && <FormProvider {...methods} >
                        <form onSubmit={methods.handleSubmit(props.onSubmit)}>
                            {buildingNamesCoordinator.building.sort((a, b) => a.sort - b.sort).map(field => (!field.fields) &&
                                <Grids key={field.name} cols={2} className={styles.rows}>
                                    <div className={styles.labels}>{field.label}</div>
                                    <WorkInput
                                        type={(field.type === 'decimal' || field.type === 'integer') ? 'number' : 'text'}
                                        name={field.name}
                                        min={field.min}
                                        step={field.min}
                                        unit={field.unit}
                                        defaultValue={field.defaultValue && field.defaultValue}
                                    />
                                </Grids>
                            )}
                            <Button className={`mx-auto block`} btn={true} type="submit">Добавить корпус</Button>
                        </form>
                    </FormProvider>}
                    {props.addSection && <FormProvider {...methods} >
                        <form onSubmit={methods.handleSubmit(props.onSubmit)}>
                            <div className={inputs.field}>
                                <select
                                    value={selectedSection ? selectedSection : ''}
                                    onChange={handleSectionChange}
                                    name="name"
                                    ref={regName.ref}
                                >
                                    {props.addSection.map((name) => (
                                        <option key={name} value={name}>{name}</option>
                                    ))}
                                </select>
                            </div>
                            {selectedSection === 'Иная' && (
                                <div className={inputs.field}>
                                    <label htmlFor="other_name">Укажите наименование раздела</label>
                                    <input name="other_name" className="mt-2"
                                           type="text" {...methods.register("other_name")} />
                                </div>
                            )}
                            <div className="mx-auto w-max">
                                <Button type="submit">Добавить</Button>
                            </div>
                        </form>
                    </FormProvider>}
                    {props.addRange && <form className={`max-w-[80vw]`} onSubmit={props.onSubmit}>
                        {(existNumbers && addRange.length !== 2) && <React.Fragment>
                            <div>{addRange.length === 0 ? 'Выберите начало диапазона' : 'Выберите конец диапазона'}</div>
                            <div className={`flex flex-wrap`}>{existNumbers.map(floor =>
                                <Btn
                                    key={floor}
                                    onClick={() => {
                                        if (floor === 'Кровля') {
                                            setAddRange([existNumbers.at(-2) + 1, existNumbers.at(-2) + 1]);
                                            return;
                                        }

                                        if (addRange.length === 0) {
                                            updateExistNumbers(floor);
                                            setAddRange([floor]);
                                        } else setAddRange([...addRange, floor]);
                                    }}
                                    className="m-0.5 bg-pult/20"
                                >{floor}</Btn>
                            )}</div>
                        </React.Fragment>}
                        {fileMessage && <div className={`${inputs['invalid-label']} text-center`}>{fileMessage}</div>}
                        {addRange.length === 2 && <React.Fragment>
                            <div className={`flex`}>
                                <span className={`mr-2 my-auto`}>{addRange[0] > existNumbers.at(-2)
                                    ? "Выбрана кровля"
                                    : `Диапазон от ${addRange[0]} до ${addRange[1]}`
                                }</span>
                                <label htmlFor="file-upload"
                                       className={`${buttonsStyles.button} ${fileName === "Обзор" ? 'col-span-2' : ''}`}>
                                    {fileName}
                                    <input
                                        key={fileKey}
                                        name="plan"
                                        id="file-upload"
                                        type="file"
                                        className="hidden"
                                        onChange={setFileNameHandler}
                                    />
                                </label>
                            </div>
                            {fileName !== "Обзор" && <div className={`w-max mx-auto mt-2`}>
                                <input type="hidden" name="min"
                                       value={addRange[0] > existNumbers.at(-2) ? existNumbers.at(-2) + 1 : addRange[0]}/>
                                <input type="hidden" name="max"
                                       value={addRange[1] > existNumbers.at(-2) ? existNumbers.at(-2) + 1 : addRange[1]}/>
                                <Button
                                    type={`submit`}>{(props.addRange.min && props.addRange.max) ? 'Добавить' : 'Изменить'}</Button>
                            </div>}
                        </React.Fragment>}
                    </form>}
                    {props.addPdfRange && <React.Fragment>
                        {fileMessage && <div className={`${inputs['invalid-label']} text-center`}>{fileMessage}</div>}
                        {(!pdfArray || pdfArray.length === 0) ?
                            !isUpload ?
                                <label htmlFor="file-upload">
                                    <Btn method={`file`} file={`pdf`} color={`button`} center>
                                        {fileName}
                                    </Btn>
                                    <input
                                        key={fileKey}
                                        name="plan"
                                        id="file-upload"
                                        type="file"
                                        className="hidden"
                                        onChange={uploadPdf}
                                    />
                                </label> :
                                <Warnings info>Дождитесь загрузки PDF</Warnings>
                            :
                            <React.Fragment>
                                <h2>Выберите изображения для диапазонов</h2>
                                {Object.keys(rangeImageValues).length > 0 &&
                                    <Btn method={`save`} color={`button`} onClick={saveSchemePdf}>Сохранить схему</Btn>}
                                {(pdfArray) &&
                                    <div className={`max-h-[70vh] overflow-y-auto`}>
                                        <Grids cols={3}>{pdfArray.map((p, id) => (
                                            <div key={id}>
                                                <div
                                                    className={`px-2 rounded-md mb-2 overflow-auto ${imgActive === id ? 'border-pult border-2' : Object.keys(rangeImageValues).includes(`${id}`) ? '' : 'border-pult/20 border'}`}>
                                                    {rangeImageValues[id] && <div className={`flex`}>
                                                        <div className={`grow my-auto`}>Диапазон
                                                            от {rangeImageValues[id][0]} до {rangeImageValues[id][1]} этажей
                                                        </div>
                                                        <Btn
                                                            method={`cancel`}
                                                            icononly={true}
                                                            title={`Сбросить диапазон`}
                                                            onClick={() => {
                                                                const updatedRangeImageValues = {...rangeImageValues};
                                                                delete updatedRangeImageValues[id];
                                                                setRangeImageValues(updatedRangeImageValues);
                                                            }}
                                                        />
                                                    </div>}
                                                    <img
                                                        className={Object.keys(rangeImageValues).includes(`${id}`) ? `` : `cursor-pointer`}
                                                        src={p}
                                                        alt={``}
                                                        onClick={() => {
                                                            if (!Object.keys(rangeImageValues).includes(`${id}`)) {
                                                                setImageActive(id);
                                                                setImageRange();
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                {imgActive === id && <React.Fragment>
                                                    <div>{!imageRange ? 'Выберите начало диапазона' : 'Выберите конец диапазона'}</div>
                                                    <div
                                                        className={`flex flex-wrap`}>{PDFValues.length > 0 && PDFValues.filter(floor => floor !== 0).map(floor =>
                                                        <Btn
                                                            key={floor}
                                                            onClick={() => {
                                                                if (floor === "Кровля") setRangeImageValues({[id]: [props.addPdfRange.max + 1, props.addPdfRange.max + 1]})
                                                                createRangeHandler(floor, id)
                                                            }}
                                                            className="m-0.5 bg-pult/20"
                                                        >
                                                            {floor}
                                                        </Btn>
                                                    )}</div>
                                                </React.Fragment>}
                                            </div>
                                        ))}</Grids>
                                    </div>
                                }
                            </React.Fragment>
                        }
                    </React.Fragment>}
                    {props.editRangeImage && <form onSubmit={props.onSubmit}>
                        <div className={`flex`}>
                            <label htmlFor="file-upload"
                                   className={`${buttonsStyles.button} ${fileName === "Обзор" ? 'col-span-2' : ''}`}>
                                {fileName}
                                <input
                                    key={fileKey}
                                    name="plan"
                                    id="file-upload"
                                    type="file"
                                    className="hidden"
                                    onChange={setFileNameHandler}
                                />
                            </label>
                        </div>
                        {fileName !== "Обзор" && <div className={`w-max mx-auto mt-2`}>
                            <Button type={`submit`}>Загрузить изображение</Button>
                        </div>}
                    </form>}
                    {props.calendar && <React.Fragment>
                        <div className={`flex flex-wrap w-max mx-auto`}>{props.calendar.map(d =>
                            <div key={d} className={styles.items}>{new Date(d).toLocaleString().split(',')[0]}</div>
                        )}</div>
                        {!is_workday ? <React.Fragment>
                                <div>Перевести в</div>
                                <Grids className='flex justify-center w-full' cols="auto">
                                    <Button onClick={() => {
                                        setIs_workday(1);
                                        setUserReason(null);
                                    }}>Рабочие дни</Button>
                                    <Button onClick={() => {
                                        setIs_workday(2);
                                        setUserReason(null);
                                        setWorkHours(0);
                                    }}>Выходные дни</Button>
                                </Grids>
                            </React.Fragment> :
                            <div className={`w-max mx-auto`}><Button onClick={() => setIs_workday(null)}>Назад</Button>
                            </div>
                        }
                        {is_workday !== undefined &&
                            <React.Fragment>
                                {is_workday === 1 && <React.Fragment>
                                    <label>Укажите причину</label>
                                    <textarea name="reason" className={inputs.input}
                                              onChange={(e) => setUserReason(e.target.value)}/>
                                    <Grids cols={2}>
                                        <label>Укажите количество рабочих часов</label>
                                        <input
                                            type="number"
                                            min={1}
                                            max={23}
                                            step={1}
                                            name="hours"
                                            value={workHours}
                                            className={inputs.input}
                                            onChange={(e) => {
                                                const newValue = parseInt(e.target.value, 10);
                                                if (!isNaN(newValue)) setWorkHours(newValue > 23 ? 23 : newValue);
                                            }}
                                        />
                                    </Grids>
                                </React.Fragment>}
                                {is_workday === 2 && <React.Fragment>
                                    <div>Укажите причину</div>

                                    <ToggleButtonGroup
                                        value={userReason}
                                        exclusive
                                        onChange={handleAlignment}
                                        aria-label="reason"
                                    >
                                        {calendar.reason.map(r =>
                                            <ToggleButton
                                                key={r}
                                                value={r}
                                            >
                                                {r}
                                            </ToggleButton>
                                        )}
                                    </ToggleButtonGroup>

                                    {otherReason && <div className={`mt-2`}><input className={inputs.input} type="text"
                                                                                   onChange={(e) => setUserReason(e.target.value)}/>
                                    </div>}
                                    <input type="text" name="reason" className={`hidden`} value={userReason || ''}
                                           onChange={(e) => setUserReason(e.target.value)}/>
                                </React.Fragment>}
                                {userReason &&
                                    <div className={`mx-auto w-max mt-2`}><Button onClick={() => props.onSubmit({
                                        is_workday: is_workday === 1,
                                        reason: userReason,
                                        hours: is_workday === 1 ? workHours : 0,
                                    })}>Изменить даты</Button></div>}
                            </React.Fragment>}
                    </React.Fragment>}
                    {props.representatives && (
                        !ownership ?
                            (<Grids cols={2}>
                                <div className={`col-span-2`}>{props.title}</div>
                                <Button color="button" onClick={() => {
                                    setOwnership('2');
                                    methods.reset();
                                }}>Юридическое лицо</Button>
                                <Button color="button" onClick={() => {
                                    setOwnership('3');
                                    methods.reset();
                                }}>Физическое лицо</Button>
                            </Grids>)
                            : (ownership !== '3' && !innData) ?
                                (<FormProvider {...methods}>
                                    <div>{props.title}</div>
                                    <form onSubmit={methods.handleSubmit(checkInnHandler)}>
                                        {innError ? <div className={`text-red-700`}>{innError}</div> : ''}
                                        <RegistrationInput
                                            name="inn_check"
                                            label="Укажите ИНН Юридического лица или ИП"
                                            length={0}
                                            type="number"
                                        />
                                        <div className={`w-max mx-auto`}><Button type="submit">Запросить данные</Button>
                                        </div>
                                    </form>
                                </FormProvider>)
                                :
                                <FormProvider {...methods}>
                                    <div>{props.title}</div>
                                    <form onSubmit={methods.handleSubmit(props.onSubmit)}>
                                        {methods.setValue("type", ownership)}
                                        <RegistrationInput
                                            name="name"
                                            label={innData ? 'Наименование компании' : 'Ф.И.О.'}
                                            type="text"
                                            length={0}
                                            disabled={!!innData}
                                            defaultValue={ownership === '2' ? innData.name.short_with_opf : innData ? innData.name.short_with_opf : ''}
                                        />
                                        {/*{ownership === '1' && <RegistrationInput*/}
                                        {/*    name="registration_certificate"*/}
                                        {/*    label="Свидетельство о регистрации (номер, дата)"*/}
                                        {/*    type="text"*/}
                                        {/*    length={0}*/}
                                        {/*/>}*/}
                                        {ownership !== '2' && <RegistrationInput
                                            name="passport_number"
                                            label="Серия номер паспорта "
                                            type="number"
                                            length={10}
                                        />}
                                        <RegistrationInput
                                            name="legal_address"
                                            label={ownership !== '3' ? "Юридический адрес" : "Адрес регистрации"}
                                            type="text"
                                            length={0}
                                            disabled={innData && innData.type === "LEGAL"}
                                            defaultValue={innData && innData.address.unrestricted_value}
                                        />
                                        {ownership !== '3' && <RegistrationInput
                                            name="postal_address"
                                            label="Почтовый адрес"
                                            type="text"
                                            length={0}
                                            defaultValue={innData && innData.address.unrestricted_value}
                                        />}
                                        {ownership === '2' && <RegistrationInput
                                            name="inn"
                                            label="ИНН"
                                            type="number"
                                            length={0}
                                            disabled={true}
                                            defaultValue={innData && innData.inn}
                                        />}
                                        {ownership === '2' && <RegistrationInput
                                            name="ogrn"
                                            label="ОГРН"
                                            type="number"
                                            length={0}
                                            disabled={true}
                                            defaultValue={innData && innData.ogrn}
                                        />}
                                        {ownership !== '2' && <React.Fragment>
                                            <InputFile
                                                name="scanned_passport_page_2"
                                                format="image"
                                                label="Страница паспорта 2"
                                            />
                                            <InputFile
                                                name="scanned_passport_page_3"
                                                format="image"
                                                label="Страница паспорта 3"
                                            />
                                            <InputFile
                                                name="scanned_passport_page_4"
                                                format="image"
                                                label="Страница паспорта 4"
                                            />
                                            <InputFile
                                                name="scanned_passport_page_5"
                                                format="image"
                                                label="Страница паспорта 5"
                                            />
                                        </React.Fragment>}
                                        <div className={`w-max mx-auto`}><Button type="submit">Добавить лицо</Button></div>
                                    </form>
                                </FormProvider>
                    )}
                    {props.edit_representatives && <FormProvider {...methods}>
                        <div>{props.title}</div>
                        <form onSubmit={methods.handleSubmit(props.onSubmit)}>
                            <RegistrationInput
                                name="name"
                                label={props.edit_representatives.type !== 3 ? 'Наименование компании' : 'Ф.И.О.'}
                                type="text"
                                length={0}
                                disabled={props.edit_representatives.type !== 3}
                                defaultValue={props.edit_representatives.name}
                            />
                            {props.edit_representatives.type === 3 && <RegistrationInput
                                name="passport_number"
                                label="Серия номер паспорта "
                                type="number"
                                length={10}
                                defaultValue={props.edit_representatives.passport_number}
                            />}
                            <RegistrationInput
                                name="legal_address"
                                label={props.edit_representatives.type !== 3 ? "Юридический адрес" : "Адрес регистрации"}
                                type="text"
                                length={0}
                                disabled={props.edit_representatives.type !== 3}
                                defaultValue={props.edit_representatives.legal_address}
                            />
                            {props.edit_representatives.type !== 3 && <RegistrationInput
                                name="postal_address"
                                label="Почтовый адрес"
                                type="text"
                                length={0}
                                defaultValue={props.edit_representatives.postal_address}
                            />}
                            {props.edit_representatives.type !== 3 && <RegistrationInput
                                name="inn"
                                label="ИНН"
                                type="number"
                                length={0}
                                disabled={true}
                                defaultValue={props.edit_representatives.inn}
                            />}
                            {props.edit_representatives.type === 1 && <RegistrationInput
                                name="ogrn"
                                label="ОГРН"
                                type="number"
                                length={0}
                                disabled={true}
                                defaultValue={props.edit_representatives.ogrn}
                            />}
                            {props.edit_representatives.type === 3 && <React.Fragment>
                                <InputFile
                                    name="scanned_passport_page_2"
                                    format="image"
                                    label="Страница паспорта 2"
                                    fileUrl={`${userSettings.fileServer}${props.edit_representatives.scanned_passport_page_2}`}
                                />
                                <InputFile
                                    name="scanned_passport_page_3"
                                    format="image"
                                    label="Страница паспорта 3"
                                    fileUrl={`${userSettings.fileServer}${props.edit_representatives.scanned_passport_page_3}`}
                                />
                                <InputFile
                                    name="scanned_passport_page_4"
                                    format="image"
                                    label="Страница паспорта 4"
                                    fileUrl={`${userSettings.fileServer}${props.edit_representatives.scanned_passport_page_4}`}
                                />
                                <InputFile
                                    name="scanned_passport_page_5"
                                    format="image"
                                    label="Страница паспорта 5"
                                    fileUrl={`${userSettings.fileServer}${props.edit_representatives.scanned_passport_page_5}`}
                                />
                            </React.Fragment>}
                            <Grids cols={2}>
                                <Button type="submit">Редактировать лицо</Button>
                                <Button onClick={props.addNewPerson}>Заменить новым лицом</Button>
                            </Grids>
                        </form>
                    </FormProvider>}
                    {props.subcompanyRegisterNewDocumentsListUpload && <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(props.onSubmit)}>
                            <Grids
                                cols={2}>{props.subcompanyRegisterNewDocumentsListUpload.documentsList.map((document, id) => {

                                return <div key={id} className={dashStyles.card}>
                                    <div className={dashStyles.label}>{document[0].name.split('_')[0]}</div>
                                    <div className={dashStyles.area}>
                                        {document.map(field => <RegistrationInput
                                            key={field.name}
                                            type={field.type}
                                            name={field.name}
                                            label={field.label}
                                            options={field.options}
                                            defaultValue={field.defaultValue}
                                            min={field.min}
                                            max={field.max}
                                            step={field.step}
                                        />)}
                                    </div>
                                </div>
                            })}</Grids>
                            <Button type="submit"
                                    className={`w-full`}>{props.subcompanyRegisterNewDocumentsListUpload.submit}</Button>
                        </form>
                    </FormProvider>}

                    {props.content && <div>{props.content}</div>}
                </div>
            </div>,
            document.getElementById("modal")
        )}
    </React.Fragment>);
};

export default Popup;