import styles from "./header.module.css";
import Alink from "../UI/Buttons/Alink";
import React from "react";
import {useCookies} from "react-cookie";
import {Link} from "react-router-dom";

const Header = (props) => {
    const removeCookie = useCookies(['access'])[2];

    const leaveAccount = () => {
        removeCookie('access',{path:'/'});
        removeCookie('access',{path:'/work'});
    }

    return (
        <header className={styles.header}>
            <div className={styles.logo}>
                <Alink href="/" title="На главную">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2}
                         stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"/>
                    </svg>
                    <span>На главную</span>
                </Alink>
            </div>
            <React.Fragment>
                {props.isLogin ?
                    <div className="flex">
                        <Link
                            className="w-max rounded-md py-1 px-2 block h-max my-auto mx-2 text-white cursor-pointer transition-all duration-300 ease-in-out bg-green-600 hover:bg-green-800"
                            to="/work"
                        >
                            Войти в систему
                        </Link>
                        <div
                            className="w-max rounded-md py-1 px-2 block h-max my-auto mx-2 text-white cursor-pointer transition-all duration-300 ease-in-out bg-red-600 hover:bg-red-800"
                            onClick={leaveAccount}
                        >
                            Выйти
                        </div>
                    </div>
                    :
                    <Alink href="/authorization">Авторизация</Alink>
                }
            </React.Fragment>

            <Alink href="/registration-company">Регистрация</Alink>
            <Alink href="/tenders">Тендеры</Alink>
        </header>
    );
}

export default Header;