import {Link, useLocation} from "react-router-dom";
import axios from "axios";
import {useEffect, useState} from "react";
import PageSection from "../../UI/Sections/PageSection";
import Section from "../../UI/Sections/Section";
import {FormProvider, useForm} from "react-hook-form";
import PasswordInputs from "../../UI/Inputs/PasswordInputs";
import Button from "../../UI/Buttons/Button";
import Btn from "../../UI/Buttons/Btn";

const Invite = (props) => {

    const uuid = useLocation().pathname.replace('/invite/', '');
    const [password_uuid, setPassword_uuid] = useState();
    

    useEffect(() => {
        axios.get(`${props.erpApiUrl}/invites/check_invite/${uuid}`)
            .then(response => {
            if (!response.data.errors) {
                setPassword_uuid(response.data.password_uuid);
            }
        }).catch(error => {
                console.error(error);
            });
    }, [props.erpApiUrl,uuid]);

    const methods = useForm();
    const createPasswordHandler = (data) => {

        axios.post(`${props.erpApiUrl}/create_password_confirm/${password_uuid}`,{
            "password": data.password
        }).then(response => {
            if (response.data.status) {
                window.location.replace('/authorization');
            }
        }).catch(error => {
            console.error(error);
        });
    };

    if (password_uuid) {
        return (
            <PageSection>
                <Section>
                    <FormProvider {...methods} >
                        <form onSubmit={methods.handleSubmit(createPasswordHandler)}>
                            <PasswordInputs name="password" />
                            <PasswordInputs name="repeatPassword" check={methods.watch("password")} />
                            <Button type="submit">Создать пароль</Button>
                        </form>
                    </FormProvider>
                </Section>
            </PageSection>
        );
    } else {
        return (
            <PageSection>
                <Section>
                    <div>Приглашение не действительно</div>
                    <Btn color="button" className="mx-auto"><Link to={'/'}>На главную</Link></Btn>
                </Section>
            </PageSection>
        );
    }
}

export default Invite;