import React, {useEffect, useMemo, useState} from "react";
import KS2 from "../../../UI/PdfDocuments/KS-2";
import {pdf, PDFDownloadLink} from "@react-pdf/renderer";
import useFetchedInfo from "./hooks/useFetchedInfo";
import {Box, Button, Typography} from "@mui/material";
import Select from "../../../UI/Select/Select";

const GenerateKS2PDF = ({setKs2}) => {
    const subcompany_id = +window.localStorage.getItem("company");
    const tender_id = +window.localStorage.getItem("tender");

    const {fetchedInfo, isLoading} = useFetchedInfo(subcompany_id, tender_id);

    const [selectedRepresentativeId, setSelectedRepresentativeId] = useState(null);

    useEffect(() => {
        if (!fetchedInfo) return;

        pdf(<KS2 fetchedInfo={fetchedInfo}/>)
            .toBlob()
            .then((blob) => setKs2(new File([blob], "KS-2.pdf")));
    }, [fetchedInfo]);

    const representatives = useMemo(() => {
        if (!fetchedInfo) return []
        return fetchedInfo
            .personsList
            .find((p) => p.person === 1)
            ?.representatives
    }, [fetchedInfo]);

    return (
        <Box>
            <Typography variant="h1">КС-2:</Typography>
            {!isLoading && fetchedInfo && (
                <Box className="space-y-2 m-3 p-3 bg-[#e4e4e7] rounded-lg">
                    <Box className={'flex gap-8 items-center'}>
                        <Select
                            placeholder={'Выберите подписанта'}
                            options={representatives}
                            handleOnChange={({target: {value}}) => setSelectedRepresentativeId(value)}
                        />
                        <PDFDownloadLink
                            document={<KS2 fetchedInfo={{...fetchedInfo, representativeId: selectedRepresentativeId}}/>}
                            fileName={"KS-2"}
                        >
                            <Button variant="contained" disabled={!selectedRepresentativeId}>
                                Готово к загрузке!
                            </Button>
                        </PDFDownloadLink>

                        {/*<PDFViewer style={{width: '100%', height: '100%'}}>*/}
                        {/*    <KS2*/}
                        {/*        fetchedInfo={fetchedInfo}*/}
                        {/*        customerState={customerState}*/}
                        {/*    />*/}
                        {/*</PDFViewer>*/}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default GenerateKS2PDF;
