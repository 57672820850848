import axios from "axios";

export const getSubcompanies = async (userSettings, company_id, project_id) => {
  try {
    const allSubcompanies = await axios.get(
      `${userSettings.api}/company/${company_id}/subcompanies`,
      userSettings.headers.get,
    );

    const getCompaniesInfo = [];
    for (const company of allSubcompanies.data.data) {
      try {
        const response = await axios.get(
          `${userSettings.api}/company/${company.subcompany}/`,
          userSettings.headers.get,
        );

        getCompaniesInfo.push(response.data);
      } catch (error) {
        console.error(
          `Ошибка при получении информации о компании ${company.subcompany}:`,
          error.response,
        );
        getCompaniesInfo.push(null);
      }
    }

    const allTenders = await axios.get(
      `${userSettings.api}/tenders/companies/${company_id}/`,
      userSettings.headers.get,
    );
    const tenders = allTenders.data.filter((t) => t.project.id === +project_id);

    const subcompanies = allSubcompanies.data.data
      .map((subcompany) => {
        const tender = tenders.find(
          (tender) => tender.id === subcompany.tender,
        );
        const info = getCompaniesInfo.find(
          (c) => c.id === subcompany.subcompany,
        );

        return (
          tender && {
            ...subcompany,
            tender,
            info,
          }
        );
      })
      .filter((s) => s);

    if (tenders?.length > 0 && subcompanies?.length > 0) {
      return subcompanies;
    } else {
      return [];
    }
  } catch (error) {
    console.error("\nОшибка в getSubcompanies:", error.response);
  }
};

export const getTender = async (userSettings, subcompany_id) => {
  try {
    const allTenders = await axios.get(
      `${userSettings.api}/subcompanies/${subcompany_id}/won-tender-lots`,
      userSettings.headers.get,
    );
    return allTenders.data;
  } catch (error) {
    console.error("\nОшибка в getTender:", error.response);
  }
};
