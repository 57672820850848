import React, {useContext, useEffect, useState} from "react";
import UserContext from "../../../store/user/user-context";
import RouteScheme from "./RouteScheme.json";
import ControlItem from "./ControlItem";
import axios from "axios";
import {getCompany} from "../../UI/GetInfo/getCompany";
import {getOrders} from "../../UI/GetInfo/getOrders";
import {getTariffsRequests} from "../../UI/GetInfo/getTariffsRequests";
import {getQualityDocsList} from "../../SendRequests/QualityDocumentsData";
import {getSubcomanyList} from "../../SendRequests/workJournalData";
import {ReactComponent as LogoutIcon} from "../../UI/Icons/ControlPanel/logout.svg";
import Btn from "../../UI/Buttons/Btn";
import {useCookies} from "react-cookie";
import {useGetQualityDocsQuery} from "../../SendRequests/api/QualityDocs/queries/useGetQualityDocsQuery";

const ProjectControl = (props) => {
    const userSettings = useContext(UserContext);
    const currentRole = +window.localStorage.getItem("role");
    const company_id = +window.localStorage.getItem("company");
    const tender_id = +window.localStorage.getItem("tender");
    const project_id = +window.localStorage.getItem("project");

    const currentRoleSettings = RouteScheme.find(
        (route) => route.role === currentRole,
    );

    const [companyContracts, setCompanyContracts] = useState(null);
    const [companiesInfo, setCompaniesInfo] = useState(null);
    const [subcontractorsWithoutSsr, setSubcontractorsWithoutSsr] =
        useState(null);

    const [isLockedWorkJournal, setIsLockedWorkJournal] = useState(false);

    const [calledOrders, setCalledOrders] = useState(null);

    const [tariffRequests, setTariffRequests] = useState(null);
    const [showOnlyTariffTab, setShowOnlyTariffTab] = useState(false);

    const [currentContract, setCurrentContract] = useState(null);
    const [approvedQualityDocs, setApprovedQualityDocs] = useState(null);

    const [isQualityDocsEnabled, setIsQualityDocsEnabled] = useState(false);
    const {data: qualityDocs} = useGetQualityDocsQuery(userSettings, company_id, project_id, currentRole, isQualityDocsEnabled)

    useEffect(() => {
        const registerContractInfo = async () => {
            try {
                const {data} = await axios.get(
                    `${userSettings.api}/company/${company_id}/subcompanies-to-accept`,
                    userSettings.headers.get,
                );

                setCompanyContracts(data.length > 0 ? data : []);
            } catch (error) {
                console.error("Error fetching contracts:", error);
            }
        };
        const subcontractorsWithoutSsr = async () => {
            try {
                // const subcompaniesResponse = await axios.get(
                //     `${userSettings.api}/company/${company_id}/subcompanies`,
                //     userSettings.headers.get
                // );
                const allTenders = await axios.get(
                    `${userSettings.api}/tenders/companies/${company_id}/`,
                    userSettings.headers.get,
                );
                const tenderIds = allTenders.data
                    .filter((tender) => tender.project.id === project_id)
                    .map((tender) => tender.id);

                const subcontractorsWithoutSsr = tenderIds.map(async (tender_id) => {
                    const response = await axios.get(
                        `${userSettings.api}/work-journal/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/subcontractors-without-ssr`,
                        userSettings.headers.get,
                    );

                    if (response?.data.length > 0)
                        return {
                            subcompany: response.data[0],
                            tender: allTenders.data.find((t) => t.id === tender_id).name,
                            tender_id,
                        };
                });
                const allSubcontractorsWithoutSsr = [
                    ...(await Promise.all(subcontractorsWithoutSsr)),
                ].filter((a) => a);
                if (allSubcontractorsWithoutSsr.length > 0)
                    setSubcontractorsWithoutSsr(allSubcontractorsWithoutSsr);
            } catch (error) {
                console.error("Error fetching subcontractors Without Ssr:", error);
            }
        };
        const unapprovedWorkLog = async () => {
            try {
                const wonTenderLots = await axios.get(
                    `${userSettings.api}/subcompanies/${company_id}/won-tender-lots`,
                    userSettings.headers.get,
                );

                const currentTender = wonTenderLots.data.find(
                    (lot) => lot.tender.id === tender_id,
                );
                setCurrentContract(currentTender);

                // const buildings = await axios.get(
                //     `${userSettings.api}/tep-building/companies/${main_company_id}/projects/${project_id}/buildings/`,
                //     userSettings.headers.get
                // );

                // const getSections = buildings.data.data.map(async (b) => {
                //
                //     const building_id = b.id;
                //
                //     const response = await axios.get(
                //         `${userSettings.api}/plans-configurator/companies/${main_company_id}/projects/${project_id}/building/${building_id}/sections/`,
                //         userSettings.headers.get
                //     );
                //
                //     return(response.data)
                // });
                // const sections = (await Promise.all(getSections)).flat();

                // const getPermission = sections.map( async (s) => {
                //
                //     const building_id = s.building;
                //     const section_id = s.id;
                //
                //     const response = await axios.get(
                //         `${userSettings.api}/work_payment/companies/${main_company_id}/projects/${project_id}/buildings/${building_id}/sections/${section_id}/permission`,
                //         userSettings.headers.get
                //     );
                //
                //     return(response.data)
                // })
                // const permission = (await Promise.all(getPermission)).flat();

                // const falseOrders = permission
                //     .filter(item => !item.permission)
                //     .flatMap(item => item.orders.map(order => order.id));

                const estimateElements = await axios(
                    `${userSettings.api}/work-journal/companies/${currentTender.contract.main}/projects/${currentTender.contract.project_id}/tenders/${currentTender.contract.tender}/subcompanies/${currentTender.contract.subcompany}/estimate-elements/`,
                    userSettings.headers.get,
                );
                setIsLockedWorkJournal(estimateElements.data.is_locked);
            } catch (error) {
                console.error("Error unapproved Work Log:", error.response);
            }
        };
        const getPermission = async () => {
        };
        const callToOrders = async () => {
            const orders = await getOrders(userSettings, company_id, project_id);
            if (orders.answer) {
                return;
            }
            const updateCalledOrders = orders?.filter(
                (o) => o.history[o.history.length - 1].status === 5,
            );
            setCalledOrders(updateCalledOrders);
        };
        const resolveTariffRequests = async () => {
            try {
                const companyTariffRequests = await getTariffsRequests(userSettings);
                const onlyNewTariff = companyTariffRequests.filter(
                    (tariff) => tariff.status === "new",
                );

                setShowOnlyTariffTab(true);

                setTariffRequests(onlyNewTariff.length > 0 && onlyNewTariff);
            } catch (error) {
                console.error("Error fetching companyTariffRequests:", error);
            }
        };


        const getApprovedQualityDocs = async () => {
            if (!currentContract) return;

            const docs = await axios.get(
                `${userSettings.api}/work_payment/companies/${currentContract.contract.main}/projects/${currentContract.contract.project_id}/tenders/${currentContract.contract.tender}/subcompanies/${currentContract.contract.subcompany}/quality-docs`,
                userSettings.headers.get,
            );

            const response = docs.data;

            const approvedDocs = response.filter(
                (doc) =>
                    doc.is_build_control_approved &&
                    doc.is_manager_approved &&
                    !doc.is_approved,
            );
            setApprovedQualityDocs(approvedDocs);
        };

        if (currentRole === 0) resolveTariffRequests();
        if (currentRole === 1) resolveTariffRequests();

        if (currentRole === 2) registerContractInfo();
        if (currentRole === 4) {
            subcontractorsWithoutSsr();
            setIsQualityDocsEnabled(true);
        }
        if (currentRole === 6) {
            const getCurrentContract = async () => {
                const wonTenderLots = await axios.get(
                    `${userSettings.api}/subcompanies/${company_id}/won-tender-lots`,
                    userSettings.headers.get,
                );

                const currentTender = wonTenderLots.data.find(
                    (lot) => lot.tender.id === tender_id,
                );
                setCurrentContract(currentTender);
            };

            getCurrentContract();

            getApprovedQualityDocs();
            unapprovedWorkLog();
            getPermission();
        }
        if (currentRole === 3) {
            callToOrders();
            setIsQualityDocsEnabled(true);
        }

    }, [userSettings, company_id, currentRole, currentContract?.contract?.main]);
    useEffect(() => {
        if (companyContracts) {
            const subcompanyIdList = [
                ...new Set(companyContracts.map((c) => c.subcompany)),
            ];
            getCompany(subcompanyIdList, userSettings)
                .then((results) => {
                    setCompaniesInfo(results);
                })
                .catch((error) => {
                    console.error("Произошла ошибка при получении компаний:", error);
                });
        }
    }, [companyContracts, userSettings]);

    const removeCookie = useCookies(['access'])[2];

    const leaveAccount = () => {
        removeCookie('access', {path: '/'});
        removeCookie('access', {path: '/work'});

        window.localStorage.removeItem("company");
        window.localStorage.removeItem("role");
        window.localStorage.removeItem("project");
        window.location.replace("/");
    }
    return (
        <div className="flex flex-col justify-between min-h-[calc(100%-20px)]">
            <div>
                {approvedQualityDocs?.length > 0 && (
                    <ControlItem
                        id={`approved-docs`}
                        state={props.state}
                        hasApprovedDocs={approvedQualityDocs?.length > 0}
                    />
                )}
                {showOnlyTariffTab && (
                    <ControlItem
                        id={`tariff-requests`}
                        state={props.state}
                        showOnlyTariffTab={showOnlyTariffTab}
                        tariffRequests={tariffRequests}
                        currentRole={currentRole}
                        companiesInfo={companiesInfo}
                        shownList={props.shownList}
                        showListHandler={props.showListHandler}
                    />
                )}
                {companyContracts && companyContracts.length > 0 && (
                    <ControlItem
                        id={`contracts`}
                        state={props.state}
                        contracts={companyContracts}
                        companiesInfo={companiesInfo}
                        shownList={props.shownList}
                        showListHandler={props.showListHandler}
                    />
                )}
                {subcontractorsWithoutSsr && (
                    <ControlItem
                        id={`withoutSsr`}
                        subcontractorsWithoutSsr={subcontractorsWithoutSsr}
                        state={props.state}
                        shownList={props.shownList}
                        showListHandler={props.showListHandler}
                    />
                )}
                {isLockedWorkJournal && (
                    <ControlItem
                        id={`notApprovedWorkLog`}
                        isLockedWorkJournal={isLockedWorkJournal}
                        state={props.state}
                        shownList={props.shownList}
                        showListHandler={props.showListHandler}
                    />
                )}
                {calledOrders && (
                    <ControlItem
                        id={`calledOrders`}
                        calledOrders={calledOrders}
                        state={props.state}
                        shownList={props.shownList}
                        showListHandler={props.showListHandler}
                    />
                )}
                {qualityDocs && (
                    <ControlItem
                        id={`qualityDocs`}
                        qualityDocs={qualityDocs}
                        state={props.state}
                        shownList={props.shownList}
                        showListHandler={props.showListHandler}
                    />
                )}
                {currentRoleSettings &&
                    currentRoleSettings.routes.length > 0 &&
                    currentRoleSettings.routes.map((route, id) => (
                        <ControlItem
                            key={id}
                            id={id}
                            href={`${route.route}`}
                            label={route.label}
                            shownList={props.shownList}
                            showListHandler={props.showListHandler}
                            state={props.state}
                        />
                    ))}
                <Btn
                    color={props.state ? `white` : "button"}
                    title={`Вернуться на главную страницу`}
                    onClick={leaveAccount}
                    icononly={!props.state}
                    className={'mx-4'}
                >
                    {!props.state ? <LogoutIcon/> : "Выйти"}
                </Btn>
            </div>
        </div>
    );
};

export default ProjectControl;
