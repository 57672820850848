import { createQueryKeys } from '@lukemorales/query-key-factory'
import {getQualityDocs, getQualityDocsList} from "../../QualityDocumentsData";

export const qualityDocsStore = createQueryKeys('qualityDocs', {
    docsList: (userSettings, company_id, project_id, subcompany_id, tender_id) => ({
        queryKey: ['docsList', company_id, project_id, subcompany_id, tender_id],
        queryFn: () => getQualityDocsList(userSettings, company_id, project_id, subcompany_id, tender_id),
    }),
    docs: (userSettings, company_id, project_id, currentRole) => ({
        queryKey: ['docs', company_id, project_id],
        queryFn: () => getQualityDocs(userSettings, company_id, project_id, currentRole),
    })
})