import {useMutation, useQueryClient} from "@tanstack/react-query";
import {approveQualityDocuments} from "../../../QualityDocumentsData";
import {qualityDocsStore} from "../qualityDocs.store";

export const useApproveQualityDocsMutation = (company_id, project_id) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({userSettings, tender_id, subcompany_id, documents}) => approveQualityDocuments(userSettings, company_id, project_id, tender_id, subcompany_id, documents),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: qualityDocsStore.docs._def
            })
        }
    })
}