export const getRangeDates = (range, year) => {
    let first, second;
    const period = [];
    if (range.first <= range.second) {
        first = range.first;
        second = range.second;
    } else {
        first = range.second;
        second = range.first;
    }

    const firstDateMonth = Number(first.slice(5,7));
    const secondDateMonth = Number(second.slice(5,7));
    const set = firstDateMonth !== secondDateMonth
        ? year
            .filter(month => month.month === firstDateMonth || month.month === secondDateMonth)
            .map(month => month.days).flat()
        : year
            .filter(month => month.month === firstDateMonth)
            .map(month => month.days).flat();

    set.forEach(date => {
        if (first <= date.date && date.date <= second ) period.push(date.date)
    })

    return period;
}