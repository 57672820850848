import React, {useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {ReactComponent as Warning} from "../../UI/Icons/ControlPanel/warning.svg";
import Header from "../Header";
import PageSection from "../../UI/Sections/PageSection";
import Section from "../../UI/Sections/Section";
import Button from "../../UI/Buttons/Button";
import PasswordInputs from "../../UI/Inputs/PasswordInputs";
import RegistrationInput from "../../UI/Inputs/RegistrationInput";
import Alink from "../../UI/Buttons/Alink";
import Grids from "../../UI/Grids/Grids";
import axios from "axios";
import {useCookies} from "react-cookie";

const Authorization = (props) => {

    const [cookie, setCookie] = useCookies(['access']);
    const [authError, setAuthError] = useState();

    const methods = useForm();

    const authLoginHandler = (data) => {
        document.cookie = "access =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

        document.cookie.split(";").forEach((cookie) => {
            const [name] = cookie.split("=");
            document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
        });

        axios
            .post(
                `${props.erpApiUrl}/auth/login/`,
                {
                    email: (data.login).toLowerCase(),
                    password: data.password,
                },
                {
                    headers: {
                        accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((response) => {
                if (response.data.access) {
                    setCookie("access", response.data.access, {
                        sameSite: "Lax", // или "None"
                        secure: true,
                        maxAge: 16 * 3600,
                    });
                    window.location.replace("/work/");
                }
            })
            .catch((error) => {
                console.error("\n error", error.response.data);
                setAuthError(error.response.data.errors[0].detail);
            });
    };

    localStorage.removeItem("main_company_id");
    localStorage.removeItem("company");
    localStorage.removeItem("project");
    localStorage.removeItem("role");

    return (
        <PageSection>
            <Header isLogin={cookie.access}/>
            <Section>
                <h1>Введите эл. почты и пароль</h1>
                {authError ? (
                    <div className="bg-red-100 text-red-900/90 p-2 rounded-md flex">
                        <Warning className="h-6 my-auto" />
                        <span className="my-auto ml-2">{authError}</span>
                    </div>
                ) : (
                    ""
                )}
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(authLoginHandler)}>
                        <RegistrationInput name="login" type="email" label="Адрес эл. почты" />
                        <PasswordInputs name="password" />
                        <Grids cols={2}>
                            <Button type="submit">Войти</Button>
                            <Alink href="/forgot-password">Не помню пароль</Alink>
                        </Grids>
                    </form>
                </FormProvider>
            </Section>
        </PageSection>
    );
};

export default Authorization;