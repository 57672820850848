import React from "react";
import {Document, Font, Page, StyleSheet, Text, View} from "@react-pdf/renderer";

Font.register({
    family: "Roboto", src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"
});

const styles = StyleSheet.create({
    body: {
        fontFamily: "Roboto", fontSize: 10, padding: 20, display: "flex", flexDirection: "column", gap: "12px"
    }, page: {
        display: "flex", flexDirection: "column", padding: 10,
    }, block: {
        margin: "10px 0", display: "flex", flexDirection: "column",
    }, row: {
        display: "flex", flexDirection: "row", gap: "4px"
    }, spaceBetween: {
        display: "flex", justifyContent: "space-between"
    }, end: {
        alignItems: "flex-end",
    }, horizontalBreak: {
        width: "100%", borderBottom: "1px solid black"
    }, underline: {
        textDecoration: "underline"
    }, textCenter: {
        textAlign: "center",
    }
});

const GeodeticAct = (props) => {
    return (<Document title='АКТ ПРИЕМКИ ГЕОДЕЗИЧЕСКОЙ РАЗБИВОЧНОЙ ОСНОВЫ ДЛЯ СТРОИТЕЛЬСТВА'>
        <Page style={styles.body}>
            <View style={[styles.block, styles.textCenter]}>
                <Text>АКТ ПРИЕМКИ ГЕОДЕЗИЧЕСКОЙ РАЗБИВОЧНОЙ ОСНОВЫ ДЛЯ СТРОИТЕЛЬСТВА</Text>
                <View>
                    <Text style={styles.underline}>
                        Жилой комплекс со встроено-пристроенными помещениями, подземной автостоянкой (в том числе
                        ДОУ семейного типа не менее чем на 20 мест), с реставрацией и приспособлением объектов
                        культурного наследия для современного использования (объектов культурного наследия
                        регионального значения: «Дом Корлякова» (СПб, наб. р. Карповки, 27, лит. А; «Здание
                        кинематографа «Гранд-Палас» (СПб, наб. р. Карповки, 27, лит. Г); «Постройки
                        мебельно-столярной фабрики Ф.А. Мельцера» (СПб, наб. р. Карповки, 27, лит. Б
                        (Производственный корпус литера Б8, литера Б4, Дымовая Труба литера Б1)», расположенного по
                        адресу: г. Санкт-Петербург, набережная реки Карповки, дом 27, литера А, общей площадью 35
                        150,1 кв.м., кадастровый номер земельного участка 78:07:0003125:2
                    </Text>
                    <Text>(наименование объекта строительства)</Text>
                </View>
            </View>

            <View>
                <Text>
                    Комиссия в составе:
                </Text>
                <View style={styles.row}>
                    <Text>ответственного представителя заказчика</Text>
                    <Text style={[styles.horizontalBreak, {width: "250px"}]}>{" "}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.underline}>Руководитель строительства ООО «», Приказ № от г. </Text>
                    <Text>(фамилия, инициалы, должность)</Text>
                </View>
            </View>

            <View>
                <View style={styles.row}>
                    <Text>ответственных представителей строительно-монтажной организации </Text>
                    <View style={[styles.horizontalBreak, {width: "30px"}]}>
                        <Text>{" "}</Text>
                    </View>
                </View>
                <View>
                    <Text style={styles.underline}>Главный инженер ООО «» , приказ № от</Text>
                    <Text>(фамилия, инициалы, должность)</Text>
                </View>
                <Text>рассмотрела представленную техническую документацию на геодезическую разбивочную основу для
                    строительства «Жилой комплекс со встроено-пристроенными помещениями, подземной автостоянкой (в
                    том числе ДОУ семейного типа не менее чем на 20 мест), с реставрацией и приспособлением объектов
                    культурного наследия для современного использования (объектов культурного наследия регионального
                    значения: «Дом Корлякова» (СПб, наб. р. Карповки, 27, лит. А; «Здание кинематографа
                    «Гранд-Палас» (СПб, наб. р. Карповки, 27, лит. Г); «Постройки мебельно-столярной фабрики Ф.А.
                    Мельцера» (СПб, наб. р. Карповки, 27, лит. Б (Производственный корпус литера Б8, литера Б4,
                    Дымовая Труба литера Б1)», расположенного по адресу: г. Санкт-Петербург, набережная реки
                    Карповки, дом 27, литера А, общей площадью 35 150,1 кв.м., кадастровый номер земельного участка
                    78:07:0003125:2»</Text>
                <Text style={styles.textCenter}>(наименование объекта строительства)</Text>
                <Text>и провела осмотр закрепленных на местности знаков этой основы.</Text>
            </View>

            <View>
                <Text>
                    Предъявленные к приемке знаки геодезической разбивочной основы для строительства, их координаты,
                    отметки, места установки и способы закрепления соответствуют представленной технической
                    документации
                </Text>
                <Text style={styles.underline}>План геодезической разбивочной основы</Text>
                <Text style={styles.textCenter}>(наименование проектной организации, номера чертежей, дата
                    выпуска)</Text>
                <Text>и выполнены с соблюдением заданной точности построений и измерений.
                    На основании изложенного комиссия считает, что заказчик сдал, а подрядчик принял знаки
                    геодезической разбивочной основы для строительства (наименование объекта или его отдельных
                    цехов, зданий, сооружений)
                </Text>
            </View>

            <View>
                <View style={styles.row}>
                    <Text>Приложения:</Text>
                    <Text style={styles.underline}>План геодезической разбивочной основы</Text>
                </View>
                <Text>(чертежи, схемы, ведомости и т.п.)</Text>
                <Text style={[styles.horizontalBreak]}>{" "}</Text>
            </View>

            <View>
                <View style={[styles.row, styles.spaceBetween]}>
                    <Text>Представитель заказчика</Text>
                    <View style={[styles.textCenter, {width: "200px"}]}>
                        <Text style={styles.horizontalBreak}>{" "}</Text>
                        <Text>(подпись)</Text>
                    </View>
                </View>

                <View>
                    <Text>Представители подрядчика:</Text>
                    <View style={[styles.row, styles.spaceBetween]}>
                        <Text>производитель работ</Text>
                        <View style={[styles.textCenter, {width: "200px"}]}>
                            <Text style={styles.horizontalBreak}>{" "}</Text>
                            <Text>(подпись)</Text>
                        </View>
                    </View>
                </View>

                <View style={[styles.row, styles.spaceBetween]}>
                    <Text>работник геодезической службы</Text>
                    <View style={[styles.textCenter, {width: "200px"}]}>
                        <Text style={styles.horizontalBreak}>{" "}</Text>
                        <Text>(подпись)</Text>
                    </View>
                </View>
            </View>
        </Page>
    </Document>)
}

export default GeodeticAct;