import PageSection from "../../UI/Sections/PageSection";
import Section from "../../UI/Sections/Section";
import { useForm, FormProvider } from "react-hook-form";
import PasswordInputs from "../../UI/Inputs/PasswordInputs";
import Button from "../../UI/Buttons/Button";
import {useLocation} from "react-router-dom";
import axios from "axios";
import React, {useState} from "react";
import Warnings from "../../UI/Sections/Warnings";

const PasswordReset = (props) => {

    const methods = useForm();
    const token = useLocation().pathname.replace('/password-reset/','');
    const [answers,setAnswers] = useState(null);
    const changePasswordHandler = async (data) => {

        try {
            const sendPassword = await axios.post(
                `${props.erpApiUrl}/password_reset/confirm/`,
                {
                    "password": data.password,
                    "token": token
                },
                {headers: {'accept': 'application/json','Content-Type': 'application/json'}}
            )
            if (sendPassword.status === 200) window.location.replace('/authorization')
        }
        catch (error) {
            setAnswers(<Warnings>Ошибка создания пароля - {
                error.response.data.errors ?
                    error.response.data.errors.map((e, id) => <p key={id}>{e.detail}</p>)
                    :
                    error.response.status
            }</Warnings>)
            console.error(`\n error`, error.response);
        }
    };

    if (token !== '') return (
        <PageSection>
            <Section>
                {answers && answers}
                <FormProvider {...methods} >
                    <form onSubmit={methods.handleSubmit(changePasswordHandler)}>
                        <PasswordInputs name="password" />
                        <PasswordInputs name="repeatPassword" check={methods.watch("password")} />
                        <Button type="submit">Создать пароль</Button>
                    </form>
                </FormProvider>
            </Section>
        </PageSection>

    );
}

export default PasswordReset;