import React, {useMemo} from "react";
import {FormProvider, useForm} from "react-hook-form";
import RegistrationInput from "../Inputs/RegistrationInput";
import Btn from "../Buttons/Btn";

export default function CreateOrder(
    {
        createOrder,
        subcompanies,
        createOrderHandler,
    }
) {
    const methods = useForm();
    const {watch} = methods;

    const selectedSubcompanyId = watch("subcompany");

    const tenderOptions = useMemo(() => {
        if (!selectedSubcompanyId) return [];
        const subcompany = subcompanies?.find(
            (s) => s.info.id === parseInt(selectedSubcompanyId, 10)
        );
        if (!subcompany) return [];
        return [
            {
                name: subcompany.tender.name,
                id: subcompany.tender.id,
            },
        ];
    }, [selectedSubcompanyId, subcompanies]);

    const fields = [
        {
            label: "Подрядчик",
            name: "subcompany",
            type: "select",
            options: subcompanies?.map((subcompany) => ({
                name: subcompany.info.name,
                id: subcompany.info.id,
            })),
            firstEmpty: true,
        },
        {
            label: "Выбрать договор",
            name: "tender_id",
            type: "select",
            options: [],
            firstEmpty: true,
        },
        {
            label: "Категория",
            name: "category",
            type: "select",
            options: [
                {name: 1, id: 1},
                {name: 2, id: 2},
                {name: 3, id: 3},
            ],
            firstEmpty: true,
        },
        {
            label: "Дата устранения",
            name: "date_completion",
            type: "date",
            min: new Date().toISOString().split("T")[0],
        },
        {
            label: "Описание",
            name: "description",
            type: "text",
        },
        {
            label: "Норматив",
            name: "standard",
            type: "text",
        },
        {
            label: "Мероприятия по устранению",
            name: "measures",
            type: "text",
        },
        {
            label: "Добавить фото",
            name: "photos_to_upload",
            type: "file",
            multiple: true,
            accept: ".jpg,.jpeg,.png,.gif",
            allowedFileTypes: ".jpg, .jpeg, .png, .gif",
        },
    ];

    const sendOrder = (data) => {
        data["coordinate_x"] = createOrder[0];
        data["coordinate_y"] = createOrder[1];
        delete data["contract"];
        createOrderHandler(data);
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(sendOrder)}>
                {fields.map((field) => {
                    if (field.name === "tender_id") {
                        return (tenderOptions.length > 0 &&
                            <RegistrationInput
                                key={field.name}
                                {...field}
                                options={tenderOptions}
                            />
                        );
                    }
                    return <RegistrationInput key={field.name} {...field} />;
                })}
                <Btn method={"save"} color={"button"} center>
                    <button type="submit">Записать предписание</button>
                </Btn>
            </form>
        </FormProvider>
    );
}
