import {Alert} from "@mui/material";
import axios from "axios";

export const getCompanyInfo = async (userSettings, company_id) => {
    try {
        const response = await axios.get(
            `${userSettings.api}/company/${company_id}/`,
            userSettings.headers.get
        );
        return response?.data;
    }
    catch (error) {
        console.error('\n getCompanyInfo', error.response);

        if (error.response.data) {

            console.error('\n ', error?.response?.data.errors);

            return ({
                success: false,
                message: error?.response?.data.errors.map((e, i) => <Alert severity="error" key={i}>attr: {e.attr} detail: {e.detail} code: {e.code} </Alert>)
            })
        }
    }
}

export const getSubcomanyList = async (userSettings, company_id, project_id) => {

    try {

        const response = await axios.get(
            `${userSettings.api}/company/${company_id}/subcompanies`,
            userSettings.headers.get
        );
        const subcompanyByProject = response?.data?.data?.filter(subcompany => subcompany.project_id === +project_id);
        const getSubcompanyByInfo = subcompanyByProject.map(async (s) => {

           const getInfo = await getCompanyInfo(userSettings, s.subcompany);

           return ({
               ...s,
               info: getInfo,
           })
        });
        const subcompanyByInfo = await Promise.all(getSubcompanyByInfo);
        return ({
            success: true,
            data: subcompanyByInfo
        });
    }
    catch (error) {
        console.error('\n getSubcomanyList', error.response);

        if (error.response.data) {

            return ({
                success: false,
                message: error?.response?.data.errors.map((e, i) => <Alert severity="error" key={i}>attr: {e.attr} detail: {e.detail} code: {e.code} </Alert>)
            })
        }
    }
}

export const getEstimateList = async (userSettings, company_id, project_id) => {
    try {
        const getSubcompanies = await getSubcomanyList(userSettings, company_id, project_id);

        if (getSubcompanies?.success) {
            const getEstimates = getSubcompanies?.data?.map(async (s) => {
                try {
                    const tender_id = s.tender;
                    const subcompany_id = s.subcompany;

                    const response = await axios.get(
                        `${userSettings.api}/work-journal/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/subcompanies/${subcompany_id}/estimate-elements/`,
                        userSettings.headers.get
                    );

                    const estimateCorrect = response?.data?.estimate?.map(e => ({ ...e, subcompany: s }));

                    return { success: true, data: estimateCorrect };
                } catch (error) {
                    console.error(`Ошибка для subcompany ${s.subcompany}:`, error.response.data?.errors || error.response.status);
                    return {
                        success: false,
                        message: error?.response?.data?.errors?.map((e, i) => `attr: ${e.attr} detail: ${e.detail} code: ${e.code}`) || `Статус запроса ${error.response.status}`
                    }
                }
            });

            const results = await Promise.allSettled(getEstimates);

            // Отфильтровываем успешные запросы
            const successfulEstimates = results
                .filter(result => result.status === 'fulfilled' && result.value.success)
                .map(result => result.value.data);

            // Отфильтровываем ошибки
            const errors = results
                .filter(result => result.status === 'rejected' || (result.status === 'fulfilled' && !result.value.success))
                .map(result => result.value?.error);

            if (successfulEstimates.length > 0) {
                return {
                    success: true,
                    data: successfulEstimates
                };
            } else {
                return {
                    success: false,
                    message: 'Все запросы завершились ошибками',
                    errors
                };
            }
        }
    } catch (error) {
        console.error('\n getEstimateList', error.response);

        return {
            success: false,
            message: error?.response?.data?.errors?.map((e, i) => `attr: ${e.attr} detail: ${e.detail} code: ${e.code}`)
        };
    }
};

export const getEstimateBySubcompany = async (userSettings, company_id, project_id, subcompany_id, tender_id) => {

    try {

        const response = await axios.get(
            `${userSettings.api}/work-journal/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/subcompanies/${subcompany_id}/estimate-elements/`,
            userSettings.headers.get
        );

        return ({
            success: true,
            data: response.data
        })
    }
    catch (error) {
        console.error('\n getEstimateBySubcompany', error.response);

        if (error.response.data) {

            return ({
                success: false,
                message: error?.response?.data.errors.map((e, i) => <Alert severity="error" key={i}>attr: {e.attr} detail: {e.detail} code: {e.code} </Alert>)
            })
        }
    }
}

export const getApprovedWorks = async (userSettings, company_id, project_id) => {
    try {
        const getSubcompanies = await getSubcomanyList(userSettings, company_id, project_id);

        if (getSubcompanies?.success) {

            const getEstimates = getSubcompanies?.data?.map(async (s) => {
                try {
                    const tender_id = s.tender;
                    const subcompany_id = s.subcompany;

                    const response = await axios.get(
                        `${userSettings.api}/work-journal/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/subcompanies/${subcompany_id}/estimate-elements/`,
                        userSettings.headers.get
                    );

                    const estimateCorrect = response?.data?.estimate?.map(e => ({ ...e, subcompany: s }));

                    return { success: true, data: estimateCorrect };
                } catch (error) {
                    console.error(`Ошибка для subcompany ${s.subcompany}:`, error.response.data?.errors || error.response.status);
                    return {
                        success: false,
                        message: error?.response?.data?.errors?.map((e, i) => `attr: ${e.attr} detail: ${e.detail} code: ${e.code}`) || `Статус запроса ${error.response.status}`
                    }
                }
            });

            const results = await Promise.allSettled(getEstimates);

            // Отфильтровываем успешные запросы
            const successfulEstimates = results
                .filter(result => result.status === 'fulfilled' && result.value.success)
                .map(result => result.value.data)
                .flat()
            ;

            // Отфильтровываем ошибки
            const errors = results
                .filter(result => result.status === 'rejected' || (result.status === 'fulfilled' && !result.value.success))
                .map(result => result.value?.error);

            const approvedWorks = successfulEstimates.flatMap(e => {
                
                const approved = e.work_log.filter(d => d?.certificates_completed_work?.length > 0)

                e.work_log = approved;

                return e
            });

            if (successfulEstimates.length > 0) {
                return {
                    success: true,
                    data: approvedWorks
                };
            } else {
                return {
                    success: false,
                    message: 'Все запросы завершились ошибками',
                    errors
                };
            }
        }
    } catch (error) {
        console.error('\n getApprovedWorks', error);

        return {
            success: false,
            message: error?.response?.data?.errors?.map((e, i) => `attr: ${e.attr} detail: ${e.detail} code: ${e.code}`)
        };
    }
};

export const getWonTenderLots = async (userSettings, subcompany_id, tender_id) => {

    try {

        const response = await axios.get(
            `${userSettings.api}/subcompanies/${subcompany_id}/won-tender-lots`,
            userSettings.headers.get
        );

        return ({
            success: true,
            list: response.data,
            current: response.data.find(t => t.tender.id === tender_id)
        })
    }
    catch (error) {
        console.error('\n getWonTendertLots', error);

        return {
            success: false,
            message: error?.response?.data?.errors?.map((e, i) => `attr: ${e.attr} detail: ${e.detail} code: ${e.code}`)
        };
    }
}

export const getEstimateData = async (userSettings, subcompany_id, tender_id) => {

    try {

        const tenders = await getWonTenderLots(userSettings, subcompany_id, tender_id);
        const currentTender = tenders.current || null;

        if (currentTender) {

            const company_id = currentTender.contract.main;
            const project_id = currentTender.contract.project_id;

            const response = await axios.get(
                `${userSettings.api}/work-journal/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/subcompanies/${subcompany_id}/estimate-elements/`,
                userSettings.headers.get
            );
            return ({
                success: true,
                data: response.data.estimate
            })
        }

    }
    catch (error) {
        console.error('\n getEstimateData', error);

        return {
            success: false,
            message: error?.response?.data?.errors?.map((e, i) => `attr: ${e.attr} detail: ${e.detail} code: ${e.code}`)
        };
    }
}