import React from 'react';
import {Document, Page, Text, View, StyleSheet, Image, Font} from '@react-pdf/renderer';

Font.register({
    family: "times-new-roman",
    fonts: [
        { src: '/uploads/fonts/TimesNewRoman.ttf' },
        { src: '/uploads/fonts/TimesNewRomanBold.ttf', fontWeight: "bold" },
        { src: '/uploads/fonts/TimesNewRomanItalic.ttf', fontStyle: "italic" },
        { src: '/uploads/fonts/TimesNewRomanBoldItalic.ttf', fontWeight: "bold", fontStyle: "italic" },
    ]
});


const styles = StyleSheet.create({
    page: {
        padding: 30,
        fontFamily: "times-new-roman",
    },
    section: {
        marginBottom: 10,
    },
    label: {
        fontSize: 14,
        marginBottom: 10,
        textAlign: "center",
    },
    text: {
        fontSize: 12,
        marginBottom: 5,
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        fontSize: 10,
    },
    tableNB: {
        display: "table",
        width: "auto",
        borderRightWidth: 0,
        borderBottomWidth: 0,
        fontSize: 10,
    },
    tableRow: {
        flexDirection: "row",
    },
    tableCol: {
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableColNB: {
        borderTopWidth: 1,
        borderTopColor: "black",
        borderTopStyle: "solid",
    },
    tableCell: {
        margin: "auto",
        padding: 2,
        justifyContent: "center",
    },
    cell_10: {
        width: "10mm",
    },
    cell_30: {
        width: "30mm",
    },
    cell_50: {
        width: "50mm",
    },
    cell_60: {
        width: "60mm",
    },
    cell_70: {
        width: "70mm",
    },
    cell_130: {
        width: "130mm",
    },
    cell_190: {
        width: "190mm",
    },
    center: {
        textAlign: "center",
    },
    bold: {
        fontWeight: "bold",
    },
    italic: {
        fontStyle: "italic",
    },
});

const DocumentOrder = ({data}) => {

    if (!data) {
        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.section}>
                        <Text style={styles.header}>Нет данных для отображения</Text>
                    </View>
                </Page>
            </Document>
        );
    }

    const headTable = [
        [
            {
                text: "Заказчик:",
                width: 60,
                bold: true,
            },
            {
                text: data.company ? [
                    data.company.name,
                    data.company.inn && " ИНН: " + data.company.inn
                ] : "",
                width: 130,
            },
        ],
        [
            {
                text: "Подрядчик:",
                width: 60,
                bold: true,
            },
            {
                text: data.subcompany ? [
                    data.subcompany.name,
                    " Договор №: " + data.contract,
                    data.subcompany.inn && " ИНН: " + data.subcompany.inn,
                ] : "",
                width: 130,
            },
        ],
        [
            {
                text: "Объект:",
                bold: true,
                width: 60,
            },
            {
                text: data.object?.object_name || "Обратитесь к координатору",
                width: 130,
            },
        ],
        [
            {
                text: "№ п/п",
                width: 10,
                bold: true,
            },
            {
                text: "Наименование выявленных нарушений (дефектов)",
                width: 50,
                bold: true,
            },
            {
                text: "Нарушение (дефект) (ссылка на пункт договора, СНиП, иное)",
                width: 70,
                bold: true,
            },
            {
                text: "Срок устранения",
                width: 30,
                bold: true,
            },
            {
                text: "Отметка о выполнении",
                width: 30,
                bold: true,
            },
        ],
        [
            {
                text: 1,
                width: 10,
            },
            {
                text: data.description,
                width: 50,
            },
            {
                text: data.standard,
                width: 70,
            },
            {
                text: new Date(data.date_completion).toLocaleDateString(),
                width: 30,
            },
            {
                text: "",
                width: 30,
            },
        ]
    ];

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Text style={[styles.label, styles.bold]}>Акт-предписание об устранении выявленных нарушений
                        (дефектов) № {data.id}</Text>
                    <Text style={styles.text}>Дата
                        составления: {data.history && new Date(data.history[0]?.date_time).toLocaleDateString()}</Text>
                    <View style={styles.table}>
                        {headTable?.map((row, num) => {

                            return (<View style={styles.tableRow} key={num}>
                                {row.map((cell, col) => {

                                    return (
                                        <View style={styles.tableCol} key={`${col}-${num}`}>
                                            <Text
                                                style={[styles.tableCell, styles["cell_" + cell.width], styles[cell.bold && "bold"]]}>{cell.text}</Text>
                                        </View>
                                    );
                                })}
                            </View>);
                        })}
                    </View>
                </View>

                <View style={styles.section}>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={[styles.tableCell, styles.cell_190, styles.bold]}>Мероприятия предписанные
                                    к выполнению для устранения нарушения (дефекта)</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={[styles.tableCell, styles.cell_190]}>{data.measures}</Text>
                            </View>
                        </View>
                    </View>
                </View>

                <View style={styles.section}>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={[styles.tableCell, styles.cell_190, styles.bold]}>Комментарии
                                    Подрядчика</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={[styles.tableCell, styles.cell_190]}> </Text>
                            </View>
                        </View>
                    </View>
                </View>

                <View style={styles.section}>
                    <Text style={[styles.text, styles.bold]}>Нарушения (дефекты) устраняются за счет Подрядчика.</Text>
                    <Text style={styles.text}>Акт–предписание выдал:</Text>
                    <View style={styles.tableNB}>
                        <View style={styles.tableRow}>
                            <View>
                                <Text style={[styles.tableCell, styles.cell_60, styles.center]}> </Text>
                            </View>
                            <View>
                                <Text style={[styles.tableCell, styles.cell_70, styles.center]}> </Text>
                            </View>
                            <View>
                                <Text style={[styles.tableCell, styles.cell_30, styles.center]}> </Text>
                            </View>
                            <View>
                                <Text style={[styles.tableCell, styles.cell_30, styles.center]}> </Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableColNB}>
                                <Text
                                    style={[styles.tableCell, styles.cell_60, styles.italic, styles.center]}>должность</Text>
                            </View>
                            <View style={styles.tableColNB}>
                                <Text style={[styles.tableCell, styles.cell_70, styles.italic, styles.center]}>фамилия,
                                    инициалы</Text>
                            </View>
                            <View style={styles.tableColNB}>
                                <Text
                                    style={[styles.tableCell, styles.cell_30, styles.italic, styles.center]}>подпись</Text>
                            </View>
                            <View style={styles.tableColNB}>
                                <Text
                                    style={[styles.tableCell, styles.cell_30, styles.italic, styles.center]}>дата</Text>
                            </View>
                        </View>
                    </View>
                    <Text style={styles.text}>Акт–предписание получил:</Text>
                    <View style={styles.tableNB}>
                        <View style={styles.tableRow}>
                            <View>
                                <Text style={[styles.tableCell, styles.cell_60, styles.center]}>{
                                    // sendStatus && sendStatus.
                                }</Text>
                            </View>
                            <View>
                                <Text style={[styles.tableCell, styles.cell_70, styles.center]}> </Text>
                            </View>
                            <View>
                                <Text style={[styles.tableCell, styles.cell_30, styles.center]}> </Text>
                            </View>
                            <View>
                                <Text style={[styles.tableCell, styles.cell_30, styles.center]}> </Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableColNB}>
                                <Text
                                    style={[styles.tableCell, styles.cell_60, styles.italic, styles.center]}>должность</Text>
                            </View>
                            <View style={styles.tableColNB}>
                                <Text style={[styles.tableCell, styles.cell_70, styles.italic, styles.center]}>фамилия,
                                    инициалы</Text>
                            </View>
                            <View style={styles.tableColNB}>
                                <Text
                                    style={[styles.tableCell, styles.cell_30, styles.italic, styles.center]}>подпись</Text>
                            </View>
                            <View style={styles.tableColNB}>
                                <Text
                                    style={[styles.tableCell, styles.cell_30, styles.italic, styles.center]}>дата</Text>
                            </View>
                        </View>
                    </View>
                </View>

                <View style={styles.section}>
                    <Text style={[styles.text, styles.italic]}>Примечание: оригинал акта обязательно остается у
                        подрядчика.</Text>
                </View>

                <View style={styles.section}>
                    <Text style={[styles.text, styles.italic]}>Приложения:</Text>
                    <Text style={[styles.text, styles.italic]}>Фотофиксация нарушений:</Text>
                    {data.photos?.length > 0 ? (
                        data.photos.map((photo, index) => (
                            <Image
                                key={index}
                                src={`${data.fileServer}${photo.file}`}
                                style={{marginBottom: 10, width: '100%', height: 'auto'}}
                            />
                        ))
                    ) : (
                        <Text style={styles.text}>Нет приложенных фотографий</Text>
                    )}
                </View>
            </Page>
        </Document>
    );
};
export default DocumentOrder;
