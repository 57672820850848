import React, {useContext, useEffect, useState} from 'react';
import UserSection from "../../UI/Sections/UserSection";
import GenerateKS2PDF from "./KS-2/GenerateKS-2PDF";
import GenerateKS3PDF from "./KS-3/GenerateKS-3PDF";
import {Alert, Box, Button, Snackbar, Typography} from "@mui/material";
import {getTender} from "../../UI/GetInfo/getSubcompanies";
import UserContext from "../../../store/user/user-context";
import axios from "axios";
import GenerateAktOsvidetelstvovaniyaPDF from "./Akt-osvidetelstvovaniya/GenerateAkt-OsvidetelstvovaniyaPDF";
import {Link} from "react-router-dom";

const CreateActs = () => {
        const userSettings = useContext(UserContext);

        const subcompany_id = +window.localStorage.getItem('company');
        const tender_id = +window.localStorage.getItem('tender');

        const [currentContract, setCurrentContract] = useState(null);
        const [approvedQualityDocs, setApprovedQualityDocs] = useState(null);
        const [subcompanyDocs, setSubcompanyDocs] = useState([])

        const [aktOsv, setAktOsv] = useState(null);
        const [ks2, setKs2] = useState(null);
        const [ks3, setKs3] = useState(null);

        useEffect(() => {
            const getData = async () => {
                const allTenders = await getTender(userSettings, subcompany_id);
                const curContract = allTenders.find(tender => tender.tender.id === tender_id);

                const docs = await axios.get(
                    `${userSettings.api}/work_payment/companies/${curContract?.contract?.main}/projects/${curContract?.contract?.project_id}/tenders/${tender_id}/subcompanies/${curContract?.contract?.subcompany}/quality-docs`,
                    userSettings.headers.get
                );

                const acts = await axios.get(
                    `${userSettings.api}/subcompanies/${curContract?.contract?.subcompany}/companies/${curContract?.contract?.main}/projects/${curContract?.contract?.project_id}/tenders/${curContract?.contract?.tender}/register-acts/`,
                    userSettings.headers.get
                )

                const response = docs.data;

                const approvedDocs = response.filter(doc => doc.is_build_control_approved && doc.is_manager_approved && !doc.is_approved);

                setApprovedQualityDocs(approvedDocs);
                setCurrentContract(curContract);
                setSubcompanyDocs(acts.data)
            }

            getData();
        }, []);

        const collectApprovedDocsId = () => approvedQualityDocs.map(doc => doc.id);

        const [notAllActs, setNotAllActs] = useState(false);

        const [isActsAdded, setIsActsAdded] = useState(false);
        const [isActsAlreadyAdded, setIsActsAlreadyAdded] = useState(false);


        const onSubmit = async () => {
            if (!aktOsv || !ks2 || !ks3) {
                setNotAllActs(true);
                return;
            }

            try {
                const qualityDocumentsIds = collectApprovedDocsId(approvedQualityDocs);

                const formDataAkt = new FormData();
                formDataAkt.append("type", 1);
                formDataAkt.append("document", aktOsv);
                for (let i = 0; i < qualityDocumentsIds.length; i++) {
                    formDataAkt.append('quality_documents', qualityDocumentsIds[i]);
                }
                const aktOsvRequest = await axios.post(`${userSettings.api}/work_payment/companies/${currentContract.contract.main}/projects/${currentContract.contract.project_id}/subcompanies/${currentContract.contract.subcompany}/tenders/${tender_id}/work-payment-certificates/`,
                    formDataAkt,
                    {headers: userSettings.headers.postForm}
                )

                const formDataKs2 = new FormData();
                formDataKs2.append("type", 2);
                formDataKs2.append("document", ks2);
                for (let i = 0; i < qualityDocumentsIds.length; i++) {
                    formDataKs2.append('quality_documents', qualityDocumentsIds[i]);
                }

                const ks2Request = await axios.post(`${userSettings.api}/work_payment/companies/${currentContract.contract.main}/projects/${currentContract.contract.project_id}/subcompanies/${currentContract.contract.subcompany}/tenders/${tender_id}/work-payment-certificates/`,
                    formDataKs2,
                    {headers: userSettings.headers.postForm}
                )

                const formDataKs3 = new FormData();
                formDataKs3.append("type", 3);
                formDataKs3.append("document", ks3);
                for (let i = 0; i < qualityDocumentsIds.length; i++) {
                    formDataKs3.append('quality_documents', qualityDocumentsIds[i]);
                }
                const ks3Request = await axios.post(`${userSettings.api}/work_payment/companies/${currentContract.contract.main}/projects/${currentContract.contract.project_id}/subcompanies/${currentContract.contract.subcompany}/tenders/${tender_id}/work-payment-certificates/`,
                    formDataKs3,
                    {headers: userSettings.headers.postForm})

                setIsActsAdded(true);

                await axios.patch(`${userSettings.api}/work-journal/companies/${currentContract.contract.main}/projects/${currentContract.contract.project_id}/tenders/${currentContract.contract.tender}/subcompanies/${currentContract.contract.subcompany}/estimate-elements/change_access/`, {is_locked: false}, {headers: userSettings.headers.post})
            } catch (e) {
                setIsActsAlreadyAdded(true);
            }
        }


        return (
            <UserSection>
                <Snackbar
                    anchorOrigin={{vertical: "top", horizontal: "right"}}
                    open={notAllActs}
                    autoHideDuration={4000}
                    onClose={() => setNotAllActs(false)}
                >
                    <Alert severity="error">
                        Не все акты заполнены, нельзя продолжить пока не будут указаны все данные
                    </Alert>
                </Snackbar>

                <Snackbar
                    anchorOrigin={{vertical: "top", horizontal: "right"}}
                    open={isActsAdded}
                    autoHideDuration={2000}
                    onClose={() => setIsActsAdded(false)}
                >
                    <Alert severity="success">
                        Все 3 акта были прикреплены к текущим документам о качестве
                    </Alert>
                </Snackbar>

                <Snackbar
                    anchorOrigin={{vertical: "top", horizontal: "right"}}
                    open={isActsAlreadyAdded}
                    autoHideDuration={2000}
                    onClose={() => setIsActsAlreadyAdded(false)}
                >
                    <Alert severity="error">
                        К документам о качестве уже привязаны акты
                    </Alert>
                </Snackbar>

                <Typography variant="h1">Документы о качестве, к которым будут прикреплены акты: </Typography>
                {approvedQualityDocs?.map(doc =>
                    <Button
                        key={doc.id}
                        className="w-fit"
                        color="secondary"
                        href={`${userSettings.fileServer}${doc.document}`}
                        target="_blank"
                    >Перейти к документу о качестве #{doc.id}</Button>
                )}

                <Typography variant="h1">Акты подрядчика:</Typography>
                <Box className="flex flex-wrap gap-4">
                    {subcompanyDocs?.map(doc =>
                        <Box key={doc.id} className="flex flex-col pb-2 border border-black rounded gap-4">
                            <Typography className="text-center text-white bg-black px-2">{doc.name}</Typography>
                            <Box className="flex flex-col justify-center gap-4 px-4">
                                <Link className="w-full" to={`${userSettings.fileServer}${doc.file}`} target="_blank">
                                    <Button className="w-full" variant="contained">
                                        Скачать
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                    )}
                </Box>

                <Box className="flex flex-col gap-5">
                    <GenerateAktOsvidetelstvovaniyaPDF
                        setAktOsv={setAktOsv}
                    />
                    <GenerateKS2PDF
                        setKs2={setKs2}
                    />
                    <GenerateKS3PDF
                        setKs3={setKs3}
                    />

                    <Button color="success" variant="contained" onClick={onSubmit}>Прикрепить акты к документам о
                        качестве</Button>
                </Box>
            </UserSection>
        );
    }
;

export default CreateActs;