import React, {useContext, useEffect, useState} from "react";
import UserSection from "../../UI/Sections/UserSection";
import UserContext from "../../../store/user/user-context";
import {ErrorOccurred} from "../../UI/Sections/ErrorOccurred";
import TheadSort from "../../UI/Tables/TheadSort";
import Btn from "../../UI/Buttons/Btn";
import tables from "../../UI/Tables/table.module.css";
import axios from "axios";
import {Price} from "../../UI/Convertors/Price";
import Warnings from "../../UI/Sections/Warnings";
import inputs from "../../UI/Inputs/inputs.module.css";
import {sendSequentialRequests} from "../../SendRequests/sendSequentialRequests";
import Popup from "../../UI/Popup/Popup";
import {Alert, Box, Button} from "@mui/material";

export default function AddJobLog() {

    const userSettings = useContext(UserContext);
    const subcompany_id = +window.localStorage.getItem('company');
    const tender_id = +window.localStorage.getItem('tender');

    const [answers, setAnswers] = useState(null);
    const [popupShow, setPopupShow] = useState(false);

    const [estimate, setEstimate] = useState(null);
    const [estimateInfo, setEstimateInfo] = useState(null)
    const [isLockedWorkJournal, setIsLockedWorkJournal] = useState(false);

    const [workDate, setWorkDate] = useState(null);
    const [tender, setTender] = useState(null);
    const [valueData, setValueData] = useState({});
    const [savePermission, setSavePermission] = useState(false);

    const [unapprovedWorkLog, setUnapprovedWorkLog] = useState(null);

    const company_id = tender?.tender.project.company.id;
    const project_id = tender?.tender.project.id;
    const headerNames = [{
        "name": "№", "tag": "number",
    }, {
        "name": "Наименование работ", "tag": "name_works",
    }, {
        "name": "Ед. изм.", "tag": "units",
    }, {
        "name": "Объем", "tag": "value",
    }, {
        "name": "Стоимость материалов и работ", "tag": "unit_cost_total",
    }, {
        "name": "Объем работ за день", "tag": "work_log_value"
    }, {
        "name": "Комментарий к работе", "tag": "work_log_comment"
    }];

    const handleInputChange = (elementId, value, type) => {
        const updateValueData = {...valueData};
        const element = estimate.find(e => e.id === elementId);
        const isNumber = type === "value" && value.length > 0 && !isNaN(value);

        if (isNumber && +element.value >= +value) {
            if (!updateValueData[elementId]) updateValueData[elementId] = {};
            updateValueData[elementId].value = +value;
        } else if (type === "comment") {
            if (!updateValueData[elementId]) updateValueData[elementId] = {};
            updateValueData[elementId].comment = value;
        }

        if (value.length === 0) {
            delete updateValueData[elementId][type];
        }

        if (updateValueData[elementId] && Object.values(updateValueData[elementId]).length === 0) delete updateValueData[elementId];

        setValueData(updateValueData);
    };
    const writeWorkLogHandler = async (e) => {

        e.preventDefault();

        if (savePermission && Object.values(valueData).length > 0) {

            const requests = Object.keys(valueData).map((element_id) => {

                const formData = new FormData();
                formData.append("date", new Date(workDate).toISOString().split('T')[0]);
                Object.keys(valueData[element_id]).forEach(key => formData.append(key, valueData[element_id][key]));

                return ({
                    method: 'POST',
                    url: `${userSettings.api}/work-journal/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/subcompanies/${subcompany_id}/estimate-elements/${element_id}/create-work-log/`,
                    data: formData,
                    headers: userSettings.headers.postForm
                });
            });
            postWorkLog(requests);
        } else {

            const writeEmptyWorkLog = () => {

                const requests = estimate.map(e => {

                    const element_id = e.id;

                    const formData = new FormData();
                    formData.append("date", new Date(workDate).toISOString().split('T')[0]);
                    formData.append("value", "0");
                    formData.append("comment", "");

                    return ({
                        method: 'POST',
                        url: `${userSettings.api}/work-journal/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/subcompanies/${subcompany_id}/estimate-elements/${element_id}/create-work-log/`,
                        data: formData,
                        headers: userSettings.headers.postForm
                    });
                });
                postWorkLog(requests);
                setPopupShow(false);
                setAnswers(<Warnings info>Подождите идет запись в журнал работ</Warnings>);
            }

            setPopupShow(<Popup
                title={`Записать работы за ${new Date(workDate).toLocaleDateString()}`}
                content={<React.Fragment>
                    <Warnings>За указанный день не указаны работы</Warnings>
                    <div className={`flex gap-2 items-center w-max mx-auto`}>
                        <Btn method={`apply`} onClick={writeEmptyWorkLog}>Принять</Btn>
                        <Btn method={`cancel`} onClick={() => setPopupShow(false)}>Отклонить</Btn>
                    </div>
                </React.Fragment>}
                onCloseModal={() => setPopupShow(false)}
            />);
        }
    }
    const postWorkLog = async (requests) => {

        if (requests?.length > 0) {

            try {

                const results = await sendSequentialRequests(requests);
                const errorsResult = results.filter(r => r.error).map(r => r.errorMessage).flat();
                if (errorsResult.length > 0) {
                    const responseError = ErrorOccurred(null, "Ошибка определения ССР", "saveEstimateHandler", errorsResult);
                    setAnswers(<Alert severity="error">{errorsResult[0]?.detail}</Alert>);
                }

                const correctResult = results.filter(r => !r.error);
                if (correctResult.length > 0) {
                    window.location.reload();
                }
            } catch (error) {

                const responseError = ErrorOccurred(error.response, "Ошибка записи в журнал работ", "postWorkLog");
                setAnswers(responseError.jsx);
            }
        }
    }

    const lockWorkJournal = async () => {
        await axios.patch(`${userSettings.api}/work-journal/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/subcompanies/${subcompany_id}/estimate-elements/change_access/`, {is_locked: true}, {headers: userSettings.headers.post})

        setIsLockedWorkJournal(true);
        window.location.reload();
    }

    useEffect(() => {
        const getTender = async () => {
            try {
                const wonTenderLots = await axios.get(`${userSettings.api}/subcompanies/${subcompany_id}/won-tender-lots`, userSettings.headers.get);

                const updateCurrentTender = wonTenderLots.data.find(contract => contract.tender.id === tender_id);
                setTender(updateCurrentTender);
            } catch (error) {

                const responseError = ErrorOccurred(error.response, "Ошибка получения документов при регистрации", "responseError");
                setAnswers(responseError.jsx);
            }
        }

        if (subcompany_id) getTender();
    }, [userSettings, subcompany_id, tender_id]);
    useEffect(() => {

        const getEstimate = async () => {

            try {

                const estimateElements = await axios(`${userSettings.api}/work-journal/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/subcompanies/${subcompany_id}/estimate-elements/`, userSettings.headers.get);
                setIsLockedWorkJournal(estimateElements.data.is_locked);
                const estimateValueAddWorkLog = estimateElements.data.estimate.map(e => {
                    const workLogValues = e.work_log.reduce((acc, w) => acc + (+w.value), 0);

                    e.value = Math.round((+e.value - workLogValues) * 1000) / 1000;

                    return e;
                });

                const {id, is_edited_today, is_locked, today_date} = estimateElements.data;
                setEstimateInfo({id, is_edited_today, is_locked, today_date})
                setEstimate(estimateValueAddWorkLog);
            } catch (error) {

                const responseError = ErrorOccurred(error.response, "Ошибка получения журнала работ", "getEstimate");
                setAnswers(responseError.jsx);
            }
        }

        if (company_id && project_id && tender_id && subcompany_id) getEstimate();
    }, [company_id, project_id, tender_id, subcompany_id, userSettings]);

    useEffect(() => {
        if (!estimateInfo) return;
        setWorkDate(estimateInfo.today_date);
    }, [estimateInfo]);

    useEffect(() => {

        if (Object.values(valueData).length === 0) {
            setSavePermission(true);
        } else {

            let premissions = true;

            Object.values(valueData).forEach(el => {

                if (!(el.value > 0 && el.comment?.length > 0)) premissions = null;
            });

            setSavePermission(premissions);
        }
    }, [valueData]);
    useEffect(() => {
        const getUnapprovedWorkLog = async () => {
            if (!tender) return;
            const getUpdateUnapprovedWorkLog = await axios.get(`${userSettings.api}/work_payment/companies/${tender.contract.main}/projects/${tender.contract.project_id}/tenders/${tender.contract.tender}/subcompanies/${tender.contract.subcompany}/unapproved-work-log`, userSettings.headers.get);

            const withLogs = getUpdateUnapprovedWorkLog.data.filter(w => w.not_approved_work_log.length > 0)

            setUnapprovedWorkLog(withLogs);
        }

        getUnapprovedWorkLog();
    }, [tender?.contract]);

    const [isAgreedPeriod, setIsAgreedPeriod] = useState(false)
    useEffect(() => {
        if (!workDate) return;

        const day = new Date(workDate).getDate();
        if (day >= 20 && day <= 25) setIsAgreedPeriod(true);

    }, [workDate]);

    return (<UserSection>
        {popupShow}
        <h1>Журнал работы</h1>
        {answers && answers}
        {isLockedWorkJournal &&
            <Alert severity="info">Пока есть неподтверждённые работы вы не можете заполнять журнал работ</Alert>
        }
        {!isLockedWorkJournal && estimate &&
            <form onSubmit={writeWorkLogHandler} className={`grow ${tables.container}`}>
                {(workDate && savePermission)
                    ?
                    <Box className="flex gap-4 m-4 h-[60px]">
                        {/*{!estimateInfo.is_edited_today ?*/}
                        {true ?
                            <Btn method={`save`} color={`button`}>
                                <button type="submit">
                                    Сохранить работы за {new Date(workDate).toLocaleDateString()}
                                </button>
                            </Btn>
                            : <Alert severity="info">За сегодня журнал уже был заполнен</Alert>
                        }
                        {/*{isAgreedPeriod &&*/}
                        {true &&
                            <Button onClick={lockWorkJournal} variant="contained" color="success">Согласовать</Button>
                        }
                    </Box>
                    :
                    <Box className="flex gap-4 m-4 h-[60px]"></Box>
                }
                <table className={tables.table}>
                    <TheadSort
                        names={headerNames}
                        array={estimate}
                        setArray={setEstimate}
                    />
                    <tbody>
                    {estimate.map(element => {

                        // const work_log = element.work_log.length > 0 && +sumWorkLog(element.work_log);
                        // const elValue = work_log ? element.value - work_log : +element.value;

                        return (element.value > 0 && <tr key={element.id} className={`text-center`}>
                            {headerNames.map((sn, index) => {

                                const value = sn.tag === "unit_cost_total" ? Price(element[sn.tag]) : element[sn.tag];

                                return (
                                    <td key={index} className={`${sn.tag === "name_works" ? "text-left" : ""}`}>
                                        {sn.tag === "work_log_value" ? <React.Fragment>
                                            {(valueData[element.id] && valueData[element.id].comment && !valueData[element.id].value) &&
                                                <Warnings>Укажите объем работ</Warnings>}
                                            <input
                                                name={`${element.id}_value`}
                                                className={inputs.input}
                                                type="number"
                                                onChange={(e) => handleInputChange(element.id, e.target.value, 'value')}
                                                value={(valueData[element.id] && valueData[element.id].value) ? valueData[element.id].value : ''}
                                            />
                                        </React.Fragment> : sn.tag === "work_log_comment" ? <React.Fragment>
                                            {(valueData[element.id] && valueData[element.id].value && !valueData[element.id].comment) &&
                                                <Warnings>Оставьте комментарий к работе</Warnings>}
                                            <textarea
                                                name={`${element.id}_comment`}
                                                className={inputs.input}
                                                onChange={(e) => handleInputChange(element.id, e.target.value, 'comment')}
                                                value={(valueData[element.id] && valueData[element.id].comment) ? valueData[element.id].comment : ''}
                                                placeholder={`Укажите работы`}
                                            />
                                        </React.Fragment> : value}
                                    </td>)
                            })}
                        </tr>);
                    })}
                    </tbody>
                </table>
            </form>}
    </UserSection>);
}