import {Box, Button} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import React from "react";

export default function PaymentEvent() {
    const data = new URLSearchParams(useLocation().search);
    const company_id = +data.get('company');
    const amount = +data.get('amount');

    return (
        <Box className="flex items-center h-full">
            <Box className="w-max mx-auto flex gap-2">
                <Button
                    component={Link}
                    variant="contained"
                    color="success"
                    to={`/work/payment-company/?company=${company_id}&amount=${amount}&status=success`}
                >Оплата прошла</Button>
                <Button
                    component={Link}
                    variant="contained"
                    color="error"
                    to={`/work/payment-company/?company=${company_id}&amount=${amount}&status=error`}
                >Оплата не прошла</Button>
                <Button
                    component={Link}
                    variant="contained"
                    color="warning"
                    to={`/work/payment-company/?company=${company_id}&amount=${amount}&status=rejected`}
                >Отказ от оплаты</Button>
            </Box>
        </Box>
    );
}
