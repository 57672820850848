import React, {useContext, useEffect, useState} from "react";
import UserSection from "../../UI/Sections/UserSection";
import UserContext from "../../../store/user/user-context";
import tables from "../../UI/Tables/table.module.css";
import styles from "../../UI/Tables/table.module.css";
import {Price} from "../../UI/Convertors/Price";
import DiagramFinancialSchedule from "../../UI/Diagrams/DiagramFinancialSchedule";
import {useNavigate} from "react-router-dom";
import {getFP, getWJ} from "../../SendRequests/FinancialPlanData";

export default function FinancialControlPlanFact() {

    const navigate = useNavigate();
    const role = +window.localStorage.getItem('role');
    if (role !== 0 && role !== 4) navigate('/work/');

    const userSettings = useContext(UserContext);
    const company_id = +window.localStorage.getItem('company');
    const project_id = +window.localStorage.getItem('project');

    // const [answers, setAnswers] = useState(null);
    const [financialSchedule, setFinancialSchedule] = useState(null);
    const [summaryAllRowsMonths, setSummaryAllRowsMonths] = useState(null);
    const [summaryAllRowsPeriods, setSummaryAllRowsPeriods] = useState(null);

    const types = {
        plan: "План",
        fact: "Факт",
    };

    const tableNames = [
        {
            name: "ССР",
            type: {span: Object.keys(types).length},
            key: "ssr",
        },
        {
            name: "Наименование работ и затрат",
            type: {span: Object.keys(types).length},
            key: "name",
        },
        {
            name: "Общая сметная стоимость",
            type: types,
            key: "summary_periods",
        }
    ];
    const months = [
        "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
        "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
    ];
    if (summaryAllRowsMonths) Object.keys(summaryAllRowsMonths.plan).forEach(date => {
        const timestamp = parseInt(date); // Преобразуем дату в целое число
        const month = months[new Date(timestamp * 1000).getMonth()];
        const year = new Date(timestamp * 1000).getFullYear();

        // Добавляем в tableNames
        tableNames.push({
            name: (
                <React.Fragment>
                    <div>{month}</div>
                    <div>{year}</div>
                </React.Fragment>
            ),
            type: types,
            date: timestamp // Добавляем дату как отдельное поле, если нужно
        });

    });

    tableNames.pop()

    useEffect(() => {

        const getInfo = async () => {

            const getFPData = await getFP(userSettings, company_id, project_id);
            const getWJData = await getWJ(userSettings, company_id, project_id);
            const wj = getWJData.flatMap(w => w.estimate);
            const ssrFact = {};

            function toFactTimestamp(dateStr) {
                const date = new Date(dateStr);
                date.setHours(date.getHours() - 3);
                date.setDate(1);
                return Math.floor(date.getTime() / 1000);
            }

            wj.forEach(e => {

                const wl = e.work_log.length > 0 && e.work_log.filter(d => d.certificates_completed_work.length > 0);

                if (wl.length > 0) {

                    ssrFact[e.ssr] = {};

                    wl.forEach(d => {

                        const date = toFactTimestamp(d.date);

                        const sum = +d.value * (+e.unit_cost_materials + +e.unit_cost_smr);

                        ssrFact[e.ssr][date] = 0;
                        ssrFact[e.ssr][date] += sum;
                    });
                }
            });

            const fp = Object.values(getFPData).map(f => ({
                ...f,
                periods: {
                    plan: f.periods,
                    fact: ssrFact[f.ssr] || {},
                },
                parent: f?.ssr?.includes('.') ? f?.ssr?.split('.')?.slice(0, -1)?.join('.') : null,
            }));

            const data = fp;
            const totals = {};
            data.forEach(item => {
                if (item.type === 'group') {
                    totals[item.ssr] = {
                        plan: {},
                        fact: {}
                    };
                }
            });
            data.forEach(item => {
                if (item.type === 'row') {
                    const parent = item.parent;

                    if (parent && totals[parent]) {
                        Object.entries(item.periods.plan).forEach(([date, value]) => {
                            totals[parent].plan[date] = (totals[parent].plan[date] || 0) + value;
                        });

                        Object.entries(item.periods.fact).forEach(([date, value]) => {
                            totals[parent].fact[date] = (totals[parent].fact[date] || 0) + value;
                        });
                    }
                }
            });
            data.forEach(item => {
                if (item.type === 'group') {
                    const parent = item.parent;

                    // Если у группы есть родитель, суммируем
                    if (parent && totals[parent]) {
                        Object.entries(totals[item.ssr].plan).forEach(([date, value]) => {
                            totals[parent].plan[date] = (totals[parent].plan[date] || 0) + value;
                        });

                        Object.entries(totals[item.ssr].fact).forEach(([date, value]) => {
                            totals[parent].fact[date] = (totals[parent].fact[date] || 0) + value;
                        });
                    }
                    item.periods.plan = totals[item.ssr].plan;
                    item.periods.fact = totals[item.ssr].fact;
                }
            });

            const summary = data.map(row => {
                let totalFact = 0;
                let totalPlan = 0;

                totalPlan = Object.values(row.periods.plan).reduce((sum, value) => sum + value, 0);

                totalFact = Object.values(row.periods.fact).reduce((sum, value) => sum + value, 0);

                row.summary_periods = {
                    fact: totalFact,
                    plan: totalPlan,
                };

                return row;
            });
            setFinancialSchedule(summary);

            const rowSumsByDate = {
                plan: {},
                fact: {}
            };
            summary.forEach(item => {
                if (item.type === 'row') {
                    Object.entries(item.periods.plan).forEach(([date, value]) => {
                        rowSumsByDate.plan[date] = (rowSumsByDate.plan[date] || 0) + value;
                    });

                    Object.entries(item.periods.fact).forEach(([date, value]) => {
                        rowSumsByDate.fact[date] = (rowSumsByDate.fact[date] || 0) + value;
                    });
                }
            });
            summary.forEach(item => {
                if (item.type === 'row') {
                    item.rowSums = {...rowSumsByDate};
                }
            });
            setSummaryAllRowsMonths(rowSumsByDate);

            let accumulatedPlanSum = 0;
            let accumulatedFactSum = 0;

            const cumulativeMonths = {
                plan: {},
                fact: {}
            };

            const allDates = [...new Set([...Object.keys(rowSumsByDate.plan), ...Object.keys(rowSumsByDate.fact)])].sort();

            allDates.forEach((date) => {
                if (rowSumsByDate.plan[date] !== undefined) {
                    accumulatedPlanSum += rowSumsByDate.plan[date];
                }
                cumulativeMonths.plan[date] = accumulatedPlanSum;

                if (rowSumsByDate.fact[date] !== undefined) {
                    accumulatedFactSum += rowSumsByDate.fact[date];
                }
                cumulativeMonths.fact[date] = accumulatedFactSum;
            });
            setSummaryAllRowsPeriods(cumulativeMonths);
        }

        getInfo();
    }, [company_id, project_id, userSettings]);

    return (
        <UserSection>
            <h1>Контроль финансов</h1>
            {/*{answers && answers}*/}
            {summaryAllRowsPeriods && <DiagramFinancialSchedule data={summaryAllRowsPeriods}/>}
            <div className={`${tables.container} grow ${styles.container}`}>
                <table className={tables.table}>
                    <thead>
                    <tr>
                        {tableNames.map((n, index) => (
                            <th
                                key={index}
                                rowSpan={n.type?.span}
                                colSpan={!n.type?.span ? Object.keys(n.type).length : 0}
                                className={`
                                    ${index === 0 ? "sticky left-0 z-30 bg-[#0F1937]  border border-amber-100" : ""}
                                    ${index === 1 ? "sticky left-[67px] z-30 bg-[#0F1937] border border-amber-100" : ""}
                               `}
                            >
                                {n.name}
                            </th>
                        ))}
                    </tr>
                    <tr>
                        {tableNames
                            .filter(n => !n.type?.span)
                            .flatMap((n, index) =>
                                Object.values(n.type).map((name, id) => (
                                    <th key={`${id}-${index}`}>
                                        {name}
                                    </th>
                                ))
                            )
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {financialSchedule?.map((f, index) => {

                        const summary_periods = f.summary_periods;
                        const countDots = (str) => (str.split('.').length - 1);
                        const types = [
                            'group',
                            'sub-group',
                            'sub-sub-group',
                        ];

                        return (
                            <tr key={index} className={f.type === "group" ? tables[types[countDots(f.ssr)]] : ""}>
                                {tableNames.map((n, id) => {

                                    const planDate = f.periods.plan[n.date];
                                    const factDate = f.periods.fact[n.date];

                                    return (
                                        (n.key === "summary_periods") ?
                                            summary_periods ?
                                                <React.Fragment key={id}>
                                                    <td className={`text-center whitespace-nowrap`}>{summary_periods.plan > 0 && Price(summary_periods.plan)}</td>
                                                    <td className={`text-center whitespace-nowrap`}>{summary_periods.fact > 0 && Price(summary_periods.fact)}</td>
                                                </React.Fragment> :
                                                <React.Fragment key={id}>
                                                    <td/>
                                                    <td/>
                                                </React.Fragment> :
                                            n.date ?
                                                <React.Fragment key={id}>
                                                    <td className={`text-center whitespace-nowrap`}>{planDate && Price(planDate)}</td>
                                                    <td className={`text-center whitespace-nowrap`}>{factDate && Price(factDate)}</td>
                                                </React.Fragment> :
                                                <td key={id}
                                                    className={`
                                                    ${n.key !== "name" ? "text-center" : "min-w-[300px]"}
                                                    ${id === 0 ? "sticky left-0" : ""}
                                                    ${id === 1 ? "sticky left-[67px]" : ""}
                                                    ${f.type === "group" ? tables[types[countDots(f.ssr)]] : index % 2 === 0 ? "bg-white" : "bg-gray-200"}
                                                    `}>
                                                    {f[n.key]}
                                                </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                    {summaryAllRowsMonths && <React.Fragment>
                        <tr key={`all`}>
                            <td colSpan={2}>Итого за месяц</td>
                            <td className={`text-center whitespace-nowrap`} rowSpan={2}>
                                {Object.values(summaryAllRowsMonths.plan).reduce((acc, w) => (acc + w), 0) > 0 &&
                                    Price(Object.values(summaryAllRowsMonths.plan).reduce((acc, w) => (acc + w), 0))
                                }
                            </td>
                            <td className={`text-center whitespace-nowrap`} rowSpan={2}>
                                {Object.values(summaryAllRowsMonths.fact).reduce((acc, w) => (acc + w), 0) > 0 &&
                                    Price(Object.values(summaryAllRowsMonths.fact).reduce((acc, w) => (acc + w), 0))
                                }
                            </td>
                            {tableNames
                                .filter(n => n.date)
                                .map((n, id) => {

                                        return (
                                            <React.Fragment key={id}>
                                                <td className={`text-center whitespace-nowrap`}>{summaryAllRowsMonths?.plan[n.date] > 0 &&
                                                    Price(summaryAllRowsMonths?.plan[n.date])
                                                }</td>
                                                <td className={`text-center whitespace-nowrap`}>{summaryAllRowsMonths?.fact[n.date] > 0 &&
                                                    Price(summaryAllRowsMonths?.fact[n.date])
                                                }</td>
                                            </React.Fragment>
                                        )
                                    }
                                )
                            }
                        </tr>
                        <tr key={`all_summary`}>
                            <td colSpan={2}>Итого за период</td>
                            {tableNames
                                .filter(n => n.date)
                                .map((n, id) => {

                                        return (
                                            <React.Fragment key={id}>
                                                <td className={`text-center whitespace-nowrap`}>{summaryAllRowsPeriods?.plan[n.date] > 0 &&
                                                    Price(summaryAllRowsPeriods?.plan[n.date])
                                                }</td>
                                                <td className={`text-center whitespace-nowrap`}>{summaryAllRowsPeriods?.fact[n.date] > 0 &&
                                                    Price(summaryAllRowsPeriods?.fact[n.date])
                                                }</td>
                                            </React.Fragment>
                                        )
                                    }
                                )
                            }
                        </tr>
                    </React.Fragment>}
                    </tbody>
                </table>
            </div>
        </UserSection>
    );
}