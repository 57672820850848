import React, {useContext, useEffect, useState} from 'react';
import UserSection from "../../UI/Sections/UserSection";
import {Alert, Box, Button, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import RegistrationInput from "../../UI/Inputs/RegistrationInput";
import {FormProvider, useForm} from "react-hook-form";
import axios from "axios";
import UserContext from "../../../store/user/user-context";

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function CustomizedTables({representatives}) {
    return (
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 300}} aria-label="representatives table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Представитель</StyledTableCell>
                        <StyledTableCell align="center">Состояние</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {representatives.map((representative) => (
                        <StyledTableRow key={representative.id}>
                            <StyledTableCell component="th" scope="row">
                                <Box className="flex flex-col">
                                    <Typography>
                                        {representative.full_name}
                                    </Typography>
                                    <Typography>
                                        {representative.email}
                                    </Typography>
                                    <Typography>
                                        {representative.phone}
                                    </Typography>
                                </Box>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <Box className="flex flex-col">
                                    <Alert severity={representative.is_active ? "success" : "error"}>
                                        {representative.is_active ? "Пользователь активен" : "Пользователь не активен"}
                                    </Alert>
                                    <Alert severity={!representative.is_blocked ? "success" : "error"}>
                                        {!representative.is_blocked ? "Пользователь не заблокирован" : "Пользователь заблокирован"}
                                    </Alert>
                                    <Alert severity={!representative.is_deleted ? "success" : "error"}>
                                        {!representative.is_deleted ? "Пользователь не удалён" : "Пользователь удалён"}
                                    </Alert>
                                </Box>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const Representatives = () => {
    const methods = useForm();
    const {handleSubmit} = methods;
    const userSettings = useContext(UserContext);
    const company_id = localStorage.getItem("company")
    const [users, setUsers] = useState(null)

    useEffect(() => {
        if (!company_id) return;

        const getUsers = async () => {
            const updateCompanyUsers = await axios.get(
                `${userSettings.api}/company/${company_id}/users`,
                userSettings.headers.get
            );
            setUsers(updateCompanyUsers.data.users)
        }

        getUsers();
    }, [company_id]);

    const handleAddUser = async (data) => {
        try {
            const answerInviteUser = await axios.post(
                `${userSettings.api}/invites/companies/${company_id}/invite`,
                {
                    "role": 6,
                    "email": data.email,
                },
                {
                    headers: userSettings.headers.post
                }
            );

        } catch (e) {
            console.error(e)
        }
    }

    return (
        <UserSection>

            <Typography variant="h1">
                Представители подрядчика
            </Typography>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(handleAddUser)}>

                    <Box className="flex flex-col justify-center gap-4 w-[300px] mb-4">
                        <RegistrationInput label="Укажите e-mail сотрудника" name="email" type="email"/>
                        <Button className="h-fit" variant="contained" type="submit">Добавить представителя</Button>
                    </Box>
                </form>
            </FormProvider>
            {users &&
                <CustomizedTables representatives={users}/>
            }
        </UserSection>
    );
};

export default Representatives;