import React, {useContext, useEffect, useState} from "react";
import UserContext from "../../../store/user/user-context";
import axios from "axios";
import styles from "../dash.module.css";
import Btn from "../../UI/Buttons/Btn";
import {Link} from "react-router-dom";
import UserSection from "../../UI/Sections/UserSection";
import {ReactComponent as Send} from "../../UI/Icons/Plan/send.svg";
import {ReactComponent as Sign} from "../../UI/Icons/Plan/sing.svg";
import {ReactComponent as Call} from "../../UI/Icons/Plan/call.svg";
import {ReactComponent as Reject} from "../../UI/Icons/Plan/reject.svg";
import {ReactComponent as Apply} from "../../UI/Icons/Plan/accepted.svg";

export default function SubcompanyWelcome() {

    const userSettings = useContext(UserContext);
    const subcompany_id = +window.localStorage.getItem('company') || null;
    const subcompany = subcompany_id && userSettings.companies.find(c => c.id === subcompany_id);
    const tender_id = +window.localStorage.getItem('tender') || null;


    const [answers, setAnswers] = useState(null);
    const [tender, setTender] = useState(null);
    const [orderStatuses, setOrderStatuses] = useState(null);

    const statuses = {
        2: {
            img: <Send/>,
            label: "На подпись"
        },
        3: {
            img: <Sign/>,
            label: "Предписания в работе"
        },
        5: {
            img: <Call/>,
            label: "Предписания с вызовом Стройконтроль"
        },
        4: {
            img: <Reject/>,
            label: "Отклоненные предписания"
        },
        6: {
            img: <Apply/>,
            label: "Завершенные предписания"
        },
    }

    useEffect(() => {
        const getInfo = async () => {
            try {
                const company_id = tender.tender.project.company.id;
                const project_id = tender.tender.project.id;


                const systemAccess = await axios.get(
                    `${userSettings.api}/subcompanies/${subcompany_id}/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/system-access`,
                    userSettings.headers.get
                )

                if (systemAccess) {
                    const getBuildings = await axios.get(
                        `${userSettings.api}/tep-building/companies/${company_id}/projects/${project_id}/buildings/?subcompany_id=${subcompany_id}`,
                        userSettings.headers.get
                    );
                    if (getBuildings.data.data && getBuildings.data.data.length > 0) {

                        const buildingRequests = getBuildings.data.data.map(async building => {
                            const response = await axios.get(
                                `${userSettings.api}/plans-configurator/companies/${company_id}/projects/${project_id}/building/${building.id}/sections/`,
                                userSettings.headers.get
                            );
                            return response.data;
                        });
                        const sectionsData = (await Promise.all(buildingRequests)).flatMap(buildingData => buildingData);
                        const ordersRequests = sectionsData.map(async section => {
                            const response = await axios.get(
                                `${userSettings.api}/company/${company_id}/projects/${project_id}/tenders/${tender.tender.id}/buildings/${section.building}/sections/${section.id}/orders/?subcompany_id=${subcompany_id}`,
                                userSettings.headers.get
                            );
                            return response.data.data.map(order => ({
                                ...order,
                                building_id: section.building,
                                company_id: company_id,
                                project_id: project_id
                            }));
                        });

                        const ordersData = (await Promise.all(ordersRequests)).flatMap(orders => orders);

                        const updateOrderStatuses = {};
                        ordersData.forEach(order => {
                            const lastStatus = order.history[order.history.length - 1].status;

                            if (lastStatus !== 1) {
                                if (!updateOrderStatuses[lastStatus]) updateOrderStatuses[lastStatus] = [];
                                updateOrderStatuses[lastStatus].push(order);
                            }
                        });
                        setOrderStatuses(Object.keys(updateOrderStatuses).length > 0 ? updateOrderStatuses : null);
                    }
                }
            } catch (error) {
                // setAnswers(<Alert severity='warning'>Ошибка получения предписаний - не был выдан доступ системой</Alert>)
                console.error(`\n error`, error.response);
            }
        };

        if (tender) getInfo();
    }, [userSettings, tender, tender_id, subcompany_id]);

    return (
        <UserSection>
            <h2>Предписания:</h2>
            {orderStatuses && <React.Fragment>
                {Object.keys(orderStatuses).map(status => {

                    return (<div className={styles.orders} key={status}>
                        <h3>{statuses[status].img} {statuses[status].label}</h3>
                        <ul>
                            {orderStatuses[status]?.map(o => {

                                const date_completion = (status !== '6') && new Date() > new Date(o.date_completion);

                                return (
                                    <li key={o.id}>
                                        <Btn color={date_completion ? 'red' : 'link'}>
                                            <Link
                                                to={`/work/subcompany/view-order?company=${o.company_id}&tender=${tender.tender.id}&project=${o.project_id}&building=${o.building_id}&section=${o.section}&floor=${o.floor}&order=${o.id}`}>
                                                {date_completion &&
                                                    <div className={`text-red-800`}>Предписание просрочено</div>}
                                                <div>{o.contract}-{o.id}</div>
                                            </Link>
                                        </Btn>
                                    </li>
                                );
                            })}
                        </ul>

                    </div>);
                })}
            </React.Fragment>}
        </UserSection>
    );
}