import React, {useContext, useEffect, useState} from 'react';
import Header from "../Header";
import PageSection from "../../UI/Sections/PageSection";
import useTenderLogic from "./useTenderLogic";
import {Link, useLocation} from "react-router-dom";
import styles from "./tenders.module.css";
import {Price} from "../../UI/Convertors/Price";
import Btn from "../../UI/Buttons/Btn";
import {ReactComponent as MailIcon} from "../../UI/Icons/mail.svg";
import {ReactComponent as PhoneIcon} from "../../UI/Icons/phone.svg";
import Alink from "../../UI/Buttons/Alink";
import Grids from "../../UI/Grids/Grids";
import axios from "axios";
import {read, utils} from 'xlsx';
import inputs from "../../UI/Inputs/inputs.module.css";
import tables from "../../UI/Tables/table.module.css";
import TheadSort from "../../UI/Tables/TheadSort";
import {Controller, FormProvider, useForm} from "react-hook-form";
import RegistrationInput from "../../UI/Inputs/RegistrationInput";
import {checkEstimateTable} from "./checkEstimateTable";
import {Alert, Box, Button, Typography} from "@mui/material";
import UserContext from "../../../store/user/user-context";

const ParticipateTender = (props) => {
    const tenderLotId = parseInt(useLocation().pathname.split("/")[useLocation().pathname.split("/").length - 1], 10);

    const {authError, cookies, tenders, authorizationHandler, setCookie, subCompanies} = useTenderLogic(props.api);
    const [modal, setModal] = useState(false);
    const [currentTender, setCurrentTender] = useState(null);
    const [importedCounts, setImportedCounts] = useState([]);
    const [countsPrice, setCountsPrice] = useState(0);
    const headerNames = [
        {
            "name": "№",
            "tag": "number",
            "id": 0
        },
        {
            "name": "Наименование работ",
            "tag": "name_works",
            "id": 1
        },
        {
            "name": "Ед. изм.",
            "tag": "units",
            "id": 2
        },
        {
            "name": "Объем",
            "tag": "value",
            "id": 3
        },
        {
            "name": "Материалы",
            "tag": "unit_cost_materials",
            "id": 4
        },
        {
            "name": "СМР",
            "tag": "unit_cost_smr",
            "id": 5
        },
        {
            "name": "Стоимость материалов и работ",
            "tag": "unit_cost_total",
        }
    ];

    const uploadHandler = async (e) => {
        const file = await e.target.files[0].arrayBuffer();
        const workbook = read(file);
        const data = utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {header: 1});

        let firstRow = 0;
        const updateImportedCounts = [];

        const findHeaderIndex = (headerName) => headerNames.find(h => h.tag === headerName).id;

        data.forEach((row, i) => {
            if (row[0] === '№') {
                firstRow = i + 2;
            } else if (i >= firstRow) {
                const value_id = findHeaderIndex("value");
                const name_works_id = findHeaderIndex("name_works");

                const isValidRow = row[name_works_id] && row[value_id] && row[value_id] !== "Объем";

                if (isValidRow) {
                    let countRow = {};

                    headerNames.forEach(h => {

                        if (["value", "unit_cost_materials", "unit_cost_smr"].includes(h.tag)) {
                            countRow[h.tag] = Math.round(row[h.id] * 100) / 100;
                        } else {
                            countRow[h.tag] = row[h.id];
                        }
                    });

                    countRow['unit_cost_total'] = Math.round(((countRow.value * countRow.unit_cost_materials) + (countRow.value * countRow.unit_cost_smr)) * 100) / 100;

                    updateImportedCounts.push(countRow);
                }
            }
        });

        setImportedCounts(updateImportedCounts);
    };
    const [periodWorks, setPeriodWorks] = useState({
        deadline: '',
        startDay: '',
    });
    const [periodWorksError, setPeriodWorksError] = useState();
    const [periodWorksCounts, setPeriodWorksCounts] = useState([]);
    const [periodsNames, setPeriodsNames] = useState([]);
    const [allPeriodsError, setAllPeriodsError] = useState();
    const [countsFinished, setCountsFinished] = useState([]);
    const [currentSubcompany, setCurrentSubcompany] = useState(subCompanies?.length > 0 ? subCompanies[0] : null);
    const [tenderApply, setTenderApply] = useState(null);
    const [applyError, setApplyError] = useState(null);

    const methods = useForm({
        defaultValues: {
            rangeStart: 1,
            rangeEnd: 1,
        },
    });
    const {control, getValues, watch} = methods;
    const rangeStart = watch("rangeStart");


    const handleChange = (e) => {
        setPeriodWorks({
            ...periodWorks,
            [e.target.name]: e.target.value,
        });
    };

    const periodWorksSubmit = (e) => {

        e.preventDefault();

        const checkError = Object.values(periodWorks).filter(p => p.length).length;

        if (checkError === 2) {

            setPeriodWorksError();

            function buildDateArray(data) {
                const result = [];

                const startDate = new Date(data.startDay);

                result.push(startDate.getTime() / 1000);

                for (let i = 1; i < data.deadline; i++) {
                    const nextMonth = new Date(startDate);
                    nextMonth.setUTCMonth(startDate.getUTCMonth() + i, 1);
                    result.push(nextMonth.getTime() / 1000);
                }

                return result;
            }

            setPeriodWorksCounts(buildDateArray(periodWorks));
        } else {
            setPeriodWorksError('Заполните все обязательные поля');
            setPeriodWorksCounts([]);
        }
    };

    const distributeHandler = (e) => {
        const row = e.currentTarget.closest('tr');

        if (row) {
            const priceCell = row.querySelector('td[datatype="price"]');
            const totalAmount = parseFloat(priceCell.textContent.replace(/\s/g, '').replace(',', '.'));
            const numberInputs = row.querySelectorAll('input[type="number"]');

            let written = 0;
            let current = 0;

            numberInputs.forEach(input => {
                if (input.value) {
                    written++;
                    current += parseFloat(input.value);
                }
            });

            const remaining = totalAmount - current;
            const distributedValue = Math.floor((remaining / (numberInputs.length - written)) * 100) / 100;

            let used = 0;
            numberInputs.forEach((input, index) => {
                if (!input.value) {
                    if (index === numberInputs.length - 1) {
                        input.value = (totalAmount - current - used).toFixed(2);
                    } else {
                        input.value = distributedValue.toFixed(2);
                        used += distributedValue;
                    }
                }
            });
        }
    };

    const distributeHandlerAll = () => {
        const rows = document.querySelectorAll('table tbody tr');
        const {rangeStart, rangeEnd} = getValues();

        rows.forEach(row => {
            const priceCell = row.querySelector('td[datatype="price"]');
            const totalAmount = parseFloat(priceCell.textContent.replace(/\s/g, '').replace(',', '.'));
            const numberInputs = Array.from(row.querySelectorAll('input[type="number"]'));

            const filteredInputs = numberInputs.slice(rangeStart - 1, rangeEnd);

            let written = 0;
            let current = 0;

            numberInputs.forEach(input => {
                if (input.value) {
                    written++;
                    current += parseFloat(input.value);
                }
            });

            const remaining = totalAmount - current;
            const distributedValue = Math.floor((remaining / filteredInputs.length) * 100) / 100;

            let used = 0;
            filteredInputs.forEach((input, index) => {
                if (!input.value) {
                    if (index === filteredInputs.length - 1) {
                        input.value = (totalAmount - current - used).toFixed(2);
                    } else {
                        input.value = distributedValue.toFixed(2);
                        used += distributedValue;
                    }
                }
            });
        });
    };

    const handleCheckAllPeriods = () => {
        const rows = document.querySelectorAll('table tbody tr');

        const mismatchedRows = [];

        rows.forEach((row, index) => {
            const priceCell = row.querySelector('td[datatype="price"]');
            const totalAmount = parseFloat(priceCell.textContent.replace(/\s/g, '').replace(',', '.'));

            const numberInputs = row.querySelectorAll('input[type="number"]');
            const sumInputs = Array.from(numberInputs)
                .map((input) => parseFloat(input.value) || 0)
                .reduce((acc, currentValue) => acc + currentValue, 0);

            if (Math.abs(sumInputs - totalAmount) > 0.01) {
                mismatchedRows.push(index + 1); // добавляем номер строки в массив (индекс + 1)
            }
        });

        if (mismatchedRows.length === 0) {

            setAllPeriodsError(null);

            const updateCountsFinished = importedCounts.map((count, i) => {
                const dates = rows[i].querySelectorAll('input[type="number"]');
                const periods = Array.from(dates).map(d => ({
                    "date": new Date(d.name * 1000).toISOString().slice(0, 10),
                    "value": +d.value
                })).filter(p => p.value > 0);

                const special = rows[i].querySelector('input[name="special"]');
                const responsible_structures = rows[i].querySelector('input[name="responsible_structures"]');

                return {
                    ...count,
                    'periods': periods,
                    'special': special.checked,
                    'responsible_structures': responsible_structures.checked
                };
            });

            setCountsFinished(updateCountsFinished);
        } else {
            // Некоторые строки не совпадают
            setAllPeriodsError(mismatchedRows);
            // Добавьте код, который нужно выполнить, если есть несовпадающие строки
        }
    };

    useEffect(() => {
        if (!subCompanies?.length > 0) return;
        setCurrentSubcompany(subCompanies[0].id)
    }, [subCompanies]);


    const sendTenderHandler = (data) => {
        const sendData = {
            "estimate": countsFinished,
            "criteria": currentTender.criteria.map(c => {

                const value = +data[Object.keys(data).find(d => d === `criteria_${c.id}`)];


                return ({
                    "name": c.name,
                    "value": value
                })
            })
        };

        if (currentSubcompany) axios.post(
            `${props.api}/subcompanies/${currentSubcompany}/tenders/${currentTender.id}/tender-lots/`,
            sendData,
            {
                headers: {
                    'Authorization': `Bearer ${cookies.access}`,
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        )
            .then((response) => {

                setTenderApply(true);
            })
            .catch(error => {

                if ([...error.response.data.errors][0].detail === "Подрядчик уже принимает участие в тендере") {
                    setCurrentSubcompany(null);
                    setApplyError([...error.response.data.errors][0].detail);
                }
            });
    }

    useEffect(() => {

        if (tenders && tenders.length > 0) {

            const getCurrentTender = tenders.find(t => t.id === +tenderLotId);

            setCurrentTender(getCurrentTender);
        }
    }, [tenders, tenderLotId, props, cookies.access]);
    useEffect(() => {

        if (importedCounts && importedCounts.length > 0) {

            setCountsPrice(Math.round(importedCounts.map(c => c.unit_cost_total).reduce((acc, val) => acc + val, 0) * 100) / 100);
        }
    }, [importedCounts]);
    useEffect(() => {
        const updatePeriodsNames = [
            {
                "name": "№",
                "tag": "number"
            },
            {
                "name": "Наименование работ",
                "tag": "name_works"
            },
            {
                "name": "Спецжурнал",
                "tag": "special"
            },
            {
                "name": "Ответственные конструкции",
                "tag": "responsible_structures"
            },
            {
                "name": "Стоимость",
                "tag": "unit_cost_total"
            },
            {
                "name": "Распределить",
                "tag": "distribute"
            },
        ];

        const updatedPeriodsNames = periodWorksCounts.map(date => {
            const formattedDate = new Date(date * 1000);
            const monthNames = [
                'Январь', 'Февраль', 'Март', 'Апрель',
                'Май', 'Июнь', 'Июль', 'Август',
                'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
            ];
            const month = monthNames[formattedDate.getMonth()];
            const year = formattedDate.getFullYear();
            const formattedName = `${month} ${year}`;

            return {
                "name": formattedName,
                "tag": date.toString()
            };
        });

        const finalPeriodsNames = [...updatePeriodsNames, ...updatedPeriodsNames];

        setPeriodsNames(finalPeriodsNames);
    }, [periodWorksCounts]);

    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        if (!importedCounts.length > 0) return;
        const estimateStatus = checkEstimateTable(importedCounts);
        setIsValid(estimateStatus);
    }, [importedCounts]);

    return (
        <PageSection>
            <Header
                tenders={true}
                onAuth={authorizationHandler}
                authError={authError}
                isLogin={(cookies.access && cookies.access.length > 0)}
                modal={modal}
            />
            {(cookies.access && cookies.access.length > 0 && currentTender) ?
                <div className={`${styles.section} h-screen flex-col flex`}>
                    <h1>Принять участие в тендере {currentTender.name}</h1>
                    <Grids cols={`auto`}>
                        <div className={`mr-5`}>Дата окончания
                            тендера <span>{new Date(currentTender.end_date).toLocaleDateString()}</span></div>
                        <div>Категория: <span>{props.tender_directions[currentTender.category]}</span></div>
                    </Grids>
                    <div>НМЦ лота: <span className={styles.price}>{Price(currentTender.nmc)} р.</span></div>
                    <div>Адрес объекта: <span>{currentTender.address}</span></div>
                    <Grids cols={`auto`}>
                        <div className={`my-auto`}>Ответственный: {currentTender.representative}</div>
                        <Btn icon={<MailIcon className={`scale-75`}/>} className={`my-auto mx-2`}>
                            <Link to={`mailto:${currentTender.email}`}>{currentTender.email}</Link>
                        </Btn>
                        <Btn icon={<PhoneIcon className={`scale-75`}/>} className={`my-auto mx-2`}>
                            <Link to={`tel:${currentTender.phone}`}>{currentTender.phone}</Link>
                        </Btn>
                    </Grids>
                    <div>Документы:</div>
                    <div className={`flex flex-wrap border border-pult/30 rounded-md py-2`}>
                        <Grids cols={`auto`}>
                            <Btn link>
                                <Link
                                    target={`_blank`}
                                    to={currentTender?.estimate_template ? `${props.fileServer}${currentTender?.estimate_template}` : `/uploads/docs/contractors_estimate.xlsx`}>
                                    Шаблон сметы подрядчика
                                </Link>

                            </Btn>
                            {currentTender.attachments.map(d => <Alink key={d.file}
                                                                       href={`${props.fileServer}${d.file}`}
                                                                       target={`_blank`}>{d.name}</Alink>)}
                        </Grids>
                    </div>

                    {!tenderApply ?
                        <div className={`mt-2 grow`}>
                            {countsFinished.length === 0 ?
                                importedCounts.length === 0
                                    ?
                                    <React.Fragment>
                                        <h2>Для подачи заявки на участие в тендере требуется загрузить смету по
                                            шаблону</h2>
                                        <input className={`${inputs.file} w-max`} type="file" onChange={uploadHandler}/>
                                    </React.Fragment>
                                    :
                                    periodWorksCounts.length === 0
                                        ?
                                        <Box>
                                            <Box className={`flex flex-col`}>
                                                <Btn method={`back`} color={`button`} onClick={() => {
                                                    setImportedCounts([])
                                                }}>Вернуться и загрузить смету заново</Btn>
                                                {isValid &&
                                                    <Typography variant="h1">Общая стоимость работ
                                                        на <b>{Price(countsPrice)} р.</b></Typography>
                                                }
                                                {!isValid &&
                                                    <Alert className="w-fit" severity="error">
                                                        Смета заполнена неверно, есть отсутствующие поля
                                                    </Alert>
                                                }
                                            </Box>
                                            {isValid &&
                                                <Box>
                                                    <form onSubmit={periodWorksSubmit} className={`my-2`}>
                                                        {periodWorksError &&
                                                            <Alert className="w-fit" severity="error">
                                                                {periodWorksError}
                                                            </Alert>
                                                        }
                                                        <div className={`md:flex flex-row`}>
                                                            <div className={`my-0.5 md:my-auto`}>
                                                                Укажите срок выполнения работ в месяцах
                                                                <input
                                                                    type={`number`}
                                                                    name={`deadline`}
                                                                    min={1}
                                                                    max={100}
                                                                    value={periodWorks.deadline}
                                                                    onChange={handleChange}
                                                                    className={`${inputs.work} w-16 ml-2`}
                                                                />
                                                            </div>
                                                            <div className={`md:mx-2 my-0.5 md:my-auto`}>
                                                                Укажите дату начала выполнения работ
                                                                <input
                                                                    type={`date`}
                                                                    name={`startDay`}
                                                                    value={periodWorks.startDay}
                                                                    onChange={handleChange}
                                                                    className={`${inputs.work} ml-2`}
                                                                />
                                                            </div>
                                                            <Button
                                                                onClick={periodWorksSubmit}
                                                                className={`my-0.5 md:my-auto h-fit`}
                                                                variant="contained"
                                                            >
                                                                Далее
                                                            </Button>
                                                        </div>
                                                    </form>
                                                    <div className={tables.container}>
                                                        <table className={tables.table}>
                                                            <TheadSort
                                                                names={headerNames}
                                                                array={importedCounts}
                                                                setArray={setImportedCounts}
                                                            />
                                                            <tbody>{importedCounts.map((row, i) =>
                                                                <tr key={i}>{headerNames.map(n => {

                                                                    if (n.tag === 'name_works') return (
                                                                        <td key={n.tag}>{row[n.tag]}</td>);
                                                                    else if (n.tag === 'units') return (<td key={n.tag}
                                                                                                            className={`text-center`}>{row[n.tag]}</td>);
                                                                    else return (<td key={n.tag}
                                                                                     className={`text-center whitespace-nowrap`}>{Price(row[n.tag])}</td>);
                                                                })}</tr>
                                                            )}</tbody>
                                                        </table>
                                                    </div>
                                                </Box>
                                            }
                                        </Box>
                                        :
                                        <React.Fragment>
                                            <h2>Распределите денежные средства по периодам оплаты</h2>
                                            <div className={`flex flex-row gap-2`}>
                                                <Btn method={`back`} color={`button`} onClick={() => {
                                                    setPeriodWorksCounts([])
                                                }}>Назад</Btn>
                                                <div>
                                                    <div className="flex justify-between">
                                                        <label>Начиная с:</label>
                                                        <Controller
                                                            name="rangeStart"
                                                            control={control}
                                                            render={({field}) => (
                                                                <input className={`${inputs.work} w-16 ml-2`}
                                                                       type="number" min="1"
                                                                       max={periodWorks.deadline} {...field} />
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="flex justify-between">
                                                        <label>По:</label>
                                                        <Controller
                                                            name="rangeEnd"
                                                            control={control}
                                                            render={({field}) => (
                                                                <input className={`${inputs.work} w-16 ml-2`}
                                                                       type="number" min={rangeStart || 1}
                                                                       max={periodWorks.deadline} {...field} />
                                                            )}
                                                        />
                                                    </div>
                                                    <Btn color="button" onClick={distributeHandlerAll}>
                                                        Распределить все
                                                    </Btn>
                                                </div>
                                                <Btn color={`button`} onClick={handleCheckAllPeriods}>
                                                    Далее
                                                </Btn>
                                            </div>
                                            <div
                                                className={`${tables.container} max-h-[60vh] overflow-x-auto overflow-y-auto`}>
                                                <table className={`${tables.table} `}>
                                                    <TheadSort
                                                        names={periodsNames}
                                                        array={importedCounts}
                                                        setArray={setImportedCounts}
                                                    />
                                                    <tbody>
                                                    {importedCounts.map((row, i) => (
                                                        <tr
                                                            key={i}
                                                            className={`${
                                                                allPeriodsError && allPeriodsError.includes(i + 1)
                                                                    ? tables.invalid
                                                                    : ``
                                                            }`}
                                                        >
                                                            {periodsNames.map((p, j) => {
                                                                if (p.tag === "unit_cost_total")
                                                                    return (
                                                                        <td
                                                                            key={j}
                                                                            className={`text-center whitespace-nowrap px-4 min-w-[300px]`}
                                                                            datatype={"price"}
                                                                        >
                                                                            {Price(row[p.tag])}
                                                                        </td>
                                                                    );
                                                                else if (p.tag === "special" || p.tag === "responsible_structures")
                                                                    return (
                                                                        <td key={j}
                                                                            className={`text-center px-4`}>
                                                                            <input
                                                                                className={inputs.work}
                                                                                type={`checkbox`}
                                                                                name={p.tag}
                                                                            />
                                                                        </td>
                                                                    );
                                                                else if (p.tag === "distribute")
                                                                    return (
                                                                        <td key={j} className={`px-4 w-[1450px]`}>
                                                                            <Btn
                                                                                color={`button`}
                                                                                onClick={(e) => distributeHandler(e)}
                                                                            >
                                                                                Распределить
                                                                            </Btn>
                                                                        </td>
                                                                    );
                                                                else if (!isNaN(p.tag))
                                                                    return (
                                                                        <td key={j} className={`px-4 w-[150px]`}>
                                                                            <input
                                                                                className={inputs.work}
                                                                                type={`number`}
                                                                                name={p.tag}
                                                                            />
                                                                        </td>
                                                                    );
                                                                else if (p.tag === 'name_works') return (
                                                                    <td key={j}
                                                                        className={`px-4 min-w-[450px]`}>{row[p.tag]}</td>
                                                                )
                                                                else return <td key={j}
                                                                                className={`px-4`}>{row[p.tag]}</td>;
                                                            })}
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </React.Fragment>
                                :
                                <Box className='p-4'>
                                    <h2>Заполните критерии тендера</h2>
                                    <Btn method={`back`} color={`button`} onClick={() => {
                                        setCountsFinished([])
                                    }}>Назад</Btn>
                                    {applyError && <p className={`text-red-500`}>{applyError}</p>}
                                    <FormProvider {...methods} >
                                        <form onSubmit={methods.handleSubmit(sendTenderHandler)}>
                                            <Grids cols={3}>
                                                {currentTender?.criteria?.map(c => {

                                                    let defaultValue = '';

                                                    if (c.name === "Общая стоимость работ") defaultValue = countsFinished
                                                        .map(c => c.unit_cost_total)
                                                        .reduce((sum, count) => sum + (typeof count === 'number' ? count : 0), 0)
                                                        .toFixed(2)
                                                    ;
                                                    else if (c.name === "Срок выполнения работ в месяцах") {

                                                        defaultValue = periodWorksCounts.length;
                                                    }

                                                    return (<RegistrationInput
                                                        key={c.id}
                                                        type={`number`}
                                                        name={`criteria_${c.id}`}
                                                        label={c.name}
                                                        defaultValue={defaultValue}
                                                        disabled={defaultValue !== ''}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "-") {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    />);
                                                })}
                                            </Grids>
                                            <Box className={`w-max mx-auto flex flex-col gap-4`}>
                                                <Typography variant="h1">Выберите компанию участника</Typography>
                                                <select
                                                    className={inputs.input}
                                                    onChange={(e) => {
                                                        setCurrentSubcompany(+e.target.value)
                                                    }}
                                                >
                                                    {subCompanies.map(s => {
                                                        return (
                                                            <option key={s.id} value={s.id}>{s.name}</option>
                                                        );
                                                    })}
                                                </select>

                                                <Button type={`submit`} variant="contained">
                                                    Отправить заявку на участие
                                                </Button>
                                            </Box>
                                        </form>
                                    </FormProvider>
                                </Box>
                            }
                        </div>
                        :
                        <div>Ваша заявка успешно принята</div>
                    }
                </div>
                :
                <div className={`flex`}>
                    <Alert severity="info" className="items-center">
                        <Box className="flex gap-2 items-center">
                            <Typography>
                                Для подачи на участие в тендере требуется
                            </Typography>
                            <Button
                                variant="contained"
                                href="/authorization"
                            >
                                авторизоваться
                            </Button>
                        </Box>
                    </Alert>

                </div>
            }
        </PageSection>
    );
}

export default ParticipateTender;