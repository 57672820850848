import UserSection from "../../UI/Sections/UserSection";
import {useNavigate} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import UserContext from "../../../store/user/user-context";
import axios from "axios";
import tableStyles from "../../UI/Tables/table.module.css";
import Btn from "../../UI/Buttons/Btn";
import Grids from "../../UI/Grids/Grids";
import styles from "../dash.module.css";
import Popup from "../../UI/Popup/Popup";
import PrintCalendar from "./PrintCalendar";
import {ShortName} from "../../UI/Convertors/ShortName";
import {Button} from "@mui/material";
import {getRangeDates} from "./getRangeDates";

const ProductionCalendar = () => {
    const navigate = useNavigate();
    const userSettings = useContext(UserContext);
    const company_id = window.localStorage.getItem('company');
    const project_id = window.localStorage.getItem('project');


    if (window.localStorage.getItem('role') !== '2') navigate('/work/');

    const [range, setRange] = useState(
        {
            first: "",
            second: ""
        }
    )

    const [rangeMode, setRangeMode] = useState(false);

    const [popupShow, setPopupShow] = useState(false);
    const [users, setUsers] = useState([]);
    const [mainCalendar, setMainCalendar] = useState(null);

    useEffect(() => {
        const getData = async () => {
            const users = await axios.get(
                `${userSettings.api}/company/${company_id}/users`,
                userSettings.headers.get
            )
                .then(response => {
                    if (response.data) {
                        const filteredUsers = response.data.users.filter(u => u.projects.includes(+project_id));
                        setUsers(filteredUsers);
                    }
                })
                .catch(error => console.error('\n error', error));

            const calendar = await axios.get(
                `${userSettings.api}/production-calendar/${new Date().getFullYear()}?type=month`,
                userSettings.headers.get
            )

            const calendarData = calendar.data.filter(month => month.days.length !== 0);

            setMainCalendar(calendarData);
        }

        getData();
    }, [company_id, userSettings, project_id]);

    const [user_id, setUser_id] = useState();
    const [userCalendar, setUserCalendar] = useState();
    const [hoursStatistics, setHoursStatistics] = useState();

    const dataFromServer = (dates, calendar) => {
        setUserCalendar(() => {

            return calendar.map((month) => {
                if (month.days) {
                    month.days = month.days.map((day) => {
                        const foundDate = dates.find((userDay) => userDay.date === day.date);
                        if (foundDate) {
                            if (!foundDate.is_workday && foundDate.hours > 0) {
                                day.hours = 0;
                            } else {
                                day.hours = foundDate.hours;
                            }
                            day.is_workday = foundDate.is_workday;
                            day.id = foundDate.id;
                            day.comment = foundDate.comment;
                        }
                        return day;
                    });
                }
                return month;
            });
        });
    };

    useEffect(() => {
        if (user_id && mainCalendar) {

            axios.get(`${userSettings.api}/production-calendar/${new Date().getFullYear()}/companies/${company_id}/users/${user_id}`, userSettings.headers.get)
                .then(response => {
                    const data = response.data.length > 0 ? response.data : [];

                    dataFromServer(data, mainCalendar);
                })
                .catch(error => {
                    console.error('\n error', error.message);
                });
        }
    }, [company_id, user_id, mainCalendar, userSettings]);

    useEffect(() => {
        if (userCalendar && userCalendar.length > 0) {
            const calculateYearTotal = (calendar) => {
                if (!calendar) {
                    return 0;
                }

                let totalHours = 0;

                calendar.forEach((month) => {
                    if (month.days) {
                        month.days.forEach((day) => {
                            totalHours += day.hours;
                        });
                    }
                });

                return totalHours;
            };

            const yearMainTotal = calculateYearTotal(mainCalendar);
            const yearUserTotal = calculateYearTotal(userCalendar);

            const yearHoursMainCalendar = mainCalendar.map((m) => ({
                month: m.month,
                hours: m.days.reduce((total, day) => total + day.hours, 0),
            }));

            const yearHoursUserCalendar = userCalendar.map((m) => ({
                month: m.month,
                hours: m.days.reduce((total, day) => total + day.hours, 0),
            }));

            setHoursStatistics({
                yearMainTotal,
                yearUserTotal,
                yearHoursMainCalendar,
                yearHoursUserCalendar,
            });
        }
    }, [mainCalendar, userCalendar]);

    const filteredUser = users.find(u => u.id === user_id);
    const [editCalendar, setEditCalendar] = useState(false);
    const [selectedDays, setSelectedDays] = useState([]);

    const changeDayHandler = () => {
        const changeDay = (data) => {
            const formData = selectedDays.map(date => ({
                "date": date,
                "hours": data.hours,
                "is_workday": data.is_workday,
                "comment": data.reason,
            }));

            axios.post(
                `${userSettings.api}/production-calendar/companies/${company_id}/users/${user_id}/days`,
                formData,
                {headers: userSettings.headers.post}
            )
                .then((response) => {
                    dataFromServer(response.data, userCalendar);
                    setPopupShow(false);
                    setEditCalendar(false);
                    setSelectedDays([]);
                })
                .catch(error => console.error('\n error', error));
        };

        setPopupShow(
            <Popup
                title={`Изменить даты`}
                calendar={selectedDays}
                onSubmit={changeDay}
                onCloseModal={() => setPopupShow(false)}
            />
        );
    };

    const changePeriodHandler = () => {
        const selectedPeriod = getRangeDates(range, userCalendar);

        const changePeriod = (data) => {
            const formData = selectedPeriod.map(date => ({
                "date": date,
                "hours": data.hours,
                "is_workday": data.is_workday,
                "comment": data.reason,
            }));

            axios.post(
                `${userSettings.api}/production-calendar/companies/${company_id}/users/${user_id}/days`,
                formData,
                {headers: userSettings.headers.post}
            )
                .then((response) => {
                    dataFromServer(response.data, userCalendar);
                    setPopupShow(false);
                    setRangeMode(false);
                    setRange({first: "", second: ""});
                })
                .catch(error => console.error('\n error', error));
        };

        setPopupShow(
            <Popup
                title={`Изменить период`}
                calendar={selectedPeriod}
                onSubmit={changePeriod}
                onCloseModal={() => setPopupShow(false)}
            />
        );
    };

    const monthNames = {
        1: `Январь`,
        2: `Февраль`,
        3: `Март`,
        4: `Апрель`,
        5: `Май`,
        6: `Июнь`,
        7: `Июль`,
        8: `Август`,
        9: `Сентябрь`,
        10: `Октябрь`,
        11: `Ноябрь`,
        12: `Декабрь`
    };
    const enableRangeMode = () => setRangeMode(true);
    const cancelRangeMode = () => {
        setRangeMode(false);
        setRange({first: "", second: ""})
    };

    return (
        <UserSection>
            {popupShow}
            <h1>Производственный календарь</h1>
            {(users.length > 0 && !user_id) && (
                <React.Fragment>
                    <h2>Пользователи:</h2>
                    <div className={tableStyles.container}>
                        <table className={tableStyles.table}>
                            <thead>
                            <tr>
                                <td>Пользователь</td>
                                <td>Роль</td>
                                <td>Редактировать</td>
                            </tr>
                            </thead>
                            <tbody>
                            {users.map((user) => (
                                <tr key={user.id}>
                                    <td>
                                        {user.short_name && (
                                            <div className={`text-2xl whitespace-nowrap`}>{user.short_name}</div>
                                        )}
                                        <div>{user.email}</div>
                                        {user.phone && <div>{user.phone}</div>}
                                    </td>
                                    <td>
                                        <div className={`flex flex-wrap`}>
                                            {user.role.map((role) => (
                                                <span
                                                    key={role}
                                                    className={`py-0.5 px-1.5 m-0.5 border border-pult/10 rounded-md bg-white whitespace-nowrap h-max my-auto`}
                                                >
                            {userSettings.template_roles[role]}
                          </span>
                                            ))}
                                        </div>
                                    </td>
                                    <td>
                                        <Btn
                                            color="button"
                                            icon={
                                                <svg viewBox="0 0 16 16" className={`scale-75`}>
                                                    <path
                                                        fill="currentColor"
                                                        d="M5 6h2v2H5zm3 0h2v2H8zm3 0h2v2h-2zm-9 6h2v2H2zm3 0h2v2H5zm3 0h2v2H8zM5 9h2v2H5zm3 0h2v2H8zm3 0h2v2h-2zM2 9h2v2H2zm11-9v1h-2V0H4v1H2V0H0v16h15V0h-2zm1 15H1V4h13v11z"
                                                    />
                                                </svg>
                                            }
                                            className={`mx-auto h-max`}
                                            onClick={() => setUser_id(user.id)}
                                        >Календарь</Btn>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </React.Fragment>
            )}
            {(users.length > 0 && user_id) && (
                <React.Fragment>
                    <Grids cols="4">
                        {filteredUser && (
                            <React.Fragment>
                                <div className={`w-max`}>
                                    {(filteredUser.short_name || filteredUser.full_name) && (
                                        <div className={`text-2xl`}>
                                            {filteredUser.short_name ? filteredUser.short_name : ShortName(filteredUser.full_name)}
                                        </div>
                                    )}
                                    <div>{filteredUser.email}</div>
                                    {filteredUser.phone && <div>{filteredUser.phone}</div>}
                                </div>
                                <div className={`flex flex-wrap`}>{filteredUser.role.map((role) => (
                                    <span key={role} className={styles.items}>{userSettings.template_roles[role]}</span>
                                ))}</div>
                            </React.Fragment>
                        )}
                        {(hoursStatistics !== null && hoursStatistics !== undefined) && (
                            <div className={`my-auto`}>
                                <div>Всего рабочих часов в году - {hoursStatistics.yearMainTotal}</div>
                                <div>Всего рабочих часов у пользователя - {hoursStatistics.yearUserTotal}</div>
                            </div>
                        )}
                        <div className={`my-auto`}>
                            <Btn
                                color="button"
                                icon={
                                    <svg viewBox="0 0 16 16" className={`scale-75`}>
                                        <path
                                            fill="currentColor"
                                            d="M5 6h2v2H5zm3 0h2v2H8zm3 0h2v2h-2zm-9 6h2v2H2zm3 0h2v2H5zm3 0h2v2H8zM5 9h2v2H5zm3 0h2v2H8zm3 0h2v2h-2zM2 9h2v2H2zm11-9v1h-2V0H4v1H2V0H0v16h15V0h-2zm1 15H1V4h13v11z"
                                        />
                                    </svg>
                                }
                                className={`mx-auto h-max`}
                                onClick={() => {
                                    setPopupShow(
                                        <Popup
                                            title={`Рабочих часов в году`}
                                            content={
                                                <div className={tableStyles.container}>
                                                    <table className={`${tableStyles.table} table-fixed`}>
                                                        <thead>
                                                        <tr className={`text-center`}>
                                                            <td>Месяц</td>
                                                            <td>8ч р/д</td>
                                                            <td>Сформированный р/д</td>
                                                            <td>Разница</td>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {hoursStatistics && hoursStatistics.yearHoursMainCalendar.map((m, id) =>
                                                            <tr key={id}>
                                                                <td>{monthNames[m.month]}</td>
                                                                <td className={`text-center`}>{m.hours}</td>
                                                                <td className={`text-center`}>{hoursStatistics.yearHoursUserCalendar[id].hours}</td>
                                                                <td className={`text-center`}>{hoursStatistics.yearHoursUserCalendar[id].hours - m.hours}</td>
                                                            </tr>
                                                        )}
                                                        <tr>
                                                            <td>Итого за год</td>
                                                            <td className={`text-center`}>{hoursStatistics.yearMainTotal}</td>
                                                            <td className={`text-center`}>{hoursStatistics.yearUserTotal}</td>
                                                            <td/>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            }
                                            onCloseModal={() => setPopupShow(false)}
                                        />
                                    );
                                }}
                            >
                                Сформированный график
                            </Btn>
                            <Btn
                                color="button"
                                method="back"
                                className={`mx-auto h-max`}
                                onClick={() => {
                                    setUser_id(null)
                                    setUserCalendar(null);
                                }}
                            >Назад</Btn>
                        </div>
                    </Grids>
                    <div className={`my-2.5 flex w-max`}>
                        <div className={`my-auto mr-2`}>Календарь на {new Date().getFullYear()}</div>
                        {!editCalendar && (
                            <React.Fragment>
                                <Btn
                                    color="button"
                                    method="edit"
                                    className={`mr-2`}
                                    onClick={() => setEditCalendar(true)}
                                >Редактировать календарь</Btn>
                            </React.Fragment>
                        )}
                        {editCalendar && (
                            <React.Fragment>
                                {selectedDays.length > 0 && (
                                    <Btn
                                        color="button"
                                        method="edit"
                                        className={`ml-2 h-max`}
                                        onClick={changeDayHandler}
                                    >Редактировать даты</Btn>
                                )}
                                <Btn
                                    color="button"
                                    method="cancel"
                                    className={`ml-2 h-max`}
                                    onClick={() => {
                                        setEditCalendar(false);
                                        setSelectedDays([]);
                                    }}
                                >Отмена</Btn>
                            </React.Fragment>
                        )}
                        <Button
                            onClick={rangeMode ? cancelRangeMode : enableRangeMode}
                            variant={rangeMode ? "outlined" : "contained"}
                        >
                            {rangeMode ? "Отменить выбор" : "Выбрать период"}
                        </Button>

                        {range.first && range.second &&
                            <Button
                                onClick={changePeriodHandler}
                                variant='contained'
                            >
                                Изменить период с {range.first} по {range.second}
                            </Button>
                        }
                    </div>
                    {userCalendar && (
                        <div>
                            <PrintCalendar
                                data={userCalendar}
                                editCalendar={editCalendar}
                                rangeMode={rangeMode}
                                range={range}
                                setRange={setRange}
                                selectedDays={selectedDays}
                                setSelectedDays={setSelectedDays}
                            />
                        </div>
                    )}

                </React.Fragment>
            )}
        </UserSection>
    );
};

export default ProductionCalendar;