import React, {useContext, useEffect, useState} from 'react';
import {Box, Typography} from "@mui/material";
import UserContext from "../../../store/user/user-context";
import {getEstimateData} from "../../SendRequests/workJournalData";
import tables from "../../UI/Tables/table.module.css";
import TheadSort from "../../UI/Tables/TheadSort";
import {Price} from "../../UI/Convertors/Price";

export default function ReportKS6A () {

    const userSettings = useContext(UserContext);
    const subcompany_id = +window.localStorage.getItem('company');
    const tender_id = +window.localStorage.getItem('tender');

    const [works, setWorks] = useState(null);
    const [periods, setPeriods] = useState(null);

    useEffect(() => {

        const getEstimate = async () => {
            const estimate = await getEstimateData(userSettings, subcompany_id, tender_id);

            if (estimate.success) {

                const approvedWorks = estimate.data
                    .map(w => {

                        const periods = w.periods.map(({ date, value }) => ({
                            date: new Date(date).toISOString().slice(0, 7) + '-01',
                            value
                        }));

                        const work_log = w.work_log.filter(l => l.certificates_completed_work.length > 0);

                        const fact = Object.entries(
                                work_log
                                    .map(({ date, value }) => {
                                        const firstDay = new Date(date).toISOString().slice(0, 7) + '-01';
                                        return { firstDay, value: Number(value) };
                                    })
                                    .reduce((acc, { firstDay, value }) => {
                                        acc[firstDay] = (acc[firstDay] || 0) + value;
                                        return acc;
                                    }, {})
                            ).map(([date, value]) => ({ date, value }))
                        ;

                        const unit_cost_materials = +w.unit_cost_materials;
                        const unit_cost_smr = +w.unit_cost_smr;
                        const unit_cost = +w.unit_cost_materials + +w.unit_cost_smr;
                        const total_value = Object.values(fact).reduce((sum, f) => sum + f.value, 0);
                        const total_cost = total_value * unit_cost;

                        return {
                            ...w,
                            work_log,
                            periods,
                            fact,
                            unit_cost_materials,
                            unit_cost_smr,
                            unit_cost,
                            total_value,
                            total_cost,
                        };
                    })
                    .filter(w => w.work_log.length > 0);
                setWorks(approvedWorks);

                const updatePeriods = [...new Set(
                    approvedWorks.flatMap(work => [
                        ...work.fact.map(f => f.date)
                    ])
                )].sort();
                setPeriods(updatePeriods);

            }
        };

        getEstimate();
    }, [userSettings, subcompany_id, tender_id]);

    const columns = [
        {
            tag: "number",
            name: "№",
        },
        {
            tag: "ssr",
            name: "ССР",
        },
        {
            tag: "name_works",
            name: "Наименование работ",
        },
        {
            tag: "units",
            name: "Ед. изм.",
        },
        {
            tag: "value",
            name: "Объем",
        },
        {
            tag: "unit_cost_materials",
            name: "Стоимость материалов",
        },
        {
            tag: "unit_cost_smr",
            name: "Стоимость СМР",
        },
        {
            tag: "unit_cost",
            name: "Стоимость за ед.",
        },
    ];
    if (periods?.length > 0) {

        periods.forEach(date => {

            const months = [
                "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
                "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
            ];
            const month = months[new Date(date).getMonth()];
            const year = new Date(date).getFullYear();

            columns.push({
                name: (<React.Fragment>
                    <div>{month}</div>
                    <div>{year}</div>
                </React.Fragment>),
                date
            });

        });

        columns.push({
            tag: "total_value",
            name: "Общий итог",
        });
        columns.push({
            tag: "total_cost",
            name: "Общая стоимость работ",
        });
    }

    return (
        <Box>
            <Typography variant="h1">Отчёт КС-6а</Typography>
            {works && <Box className={`${tables.container} grow`}>
                <table className={tables.table}>
                    <TheadSort
                        array={works}
                        names={columns}
                        setArray={setWorks}
                    />
                    <tbody>
                    {works.map(w => {

                        return (<tr key={w.id} className={`text-center`}>
                            {columns.map((sn, index) => {

                                const price = (
                                    sn.tag === "value" ||
                                    sn.tag === "unit_cost_materials" ||
                                    sn.tag === "unit_cost_smr" ||
                                    sn.tag === "unit_cost" ||
                                    sn.tag === "total_value" ||
                                    sn.tag === "total_cost"
                                ) && Price(w[sn.tag]);
                                const date = sn.date && w.fact.find(f => f.date === sn.date)?.value;

                                return (
                                    <td key={index} className={`${sn.tag === "name_works" ? "text-left min-w-[200px]" : ""}`}>
                                        {
                                            price ?
                                                price :
                                                date ?
                                                    Price(date) :
                                                    w[sn.tag]
                                        }
                                    </td>)
                            })}
                        </tr>);
                    })}
                    </tbody>
                </table>
            </Box>}
        </Box>
    );
};