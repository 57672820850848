import React, {useContext, useEffect, useState} from "react";
import UserSection from "../../UI/Sections/UserSection";
import axios from "axios";
import UserContext from "../../../store/user/user-context";
import {Link} from "react-router-dom";
import Btn from "../../UI/Buttons/Btn";

export default function SectionManagerTasks() {

    const userSettings = useContext(UserContext);
    const company_id = window.localStorage.getItem('company');
    const project_id = window.localStorage.getItem('project');

    const [orders, setOrders] = useState(null);
    const [sections, setSections] = useState(null);

    useEffect(() => {
        const getOrders = async () => {
            try {
                const buildingListResponse = await axios.get(
                    `${userSettings.api}/tep-building/companies/${company_id}/projects/${project_id}/buildings/`,
                    userSettings.headers.get
                );

                const buildingPromises = buildingListResponse.data.data.map(async (building) => {
                    const sectionListResponse = await axios.get(
                        `${userSettings.api}/plans-configurator/companies/${company_id}/projects/${project_id}/building/${building.id}/sections/`,
                        userSettings.headers.get
                    );

                    const sections = sectionListResponse.data;
                    if (sections && sections.length > 0) {
                        setSections(sections);

                        const planPromises = sections.map(async (section) => {
                            const plansResponse = await axios.get(
                                `${userSettings.api}/building-plans/companies/${company_id}/projects/${project_id}/buildings/${building.id}/sections/${section.id}/plans/`,
                                userSettings.headers.get
                            );

                            const plansWithData = plansResponse.data.map((plan) => ({
                                ...plan,
                                building_id: building.id
                            }));

                            return plansWithData;
                        });

                        const sectionPlans = await Promise.all(planPromises);
                        return sectionPlans.flat(); // Преобразуем массив массивов в один плоский массив
                    }

                    return [];
                });

                const allSectionPlans = await Promise.all(buildingPromises);
                const allOrders = allSectionPlans.flat(); // Преобразуем массив массивов в один плоский массив
                setOrders(allOrders);
            } catch (error) {
                console.error('Error fetching orders:', error);
            }
        };

        getOrders();
    }, [company_id, project_id, userSettings]);

    return (
        <UserSection>
            <h1>Предписания от Начальника участка</h1>
            {(orders && orders.length > 0) && <React.Fragment>
                <div>Предписания ГИП</div>
                <div className={`flex flex-wrap gap-2`}>
                    {orders.map(o =>
                        <Btn color={'link'} key={o.id}>
                            <Link
                                to={`/work/engineer/section-manager-tasks/view-order/?company=${company_id}&project=${project_id}&building=${o.building_id}&section=${o.section}&floor=${o.floor}&order=${o.id}`}
                            >
                                {o.building_id} - {o.section} - {o.id}: {o.comment}
                            </Link>
                        </Btn>
                    )}
                </div>
            </React.Fragment>}
        </UserSection>
    );
}