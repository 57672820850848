import React, {useContext, useEffect, useState} from 'react';
import {Alert, Box, Button, ButtonGroup, Snackbar, Typography} from "@mui/material";
import {
    getTariffsRequests,
    getTariffsRequestsByIdAccept,
    getTariffsRequestsByIdDecline
} from "../../UI/GetInfo/getTariffsRequests";
import UserContext from "../../../store/user/user-context";
import SendIcon from "@mui/icons-material/Send";
import {getTariffsCompanies} from "../../SendRequests/TariffsData";
import {patchCompaniesTariffById, postCompaniesTariffByIdCreateRequest} from "../../UI/GetInfo/getTariffsChanges";
import Popup from "../../UI/Popup/Popup";
import CustomTariffProposeModal from "./CustomTariffProposeModal";
import UserSection from "../../UI/Sections/UserSection";
import RequestItem from "../common/components/RequestItem";
import {getLotsForRequest} from "../common/helpers/getLotsForRequest";
import TariffStats from "../common/components/TariffStats";

const AdministratorTariffRequests = () => {
    const userSettings = useContext(UserContext);
    const company_id = +localStorage.getItem("company");

    const [requests, setRequests] = useState(null);

    const [prevAdded, setPrevAdded] = useState(null);

    const [popupShow, setPopupShow] = useState(false);

    const [acceptError, setAcceptError] = useState(false);
    const [acceptSuccess, setAcceptSuccess] = useState(false);

    const [declineSuccess, setDeclineSuccess] = useState(false);

    const [adminIsAllowed, setAdminIsAllowed] = useState(null);

    useEffect(() => {
        const getData = async () => {
            const tariffsRequests = await getTariffsRequests(userSettings)
            const newTariffs = tariffsRequests.filter(tariff => tariff.status === "new")
            const previous = await getAddedLotsInCompany();
            const tariff = await getTariffsCompanies([company_id], userSettings);

            setAdminIsAllowed(tariff[0]?.tariff.is_administrator_allowed_edit_tariff)
            setPrevAdded(previous);
            setRequests(newTariffs);
        }

        getData().then(() => "got data")
    }, []);


    const handleAddLotToTariff = async () => {
        try {
            const tariff = await getTariffsCompanies([company_id], userSettings);
            const added_contracts = tariff[0].tariff.contract.add // tariff

            const body = {
                "add_contract": added_contracts + 1
            }

            if (adminIsAllowed) {
                const patchResponse = await patchCompaniesTariffById(userSettings, company_id, body)
                return;
            }

            const postResponse = await postCompaniesTariffByIdCreateRequest(userSettings, company_id, body)
        } catch (error) {
            console.error("error status: ", error.response.status)
        }
    };

    const getAddedLotsInCompany = async () => {
        const tariff = await getTariffsCompanies([company_id], userSettings);
        const tariffObject = tariff[0].tariff;

        const addedCount = {}
        for (const [key, value] of Object.entries(tariffObject)) {
            if (value?.price) addedCount[key] = value;
        }

        return addedCount;
    }

    const handleAcceptRequest = async (request_id) => {
        try {
            const accept = await getTariffsRequestsByIdAccept(userSettings, request_id)
            setRequests(prev => prev.filter(req => req.id !== request_id));
            setAcceptSuccess(true);

        } catch (error) {
            setAcceptError(true);
        }
    };

    const handleDeclineRequest = async (request_id) => {
        try {
            const decline = await getTariffsRequestsByIdDecline(userSettings, request_id)
            setRequests(prev => prev.filter(req => req.id !== request_id))
            setDeclineSuccess(true);
        } catch (error) {
        }
    };

    const handleOpenPopUp = () => {
        const handleCustomChangeTariff = async (data) => {
            try {
                setPopupShow(false);

                const tariff = await getTariffsCompanies([company_id], userSettings);

                const adminIsAllowed = tariff[0].tariff.is_administrator_allowed_edit_tariff;

                const previousAdded = await getAddedLotsInCompany();

                const body = {
                    "add_investor": 0,
                    "add_administrator": previousAdded.administrator.add + (+data.administrator),
                    "add_coordinator": previousAdded.coordinator.add + (+data.coordinator),
                    "add_build_control": previousAdded.build_control.add + (+data.build_control),
                    "add_manager": previousAdded.manager.add + (+data.manager),
                    "add_gip": previousAdded.gip.add + (+data.gip),
                    "add_contract": previousAdded.contract.add + (+data.contract)
                }

                if (adminIsAllowed) {
                    const patchResponse = await patchCompaniesTariffById(userSettings, company_id, body)
                    return;
                }

                const postResponse = await postCompaniesTariffByIdCreateRequest(userSettings, company_id, body)
            } catch (error) {
                console.error(error?.response?.status)
            }
        };

        setPopupShow(<Popup
            title='Создание запроса на изменение тарифа'
            content={<CustomTariffProposeModal
                onSubmit={handleCustomChangeTariff}
            />}

            onCloseModal={() => setPopupShow(false)}
        />)
    }

    return (
        <UserSection>
            <Box className="flex flex-col gap-6">
                {popupShow}
                <Snackbar
                    anchorOrigin={{vertical: "top", horizontal: "right"}}
                    open={acceptSuccess}
                    autoHideDuration={2000}
                    onClose={() => setAcceptSuccess(false)}
                >
                    <Alert severity="success">
                        Изменения успешно принято
                    </Alert>
                </Snackbar>

                <Snackbar
                    anchorOrigin={{vertical: "top", horizontal: "right"}}
                    open={acceptError}
                    autoHideDuration={2000}
                    onClose={() => setAcceptError(false)}
                >
                    <Alert severity="warning">
                        Нельзя принять тариф, только отклонить
                    </Alert>
                </Snackbar>

                <Snackbar
                    anchorOrigin={{vertical: "top", horizontal: "right"}}
                    open={declineSuccess}
                    autoHideDuration={2000}
                    onClose={() => setDeclineSuccess(false)}
                >
                    <Alert severity="success">
                        Тариф успешно отклонён
                    </Alert>
                </Snackbar>

                {adminIsAllowed && requests?.length > 0 &&
                    <Box>
                        <Typography variant="h1">Список заявок на увеличение количества мест:</Typography>
                        {requests?.map(request =>
                            <Box key={request.id}>
                                {prevAdded && <RequestItem request={request} previousAdded={prevAdded}/>}

                                <ButtonGroup className='mt-2'>
                                    <Button onClick={() => handleAcceptRequest(request.id)}>
                                        Согласовать
                                    </Button>
                                    <Button onClick={() => handleDeclineRequest(request.id)}>
                                        Отклонить
                                    </Button>
                                </ButtonGroup>
                            </Box>
                        )}
                    </Box>
                }
                <Box>
                    <Typography variant='h1'>Создать уникальный запрос изменения тарифов:</Typography>
                    <Button
                        className={'mt-4'}
                        onClick={handleOpenPopUp}
                        variant="contained"
                        endIcon={<SendIcon/>}
                    >
                        Создать запрос на новые места в компании
                    </Button>
                </Box>
                <Box>
                    <Typography variant='h1'>Увеличить на 1 количество тендеров:</Typography>
                    <Button
                        className={'mt-4'}
                        onClick={handleAddLotToTariff}
                        variant="contained"
                        endIcon={<SendIcon/>}
                    >
                        Создать запрос на получение дополнительного слота тендер
                    </Button>
                </Box>

                <TariffStats prevAdded={prevAdded}/>
            </Box>
        </UserSection>
    );
};

export default AdministratorTariffRequests;