import {useContext, useEffect, useState} from "react";
import {getTender} from "../../../../UI/GetInfo/getSubcompanies";
import {getCompanyProjectPersons, getObjectName} from "../../../../UI/GetInfo/getCompany";
import UserContext from "../../../../../store/user/user-context";
import {getQualityDocs} from "../../../../UI/GetInfo/getTendersInfo";
import {validateWorkDatesAndNames} from "../../Akt-osvidetelstvovaniya/helpers/validateWorkDatesAndNames";
import {getWorkLogValueAndCost} from "../helpers/getWorkLogValueAndCost";
import {getEstimateElements} from "../../../../UI/GetInfo/getEstimations";

const useFetchedInfo = (subcompany_id, tender_id) => {
    const userSettings = useContext(UserContext);
    const [fetchedInfo, setFetchedInfo] = useState(null);
    const [errorInfo, setErrorInfo] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function getInfo() {
            const allTenders = await getTender(userSettings, subcompany_id);
            const currentTender = allTenders.find(tender => tender.tender.id === tender_id);

            const signatories = {
                company: currentTender?.contract?.company_info,
                subcompany: currentTender?.contract?.subcompany_info,
            }

            const company = signatories.company;
            const project_id = currentTender.tender.project.id;
            const object_name = await getObjectName(userSettings, company.id, project_id);

            const subcompany = userSettings.companies.find(s => s.id === subcompany_id);

            const personsList = await getCompanyProjectPersons(userSettings, company.id, project_id);

            const estimateElements = await getEstimateElements(userSettings, currentTender.contract.main, currentTender.contract.project_id, tender_id, currentTender.contract.subcompany);

            const qualityDocs = await getQualityDocs(userSettings, currentTender.contract.main, currentTender.contract.project_id, tender_id, currentTender.contract.subcompany)
            const filteredQualityDocs = qualityDocs.filter(doc => !doc.is_approved);
            const {workDates, workNames, works, allDates} = validateWorkDatesAndNames(filteredQualityDocs, estimateElements, true);

            const logsForTable = works.map(work => ({
                    id: work.id,
                    number: work.number,
                    units: work.units,
                    name_works: work.name_works,
                    ...getWorkLogValueAndCost(work)
                }
            ))
            const summary = (logsForTable.reduce((acc, cur) => acc + Number(cur.totalCost), 0))
            const summaryPerUnit = logsForTable.reduce((acc, unit) => acc + +unit.costByUnit, 0)
            const summaryCount = logsForTable.reduce((acc, count) => acc + +count.value, 0)

            return {
                allTenders,
                currentTender,
                company,
                project_id,
                object_name,
                subcompany,
                personsList,
                workDates,
                workNames,
                logsForTable,
                summary,
                signatories,
                summaryPerUnit,
                summaryCount,
                // estimateElements,
            }
        }

        getInfo()
            .then(setFetchedInfo)
            .catch(setErrorInfo)
            .finally(() => setIsLoading(false));
    }, [userSettings, subcompany_id, tender_id]);
    return {fetchedInfo, errorInfo, isLoading};
};

export default useFetchedInfo;