import React from "react";
import {Document, Font, Page, StyleSheet, Text, View} from "@react-pdf/renderer";

Font.register({
    family: "Roboto", src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"
});

const styles = StyleSheet.create({
    body: {
        fontFamily: "Roboto", fontSize: 10, padding: 20, display: "flex", flexDirection: "column", gap: "12px"
    },
    page: {
        display: "flex",
        flexDirection: "column",
    },
    title: {
        fontFamily: 'Roboto',
        fontSize: 12,
        fontWeight: 600
    },
    container: {
        display: "flex",
        flexDirection: "row",
        paddingLeft: 5
    },
    leftColumn: {
        flex: 2,
    },
    rightColumn: {
        flex: 1
    },
    block: {
        margin: "10px 0",
    },
    bigPadding: {
        padding: 10
    },
    boldBorder: {
        border: '1px solid black',
    },
    row: {
        display: "flex",
        flexDirection: "row",
    },
    spaceBetween: {
        display: "flex", justifyContent: "space-between"
    },
    rowHeight: {
        height: 30
    },
    smallHeader: {
        border: "0.5px solid black",
        margin: "-0.5px -0.5px 0 0"
    },
    textCenter: {
        textAlign: "center",
    },
    center: {
        alignItems: "center",
        justifyContent: "center"
    },
    end: {
        alignItems: "flex-end",
    },
    horizontalBreak: {
        width: "100%",
        borderBottom: "1px solid black",
    },
    underline: {
        borderBottom: "1px solid black",
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#bfbfbf",
    },
    tableRow: {
        flexDirection: "row",
    },
    tableColHeader: {
        borderStyle: "solid",
        borderWidth: 0.5,
        padding: 5,
        textAlign: "center",
        fontWeight: "bold",
    },
    tableCol: {
        borderStyle: "solid",
        borderWidth: 0.5,
        padding: 5,
    },
    tableCell: {
        margin: 5,
    },
    firstCol: {
        width: "60%",
        padding: 5,
    },
    secondCol: {
        width: "15%",
        textAlign: "center",
    },
    thirdCol: {
        width: "25%",
        textAlign: "center",
    },
});

const AdmittanceAct = (props) => {
    return (
        <Document title='АКТ-ДОПУСК для производства строительно-монтажных работ на территории организации'>
            <Page style={styles.body}>
                <View style={styles.end}>
                    <Text>ПРИЛОЖЕНИЕ В</Text>
                    <Text>СНиП 12-03-2001
                    </Text>
                </View>
                <View style={styles.center}>
                    <Text>АКТ-ДОПУСК</Text>
                    <View style={[styles.center, styles.textCenter]}>
                        <Text style={{width: "370px"}}>для производства строительно-монтажных работ на территории
                            организации
                            к договору №_________________________.
                        </Text>
                    </View>
                </View>

                <View style={[styles.row, styles.spaceBetween]}>
                    <Text>г. Санкт-Петербург</Text>
                    <Text>"___" ________202_г.</Text>
                </View>

                <Text>ООО «___________», именуемое в дальнейшем "Подрядчик", в лице _________________., действующего на
                    основании приказа №___от ______________, </Text>
                <Text>подписал настоящий акт о следующем:</Text>

                <View>

                    <Text>Организация Подрядчик предоставляет участок (территорию), по адресу:</Text>
                    <Text style={styles.horizontalBreak}>{" "}</Text>
                    <Text style={styles.textCenter}>(наименование осей, отметок и номер чертежа)</Text>
                </View>

                <View>
                    <Text>для производства на нем</Text>
                    <Text style={styles.horizontalBreak}>{" "}</Text>
                    <Text style={styles.textCenter}>(наименование работ)</Text>
                </View>

                <View>
                    <Text>под руководством технического персонала подрядчика на следующий срок:</Text>
                    <Text>начало «____» _______ 202_г. окончание «__» _________ 202_г.</Text>
                    <Text>Работы проводить в согласованное сторонами время и с соблюдением регламентированного шумового
                        режима.</Text>
                    <Text>До начала работ необходимо выполнить следующие мероприятия, обеспечивающие безопасность
                        производства работ:</Text>
                </View>

                <View style={styles.table}>
                    {/* Header Row */}
                    <View style={styles.tableRow}>
                        <Text style={[styles.tableColHeader, styles.firstCol]}>Наименование мероприятия</Text>
                        <Text style={[styles.tableColHeader, styles.secondCol]}>Срок выполнения</Text>
                        <Text style={[styles.tableColHeader, styles.thirdCol]}>Исполнитель</Text>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, styles.firstCol]}>
                            <Text>
                                1. Представить приказы (копии):
                            </Text>
                            <Text>
                                • Приказ о закреплении персонала (укомплектованности) на участке (ИТР, рабочие) с
                                указанием
                                контактных номеров телефонов работников подрядной организации, ответственных за
                                производство
                                работ.
                            </Text>
                            <Text>

                                • Приказ о назначении специалиста по охране труда или о возложении обязанностей
                                специалиста
                                по охране труда на одного из специалистов организации.
                            </Text>
                            <Text>
                                • Приказ о назначении специалиста по охране труда от организации на строительную
                                площадку,
                                если численность работников превышает 50 человек.
                            </Text>
                            <Text>
                                • Приказы о назначении лиц, ответственных за соблюдение требований охраны труда,
                                промышленной безопасности, электробезопасности, пожарной безопасности, санитарных норм и
                                правил, экологическую безопасность на объекте с приложением копий протоколов и
                                удостоверений.
                            </Text>
                            <Text>
                                • Приказ о назначении ответственных за организацию погрузочно-разгрузочных работ.
                            </Text>
                            <Text>
                                • Приказ о назначении ответственного за электрохозяйство с приложением копий протоколов
                                и
                                удостоверений.
                            </Text>
                            <Text>
                                • Приказ о назначение лица, ответственного за сохранность и исправность
                                электроинструмента.

                            </Text>
                            <Text>
                                • Приказ о возложение обязанностей по проверке и браковке инструмента на
                                инженерно-технического работника.
                            </Text>
                            <Text>
                                • Приказ о назначение лица, ответственного за состояние и исправность лестниц и
                                стремянок.

                            </Text>
                            <Text>
                                • Приказ о назначении специалиста, ответственного за осуществление производственного
                                контроля при эксплуатации ПС с приложением копий протоколов и удостоверений.
                            </Text>
                            <Text>
                                • Приказ о назначении специалиста, ответственного за содержание ПС в работоспособном
                                состоянии с приложением копий протоколов и удостоверений.
                            </Text>
                            <Text>
                                • Приказ о назначении специалиста, ответственного за безопасное производство работ с
                                применением ПС с приложением копий протоколов и удостоверений.
                            </Text>
                            <Text>
                                • Приказ о допуске стропальщиков, с приложением копий протоколов и удостоверений.

                            </Text>
                            <Text>
                                • Приказ о допуске к работе машинистов, помощников машинистов, слесарей, электромонтеров
                                и
                                стропальщиков с приложением копий протоколов и удостоверений.
                            </Text>
                            <Text>
                                • Приказы о назначении лиц, имеющих право выдачи наряда-допуска.

                            </Text>
                            <Text>
                                • Приказ о назначении специалиста, ответственного за пожарную безопасность при
                                производстве
                                пожароопасных (огневых работ) работ с приложением копий удостоверений.
                            </Text>

                            <Text>
                                • Приказа о создании постоянно действующей комиссии по проверке знаний требований охраны
                                труда работников организации с приложением копий протоколов и удостоверений на членов
                                комиссии.
                            </Text>
                            <Text>
                                • Приказа о создании постоянно действующей комиссии по проверке знаний работников
                                поднадзорных Ростехнадзору (электробезопасности, промбезопасности) с приложением копий
                                протоколов и удостоверений на членов комиссии.
                            </Text>
                            <Text>
                                • Приказа о назначении комиссии ежегодной проверки знаний по электробезопасности с
                                приложением копий протоколов и удостоверений на членов комиссии.
                            </Text>
                            <Text>
                                • Приказ об организации работ на высоте с приложением копий удостоверений ответственных
                                руководителей, производителей, исполнителей.
                            </Text>
                        </View>
                        <Text style={[styles.tableColHeader, styles.secondCol]}>
                            До начала работ
                        </Text>
                        <Text style={[styles.tableColHeader, styles.thirdCol]}>
                            ООО «_____________»
                        </Text>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, styles.firstCol]}>
                            <Text>
                                2. Представить:
                            </Text>
                            <Text>
                                • Сопроводительное письмо с указанием списка работников с указанием фамилии, имени,
                                отчества, профессии, должности, а так-же предоставленных им руководителем организации
                                прав и обязанностей.
                            </Text>
                            <Text>
                                • Списки техники, оборудования, с указанием характеристик и приложением копий руководств
                                по эксплуатации, сертификатов.
                            </Text>
                        </View>
                        <Text style={[styles.tableColHeader, styles.secondCol]}>
                            До начала работ
                        </Text>
                        <Text style={[styles.tableColHeader, styles.thirdCol]}>
                            ООО «_____________»
                        </Text>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, styles.firstCol]}>
                            <Text>
                                4. Представить комплект инструкций по охране труда по профессиям и видам работ (с
                                подписями в листах ознакомления).
                            </Text>
                        </View>
                        <Text style={[styles.tableColHeader, styles.secondCol]}>
                            До начала работ
                        </Text>
                        <Text style={[styles.tableColHeader, styles.thirdCol]}>
                            ООО «_____________»
                        </Text>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, styles.firstCol]}>
                            <Text>
                                5. Представить, утвержденный в своей организации, перечень работ при выполнении которых
                                необходимо оформление наряда-допуска.
                            </Text>
                        </View>
                        <Text style={[styles.tableColHeader, styles.secondCol]}>
                            До начала работ
                        </Text>
                        <Text style={[styles.tableColHeader, styles.thirdCol]}>
                            ООО «_____________»
                        </Text>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, styles.firstCol]}>
                            <Text>
                                6. Провести инструктаж на рабочем месте с учетом местных особенностей, имеющихся на
                                выделенном участке опасных факторов.
                            </Text>
                        </View>
                        <Text style={[styles.tableColHeader, styles.secondCol]}>
                            До начала работ
                        </Text>
                        <Text style={[styles.tableColHeader, styles.thirdCol]}>
                            ООО «_____________»
                        </Text>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, styles.firstCol]}>
                            <Text>
                                7. Представить журналы инструктажа на рабочем месте и инструктажа по пожарной
                                безопасности.


                            </Text>
                        </View>
                        <Text style={[styles.tableColHeader, styles.secondCol]}>
                            До начала работ
                        </Text>
                        <Text style={[styles.tableColHeader, styles.thirdCol]}>
                            ООО «_____________»
                        </Text>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, styles.firstCol]}>
                            <Text>
                                8. Представить согласованные, утвержденные ППР (технологические карты) на все виды
                                работ.
                                Всех участников трудового процесса ознакомить под роспись с ППР по мере пребывания на
                                объект для выполнения работ.

                            </Text>
                        </View>
                        <Text style={[styles.tableColHeader, styles.secondCol]}>
                            До начала работ
                        </Text>
                        <Text style={[styles.tableColHeader, styles.thirdCol]}>
                            ООО «_____________»
                        </Text>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, styles.firstCol]}>
                            <Text>
                                9. В случае привлечения своих субподрядных организаций для выполнения работ предупредить
                                подрядчика и предоставить на них документацию, перечисленную в настоящем акте-допуске.
                            </Text>
                        </View>
                        <Text style={[styles.tableColHeader, styles.secondCol]}>
                            До начала работ
                        </Text>
                        <Text style={[styles.tableColHeader, styles.thirdCol]}>
                            ООО «_____________»
                        </Text>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, styles.firstCol]}>
                            <Text>
                                10. На границах зон постоянно действующих опасных производственных факторов установить
                                защитные ограждения (по ГОСТ 12.4.059-89), а на границах зон потенциально опасных
                                производственных факторов установить сигнальные ограждения и знаки безопасности (по ГОСТ
                                12.4.026-2001).
                            </Text>
                        </View>
                        <Text style={[styles.tableColHeader, styles.secondCol]}>
                            До начала работ
                        </Text>
                        <Text style={[styles.tableColHeader, styles.thirdCol]}>
                            ООО «_____________»
                        </Text>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, styles.firstCol]}>
                            <Text>
                                11. Согласовать порядок подключения электрических машин, электрических механизмов и
                                электрооборудования с уполномоченным электротехническим персоналом Генподрядной
                                организации.
                            </Text>
                        </View>
                        <Text style={[styles.tableColHeader, styles.secondCol]}>
                            До начала работ
                        </Text>
                        <Text style={[styles.tableColHeader, styles.thirdCol]}>
                            ООО «_____________»
                        </Text>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, styles.firstCol]}>
                            <Text>
                                12. Обеспечить места проведения огневых работ, складские, санитарно-бытовые помещения
                                огнетушителями и др. средствами пожаротушения и знаками безопасности (по ГОСТ
                                12.4.026-2001), в соответствии с Правилами противопожарного режима РФ.
                            </Text>
                        </View>
                        <Text style={[styles.tableColHeader, styles.secondCol]}>
                            До начала работ
                        </Text>
                        <Text style={[styles.tableColHeader, styles.thirdCol]}>
                            ООО «_____________»
                        </Text>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, styles.firstCol]}>
                            <Text>
                                13. Организовать обеспечение медицинским имуществом в соответствии с требованиями
                                Приказа Минздравсоцразвития России от 5 марта 2011 г. № 169Н.
                            </Text>
                        </View>
                        <Text style={[styles.tableColHeader, styles.secondCol]}>
                            До начала работ
                        </Text>
                        <Text style={[styles.tableColHeader, styles.thirdCol]}>
                            ООО «_____________»
                        </Text>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, styles.firstCol]}>
                            <Text>
                                14. Обеспечить персонал средствами индивидуальной и коллективной защиты, исправными
                                инструментом, оборудованием и приспособлениями.
                            </Text>
                        </View>
                        <Text style={[styles.tableColHeader, styles.secondCol]}>
                            До начала работ
                        </Text>
                        <Text style={[styles.tableColHeader, styles.thirdCol]}>
                            ООО «_____________»
                        </Text>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, styles.firstCol]}>
                            <Text>
                                15. Соблюдать требования нормативных документов:
                            </Text>
                            <Text>• Приказ Министерства труда России от 01.06.2015 г. № 336н «Об утверждении Правил по
                                охране труда в строительстве» (ПОТ С №336н);</Text>
                            <Text>• Постановление Минтруда РФ и Минобразования РФ от 13 января 2003 г. N 1/29 "Об
                                утверждении Порядка обучения по охране труда и проверки знаний требований охраны труда
                                работников организаций";</Text>
                            <Text>• Приказ Министерства труда и социальной защиты Российской Федерации от 24 июля 2013
                                г. № 328н «Об утверждении Правил по охране труда при эксплуатации электроустановок» (ПОТ
                                ЭЭ №328н);</Text>
                            <Text>• Приказ Минтруда России № 155н от 28 марта 2014 г. «Об утверждении Правил по охране
                                труда при работе на высоте» (ПОТ РВ №155н);</Text>
                            <Text>• Приказ Минтруда России № 1101н от 23 декабря 2014 г. «Об утверждении Правил по
                                охране труда при выполнении электросварочных и газосварочных работ» (ПОТ ЭГР
                                №1101н);</Text>
                            <Text>• Приказ Минтруда России № 642н от 17 сентября 2014 г. «Об утверждении Правил по
                                охране труда при погрузочно-разгрузочных работах и размещении грузов» (ПОТ ПРР
                                №642н);</Text>
                            <Text>• Приказ Федеральной службы по экологическому, технологическому и атомному надзору от
                                12 ноября 2013 г. № 533 “Об утверждении Федеральных норм и правил в области промышленной
                                безопасности «Правила безопасности опасных производственных объектов, на которых
                                используются подъемные сооружения” (ФНП №533);</Text>
                            <Text>• Приказ № 116 от 25 марта 2014 г. Об утверждении Федеральных норм и правил в области
                                промышленной безопасности «Правила промышленной безопасности опасных производственных
                                объектов, на которых используется оборудование, работающее под избыточным давлением»
                                (ФНП №116).</Text>
                        </View>
                        <Text style={[styles.tableColHeader, styles.secondCol]}>
                            Постоянно
                        </Text>
                        <Text style={[styles.tableColHeader, styles.thirdCol]}>
                            ООО «_____________»
                        </Text>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, styles.firstCol]}>
                            <Text>
                                16. В случае проведения работ, представляющих опасность для других организаций,
                                участников строительного процесса, уведомить подрядчика письменно за 2-е суток до начала
                                указанных работ.


                            </Text>
                        </View>
                        <Text style={[styles.tableColHeader, styles.secondCol]}>
                            До начала работ
                        </Text>
                        <Text style={[styles.tableColHeader, styles.thirdCol]}>
                            ООО «_____________»
                        </Text>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, styles.firstCol]}>
                            <Text>
                                17. Разработать график выполнения совместных работ, обеспечивающих безопасные условия
                                труда, обязательный для участников строительного производства на данной территории.
                            </Text>
                        </View>
                        <Text style={[styles.tableColHeader, styles.secondCol]}>
                            До начала работ
                        </Text>
                        <Text style={[styles.tableColHeader, styles.thirdCol]}>
                            ООО «_____________»
                        </Text>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, styles.firstCol]}>
                            <Text>
                                18. Оформить наряд-допуск на совмещённые работы.
                            </Text>
                        </View>
                        <Text style={[styles.tableColHeader, styles.secondCol]}>
                            До начала работ
                        </Text>
                        <Text style={[styles.tableColHeader, styles.thirdCol]}>
                            ООО «_____________»
                        </Text>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, styles.firstCol]}>
                            <Text>
                                19. Предоставить документы, подтверждающие прохождение предварительных (периодических)
                                медосмотров и обязательных психиатрических освидетельствований.
                            </Text>
                        </View>
                        <Text style={[styles.tableColHeader, styles.secondCol]}>
                            До начала работ
                        </Text>
                        <Text style={[styles.tableColHeader, styles.thirdCol]}>
                            ООО «_____________»
                        </Text>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, styles.firstCol]}>
                            <Text>
                                20. Обеспечить выполнение общих мероприятий охраны труда и координацию действий
                                участников строительного производства по реализации мероприятий, обеспечивающих
                                безопасность производства работ, согласно акту-допуску и графику выполнения совместных
                                работ.
                            </Text>
                        </View>
                        <Text style={[styles.tableColHeader, styles.secondCol]}>
                            Постоянно
                        </Text>
                        <Text style={[styles.tableColHeader, styles.thirdCol]}>
                            ООО «_____________»
                        </Text>
                    </View>
                </View>

                <View>
                    <Text>
                        Работы по _____________________ разрешаются.
                    </Text>
                    <Text>
                        Участок передан на время выполнения производства работ по
                        ___________________(_________________), но не позднее ____________2019г.
                    </Text>
                    <Text>Стороны претензий друг к другу не имеют.</Text>
                </View>

                <View>
                    <Text>ПОДПИСИ СТОРОН</Text>
                    <Text>Передал: Подрядчик: __________________________/_________________./ </Text>
                    <Text style={{marginTop: "16px"}}>Принял: Субподрядчик:
                        ________________________/__________________./</Text>
                </View>
            </Page>
        </Document>
    )
}

export default AdmittanceAct;