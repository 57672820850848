import React, {useContext, useEffect, useState} from 'react';
import UserSection from "../../UI/Sections/UserSection";
import {Link, useNavigate} from "react-router-dom";
import UserContext from "../../../store/user/user-context";
import axios from "axios";
import styles from "../dash.module.css";
import Btn from "../../UI/Buttons/Btn";
import Grids from "../../UI/Grids/Grids";
import Popup from "../../UI/Popup/Popup";

const GeodeticCenterBase = (props) => {

    const navigate = useNavigate();
    if (window.localStorage.getItem('role') !== '4') navigate('/work/');

    const userSettings = useContext(UserContext);
    const company_id = window.localStorage.getItem('company');
    const project_id = window.localStorage.getItem('project');
    const [popupShow, setPopupShow] = useState(false);

    const [geodeticCenterBase, setGeodeticCenterBase] = useState();

    const changeGeodeticCenterBaseHandler = (type) => {

        const saveForm = (data) => {
            
            const options = {
                headers: userSettings.headers.postForm
            }

            const formData = new FormData();

            formData.append("file", data.file[0]);
            formData.append("date", data.date);
            formData.append("organization", data.organization);
            formData.append("number", data.number);

            const url = `${userSettings.api}/geodetic-center-base/companies/${company_id}/projects/${project_id}/${type === "edit" ? "update_by_project/" : ""}`

            if (type === 'edit') {
                axios.put(
                    url,
                    formData,
                    options
                ).then(response => {
                    if (response.data) {
                        setGeodeticCenterBase(response.data);
                        setPopupShow(false);
                    }
                })
                    .catch(error => console.error(`\n error`, (error.code === 'ERR_BAD_REQUEST') ? 'ERR_BAD_REQUEST' : error.data));
            } else {
                axios.post(
                    url,
                    formData,
                    options
                ).then(response => {
                    if (response.data) {
                        setGeodeticCenterBase(response.data);
                        setPopupShow(false);
                    }
                })
                    .catch(error => console.error(`\n error`, (error.code === 'ERR_BAD_REQUEST') ? 'ERR_BAD_REQUEST' : error.data));
            }
            
        };

        const fields = [
            {
                "name": "organization",
                "type": "text",
                "label": "Организация",
                "defaultValue": geodeticCenterBase ? geodeticCenterBase.organization : ''
            },
            {
                "name": "date",
                "type": "date",
                "label": "Дата",
                "defaultValue": geodeticCenterBase ? geodeticCenterBase.date : ''
            },
            {
                "name": "number",
                "type": "text",
                "label": "Номер",
                "defaultValue": geodeticCenterBase ? geodeticCenterBase.number : ''
            },
            {
                "name": "file",
                "type": "file",
                "label": "Приказ"
            }
        ];

        setPopupShow(<Popup
            title={`${type === 'edit' ? "Редактировать" : "Добавить"} сведения о геодезической разбивочной основе`}
            form={{
                fields: fields,
                submit: "Сохранить"
            }}
            onSubmit={saveForm}
            onCloseModal={() => setPopupShow(false)}
        />);
    }

    useEffect(() => {
        axios.get(
            `${userSettings.api}/geodetic-center-base/companies/${company_id}/projects/${project_id}/`,
            userSettings.headers.get
        )
            .then(response => {
                setGeodeticCenterBase(response.data);
            })
            .catch(error => {
                console.error('\n error', error.response.data);
            });
    },[company_id, project_id, userSettings]);

    return (
        <UserSection>
            {popupShow}
            <h1>Геодезическая разбивочная основа</h1>
            {geodeticCenterBase ?
                <React.Fragment>
                    <div className={`${styles.card} lg:w-1/2 xl:w-1/3`}>
                        <div className={styles.area}>
                            <div>Организация: {geodeticCenterBase.organization}</div>
                            <div>Дата: {geodeticCenterBase.date}</div>
                            <div>Номер: {geodeticCenterBase.number}</div>
                            <Grids cols={`auto`}>
                                <Btn color={`button`} method={`view`} className={`mx-auto`}>
                                    <Link to={`https://erp-system-api.sixhands.co${geodeticCenterBase.file}`} target={`_blank`}>Документ</Link>
                                </Btn>
                                <Btn color={`button`} method={`edit`} className={`mx-auto`} onClick={() => changeGeodeticCenterBaseHandler("edit")}>Редактировать</Btn>
                            </Grids>
                        </div>
                    </div>
                </React.Fragment>
                :
                <React.Fragment>
                    <div>Данные отсутствуют</div>
                    <Btn color={`button`} method={`add`} onClick={changeGeodeticCenterBaseHandler}>Добавить</Btn>
                </React.Fragment>
            }
        </UserSection>
    );
}

export default GeodeticCenterBase;