import React, {useContext, useEffect, useState} from "react";
import UserSection from "../../UI/Sections/UserSection";
import Btn from "../../UI/Buttons/Btn";
import {Link, useNavigate} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import UserContext from "../../../store/user/user-context";
import RegistrationInput from "../../UI/Inputs/RegistrationInput";
import {nanoid} from 'nanoid';
import {ErrorOccurred} from "../../UI/Sections/ErrorOccurred";
import {getCompany} from "../../UI/GetInfo/getCompany";
import {Box, Button, Typography} from "@mui/material";
import axios from "axios";

export default function TenderAdd() {

    const navigate = useNavigate();
    if (window.localStorage.getItem('role') !== '2') navigate('/work/');

    const userSettings = useContext(UserContext);
    const company_id = +window.localStorage.getItem('company');
    const project_id = +window.localStorage.getItem('project');
    const methods = useForm();
    const {unregister} = methods;

    const [answers, setAnswers] = useState(null);
    const [docs, setDocs] = useState([]);
    const [isSend, setIsSend] = useState(null);
    const [fieldsError, setFieldsError] = useState(null);
    const [companyName, setCompanyName] = useState("");

    const addLotHandler = async (data) => {

        setIsSend(true);
        setFieldsError(null);

        try {
            data.phone = data.phone.replace(/ /g, '')

            const formData = new FormData();
            Object.keys(data).forEach(key => {

                if (!key.includes('files-') && !key.includes('names-')) formData.append(key, data[key]);
            });
            formData.append('estimate_template', data.estimate_template[0])

            let fileIndex = 0;
            for (const key in data) {
                if (key.includes('files')) {
                    const docId = key.replace('files-', '');
                    const getDocName = data[`names-${docId}`];

                    formData.append(`files[${fileIndex}]`, data[key][0]);
                    formData.append(`names[${fileIndex}]`, getDocName);
                    fileIndex++;
                }
            }

            const response = await axios.post(
                `${userSettings.api}/tenders/companies/${company_id}/project/${project_id}/`,
                formData,
                {headers: userSettings.headers.postForm}
            );

            if (response.status === 200) window.location.replace('/work/coordinator/tenders');
        } catch (error) {
            console.error(error)

            const responseError = ErrorOccurred(
                error.response,
                "Ошибка создания тендера",
                "addLotHandler"
            );
            setAnswers(responseError.jsx);
            setFieldsError(responseError.errors);

            setIsSend(false);
        }
    }
    const addDocs = () => {
        const updatedDocs = [...docs];
        const newId = nanoid();
        updatedDocs.push({
            name: 'Новый документ',
            id: newId
        });
        setDocs(updatedDocs);
    }
    const removeDocHandler = (id, inputName, fileName) => {
        const updatedDocs = [...docs].filter(doc => doc.id !== id);
        unregister(inputName)
        unregister(fileName)
        setDocs(updatedDocs);
    }
    useEffect(() => {
        const getCompanyName = async () => {
            const company = await getCompany([company_id], userSettings);

            return company[0].name;
        }
        addDocs();

        getCompanyName().then(setCompanyName)
    }, [])

    const getMinTime = () => {

        const currentDate = new Date();

        currentDate.setDate(currentDate.getDate() + 2);

        currentDate.setHours(9, 0, 0, 0);

        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };
    let fields = [
        {
            "name": "category",
            "type": "select",
            "label": "Категория тендера",
            "options": Object.keys(userSettings.tender_directions).map(id => ({
                id: parseInt(id),
                name: userSettings.tender_directions[id]
            }))
        },
        {
            "name": "name",
            "type": "text",
            "label": "Наименование",
        },
        {
            "name": "organization",
            "type": "text",
            "label": "Организация",
            "defaultValue": companyName,
            "value": companyName,
            "notRequired": true,
            "disabled": true
        },
        {
            "name": "representative",
            "type": "text",
            "label": "Ф.И.О. представителя",
        },
        {
            "name": "phone",
            "type": "tel",
            "label": "Телефон представителя",
        },
        {
            "name": "email",
            "type": "email",
            "label": "E-mail представителя",
        },
        {
            "name": "nmc",
            "type": "number",
            "step": 0.01,
            "min": 0,
            "label": "НМЦ лота"
        },
        {
            "name": "address",
            "type": "text",
            "label": "Адрес объекта",
        },
        {
            "name": "end_date",
            "type": "date",
            "label": "Дата и время окончания подачи заявок",
            "min": getMinTime().split('T')[0]
        }
    ];

    if (fieldsError) console.error(`\n fieldsError`, fieldsError);

    return (
        <UserSection>
            <h1>Добавить тендер</h1>
            {answers && answers}
            <Button
                variant="contained"
                className="w-fit"
            >
                <Link to="/work/coordinator/tenders"> Вернуться к тендерам</Link>
            </Button>
            <FormProvider {...methods} >
                <form onSubmit={methods.handleSubmit(addLotHandler)}>
                    <Box className="flex flex-col gap-2">
                        <div className={`flex flex-wrap gap-2`}>
                            {fields.map((field, id) => {


                                const isFieldError = fieldsError && fieldsError[field.name];

                                if (isFieldError) console.error(`\n isFieldError`, isFieldError);

                                return (
                                    <RegistrationInput
                                        key={id}
                                        name={field.name}
                                        type={field.type}
                                        defaultValue={field.defaultValue}
                                        disabled={field.disabled}
                                        value={field.value}
                                        notRequired={field.notRequired}
                                        label={isFieldError ? isFieldError : field.label}
                                        options={field.options}
                                        step={field.step}
                                        min={field.min}
                                        error={isFieldError}
                                    />
                                );
                            })}
                        </div>
                        <div className="w-fit">
                            <RegistrationInput
                                name={`estimate_template`}
                                label={`Загрузите свой документ шаблона сметы`}
                                type={`file`}
                            />
                        </div>
                        <Btn method={`view`}>
                            <Link to={`/uploads/docs/contractors_estimate.xlsx`} target={`_blank`}>
                                Шаблон сметы подрядчика
                            </Link>
                        </Btn>
                        <Typography variant="h2">Документы тендера:</Typography>
                        <Box className={`flex flex-col gap-2 p-4 border-y-2 border-black`}>
                            <Box className={`flex flex-wrap gap-2`}>
                                {docs && docs.map((doc) => {
                                    return (
                                        <div key={doc.id}>
                                            <RegistrationInput
                                                name={`names-${doc.id}`}
                                                label={`Наименование документа`}
                                                type={'text'}
                                                defaultValue={doc.name}
                                            />
                                            <RegistrationInput
                                                name={`files-${doc.id}`}
                                                label={`Загрузите документ`}
                                                type={`file`}
                                            />
                                            <Btn
                                                method={`remove`}
                                                center
                                                onClick={() => removeDocHandler(doc.id, `names-${doc.id}`, `files-${doc.id}`)}
                                            >
                                                Убрать документ
                                            </Btn>
                                        </div>);
                                })}
                            </Box>
                            <Button
                                variant='contained'
                                color="secondary"
                                onClick={addDocs}
                                className="w-fit"
                            >
                                Добавить документ
                            </Button>
                        </Box>
                        <Box className="flex flex-col w-fit gap-2">
                            {!isSend &&
                                <Button
                                    variant="contained"
                                    color={answers ? "error" : "success"}
                                    type={`submit`}
                                >
                                    Создать тендер
                                </Button>
                            }
                        </Box>
                    </Box>
                </form>
            </FormProvider>
        </UserSection>
    );
}