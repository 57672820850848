import axios from "axios";

export const getEstimateElements = async (userSettings, company_id, project_id, tender_id, subcompany_id) => {
    try {
        const estimateElements = await axios.get(
            `${userSettings.api}/work-journal/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/subcompanies/${subcompany_id}/estimate-elements/`,
            userSettings.headers.get
        );

        return (estimateElements.data);
    }
    catch (error) {
        console.error('\n error getEstimateElements', error.response);
    }
}