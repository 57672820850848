import React, {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import Header from "../Header";
import PageSection from "../../UI/Sections/PageSection";
import Section from "../../UI/Sections/Section";
import Button from "../../UI/Buttons/Button";
import ButtonLink from '@mui/material/Button';
import Grids from "../../UI/Grids/Grids";
import RegistrationInput from "../../UI/Inputs/RegistrationInput";
import axios from "axios";
import {Box} from "@mui/material";
import {useCookies} from "react-cookie";

export default function RegisterCompany(props) {
    const [cookie,] = useCookies(['access']);

    const methods = useForm();
    const [chosenOwner, setChosenOwner] = useState(null);
    const [checkDataError, setCheckDataError] = useState(null);
    const [legalData, setLegalData] = useState(null);
    const [legalFields, setLegalFields] = useState(null);
    const [successRegistrations, setSuccessRegistrations] = useState(null);
    const [registrationEmail, setRegistrationEmail] = useState("");

    const [isExist, setIsExist] = useState(false);

    const [email, setEmail] = useState("")

    const individualFields = [
        {
            name: "name",
            label: "Ф.И.О.",
            type: "text"
        },
        {
            name: "email",
            label: "Адрес официальной эл. почты",
            type: "email",
            value: email,
            onChange: (e) => setEmail(e.target.value)
        },
        {
            name: "phone",
            label: "Контактный телефон",
            type: "tel"
        },
        {
            name: "admin_email",
            label: "Адрес эл. почты администратора",
            type: "email",
            value: email,
            defaultValue: email
        }
    ];

    const selectOwnership = (type, owner) => {
        setChosenOwner({type, owner});
    }
    const checkRegistrationDataHandler = async (data) => {
        const getInn = data['inn-check'].trim(' ');
        const getBic = data['bik-check'].trim(' ');

        const options = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Token 0081d13554403402faae0bd889c3275bf36e1dd1'
            }
        };

        try {
            const innExists = await axios.get(`${props.api}/functools/check_inn/`,
                {
                    params: {
                        inn: getInn,
                        subcompany: chosenOwner.type !== "depositor" ? 1 : 0
                    }
                });
            if (innExists.data.is_exists) {
                setCheckDataError(`На ИНН ${getInn} уже зарегистрирована компания`)
                return
            }

            const [response1, response2] = await Promise.all([
                axios.post(
                    `https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party`,
                    {query: getInn},
                    options
                ),
                axios.post(
                    `https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/bank`,
                    {query: getBic},
                    options
                ),
            ]);

            if (
                response1.data.suggestions.length > 0 &&
                response1.data.suggestions[0].data.state.status !== 'LIQUIDATED' &&
                response2.data.suggestions.length > 0
            ) {
                setLegalData({
                    company: response1.data.suggestions[0].data,
                    bank: response2.data.suggestions[0].data
                });
            } else {
                setCheckDataError('Указаны неверные регистрационные данные');
            }
        } catch (error) {
            console.error('\n checkRegistrationDataHandler error', error);
        }
    };

    const [registerClicked, setRegisterClicked] = useState(false)

    const registerHandler = async (data) => {
        setRegisterClicked(true);

        data.phone = data.phone.replace(/[ ]/g, '');

        const subcompanyPath = chosenOwner.type === 'subcompany' ? '/subcompanies' : '/auth';

        try {
            if (chosenOwner.owner === 'legal') {
                if (legalData.company.type === 'LEGAL') {
                    data['type'] = 2;
                } else {
                    data['type'] = 3;
                }

                const response = await axios.post(`${props.api}${subcompanyPath}/register/legal`, data, {
                    headers: {
                        'accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                });

                setIsExist(response.data.is_exist)

                setChosenOwner(null);
                setSuccessRegistrations(response.data.company.name);
                setRegistrationEmail(response.data.company.email);
            } else {

                data['type'] = 1;

                const response = await axios.post(`${props.api}${subcompanyPath}/register/individual`, data, {
                    headers: {
                        'accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                });

                setIsExist(response.data.is_exist)

                setChosenOwner(null);
                setSuccessRegistrations(response.data.company.name);
                setRegistrationEmail(response.data.company.email);
            }
        } catch (error) {
            console.error("Ошибка при отправке запроса:", error);
        }
    };

    useEffect(() => {
        if (!legalData) return;

        setLegalFields([
            {
                name: "name",
                label: "Наименование компании",
                type: "text",
                disabled: true,
                defaultValue: legalData.company.name.short_with_opf
            },
            {
                name: "legal_address",
                label: "Юридический адрес",
                type: "text",
                disabled: legalData.company.type === "LEGAL",
                defaultValue: legalData.company.address.unrestricted_value,
            },
            {
                name: "postal_address",
                label: "Почтовый адрес",
                type: "text",
                defaultValue: legalData.company.address.unrestricted_value,
            },
            {
                name: "kpp",
                label: "КПП",
                type: "number",
                disabled: true,
                defaultValue: legalData.company.kpp,
            },
            {
                name: "inn",
                label: "ИНН",
                type: "number",
                disabled: true,
                defaultValue: legalData.company.inn,
            },
            {
                name: "ogrn",
                label: "ОГРН",
                type: "number",
                disabled: true,
                defaultValue: legalData.company.ogrn,
            },
            {
                name: "okpo",
                label: "ОКПО",
                type: "number",
                disabled: true,
                defaultValue: legalData.company.okpo,
            },
            {
                name: "bank",
                label: "Наименование банка",
                type: "text",
                disabled: true,
                defaultValue: legalData.bank.name.payment,
            },
            {
                name: "settlement_account",
                label: "Р/С",
                type: "number",
                length: 20,
            },
            {
                name: "correspondent_account",
                label: "К/С",
                type: "number",
                disabled: true,
                defaultValue: legalData.bank.correspondent_account,
            },
            {
                name: "bik",
                label: "БИК",
                type: "number",
                disabled: true,
                defaultValue: legalData.bank.bic,
            },
            {
                name: "director",
                label: "Ген директор (Ф.И.О.)",
                type: "text",
                disabled: legalData.company.management,
                defaultValue: legalData.company.management ? legalData.company.management.name : legalData.company.type === "INDIVIDUAL" ? legalData.company.name.full : '',
            },
            {
                name: "basis",
                label: "Основание полномочий",
                type: "text",
            },
            {
                name: "email",
                label: "Адрес официальной эл. почты",
                type: "email",
            },
            {
                name: "phone",
                label: "Контактный телефон",
                type: "tel",
            },
            {
                name: "admin_email",
                label: "Адрес эл. почты администратора",
                type: "email",
            },
        ])

    }, [legalData?.bank?.bic, legalData?.company?.inn]);
    return (
        <PageSection>
            <Header isLogin={cookie.access}/>
            <Section>
                {!chosenOwner ?
                    <React.Fragment>
                        {!successRegistrations ?
                            <React.Fragment>
                                <h2 className={`text-2xl`}>Выберите право собственности</h2>
                                <div className={`w-full md:w-[80vw] lg:w-[60vw] text-left`}>
                                    <h2 className={`text-xl`}><b>ЗАКАЗЧИК</b></h2>
                                    {/*<div className={`grid gid-cols-1 md:grid-cols-3 gap-2 my-2`}>*/}
                                    {/*<img src={Depositor} alt={"Инвестор"} className={`rounded-md mx-auto max-h-[200px]`}/>*/}
                                    {/*</div>*/}
                                    <div className={`grid gid-cols-1 md:grid-cols-2 gap-2`}>
                                        <Button onClick={() => selectOwnership('depositor', 'individual')}>Физическое
                                            лицо</Button>
                                        <Button onClick={() => selectOwnership('depositor', 'legal')}>Юридическое
                                            лицо</Button>
                                    </div>
                                    <h2 className={`text-xl mt-3`}><b>ПОДРЯДЧИК</b></h2>
                                    {/*<div className={`grid gid-cols-1 md:grid-cols-3 gap-2 my-2`}>*/}
                                    {/*<img src={Subcompany} alt={"Инвестор"} className={`rounded-md mx-auto max-h-[200px]`}/>*/}
                                    {/*</div>*/}
                                    <div className={`grid gid-cols-1 md:grid-cols-2 gap-2`}>
                                        <Button onClick={() => selectOwnership('subcompany', 'individual')}>Физическое
                                            лицо</Button>
                                        <Button onClick={() => selectOwnership('subcompany', 'legal')}>Юридическое
                                            лицо</Button>
                                    </div>
                                </div>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <Box>
                                    {isExist ?
                                        <Box>
                                            <h2>ООО «{successRegistrations}» зарегистрирована. У вас уже зарегистрирован
                                                пользователь – {registrationEmail}, можете авторизоваться, используя
                                                ранее введеные данные. Если вы не помните пароль, нажмите на кнопку
                                                ниже</h2>
                                            <ButtonLink variant="contained" href='/forgot-password'>поменять
                                                пароль</ButtonLink>
                                        </Box>
                                        : <Box>
                                            <h2>ООО «{successRegistrations}» зарегистрирована. На
                                                адрес {registrationEmail} отправлено письмо для создания пароля</h2>
                                        </Box>
                                    }
                                </Box>
                            </React.Fragment>
                        }
                    </React.Fragment>
                    :
                    <React.Fragment>
                        {chosenOwner.owner === "legal" && <React.Fragment>
                            {!legalData ?
                                <FormProvider {...methods} >
                                    <form onSubmit={methods.handleSubmit(checkRegistrationDataHandler)}
                                          className={`mb-2`}>
                                        <h2>Укажите данные для регистрации
                                            компании {chosenOwner.type === "depositor" ? "Заказчика" : "Подрядчика"}</h2>

                                        <div className={`text-red-500 bg-red-100 rounded`}>{checkDataError}</div>
                                        <Grids cols={2}>
                                            <RegistrationInput
                                                name="inn-check"
                                                label="Укажите ИНН Юридического лица или ИП"
                                                length={0}
                                            />
                                            <RegistrationInput
                                                name="bik-check"
                                                label="Укажите БИК вашего банка"
                                                length={9}
                                            />
                                        </Grids>
                                        <Button className={`mx-auto w-full`} type={`submit`}>Проверить данные</Button>
                                    </form>
                                    <Button className={`mx-auto w-full`}
                                            onClick={() => setChosenOwner(null)}>Назад</Button>
                                </FormProvider>
                                :
                                <FormProvider {...methods} >
                                    <form onSubmit={methods.handleSubmit(registerHandler)}>
                                        <h2>Укажите реквизиты
                                            лица {chosenOwner.type === "depositor" ? "Заказчика" : "Подрядчика"}</h2>
                                        <Grids cols={2}>
                                            {legalFields && legalFields.map(field => {
                                                if (
                                                    (field.name !== 'kpp' || legalData.company.type !== 'INDIVIDUAL') &&
                                                    !(field.name === 'admin_email' && chosenOwner.type === 'subcompany')
                                                ) {
                                                    return (
                                                        <RegistrationInput
                                                            onChange={field.onChange}
                                                            key={field.name}
                                                            name={field.name}
                                                            label={field.label}
                                                            type={field.type}
                                                            disabled={field.disabled}
                                                            defaultValue={field.defaultValue}
                                                        />
                                                    );
                                                } else {
                                                    return null;  // Пропускаем поле в других случаях
                                                }
                                            })}
                                        </Grids>
                                        <Button type="submit">Зарегистрировать компанию</Button>
                                        <Button onClick={() => {
                                            setLegalData(null);
                                            setLegalFields(null);
                                            methods.reset();
                                        }}>Назад</Button>
                                    </form>
                                </FormProvider>
                            }
                        </React.Fragment>}
                        {chosenOwner.owner === "individual" && <React.Fragment>
                            <FormProvider {...methods}>
                                <form onSubmit={methods.handleSubmit(registerHandler)} className={`mb-2`}>
                                    <h2>Укажите данные для регистрации
                                        компании {chosenOwner.type === "depositor" ? "Заказчика" : "Подрядчика"}</h2>
                                    <Grids cols={2}>
                                        {individualFields.map(field => {
                                            if (field.name === "admin_email" && chosenOwner.type === "subcompany") {
                                                return null;
                                            }

                                            return (
                                                <RegistrationInput
                                                    onChange={field.onChange}
                                                    key={field.name}
                                                    name={field.name}
                                                    label={field.label}
                                                    type={field.type}
                                                    value={field.value}
                                                    disabled={field.disabled}
                                                    defaultValue={field.defaultValue}
                                                />
                                            );
                                        })}
                                    </Grids>
                                    <Button disabled={registerClicked} type="submit">Зарегистрировать</Button>
                                </form>
                                <Button className={`mx-auto w-full`} onClick={() => setChosenOwner(null)}>Назад</Button>
                            </FormProvider>
                        </React.Fragment>}
                    </React.Fragment>
                }
            </Section>
        </PageSection>
    );
}