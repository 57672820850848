import React, {useContext, useEffect, useState} from "react";
import UserSection from "../../UI/Sections/UserSection";
import {Link, useNavigate} from "react-router-dom";
import UserContext from "../../../store/user/user-context";
import axios from "axios";
import {ErrorOccurred} from "../../UI/Sections/ErrorOccurred";
import tables from "../../UI/Tables/table.module.css";
import Warnings from "../../UI/Sections/Warnings";
import Btn from "../../UI/Buttons/Btn";
import {sendSequentialRequests} from "../../SendRequests/sendSequentialRequests";

export default function UnapprovedWorkLogDocuments() {

    const navigate = useNavigate();
    const role = +window.localStorage.getItem('role');
    if (role !== 3) navigate('/work/');

    const userSettings = useContext(UserContext);
    const company_id = +window.localStorage.getItem('company');
    const project_id = +window.localStorage.getItem('project');
    const params = Object.fromEntries(new URLSearchParams(window.location.search));
    const subcompany_id = +params.subcompany;
    const tender_id = +params.tender;

    const [answers,setAnswers] = useState(null);
    const [subcompanyInfo, setSubcompanyInfo] = useState(null);
    const [tenderInfo, setTenderInfo] = useState(null);
    const [qualityDocs, setQualityDocs] = useState(null);

    const periods = [...new Set(qualityDocs?.flatMap(d => d.log.flatMap(l => new Date(l.date).toLocaleDateString())))].sort((a, b) => new Date(a) - new Date(b));
    const headerNames = [
        {
            "name": "№",
            "tag": "number",
        },
        {
            "name": "ССР",
            "tag": "ssr",
        },
        {
            "name": "Наименование работ",
            "tag": "name_works",
        },
        {
            "name": "Объем выполненных работ",
            "tag": "valuesWorks",
        },
        {
            "name": "Ед. изм.",
            "tag": "units",
        },
        {
            "name": "Документ",
            "tag": "doc"
        },
        {
            "name": "Отклонить",
            "tag": "reject",
        }
    ];

    const applyDocumentsHandler = async (document_id) => {
        try {

            const documents = !isNaN(document_id) ?
                qualityDocs.filter(d => d.id === document_id) :
                qualityDocs.filter(d => !d.is_approved);

            if (documents?.length > 0) {

                const requests = documents.map((document) => {

                    const formData = new FormData();
                    formData.append("type", 2);
                    formData.append("reason", "");

                    const quality_document_id = document.id;

                    return ({
                        method: 'PATCH',
                        url: `${userSettings.api}/work_payment/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/subcompanies/${subcompany_id}/quality_documents/${quality_document_id}/approve`,
                        data: formData,
                        headers: userSettings.headers.postForm
                    });
                });
                const results = await sendSequentialRequests(requests);
                const errorsResult = results.filter(r => r.error).map(r => r.errorMessage).flat();
                const responseError = ErrorOccurred(
                    null,
                    "Ошибка загрузки документов",
                    "uploadDocsHandler",
                    errorsResult
                );
                const correctResult = results.filter(r => !r.error);

                setAnswers(responseError.jsx);

                if (correctResult.length > 0) {

                    window.location.reload();
                }
            }
        }
        catch (error) {

            const responseError = ErrorOccurred(
                error.response,
                "Ошибка подтверждения документа",
                "rejectDocumentsHandler"
            );
            setAnswers(responseError.jsx);
        }
    }
    const rejectDocumentsHandler = async (document_id) => {

        try {

            const currentDocument = qualityDocs.find(d => d.id === document_id);

            const requests = currentDocument.rows.map((element_id) => {

                const formData = new FormData();

                return ({
                    method: 'PATCH',
                    // url: `${userSettings.api}/work_payment/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/subcompanies/${subcompany_id}/elements/${element_id}/work_ids/${work_id}/reject-volumes`,
                    data: formData,
                    headers: userSettings.headers.postForm
                });
            });
        }
        catch (error) {

            const responseError = ErrorOccurred(
                error.response,
                "Ошибка отклонения документа",
                "rejectDocumentsHandler"
            );
            setAnswers(responseError.jsx);
        }
    }

    useEffect(() => {
        const getData = async () => {

            try {
                const getCompany = await axios.get(
                    `${userSettings.api}/company/${subcompany_id}/`,
                    userSettings.headers.get
                );
                setSubcompanyInfo(getCompany.data);

                const tenders =  await axios.get(
                    `${userSettings.api}/tenders/companies/${company_id}/`,
                    userSettings.headers.get
                );
                const projectTenders = (tenders.data && tenders.data.length > 0) && tenders.data.find(t => t.id === tender_id);
                setTenderInfo(projectTenders);

                const getQualityDocs = await axios.get(
                    `${userSettings.api}/work_payment/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/subcompanies/${subcompany_id}/quality-docs`,
                    userSettings.headers.get
                );
                const updateUnapprovedWorkLog = getQualityDocs.data.map(d => {

                    const estimateElement = d.work_journal.estimate.find(w => w.id === d.work_journal_element_id);
                    const log = estimateElement.work_log.filter(l => d.rows.includes(l.id));

                    return ({
                        ...d,
                        log,
                        estimateElement
                    });
                });
                setQualityDocs(updateUnapprovedWorkLog.sort((a, b) => a.work_journal_element_id - b.work_journal_element_id));
            }
            catch (error) {

                const responseError = ErrorOccurred(
                    error.response,
                    "Ошибка получения данных",
                    "getData"
                );
                setAnswers(responseError.jsx);
            }
        }
        getData();
    },[subcompany_id, userSettings, tender_id, company_id, project_id]);

    return (
        <UserSection>
            <h1>Согласовать работы подрядчика</h1>
            {answers && answers}
            {subcompanyInfo && <div>Подрядчик {subcompanyInfo.name}</div>}
            {tenderInfo && <div>Тендер {tenderInfo.name}</div>}
            {periods && <div>Период выполненных работ: с {periods[0]} по {periods[(periods.length - 1)]}</div>}
            {<Btn method={`apply`} onClick={applyDocumentsHandler}>
                Согласовать все документы
            </Btn>}
            {qualityDocs && <div className={`${tables.container} grow mt-2`}>
                <table className={tables.table}>
                    <thead>
                    <tr>{headerNames.map(n => <th key={n.name}>{n.name}</th>)}</tr>
                    </thead>
                    <tbody>
                    {qualityDocs.map(d => {

                        return (
                            <tr key={d.id}>
                                {headerNames.map(n => {

                                    const estimate = d.estimateElement[n.tag];
                                    const valuesWorks = (!estimate && n.tag === "valuesWorks") && d.log.map(l => +l.value).reduce((acc, curr) => acc + curr, 0);

                                    return (
                                        <td key={n.tag} className={n.tag === 'name_works' ? '' : 'text-center'}>
                                            {estimate && estimate}
                                            {valuesWorks && valuesWorks}
                                            {n.tag === "reject" && <React.Fragment>
                                                    {d.is_approved ?
                                                        <Warnings correct>Документ согласован</Warnings> :
                                                        d.is_declined_by_build_control ?
                                                            d.is_edited_by_subcontractor ?
                                                                "Подрядчик внес изменения" :
                                                                <Warnings>{d.reason}</Warnings> :
                                                            <React.Fragment>
                                                                <Btn center method={`apply`} onClick={() => applyDocumentsHandler(d.id)}>
                                                                    Согласовать документ
                                                                </Btn>
                                                                <Btn center method={`cancel`} color={`red`} onClick={() => rejectDocumentsHandler(d.id)}>
                                                                    Отклонить документ
                                                                </Btn>
                                                            </React.Fragment>
                                                    }
                                                </React.Fragment>}
                                            {n.tag === "doc" && <React.Fragment>
                                                <Btn center method={`download`} color={`button`}>
                                                    <Link target={`_blank`} to={`${userSettings.fileServer}${d.document}`}>Документ</Link>
                                                </Btn>
                                            </React.Fragment>}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>}
        </UserSection>
    );
}