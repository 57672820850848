import React, {useEffect, useState} from "react";
import axios from "axios";
import {Price} from "../../UI/Convertors/Price";
import TendersDetail from "./TendersDetail";
import {ErrorOccurred} from "../../UI/Sections/ErrorOccurred";
import {Alert} from "@mui/material";

export default function SubCompanyAccess({company, userSettings}) {

    const [tenders, setTenders] = useState(null);
    const [answers, setAnswers] = useState(null);
    const subcompany_id = company?.id;

    useEffect(() => {
        const getInfo = async () => {
            try {
                const wonTenderLots = await axios.get(
                    `${userSettings.api}/subcompanies/${subcompany_id}/won-tender-lots`,
                    userSettings.headers.get
                );

                setAnswers(null);

                const accessTenderCheck = wonTenderLots.data.map(lot => {
                    const company_id = lot.contract.main;
                    const project_id = lot.contract.project_id;
                    const tender_id = lot.contract.tender;


                    const contractDocumentsUploaded = lot.contract.is_documents_uploaded;
                    const contractDocumentsAccepted = lot.contract.is_documents_concerted;

                    const contractActsUploaded = lot.contract.is_acts_uploaded;
                    const contractActsAccepted = lot.contract.is_acts_concerted;

                    const coordinatorAccepted = lot.contract.is_agreement_concerted;

                    // Координатор может отправить документ согласования только
                    // после того, как согласованы акты и документы
                    const getAccessCheck = lot.contract.is_agreement_concerted;

                    return {
                        company_id,
                        subcompany_id,
                        project_id,
                        tender_id,
                        getAccessCheck,
                        contractDocumentsUploaded,
                        contractDocumentsAccepted,
                        contractActsUploaded,
                        contractActsAccepted,
                        coordinatorAccepted,
                        ...lot
                    }
                })
                setTenders(accessTenderCheck);


            } catch (error) {
                const responseError = ErrorOccurred(
                    error.response,
                    "Ошибка получения данных",
                    "getInfo"
                );

                setAnswers(responseError.jsx);
            }
        }

        if (company) getInfo();
    }, [company, userSettings, subcompany_id]);

    return (
        <React.Fragment>
            {answers && answers}
            {tenders?.length === 0 &&
                <Alert severity="warning">Для управления подрядчиком нужно, чтобы он выиграл хотя бы 1 тендер</Alert>
            }
            {tenders && tenders.map(tender => {
                const contractInfo = [
                    {
                        label: "Договор",
                        value: tender.contract.id,
                    },
                    {
                        label: null,
                        value: tender.tender.address,
                    },
                    {
                        label: "Общая стоимость работ",
                        value: Price(tender.criteria.find(c => c.name === "Сумма всех работ" || c.name === 'Общая стоимость работ' || c.name === 'Общая стоимость всех работ')?.value),
                        class: "text-xl font-bold text-pult"
                    },
                ]

                return (
                    <TendersDetail
                        key={tender.id}
                        project={tender.tender.project}
                        contractInfo={contractInfo}
                        getAccessCheck={tender.getAccessCheck}
                        contractDocumentsUploaded={tender.contractDocumentsUploaded}
                        contractDocumentsAccepted={tender.contractDocumentsAccepted}
                        contractActsUploaded={tender.contractActsUploaded}
                        contractActsAccepted={tender.contractActsAccepted}
                        coordinatorAccepted={tender.coordinatorAccepted}
                        subcompany_id={subcompany_id}
                        contract={tender.contract}
                        tender={tender}
                    />
                );
            })}
        </React.Fragment>
    );
}