import React, {useState, useEffect} from "react";
import styles from "./slide-bar.module.css";
import Burger from "./Burger";
import UserPanel from "./UserPanel";
import ProjectControl from "./ProjectControl";
import {Link} from "react-router-dom";
import Btn from "../../UI/Buttons/Btn";
import {Home} from "@mui/icons-material";

const ControlPanel = (props) => {

    const [expanded, setExpanded] = useState(true);
    useEffect(() => {

        const userExpand = localStorage.getItem('expanded');

        if (userExpand === '1') setExpanded(false);
    }, []);
    const expandHandler = (state) => {

        setExpanded(state);

        state ? localStorage.setItem('expanded', '') : localStorage.setItem('expanded', '1');
    }

    const [shownList, setShownList] = useState(false);
    const showListHandler = (id) => setShownList(id);
    
    const role = window.localStorage.getItem('role');

    return (
        <div className={`${styles.navbar} ${!expanded ? styles.collapsed : ''}`}>
            <div className={`${styles['nav-but-menu']} ${!expanded ? 'flex-col-reverse' : 'pl-4'}`}>
                <div className={`${!expanded ? '' : 'flex justify-center'}`}>
                    <Link title={`Вернуться к выбору роли и компании`} to={"/work/"}>
                        <Btn
                            color={expanded ? `white` : 'button'}
                            className={`${!expanded ? 'mx-auto fill-white' : 'mr-2'}`}
                        >
                            {!expanded ? <Home/> : 'Панель управления'}
                        </Btn>
                    </Link>
                </div>
                <Burger
                    setExpanded={expandHandler}
                    state={expanded}
                />
            </div>
            <UserPanel
                state={expanded}
                shownList={shownList}
                showListHandler={showListHandler}
            />
            {role && <ul className={`${styles['controller-list']} ${expanded ? styles.project : ""}`}>
                <ProjectControl
                    state={expanded}
                    shownList={shownList}
                    showListHandler={showListHandler}
                />
            </ul>}
        </div>
    );
}

export default ControlPanel;