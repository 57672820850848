import React from 'react';
import PageSection from "../../UI/Sections/PageSection";
import Header from "../Header";
import Grids from "../../UI/Grids/Grids";
import dashStyles from "../../Work/dash.module.css";
import {Price} from "../../UI/Convertors/Price";
import useTenderLogic from "./useTenderLogic";
import styles from "./tenders.module.css";
import {Alert, Box, Button} from "@mui/material";

const TenderList = (props) => {

    const {authError, cookies, tenders, authorizationHandler, setCookie, subCompanies} = useTenderLogic(props.api);

    const disableToParticipate = subCompanies?.length === 0;

    return (
        <PageSection>
            <Header
                tenders={true}
                onAuth={authorizationHandler}
                authError={authError}
                isLogin={(cookies.access && cookies.access.length > 0)}
            />
            <div className={styles.section}>
                {!(cookies.access && cookies.access.length > 0) &&
                    <Alert severity="warning">Для подачи на участие в тендере требуется авторизоваться</Alert>
                }
                {tenders.length > 0 && <Grids cols={3}>{tenders.map(t => <div key={t.id} className={dashStyles.card}>
                    <div className={dashStyles.label}>{t.name}</div>
                    <Box className={`grid grid-cols-2 h-full gap-2 p-2 `}>
                        <div>Категория</div>
                        <div className={`text-right`}>{props.tender_directions[t.category]}</div>
                        <div>Стоимость лота</div>
                        <div className={`text-right ${styles.price}`}>{Price(t.nmc)} р.</div>
                        <div>Дата окончания тендера</div>
                        <div className={`text-right`}>{new Date(t.end_date).toLocaleDateString()}</div>
                        <div>Адрес объекта</div>
                        <div className={`text-right`}>{t.address}</div>
                    </Box>
                    {subCompanies?.length > 0
                        ? (cookies.access && cookies.access.length > 0) &&
                        <Box className="flex justify-center p-2">
                            <Button
                                disable={disableToParticipate}
                                href={`/tenders/participate/${t.id}`}
                                variant="contained"
                            >
                                Участвовать
                            </Button>
                        </Box>

                        : <Alert severity="warning">Для участия в тендере необходимо иметь компанию-подрядчик</Alert>
                    }
                </div>)}</Grids>}
            </div>
        </PageSection>
    );
}

export default TenderList;