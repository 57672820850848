import React, {useEffect, useState} from "react";
import tables from "../../UI/Tables/table.module.css";
import Btn from "../../UI/Buttons/Btn";
import {getCompany} from "../../UI/GetInfo/getCompany";
import Popup from "../../UI/Popup/Popup";

export default function TenderResults({lots, tender, userSettings, tenderConfirm}) {

    const criteriaList = tender?.criteria;
    const [criteriaView,setCriteriaView] = useState('points');
    const [subcompanies, setSubcompanies] = useState(null);
    const [resultSelectionCriteria, setResultSelectionCriteria] = useState(null);
    const [popupShow, setPopupShow] = useState(false);

    const applyLotHandler = (lot_id, state) => {

        const applyLot = (data) => {

            const reason = data ? data.reason : "";
            tenderConfirm(lot_id, reason);
        }

        if (!state) setPopupShow(<Popup
            title={`Укажите причину согласования тендера`}
            form={{
                fields: [{
                    name: "reason",
                    type: "text",
                    label: "Укажите причину согласования тендера не по высшему баллу"
                }],
                submit: "Сохранить"
            }}
            onSubmit={applyLot}
            onCloseModal={() => setPopupShow(false)}
        />);
        else applyLot();
    }

    useEffect(() => {

        const subcompaniesIds = [...new Set(lots.map(l => l.subcompany))];

        getCompany(subcompaniesIds, userSettings)
            .then(results => {
                setSubcompanies(results);
            })
            .catch(error => {console.error("Произошла ошибка при получении компаний:", error)});
    },[lots, userSettings]);
    useEffect(() => {
        if (lots && lots.length > 0 && criteriaList) {

            const updateResultSelectionCriteria = {};
            const biddersSummaryPoints = {};

            for (const lot of lots) {
                for (const bc of lot.criteria) {
                    const { name, value } = bc;
                    if (!updateResultSelectionCriteria[name]) {
                        updateResultSelectionCriteria[name] = {
                            points: {},
                            criteria: {},
                            values: {}
                        };
                    }
                    updateResultSelectionCriteria[name].values[lot.id] = value;
                }
            }

            for (const id of Object.keys(updateResultSelectionCriteria)) {
                const values = Object.values(updateResultSelectionCriteria[id].values);
                const currentCriteria = criteriaList.find(c => c.name === id);

                if (currentCriteria) {
                    const { value, weight } = currentCriteria;

                    for (const v of Object.keys(updateResultSelectionCriteria[id].values)) {
                        const currentValue = updateResultSelectionCriteria[id].values[v];
                        const criteriaMinValue = value === 1;

                        const minValue = Math.min(...values);
                        const maxValue = Math.max(...values);

                        const resultCriteria = criteriaMinValue ? (minValue / currentValue) : (currentValue / maxValue);
                        const resultpoints = criteriaMinValue ? (minValue / currentValue * weight) : (currentValue / maxValue * weight);

                        updateResultSelectionCriteria[id].criteria[v] = Math.round(resultCriteria * 10000) / 100;
                        updateResultSelectionCriteria[id].points[v] = Math.round(resultpoints * 100) / 100;

                        if (!biddersSummaryPoints[v]) {
                            biddersSummaryPoints[v] = 0;
                        }

                        biddersSummaryPoints[v] += Math.round(resultpoints * 100) / 100;
                    }
                }
            }

            updateResultSelectionCriteria['summaryPoints'] = biddersSummaryPoints;

            setResultSelectionCriteria(updateResultSelectionCriteria);
        }
    }, [lots, criteriaList]);

    return (
        <React.Fragment>
            {popupShow}
            {(lots && lots.length > 0) && <div className={`flex flex-wrap gap-2`}>
                <Btn color={criteriaView === 'points' ? "disable" : "green"} onClick={() => setCriteriaView('points')}>Баллы</Btn>
                <Btn color={criteriaView === 'criteria' ? "disable" : "button"} onClick={() => setCriteriaView('criteria')}>Критерии</Btn>
                <Btn color={criteriaView === 'values' ? "disable" : "button"} onClick={() => setCriteriaView('values')}>Значения</Btn>
            </div>}
            {(lots && lots.length > 0) ?
                <div className={tables.container}>
                    <table className={tables.table}>
                        <thead>
                        <tr>
                            <th>Подрядчик</th>
                            {criteriaList && criteriaList.sort((a, b) => b.weight - a.weight).map(c => <th key={c.id}>{c.name}</th>)}
                            {criteriaView === 'points' && <th>Сумма баллов</th>}
                        </tr>
                        </thead>
                        <tbody>
                        {(lots && subcompanies) &&
                            lots.map(l => {

                                const currentSubcompany = subcompanies.find(c => c.id === l.subcompany);

                                return (
                                    <tr key={l.id}>
                                        <td>
                                            <div className="whitespace-nowrap font-bold">
                                                {currentSubcompany.name}
                                            </div>
                                            <div>ИНН: {currentSubcompany.inn}</div>
                                        </td>
                                        {criteriaList &&
                                            criteriaList
                                                .sort((a, b) => b.weight - a.weight)
                                                .map(c => (
                                                    <td key={c.id} className="text-center">
                                                        {resultSelectionCriteria[c.name] && resultSelectionCriteria[c.name][criteriaView][l.id]}
                                                    </td>
                                                ))}
                                        {criteriaView === 'points' && (
                                            <td>
                                                <div className={`flex flex-col text-center`}>
                                                    <div
                                                        className={``}
                                                    >
                                                        {Math.round(resultSelectionCriteria.summaryPoints[l.id] * 100) / 100}
                                                    </div>
                                                    {!tender.is_won && <Btn
                                                        color={
                                                            resultSelectionCriteria.summaryPoints[l.id] >=
                                                            Math.max(...Object.values(resultSelectionCriteria.summaryPoints))
                                                                ? 'green'
                                                                : 'button'
                                                        }
                                                        onClick={() =>
                                                            applyLotHandler(
                                                                l.id,
                                                                resultSelectionCriteria.summaryPoints[l.id] >= Math.max(...Object.values(resultSelectionCriteria.summaryPoints)) &&  true
                                                            )
                                                        }
                                                    >
                                                        Выбрать
                                                    </Btn>}
                                                </div>
                                            </td>
                                        )}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                :
                <div>Отсутствуют поданные заявки на участие</div>
            }
        </React.Fragment>
    );
}