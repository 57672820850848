import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "../../UI/Tables/DataTable";
import DiagramFinancialSchedule from "../../UI/Diagrams/DiagramFinancialSchedule";
import cloneDeep from 'lodash/cloneDeep';

export default function ViewFinancialPlan({ data }) {
    const [tableData, setTableData] = useState(null);
    const [tablePeriods, setTablePeriods] = useState(null);
    const [diagramData, setDiagramData] = useState(null);

    useEffect(() => {

        const getData = cloneDeep(data);

        function isObject(obj) {return typeof obj === 'object' && obj !== null && ! Array.isArray(obj)}

        const viewData = getData.map(row => {

            if (Object.keys(row.periods).length > 0) {

                const preparePeriods = [];

                Object.keys(row.periods).forEach(date => {
                    preparePeriods.push({
                        dateTime: parseInt(date),
                        summ: row.periods[date]
                    });
                });

                row.periods = preparePeriods || [];
            }

            row.periods = isObject(row.periods) ? [] : row.periods;

            // const ssr = String(row[0]);
            const parent = row.ssr.includes('.') ? row.ssr.split('.').slice(0, -1).join('.') : null;

            row.parent = parent;

            return row;
        });

        const getGroupTypeName = (level) => {
            if (level === 1) return "group";
            if (level === 2) return "sub-group";
            return "sub-sub-group";
        };

        function calculateGroupPeriods(group, viewData) {
            let groupPeriods = {};

            const findRows = viewData.filter(r => r.parent === group.ssr);

            findRows.forEach(child => {
                if (child.type === "row" && child.periods) {
                    child.periods.forEach(period => {
                        if (!groupPeriods[period.dateTime]) {
                            groupPeriods[period.dateTime] = { summ: 0 };
                        }
                        groupPeriods[period.dateTime].summ += period.summ;
                    });
                } else if (child.type === "group") {
                    const childGroupPeriods = calculateGroupPeriods(child, viewData);
                    childGroupPeriods.forEach(period => {
                        if (!groupPeriods[period.dateTime]) {
                            groupPeriods[period.dateTime] = { summ: 0 };
                        }
                        groupPeriods[period.dateTime].summ += period.summ;
                    });
                }
            });

            return Object.keys(groupPeriods).map(dateTime => ({
                dateTime: parseInt(dateTime),
                summ: groupPeriods[dateTime].summ
            }));
        }

        const updateTableData = [];

        viewData.forEach(row => {
            if (row.type === "group") {
                const level = row.ssr.split('.').length;
                row.classGroup = getGroupTypeName(level);
                row.periods = calculateGroupPeriods(row, viewData);
            }

            if (row.periods?.length > 0) {
                const result = row.periods.map(p => p.summ).reduce((acc, num) => acc + Math.round(num * 100), 0) / 100;
                row.periods.forEach(p => row[p.dateTime] = p.summ)
                row.sum_period = result > 0 && result;
            }

            updateTableData.push(row);
        });

        setTableData(updateTableData);

        const prepareTablePeriods = viewData.flatMap(row => row.periods?.map(period => period.dateTime));
        const updateTablePeriods = [...new Set(prepareTablePeriods)].sort();
        setTablePeriods(updateTablePeriods);

        const updateDiagramData = {};
        const sortedDates = [];

        updateTableData
            .filter(row => row.type === 'row')
            .flatMap(row => row.periods)
            .forEach(p => {
                if (!updateDiagramData[p.dateTime]) updateDiagramData[p.dateTime] = 0;
                updateDiagramData[p.dateTime] += p.summ;
                if (!sortedDates.includes(p.dateTime)) sortedDates.push(p.dateTime);
            });

        sortedDates.sort((a, b) => new Date(a) - new Date(b));
        const cumulativeData = {};
        let cumulativeSum = 0;
        sortedDates.forEach(date => {
            cumulativeSum += updateDiagramData[date];
            cumulativeData[date] = cumulativeSum;
        });

        setDiagramData({ plan: cumulativeData });

    }, [data]); // Зависимостью является только data

    const columns = [
        { "tag": "ssr", "name": "ССР" },
        { "tag": "name", "name": "Наименование работ" },
        { "tag": "sum_period", "name": "Общая сметная стоимость" },
    ];

    if (tablePeriods) {
        const monthMap = {
            0: 'Январь', 1: 'Февраль', 2: 'Март', 3: 'Апрель',
            4: 'Май', 5: 'Июнь', 6: 'Июль', 7: 'Август',
            8: 'Сентябрь', 9: 'Октябрь', 10: 'Ноябрь', 11: 'Декабрь'
        };

        tablePeriods.forEach(p => columns.push({
            "tag": p,
            "name": `${monthMap[new Date(p * 1000).getMonth()]}\t${new Date(p * 1000).getFullYear()}`
        }));

        // TEMP FIX
        columns.pop()
    }

    return (
        <Box className={`flex flex-col gap-2 relative h-full`}>
            <Box className={`min-h-[250px]`}>
                {diagramData && <DiagramFinancialSchedule data={diagramData} />}
            </Box>
            <Box className="overflow-x-scroll">
                <DataTable data={tableData} columns={columns} />
            </Box>
        </Box>
    );
}
