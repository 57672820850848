import axios from "axios";
import {Alert} from "@mui/material";
import {getSubcomanyList} from "./workJournalData";

export const getTenderLotsBySubcompany = async (userSettings, subcompany_id, contract_id) => {

    try {

        const response = await axios.get(
            `${userSettings.api}/subcompanies/${subcompany_id}/won-tender-lots`,
            userSettings.headers.get
        );
        const lot = response?.data?.find(l => l.contract.id === contract_id);
        return lot;
    }
    catch (error) {
        console.error('changeLotStatusPost:', error.response ? error.response.data : error.message);

        return {
            success: false,
            message: error?.response?.data?.errors?.map((e, i) => `changeLotStatusPost - attr: ${e.attr} detail: ${e.detail} code: ${e.code}`)
        };
    }
}

export const getAnalyzeOrders = async (userSettings, company_id, project_id) => {
    try {

        const getSubcompanies = await getSubcomanyList(userSettings, company_id, project_id);
        if (getSubcompanies.success) {
            const response = getSubcompanies.data.map(async (s) => {
                const analyze = await axios.get(
                    `${userSettings.api}/work_payment/companies/${company_id}/projects/${project_id}/tenders/${s.tender}/subcompanies/${s.subcompany}/analyze-orders`,
                    userSettings.headers.get
                );

                const penalties = await axios.get(
                    `${userSettings.api}/work_payment/companies/${company_id}/projects/${project_id}/tenders/${s.tender}/subcompanies/${s.subcompany}/analyze-orders-with-penalties`,
                    userSettings.headers.get
                );

                const contract = await getTenderLotsBySubcompany(userSettings, s.subcompany, s.id);
                return {
                    ...s,
                    analyze: analyze.data,
                    penalties: penalties.data,
                    contract
                }
            })
            const allData = await Promise.all(response);
            return allData;
        }
    }
    catch (error) {
        console.error('\n getAnalyzeOrders', error.response);

        if (error.response.data) {
            return ({
                success: false,
                message: error?.response?.data.errors.map((e, i) => <Alert severity="error" key={i}>attr: {e.attr} detail: {e.detail} code: {e.code} </Alert>)
            })
        }
    }
}

export const getStatisticsData = async (userSettings, tender_id, year, type, method) => {
    try {
        const response = await axios.get(
            `${userSettings.api}/statistics/tenders/?tender_id=${tender_id}&type=${type}&year=${year}`,
            userSettings.headers.get
        );

        const names = ["u1_", "u2_", "u3_", "u4_", "k1_"];
        let statistics = [];

        if (type === 'daily') {
            // Обработка данных для дневного режима
            statistics = response?.data?.map(s => {
                const params = {};
                names.forEach(n => {
                    // Преобразуем данные в числа, заменяя отсутствующие значения на 0
                    params[`${n}${method}`] = +s[`${n}${method}`] || 0;
                });
                return {
                    date: new Date(s.created_at), // Формат даты для дневного режима
                    ...params,
                };
            });
        } else if (type === 'monthly') {
            // Обработка данных для месячного режима
            statistics = Object.entries(response?.data || {}).map(([month, values]) => {
                const params = {};

                names.forEach(n => {

                    const currentMonth = values[`${month.length<2 ? "0"+(+month+1) : month}.${year}`];

                    if (currentMonth) params[`${n}value`] = +currentMonth[`${n}value`] || 0;
                    else {params[`${n}value`] = 0}
                });

                // Преобразование строки формата "09.2024" в дату
                // const [monthPart, yearPart] = month.split('.');
                const formattedDate = new Date(Number(year), Number(month), 0); // Объект даты для первого числа месяца
                
                return {
                    date: formattedDate,
                    ...params,
                };
            });
        }
        return statistics;
    } catch (error) {
        console.error('\n getStatisticsData error:', error.response);
        if (error.response?.data) {
            return {
                success: false,
                message: error.response.data.errors.map((e, i) => (
                    <Alert severity="error" key={i}>
                        attr: {e.attr} detail: {e.detail} code: {e.code}
                    </Alert>
                )),
            };
        }
    }
};

export const contractPostStatuses = {
    1: "resume_contract",
    2: "pause_contract",
    3: "end_contract",
    4: "terminate_contract",
}

export const changeLotStatusPost = async (userSettings, type, company_id, project_id, subcompany_id, tender_id) => {

    try {

        const response = await axios.post(
            `${userSettings.api}/company/${company_id}/subcompanies/${subcompany_id}/projects/${project_id}/tenders/${tender_id}/${contractPostStatuses[type]}/`,
            '',
            {headers: userSettings.headers.post}
        );
        return {
            success: true,
            data: response.data
        };

    } catch (error) {
        console.error('changeLotStatusPost:', error.response ? error.response.data : error.message);

        return {
            success: false,
            message: error?.response?.data?.errors?.map((e, i) => `changeLotStatusPost - attr: ${e.attr} detail: ${e.detail} code: ${e.code}`)
        };
    }
}