import {Box, Button, Typography, Switch, FormControlLabel} from "@mui/material";
import {Link} from "react-router-dom";
import { LineChart } from '@mui/x-charts/LineChart';
import React, {useEffect, useState} from "react";
import {getStatisticsData} from "../../SendRequests/analyzeOrders";
import tables from "../../UI/Tables/table.module.css";
import {Price} from "../../UI/Convertors/Price";

export default function ContractDetails({contract, userSettings}) {
    const [statistics, setStatistics] = useState(null);
    const [viewMode, setViewMode] = useState("daily"); // daily - По дням, monthly - По месяцам
    const [viewMethod, setViewMethod] = useState("value"); // value - Баллы, penalty - Штрафы

    useEffect(() => {
        const getStatistics = async () => {
            // Меняем параметр "daily" или "monthly" в зависимости от состояния
            const response = await getStatisticsData(userSettings, contract.tender, 2024, viewMode, viewMethod);
            if (response) {
                setStatistics(response);
            }
        };
        getStatistics();
    }, [viewMethod, viewMode, userSettings, contract.tender]);

    const handleSwitchChange = (event) => {
        setViewMode(event.target.checked ? "monthly" : "daily");
    };
    const handleViewMethodChange = (event) => {
        setViewMethod(event.target.checked ? "penalty" : "value");
    };

    const series = [
        {
            id: 'u1',
            label: 'У1 Подписание',
            dataKey: `u1_${viewMethod}`,
            showMark: false,
            color: '#555f78'
        },
        {
            id: 'u2',
            label: 'У2 Отказы',
            dataKey: `u2_${viewMethod}`,
            showMark: false,
            color: '#5f7855'
        },
        {
            id: 'u3',
            label: 'У3 Категории',
            dataKey: `u3_${viewMethod}`,
            showMark: false,
            color: '#786e55',
        },
        {
            id: 'u4',
            label: 'У4 Просрочка',
            dataKey: `u4_${viewMethod}`,
            showMark: false,
            color: '#785555',
        },
        {
            id: 'k1',
            label: 'К1 Рассторжение',
            dataKey: `k1_${viewMethod}`,
            showMark: false,
            color: '#ff9900',
        },
    ];

    return (
        <Box className={`h-full flex gap-2 flex-col`}>
            <Typography variant="h2">Договор № {contract?.id} с подрядчиком {contract?.info.name}</Typography>
            <Box className={`flex gap-2`}>
                <Button
                    to={`/work/depositor/quality-control-works/`}
                    variant="contained"
                    color="primary"
                    component={Link}
                    sx={{width:'max-content'}}
                >
                    Назад
                </Button>
                <FormControlLabel
                    control={<Switch checked={viewMode === "monthly"} onChange={handleSwitchChange} />}
                    label={viewMode === "monthly" ? "По месяцам" : "По дням"}
                />
                <FormControlLabel
                    control={<Switch checked={viewMethod === "penalty"} onChange={handleViewMethodChange} />}
                    label={viewMethod === "penalty" ? "Штрафы" : "Баллы"}
                />
            </Box>

            {statistics && <React.Fragment>
                <Box>
                    <LineChart
                        dataset={statistics}
                        xAxis={[
                            {
                                id: 'Years',
                                dataKey: 'date',
                                scaleType: 'time',
                                valueFormatter: (date) =>
                                    viewMode === "daily" ? date.toLocaleDateString() : date.toLocaleDateString("default", { year: "numeric", month: "short" }),
                            },
                        ]}
                        series={series}
                        height={300}
                        margin={{ left: 70 }}
                    />
                </Box>
                <Box className={`grow ${tables.container}`}>
                    <table className={tables.table}>
                        <thead>
                        <tr>
                            <th>Дата</th>
                            {series.map(s => <th key={s.id}>{s.label}</th>)}
                        </tr>
                        </thead>
                        <tbody>
                        {statistics?.map((item, index) => (
                            <tr key={index}>
                                <td>{new Date(item.date).toLocaleDateString()}</td>
                                <td>{item[`u1_${viewMethod}`] ? Price(item[`u1_${viewMethod}`]) : ""}</td>
                                <td>{item[`u2_${viewMethod}`] ? Price(item[`u2_${viewMethod}`]) : ""}</td>
                                <td>{item[`u3_${viewMethod}`] ? Price(item[`u3_${viewMethod}`]) : ""}</td>
                                <td>{item[`u4_${viewMethod}`] ? Price(item[`u4_${viewMethod}`]) : ""}</td>
                                <td>{item[`k1_${viewMethod}`] ? Price(item[`k1_${viewMethod}`]) : ""}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </Box>
            </React.Fragment>}
        </Box>
    )
}