import {useEffect, useState} from 'react';
import Header from "./Header";
import PageSection from "../UI/Sections/PageSection";
import Button from "../UI/Buttons/Button";
import Grids from "../UI/Grids/Grids";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {useCookies} from "react-cookie";

const Welcome = (props) => {
    const [cookie, ] = useCookies(['access']);
    const templateRoles = {
        "investor": "Заказчик",
        "administrator": "Администратор",
        "coordinator": "Координатор",
        "manager": "Начальник участка",
        "build_control": "Стройконтроль",
        "gip": "ГИП",
        "contract": "Подрядчик"
    };
    const [tariffs, setTariffs] = useState([]);
    const navigate = useNavigate ();

    useEffect(() => {

        axios.get(`${props.erpApiUrl}/tariffs/`).then(response => {

            setTariffs(response.data);
        }).catch(error => {
            console.error(error);
        });
    },[props.erpApiUrl]);

    let tariffsElements = [];

    if (tariffs?.length > 0) {

        tariffsElements = tariffs.map(tariff =>
            <div key={tariff.id} className="text-center">
                <div>{tariff.name}</div>
                <div>{tariff.price}</div>
                <Button onClick={() => {
                    localStorage.setItem('tariff', tariff.id)
                    navigate('/registration-company');
                }}>Выбрать тариф - {tariff.name}</Button>
                <div className="my-5">
                    {Object.keys(templateRoles).map(role =>
                        <div key={role} className="grid grid-cols-2 gap-1 border p-2">
                            <div className="text-left">{templateRoles[role]}</div>
                            <div>{tariff[role]}</div>
                        </div>
                    )}
                </div>
            </div>
        );

    }

    return (
        <PageSection>
            <Header isLogin={cookie.access}/>
            <Grids className="justify-items-center" cols={3}>
                {tariffsElements}
            </Grids>
        </PageSection>
    );
}

export default Welcome;