import {Alert, Box, Button} from "@mui/material";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {read, utils} from "xlsx";
import VisuallyHiddenInput from "../../UI/Buttons/VisuallyHiddenInput";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

export default function UploadFinancialPlan({uploadFinancialPlanHandler, isUpdate, backUpdateFinancialPlanHandler}) {

    const [answers,setAnswers] = useState(null);

    const uploadFile = async (e) => {

        setAnswers(null);

        const file = await e.target.files[0].arrayBuffer();
        const workbook = read(file);
        const data = utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {header:1});
        const prepareFinancialPlan = buildFinPlan(data);

        if (Object.values(prepareFinancialPlan)?.length > 0) {
            uploadFinancialPlanHandler(prepareFinancialPlan);
        } else {
            setAnswers({
                "severity": "warning",
                "message": "Файл не по шаблону",
            })
        }
    };

    const buildFinPlan = (data) => {

        if (!data?.length) return [];

        const convertToTimestamp = (date, year) => {
            const monthMap = {
                'Январь': 0, 'Февраль': 1, 'Март': 2, 'Апрель': 3, 'Май': 4,
                'Июнь': 5, 'Июль': 6, 'Август': 7, 'Сентябрь': 8, 'Октябрь': 9,
                'Ноябрь': 10, 'Декабрь': 11
            };
            return new Date(year, monthMap[date]).getTime() / 1000;
        };

        const headPeriods = data[1]
            .slice(3)
            .map((date, index) => ({
                dateTime: convertToTimestamp(date, data[2][index + 3]),
                index: index + 3
            }))
            .sort((a, b) => a.dateTime - b.dateTime);

        const financialPlan = data
            .slice(3)
            .map(row => {
                if (!row[0] && !row[1]) return null;

                const ssr = String(row[0]);
                const parent = ssr.includes('.') ? ssr.split('.').slice(0, -1).join('.') : null;

                const periods = {};

                headPeriods.forEach(period => {
                    const sum = Math.round(row[period.index] * 100) / 100;
                    if (sum > 0) periods[period.dateTime] = sum;
                });

                return {
                    ssr,
                    name: row[1],
                    periods,
                    parent,
                    type: null
                };
            })
            .filter(Boolean)
        ;

        financialPlan
            .forEach(item => {
                const hasChildren = financialPlan.some(otherItem => otherItem.parent === item.ssr);
                item.type = hasChildren ? 'group' : 'row';
                item.periods = hasChildren ? {} : item.periods;
            })
        ;

        return financialPlan.reduce((acc, item, index) => ({ ...acc, [index]: item }), {});
    };

    return (
        <Box className={`flex flex-col gap-2 md:w-max`}>
            {isUpdate && <Button
                variant="contained"
                startIcon={<ArrowBackRoundedIcon />}
                onClick={backUpdateFinancialPlanHandler}
            >Назад</Button>}
            <Box className={`w-full md:w-[400px] flex-col gap-5`}>
                {answers && <Alert severity={answers.severity}>{answers.message}</Alert>}
                <Alert severity={isUpdate ? "warning" : "info"}>
                    {isUpdate ?
                        "Обновление финансового плана может значительно повлиять на анализ финансовых показателей и их связь со сметами подрядчиков, поэтому рекомендуем тщательно оценить его необходимость." :
                        "Финансовый план не загружен"
                    }
                </Alert>
                <Alert severity="info">Файлы необходимо загружать в Excel форме по шаблонам</Alert>
            </Box>
            <Button
                component={Link}
                to='/uploads/docs/project-financial-plan.xlsx'
                target="_blank"
                variant="outlined"
                startIcon={<CloudUploadOutlinedIcon />}
            >
                Скачать шаблон
            </Button>
            <Button
                component="label"
                variant="contained"
                startIcon={<FilePresentOutlinedIcon />}
            >
                Загрузить финансовый план
                <VisuallyHiddenInput
                    type="file"
                    onChange={uploadFile}
                    accept=".xlsx"
                />
            </Button>
        </Box>
    )
}