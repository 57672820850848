import {Box, Button, Stack, Typography} from "@mui/material";
import tables from "../../UI/Tables/table.module.css";
import TheadSort from "../../UI/Tables/TheadSort";
import React, {useEffect, useMemo, useState} from "react";
import {Price} from "../../UI/Convertors/Price";
import {Link} from "react-router-dom";
import {contractStatuses} from "./QualityControlWorks";
import Select from "../../UI/Select/Select";

const columns = [
    {
        tag: "deal",
        name: "№ договора",
    },
    {
        tag: "name",
        name: "Подрядчик",
    },
    {
        tag: "q",
        name: "Кол-во предписаний",
    },
    {
        tag: "u1",
        name: 'У1 Подписание',
    },
    {
        tag: "u2",
        name: 'У2 Отказы',
    },
    {
        tag: "u3",
        name: 'У3 Категории',
    },
    {
        tag: "u4",
        name: 'У4 Просрочка',
    },
    {
        tag: "k1",
        name: 'К1 Рассторжение',
    },
    {
        tag: "penalties",
        name: "Сумма штрафов",
    },
    {
        tag: "contract",
        name: "Статус договора",
    },
    {
        tag: "status",
        name: "Рассторжение",
    },
    {
        tag: "more",
        name: "Детали",
    },
];

export default function SubcompanyAnalyzeTable({analyzeOrders, terminateContract}) {

    const [data, setData] = useState(null);
    const [selectedContractor, setSelectedContractor] = useState(null);

    useEffect(() => {

        if (analyzeOrders?.length > 0) {

            const updateData = analyzeOrders.map(lot => {

                const deal = lot.id;
                const name = lot.info.name;
                const q = lot.analyze.q;
                const u1 = lot.penalties.u1_value;
                const u2 = lot.penalties.u2_value;
                const u3 = lot.penalties.u3_value;
                const u4 = lot.penalties.u4_value;
                const k1 = lot.penalties.k1_value;
                const penalties = lot.penalties.u1_penalty + lot.penalties.u2_penalty + lot.penalties.u3_penalty + lot.penalties.u4_penalty;
                const status = lot.penalties.k1_penalty;
                const requests = {
                    contract: lot.contract,
                    id: lot.id,
                    project_id: lot.project_id,
                    subcompany: lot.subcompany,
                    tender: lot.tender,
                }

                return ({
                    deal,
                    name,
                    q,
                    u1,
                    u2,
                    u3,
                    u4,
                    k1,
                    penalties,
                    status,
                    requests,
                });
            });

            setData(updateData);
        }
    }, [analyzeOrders]);

    const selectOptions = useMemo(() => {
        if (!data) return []
        return Array
            .from(
                new Set(data.map(item => item.name)))
            .map(item => ({name: item, id: item}))
    }, [data])

    const tableData = useMemo(() => {
        if (!data || !selectedContractor) return [];
        return data.filter(item => item.name === selectedContractor);
    }, [selectedContractor, data]);

    const selectHandler = ({target: {value}}) => {
        setSelectedContractor(value);
    }

    if (data) return (
        <React.Fragment>
            <Typography variant="h2">Анализ предписаний подрядчиков</Typography>
            <Stack className={`w-full gap-4 px-0.5`}>
                <Box width={'100%'} maxWidth={'300px'}>
                    <Select
                        options={selectOptions}
                        placeholder={'Выберите подрядчика'}
                        handleOnChange={selectHandler}
                    />
                </Box>
                {
                    selectedContractor &&
                    <Box className={`${tables.container} grow`}>
                        <table className={tables.table}>
                            <TheadSort
                                array={data}
                                names={columns}
                                setArray={setData}
                            />
                            <tbody>
                            {tableData.map((d, index) => {
                                const status = d.requests.contract?.contract?.contract;
                                return (
                                    <tr key={index}>
                                        {columns.map((c, id) => {

                                            const value = c.tag === "penalties" ?
                                                Price(d[c.tag]) :
                                                c.tag === "more" ? <Button
                                                        variant="contained"
                                                        color="primary"
                                                        component={Link}
                                                        to={`/work/depositor/quality-control-works/?contract=${d.requests?.id}`}
                                                    >
                                                        Подробнее
                                                    </Button> :
                                                    c.tag === "status" ? (d[c.tag] === "Договор можно расторгнуть" && status !== 4) &&
                                                        <Button
                                                            variant="contained"
                                                            color="error"
                                                            onClick={() => terminateContract(d.requests?.tender, d.requests?.subcompany)}
                                                        >
                                                            Расторгнуть договор
                                                        </Button> : c.tag === "contract" ? contractStatuses[status] :
                                                        d[c.tag]
                                            ;

                                            return (
                                                <td
                                                    key={id}
                                                    className={`
                                                ${c.tag === "penalties" ? "whitespace-nowrap" : ""}
                                                ${(c.tag === "deal" || c.tag === "name") ? "text-left min-w-[200px]" : "text-center"} 
                                            `}
                                                >
                                                    {value}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </Box>
                }
            </Stack>
        </React.Fragment>
    )
};