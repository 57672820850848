import axios from "axios";
import {ErrorOccurred} from "../Sections/ErrorOccurred";

export const getCompany = (companyList, userSettings) => {
    const promises = companyList.map(async company_id => {
        try {
            const companyInfo = await axios.get(
                `${userSettings.api}/company/${company_id}/`,
                userSettings.headers.get
            );
            if (companyInfo.status === 200) {
                return companyInfo.data;
            } else {
                return null;
            }
        } catch (error) {
            if (error.response) {
                console.error(`Ошибка при получении компании ${company_id}:`, error.response.data || error.response.status);
            } else {
                console.error(`Ошибка при получении компании ${company_id}:`, error.message);
            }
            return null;
        }
    });
    return Promise.all(promises);
}

export const getObjectName = async (userSettings,company_id,project_id) => {

    try {

        const response = await axios.get(
            `${userSettings.api}/object-name/companies/${company_id}/projects/${project_id}`,
            userSettings.headers.get
        );
        return response.data.data;
    }
    catch (errors) {
        const errorMessage = ErrorOccurred(errors.response, 'Ошибка удаления предписания', 'deleteOrder').jsx;
        return ({
            errorMessage,
            status: errors.response.status,
        });
    }
}

export const getCompanyProjectPersons = async (userSettings,company_id,project_id) => {
    try {
        const response = await axios.get(
            `${userSettings.api}/company/${company_id}/projects/${project_id}/persons`,
            userSettings.headers.get
        );

        return response.data;
    }
    catch (errors) {
        const errorMessage = ErrorOccurred(errors.response, 'Ошибка удаления предписания', 'deleteOrder').jsx;
        return ({
            errorMessage,
            status: errors.response.status,
        });
    }
}