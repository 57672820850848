import React, {useContext, useEffect, useState} from "react";
import UserSection from "../../UI/Sections/UserSection";
import {Link, useNavigate} from "react-router-dom";
import UserContext from "../../../store/user/user-context";
import {
    approveVolumeQualityDocuments,
    getQualityDocsList,
    getTendersList,
    rejectQualityDocuments,
    rejectVolumesQualityDocuments
} from "../../SendRequests/QualityDocumentsData";
import {Alert, Box, Button, Typography} from "@mui/material";
import {getEstimateBySubcompany} from "../../SendRequests/workJournalData";
import {Price} from "../../UI/Convertors/Price";
import tables from "../../UI/Tables/table.module.css";
import TheadSort from "../../UI/Tables/TheadSort";
import inputStyles from "../../UI/Inputs/inputs.module.css";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import {useApproveQualityDocsMutation} from "../../SendRequests/api/QualityDocs/queries/useApproveQualityDocsMutation";
import {useQueryClient} from "@tanstack/react-query";
import {qualityDocsStore} from "../../SendRequests/api/QualityDocs/qualityDocs.store";

const columnsWorks = [
    {
        tag: "number",
        name: "№",
    },
    {
        tag: "ssr",
        name: "ССР",
    },
    {
        tag: "name_works",
        name: "Наименование работ",
    },
    {
        tag: "units",
        name: "Ед. изм.",
    },
    {
        tag: "volume_agreed",
        name: "Объем",
    },
    {
        tag: "comment",
        name: "Причина отклонения",
    },
];

const columnsDocuments = [
    {
        tag: "document",
        name: "Документ",
    },
    {
        tag: "guid_dates",
        name: "Перечень работ",
    },
    {
        tag: "build_control_comments",
        name: "Причина отклонения",
    },
];

export default function UnapprovedWorkLog() {

    const navigate = useNavigate();
    const role = +window.localStorage.getItem('role');
    if (role !== 4 && role !== 3) navigate('/work/');

    const userSettings = useContext(UserContext);
    const company_id = +window.localStorage.getItem('company');
    const project_id = +window.localStorage.getItem('project');
    const params = Object.fromEntries(new URLSearchParams(window.location.search));
    const subcompany_id = +params.subcompany;
    const tender_id = +params.tender;

    const [qualityDocuments, setQualityDocuments] = useState(null);
    const [message, setMessage] = useState(null);
    const [currentTender, setCurrentTender] = useState(null);
    const [estimate, setEstimate] = useState(null);
    const [periods, setPeriods] = useState(null);
    const [comments, setComments] = useState(null);
    const [docList, setDocList] = useState(null);

    const queryClient = useQueryClient()

    const {mutateAsync: approveQualityDocuments} = useApproveQualityDocsMutation(company_id, project_id)

    useEffect(() => {

        const getData = async () => {
            try {
                const [updateQualityDocs, tenders, getEstimate] = await Promise.all([
                    getQualityDocsList(userSettings, company_id, project_id, subcompany_id, tender_id),
                    getTendersList(userSettings, company_id, project_id),
                    getEstimateBySubcompany(userSettings, company_id, project_id, subcompany_id, tender_id)
                ]);
                setQualityDocuments(updateQualityDocs);
                setDocList(updateQualityDocs.documents.filter(doc => !doc.is_approved));

                if (!tenders?.success) {
                    setMessage(tenders.message);
                } else {
                    const currentTender = tenders.data.find(t => t.id === tender_id);
                    setCurrentTender(currentTender);
                }

                if (!getEstimate?.success) {
                    setMessage(getEstimate.message);
                } else {
                    const preparePeriods = [];

                    const currentEstimate = getEstimate.data.estimate.map(w => {
                        const currentDates = w.work_log?.filter(l => updateQualityDocs.documents.some(doc => doc.guid_dates.includes(l.id))).filter(l => l.certificates_completed_work.length === 0) || [];
                        const volume_agreed = currentDates.reduce((acc, d) => acc + +d.value, 0);

                        const document = updateQualityDocs.documents.find(doc =>
                            doc.guid_dates.some(dateId => currentDates.some(work => work.id === dateId))
                        );

                        currentDates.forEach(d => {
                            preparePeriods.push(d.date);
                        });

                        return {
                            ...w,
                            volume_agreed,
                            document_id: document ? document.id : null,
                            is_approved_by_manager: document ? document.is_manager_approved : null,
                            is_approved_by_build_control: document ? document.is_build_control_approved : null,
                        };
                    });
                    const filteredEstimate = currentEstimate?.filter(e => {
                        const not_certificated = e.work_log.filter(l => l.certificates_completed_work.length === 0);
                        return not_certificated.length > 0;
                    })
                    const notApprovedEstimate = filteredEstimate.map(e => ({
                        ...e,
                        work_log: e.work_log.filter(l => l.certificates_completed_work.length === 0)
                    }))

                    setEstimate(notApprovedEstimate);
                    setPeriods([...new Set(preparePeriods)].sort((a, b) => new Date(a) - new Date(b)));
                }
            } catch (error) {
                console.error('Ошибка при получении данных:', error);
            }
        };

        getData();
    }, [userSettings, company_id, project_id, subcompany_id, tender_id]);

    const summPeriod = estimate?.map(e => {
        const valueCost = (+e.unit_cost_materials) + (+e.unit_cost_smr);
        const periodCost = Math.round(valueCost * e.volume_agreed * 100) / 100;

        return periodCost;
    }).reduce((sum, current) => sum + current, 0);

    const handleInputChange = (id, comment) => {

        const updateComments = {...comments};

        updateComments[id] = comment.trim();

        if (comment.length === 0) {
            delete updateComments[id];
        }

        setComments(Object.keys(updateComments).length > 0 ? updateComments : null);
    }
    const rejectVolumeHandler = async () => {

        const rejectComments = comments && Object.keys(comments)
            .map(id => {

                const work = estimate.find(w => w.id === +id);
                const data = {
                    "manager_comments": [
                        {
                            "id": +id,
                            "comment": String(comments[id])
                        }
                    ]
                };

                return (work && {
                    work,
                    data,
                    quality_document_id: work.document_id,
                });
            })
            .filter(Boolean)
        ;
        const used = [];
        const sendBodies = {};
        rejectComments.forEach(comm => {
            if (used.includes(comm.quality_document_id)) sendBodies[comm.quality_document_id].push(comm.data.manager_comments[0])
            else {
                sendBodies[comm.quality_document_id] = [comm.data.manager_comments[0]];
                used.push(comm.quality_document_id);
            }
        })

        const rejectHandler = await rejectVolumesQualityDocuments(userSettings, company_id, project_id, tender_id, subcompany_id, sendBodies);
        const rejectMessage = rejectHandler?.map((d, id) => {

            return (
                <Alert
                    severity={d.error ? "error" : "success"}
                    key={id}
                >
                    {d.response.detail ? d.response.detail : `Документ № ${d.response.data.id} обновлен`}
                </Alert>
            );
        });
        setComments(null);
        setMessage(rejectMessage);

        await getQualityDocsList(userSettings, company_id, project_id, subcompany_id, tender_id);
    }
    const acceptAllVolumeHandler = async () => {
        const acceptAllHandler = await approveVolumeQualityDocuments(userSettings, company_id, project_id, tender_id, subcompany_id, docList.map(d => d.id)); //
        const acceptMessages = acceptAllHandler?.map((d, id) => {

            return (
                <Alert
                    severity={d.error ? "error" : "success"}
                    key={id}
                >
                    {d.response.detail ? d.response.detail : `Документ № ${d.response.data.id} обновлен`}
                </Alert>
            );
        });
        setComments(null);
        setMessage(acceptMessages);

        queryClient.invalidateQueries({
            queryKey: qualityDocsStore.docs._def
        })

        const updateDocList = await getQualityDocsList(userSettings, company_id, project_id, subcompany_id, tender_id);
        setEstimate([])
        const updatedDocList = updateDocList.documents.filter(doc => !doc.is_manager_approved && !doc.is_approved)
        setDocList(updatedDocList);

        if (!updateDocList.length) {
            navigate('/work/')
        }
    }

    const rejectDocumentHandler = async () => {

        const rejectHandler = await rejectQualityDocuments(userSettings, company_id, project_id, tender_id, subcompany_id, comments); //

        const rejectMessage = rejectHandler?.map((d, id) => {

            return (
                <Alert
                    severity={d.error ? "error" : "success"}
                    key={id}
                >
                    {d.response.detail ? d.response.detail : `Документ № ${d.response.data.id} обновлен`}
                </Alert>
            );
        });
        setComments(null);
        setMessage(rejectMessage);

        const updateDocList = await getQualityDocsList(userSettings, company_id, project_id, subcompany_id, tender_id);
        setDocList(updateDocList.documents);
    }
    const acceptAllDocumentHandler = async () => {

        const approvedDocuments = await approveQualityDocuments({userSettings, tender_id, subcompany_id, documents: docList.map(d => d.id)}); //
        const rejectMessage = approvedDocuments?.map((d, id) => {

            return (
                <Alert
                    severity={d.error ? "error" : "success"}
                    key={id}
                >
                    {d.response.detail ? d.response.detail : `Документ № ${d.response.data.id} обновлен`}
                </Alert>
            );
        });
        setComments(null);
        setMessage(rejectMessage);

        const updateDocList = await getQualityDocsList(userSettings, company_id, project_id, subcompany_id, tender_id);
        const updatedDocList = updateDocList.documents.filter(doc => !doc.is_build_control_approved)
        setDocList(updatedDocList);

        console.log(updateDocList.length)
        if (!updateDocList.length) {
            navigate('/work/')
        }
    }

    const prepareWorkList = (guid_dates) => {
        const workList = {};
        guid_dates.forEach((date) => {
            estimate?.forEach((e) => {
                const workLog = e.work_log.find((wld) => wld.id === date);
                if (workLog) {
                    if (!workList[e.id]) {
                        workList[e.id] = {
                            number: e.number,
                            ssr: e.ssr,
                            name: e.name_works,
                            units: e.units,
                            values: [],
                        };
                    }
                    workList[e.id].values.push(+workLog.value);
                }
            });
        });
        return workList;
    };
    const renderWork = (workList) => {
        return Object.values(workList).map((work, id) => (
            <Box className="flex flex-row gap-2" key={id}>
                <Box>{work.name}</Box>
                <Box>{Price(work.values.reduce((a, b) => a + b, 0))}</Box>
                <Box>{work.units}</Box>
            </Box>
        ));
    };

    return (
        <UserSection>
            <h1>Согласовать работы подрядчика</h1>
            {message && <Box>{message.map(m => m)}</Box>}
            <Box className={`h-full relative flex flex-col gap-2`}>
                {qualityDocuments && <Typography variant="h6">Подрядчик: {qualityDocuments.company.name}</Typography>}
                {(currentTender && periods && summPeriod) && <Alert severity="info" sx={{width: "max-content"}}>
                    <Typography>Тендер: {currentTender.name}</Typography>
                    <Typography>В период
                        с {new Date(periods[0]).toLocaleDateString()} по {new Date(periods[periods.length - 1]).toLocaleDateString()}</Typography>
                    <Typography>На сумму {Price(summPeriod)}</Typography>
                </Alert>}
                {role === 4 && <React.Fragment>
                    <Button
                        onClick={comments ? rejectVolumeHandler : acceptAllVolumeHandler}
                        variant="contained"
                        color={comments ? "warning" : "success"}
                        startIcon={comments ? <CloseOutlinedIcon/> : <CheckOutlinedIcon/>}
                        sx={{width: "max-content"}}
                    >
                        {comments ? 'Отклонить' : 'Согласовать'} объем работ
                    </Button>
                    {estimate && <Box className={`${tables.container} grow`}>
                        <table className={tables.table}>
                            <TheadSort
                                array={estimate}
                                names={columnsWorks}
                                setArray={setEstimate}
                            />
                            <tbody>
                            {estimate.filter(e => e.volume_agreed > 0).map((w, num) => {
                                return (
                                    <tr key={num}>
                                        {columnsWorks.map((c, col) => {
                                            const value = c.tag === 'volume_agreed' && Price(w[c.tag]);
                                            const comment = qualityDocuments?.documents?.find(d => d.id === w.document_id)?.manager_comments?.find(c => c.id === w.id)?.comment;

                                            return (
                                                <td
                                                    key={col}
                                                    className={`${c.tag === 'name_works' ? "min-w-[250px] text-left" : "text-center"}`}
                                                >
                                                    {(c.tag === "comment") ?
                                                        <textarea
                                                            className={inputStyles.input}
                                                            name="comment"
                                                            id={w.id}
                                                            placeholder={`Укажите причину отклонения объема по работе`}
                                                            onChange={(e) => handleInputChange(w.id, e.target.value)}
                                                            defaultValue={comment || ''}
                                                        /> :
                                                        value ? value : w[c.tag]
                                                    }
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </Box>}
                </React.Fragment>}
                {role === 3 && <React.Fragment>
                    <Button
                        onClick={comments ? rejectDocumentHandler : acceptAllDocumentHandler}
                        variant="contained"
                        color={comments ? "warning" : "success"}
                        startIcon={comments ? <CloseOutlinedIcon/> : <CheckOutlinedIcon/>}
                        sx={{width: "max-content"}}
                        disabled={!docList?.length}
                    >
                        {comments ? 'Отклонить' : 'Согласовать'} документы о качестве работ
                    </Button>
                    {docList?.length > 0 && <Box className={`${tables.container} grow`}>
                        <table className={tables.table}>
                            <TheadSort
                                array={docList}
                                names={columnsDocuments}
                                setArray={setDocList}
                            />
                            <tbody>
                            {docList?.map((doc, num) => {

                                const workList = prepareWorkList(doc.guid_dates);

                                return (
                                    <tr key={num}>
                                        {columnsDocuments.map((col, colIndex) => {
                                            const documentButton = col.tag === 'document' && (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    component={Link}
                                                    to={userSettings.fileServer + doc[col.tag]}
                                                    target="_blank"
                                                    startIcon={<SimCardDownloadOutlinedIcon/>}
                                                    sx={{width: "max-content"}}
                                                >
                                                    Документ №{doc.id}
                                                </Button>
                                            );
                                            const comment = col.tag === 'build_control_comments' && (
                                                <textarea
                                                    className={inputStyles.input}
                                                    name="comment"
                                                    id={doc.id}
                                                    placeholder={`Укажите причину отклонения документа`}
                                                    onChange={(e) => handleInputChange(doc.id, e.target.value)}
                                                    defaultValue={doc.build_control_comments || ''}
                                                />
                                            )

                                            return (
                                                <td key={colIndex}>
                                                    {documentButton ||
                                                        (col.tag === "guid_dates" && Object.keys(workList).length > 0 ?
                                                            renderWork(workList) :
                                                            comment ? comment :
                                                                String(doc[col.tag]))
                                                    }
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </Box>}
                </React.Fragment>}
            </Box>
        </UserSection>
    );
}