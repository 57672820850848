export function PrintPhone(input) {

    const value = input.replace(/\D/g, '').replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, ' $1$2$3$4$5');
    const code = `+${value.substring(1, 2)}`;
    const operator = value.substring(2, 5);
    const number_1 = value.substring(5, 8);
    const number_2 = value.substring(8);

    return `${code} (${operator}) ${number_1} ${number_2}`;
}

// Пример использования:
// const formattedNumber = PrintPhone('+7(999)9999990');
