import {useContext, useEffect, useState} from "react";
import UserContext from "../../../../../../store/user/user-context";

const useFetchedInfo = (subcompany_id, tender_id) => {
    const userSettings = useContext(UserContext);
    const [fetchedInfo, setFetchedInfo] = useState(null);
    const [errorInfo, setErrorInfo] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function getInfo() {
            // const object_name = await getObjectName(userSettings, 2, 2);
            //
            // return {
            //     object_name,
            // }
            return []
        }

        getInfo()
            .then(setFetchedInfo)
            .catch(setErrorInfo)
            .finally(() => setIsLoading(false));
    }, [userSettings, subcompany_id, tender_id]);

    return {fetchedInfo, errorInfo, isLoading};
};

export default useFetchedInfo;