import React, {useContext, useEffect, useState} from "react";
import UserContext from "../../../store/user/user-context";
import axios from "axios";
import styles from "../dash.module.css";
import {Price} from "../../UI/Convertors/Price";
import UserSection from "../../UI/Sections/UserSection";
import {ErrorOccurred} from "../../UI/Sections/ErrorOccurred";

export default function SubcompanyInfo() {

    const userSettings = useContext(UserContext);
    const subcompany_id = +window.localStorage.getItem('company') || null;
    const subcompany = subcompany_id && userSettings.companies.find(c=> c.id === subcompany_id);
    const tender_id = +window.localStorage.getItem('tender') || null;


    const [answers, setAnswers] = useState(null);
    const [tender, setTender] = useState(null);

    useEffect(() => {

        const getTender = async () => {

            try {

                const wonTenderLots = await axios.get(
                    `${userSettings.api}/subcompanies/${subcompany_id}/won-tender-lots`,
                    userSettings.headers.get
                );

                const updateCurrentTender = wonTenderLots.data.find(contract => contract.tender.id === tender_id);
                setTender(updateCurrentTender);
            }
            catch (error) {

                const responseError = ErrorOccurred(
                    error.response,
                    "Ошибка получения документов при регистрации",
                    "responseError"
                );
                setAnswers(responseError.jsx);
            }
        }

        if (subcompany_id) getTender();
    },[userSettings, subcompany_id, tender_id]);

    if (subcompany && tender) return (
        <UserSection>
            <h1>Компания {subcompany.name}</h1>
            {answers && answers}
            <ul className={styles.properties}>
                <li>Договор: {tender.contract.id}</li>
                <li>Тендер: {tender.tender.name}</li>
                <li>ИНН: {subcompany.inn}</li>
                <li>КПП: {subcompany.kpp}</li>
                <li>Телефон: {subcompany.phone}</li>
                <li>Проект: {tender.tender.project.name}</li>
                <li>Адрес объекта: {tender.tender.address}</li>
                <li>Стоимость контракта: {Price(tender.tender.nmc)} р.</li>
            </ul>
            <div>
                <h2>Представители</h2>
                <div>{subcompany.email}</div>
            </div>
        </UserSection>
    );
}