import {useContext, useEffect, useState} from "react";
import {getTender} from "../../../../UI/GetInfo/getSubcompanies";
import {getCompanyProjectPersons, getObjectName} from "../../../../UI/GetInfo/getCompany";
import UserContext from "../../../../../store/user/user-context";
import {getQualityDocs} from "../../../../UI/GetInfo/getTendersInfo";
import {validateWorkDatesAndNames} from "../helpers/validateWorkDatesAndNames";
import {getEstimateElements} from "../../../../UI/GetInfo/getEstimations";
import axios from "axios";

const useFetchedInfo = (subcompany_id, tender_id) => {
    const userSettings = useContext(UserContext);
    const [fetchedInfo, setFetchedInfo] = useState(null);
    const [errorInfo, setErrorInfo] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function getInfo() {
            const allTenders = await getTender(userSettings, subcompany_id);
            const currentTender = allTenders.find(tender => tender.tender.id === tender_id);
            const company = currentTender.tender.project.company;

            const signatories = {
                company: currentTender?.contract?.company_signatory?.signatory,
                subcompany: currentTender?.contract?.subcompany_signatory?.signatory,
            }

            const usersResponse = await axios.get(
                `${userSettings.api}/company/${currentTender?.contract?.subcompany}/users`,
                userSettings.headers.get
            );

            const representatives = usersResponse.data.users;


            const project_id = currentTender.tender.project.id;
            const object_name = await getObjectName(userSettings, company.id, project_id);

            const subcompany = userSettings.companies.find(s => s.id === subcompany_id);

            const personsList = await getCompanyProjectPersons(userSettings, company.id, project_id);

            const estimateElements = await getEstimateElements(userSettings, currentTender.contract.main, currentTender.contract.project_id, tender_id, currentTender.contract.subcompany);
            const qualityDocs = await getQualityDocs(userSettings, currentTender.contract.main, currentTender.contract.project_id, tender_id, currentTender.contract.subcompany)
            const filteredQualityDocs = qualityDocs.filter(doc => !doc.is_approved);

            const {workDates, workNames} = validateWorkDatesAndNames(filteredQualityDocs, estimateElements);

            const idObject = {
                company_id: company.id,
                project_id,
                tender_id,
                subcompany_id
            }

            return {
                allTenders,
                currentTender,
                company,
                project_id,
                object_name,
                subcompany,
                personsList,
                workDates,
                workNames,
                idObject,
                signatories,
                representatives
            }
        }

        getInfo()
            .then(setFetchedInfo)
            .catch(setErrorInfo)
            .finally(() => setIsLoading(false));
    }, [userSettings, subcompany_id, tender_id]);
    return {fetchedInfo, errorInfo, isLoading};
};

export default useFetchedInfo;