import React, {useContext, useEffect, useState} from "react";
import UserSection from "../../UI/Sections/UserSection";
import {Link, useNavigate} from "react-router-dom";
import UserContext from "../../../store/user/user-context";
import axios from "axios";
import Warnings from "../../UI/Sections/Warnings";
import Btn from "../../UI/Buttons/Btn";
import {Price} from "../../UI/Convertors/Price";
import Popup from "../../UI/Popup/Popup";
import Grids from "../../UI/Grids/Grids";
import {FormProvider, useForm} from "react-hook-form";
import RegistrationInput from "../../UI/Inputs/RegistrationInput";
import TenderResults from "./TenderResults";
import {nanoid} from "nanoid";
import {Alert, Box, Button, ButtonGroup} from "@mui/material";
import tables from "../../UI/Tables/table.module.css";
import TheadSort from "../../UI/Tables/TheadSort";
import inputs from "../../UI/Inputs/inputs.module.css";

export default function TenderInfo() {

    const navigate = useNavigate();
    if (window.localStorage.getItem('role') !== '2') navigate('/work/');

    const userSettings = useContext(UserContext);
    const company_id = +window.localStorage.getItem('company');
    const project_id = +window.localStorage.getItem('project');
    const path = window.location.pathname.split('/');
    const tender_id = +path[path.length - 1];

    const methods = useForm();
    const {unregister} = methods;

    const [answers, setAnswers] = useState(null);
    const [popupShow, setPopupShow] = useState(false);
    const [tender, setTender] = useState(null);
    const [editDocs, setEditDocs] = useState(null);
    const [lots, setLots] = useState(null);

    const [criteria, setCriteria] = useState(null);
    const [criteriaIsShow, setCriteriaIsShow] = useState(null);
    const [criteriaIsEdit, setCriteriaIsEdit] = useState(null);

    useEffect(() => {
        const getData = async () => {
            try {
                const getCriteria = await axios.get(
                    `${userSettings.api}/tenders/companies/${company_id}/projects/${project_id}/tenders/${tender.id}/criteria/`,
                    userSettings.headers.get
                );

                setCriteria(getCriteria.data.sort((a, b) => a.id - b.id));
            } catch (error) {
                console.error(`\n getData error`, error.response);
            }
        }

        getData();
    }, [company_id, project_id, tender]);

    const criteriaAllWeight = criteria && criteria?.map(c => c.weight).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const headerNames = [
        {
            name: "Критерий выбора",
            tag: "name",
        },
        {
            name: `Вес критерия ${criteriaAllWeight && criteriaAllWeight}`,
            tag: "weight",
        },
        {
            name: "Значение критерия",
            tag: "value",
        },
        {
            name: "Редактировать",
            tag: "edit",
        },
    ]

    const postCriteriaHandler = async (listСriteria) => {

        try {
            if (listСriteria.length > 0) {

                const updatedCriteria = await Promise.all(listСriteria.map(async (c) => {

                    const answer = await axios.post(
                        `${userSettings.api}/tenders/companies/${company_id}/projects/${project_id}/tenders/${tender.id}/criteria/`,
                        {
                            "name": c.name,
                            "weight": c.weight,
                            "value": c.value
                        },
                        {headers: userSettings.headers.post}
                    );
                    return answer.data;
                }));
                setCriteria([...criteria, ...updatedCriteria]);
            }
        } catch (error) {
            setAnswers(<Warnings>
                Ошибка создания критериев - {error.response.data.errors ?
                error.response.data.errors.map((e, id) => <p key={id}>{e.detail}</p>)
                :
                error.response.status
            }
            </Warnings>)
            console.error(`\n postCriteriaHandler error`, error.response);
        }
    }
    const putCriteriaHandler = async (listСriteria) => {

        try {
            if (listСriteria.length > 0) {
                const updatedCriteria = await Promise.all(listСriteria.map(async (c) => {
                    const criteria_id = c.id;
                    const answer = await axios.put(
                        `${userSettings.api}/tenders/companies/${company_id}/projects/${project_id}/tenders/${tender.id}/criteria/${criteria_id}/`,
                        {
                            "name": c.name,
                            "weight": c.weight,
                            "value": c.value
                        },
                        {headers: userSettings.headers.post}
                    );
                    return answer.data;
                }));

                const updatedCriteriaIds = updatedCriteria.map((c) => c.id);
                const updatedState = criteria.map((c) => {
                    if (updatedCriteriaIds.includes(c.id)) {
                        const updatedCriterion = updatedCriteria.find((uc) => uc.id === c.id);
                        return updatedCriterion;
                    } else {
                        return c;
                    }
                });

                setCriteria(updatedState);
                setCriteriaIsEdit(null);

                setAnswers(<Warnings correct>Критерии успешно обновлены</Warnings>)
            }
        } catch (error) {
            setAnswers(<Warnings>
                Ошибка создания критериев - {error.response.data.errors ?
                error.response.data.errors.map((e, id) => <p key={id}>{e.detail}</p>)
                :
                error.response.status
            }
            </Warnings>)
            console.error(`\n postCriteriaHandler error`, error.response);
        }
    }
    const templateCriteriaHandler = () => {

        postCriteriaHandler([{name: "Новый критерий", weight: 0, value: 1}])
    }
    const changeCriteriaHandler = (id, prop, value) => {

        const updateCriteria = [...criteria].map(c => {

            if (c.id === id) c[prop] = value;

            return (c);
        });
        setCriteria(updateCriteria);

        setCriteriaIsEdit(true);
    }
    const cloneCriteriaHandler = (id) => {

        const currentCriteria = criteria.find(c => c.id === id);
        // currentCriteria.weight = 0;
        const criteriaToCopy = {...currentCriteria, weight: 0}

        postCriteriaHandler([criteriaToCopy]);
    }
    const deleteCriteriaHandler = async (criteria_id) => {

        try {
            const response = await axios.delete(
                `${userSettings.api}/tenders/companies/${company_id}/projects/${project_id}/tenders/${tender.id}/criteria/${criteria_id}/`,
                {headers: userSettings.headers.postX}
            );
            setCriteria(prev => prev.filter(c => c.id !== criteria_id))
        } catch (error) {
            setAnswers(<Warnings>
                Ошибка удаления критерия - {error.response.data.errors ?
                error.response.data.errors.map((e, id) => <p key={id}>{e.detail}</p>)
                :
                error.response.status
            }
            </Warnings>)
            console.error(`\n deleteCriteriaHandler error`, error.response);
        }
    }

    const editRepresentativeHandler = (representative, phone, email, tender_id) => {

        const editRepresentative = async (data) => {

            setPopupShow(null);

            try {

                const formData = new FormData();
                formData.append('representative', data.representative);
                formData.append('phone', data.phone.replace(/ /g, ''));
                formData.append('email', data.email);

                const response = await axios.put(
                    `${userSettings.api}/tenders/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/representative/`,
                    formData,
                    {headers: userSettings.headers.postForm}
                )

                setAnswers(<Warnings correct>Изменение представителя обновлены</Warnings>);

                setTender(response.data);
            } catch (error) {
                setAnswers(<Warnings>
                    Ошибка изменения представителя - {error.response.data.errors ?
                    error.response.data.errors.map((e, id) => <p key={id}>{e.detail}</p>)
                    :
                    error.response.status
                }
                </Warnings>)
                console.error(`\n editRepresentativeHandler error`, error.response);
            }
        }

        const fields = [
            {
                "name": "representative",
                "type": "text",
                "label": "Ф.И.О. представителя",
                "defaultValue": representative
            },
            {
                "name": "phone",
                "type": "tel",
                "label": "Телефон представителя",
                "defaultValue": phone
            },
            {
                "name": "email",
                "type": "email",
                "label": "E-mail представителя",
                "defaultValue": email
            }
        ];

        setPopupShow(<Popup
            title={`Изменить представителя тендера`}
            form={{
                fields: fields,
                submit: "Сохранить"
            }}
            onSubmit={editRepresentative}
            onCloseModal={() => setPopupShow(false)}
        />);
    }
    const putDocumentsHandler = async (data) => {
        try {

            const formData = new FormData();
            for (const key in data) {
                if (key.includes('files')) {
                    const file = data[key][0];

                    formData.append("files", file);
                } else if (key.includes('names')) {
                    formData.append("filenames", data[key]);
                }
            }

            const response = await axios.put(
                `${userSettings.api}/tenders/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/documents/`,
                formData,
                {headers: userSettings.headers.postForm}
            );
            setEditDocs(false);
            if (response.status === 200) window.location.reload();
        } catch
            (error) {
            setAnswers(<Warnings>
                Ошибка изменения документов - {error.response.data.errors ?
                error.response.data.errors.map((e, id) => <p key={id}>{e.detail}</p>)
                :
                error.response.status
            }
            </Warnings>)
            console.error(`\n putDocumentsHandler error`, error.response);
        }
    }
    const editDocsHandler = () => {

        if (tender && tender.attachments) setEditDocs(tender.attachments);
    }
    const addDoc = () => {
        const updatedDocs = [...editDocs];
        const newId = nanoid();
        updatedDocs.push({
            name: 'Новый документ',
            id: newId
        });
        setEditDocs(updatedDocs);
    }

    const publicTenderHandler = async () => {

        try {
            const formData = new FormData();
            formData.append('is_public', false);

            const response = await axios.post(
                `${userSettings.api}/tenders/companies/${company_id}/project/${project_id}/tenders/${tender_id}/change-publishing`,
                formData,
                {headers: {...userSettings.headers.post, 'Content-Type': 'multipart/form-data'}}
            );

            if (response.status === 200) window.location.reload();
        } catch (error) {
            console.error('Error', error.response.data);
            // Обработка ошибки
        }
    }
    const tenderConfirm = async (tender_lot_id, reason) => {
        try {

            const formData = new FormData();
            formData.append("reason", reason)

            const response = await axios.post(
                `${userSettings.api}/tenders/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/tender-lots/${tender_lot_id}/confirm/`,
                formData,
                {headers: userSettings.headers.post}
            );
            publicTenderHandler(false);
        } catch (error) {
            setAnswers(<Warnings>
                Ошибка выигрыша тендера - {error.response.data.errors ?
                error.response.data.errors.map((e, id) => <p key={id}>{e.detail}</p>)
                :
                error.response.status
            }
            </Warnings>)
            console.error(`\n tenderConfirm error`, error.response);
        }
    }

    const removeDocHandler = (doc_id) => {
        const updatedDocs = [...editDocs].filter(doc => doc.id !== doc_id);
        unregister("files-" + doc_id);
        unregister("names-" + doc_id);
        setEditDocs(updatedDocs);
    }

    useEffect(() => {
        window.localStorage.setItem("tender", +path[path.length - 1])

        const getData = async () => {

            try {
                const getTenders = await axios.get(
                    `${userSettings.api}/tenders/companies/${company_id}/`,
                    userSettings.headers.get
                );
                const currentTender = getTenders.data.find(t => t.id === tender_id);
                setTender(currentTender);
                setAnswers(null);

                const getLots = await axios.get(
                    `${userSettings.api}/tenders/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/tender-lots/`,
                    userSettings.headers.get
                );
                if (getLots.data && getLots.data.length > 0) setLots(getLots.data.sort((a, b) => a.id - b.id));
            } catch (error) {
                setAnswers(<Warnings>
                    Ошибка получения информации - {error.response.data.errors ?
                    error.response.data.errors.map((e, id) => <p key={id}>{e.detail}</p>)
                    :
                    error.response.status
                }
                </Warnings>)
                console.error(`\n getData error`, error.response);
            }
        }

        getData();
    }, [company_id, project_id, userSettings, tender_id]);

    return (
        <UserSection>
            <h1>Информация по тендеру {tender && tender.name}</h1>
            {popupShow}
            {answers && answers}
            <Btn method={'back'} color={`button`}>
                <Link to={"/work/coordinator/tenders"}>Назад</Link>
            </Btn>
            {tender && <React.Fragment>
                <Grids cols={2}>
                    <div>
                        <div
                            className={`grow my-auto`}>Тендер {tender.is_won ? "выигран" : !tender.is_public ? "не опубликован" : "опубликован"}</div>
                        <div>Информация по тендеру</div>
                        <div>Раздел: {userSettings.tender_directions[tender.category]}</div>
                        <div>НМЦ лота: {Price(+tender.nmc)}</div>
                        <div>Адрес объекта: {tender.address}</div>
                        <div>Дата и время окончания подачи заявок: {new Date(tender.end_date).toLocaleDateString()} г.
                        </div>
                    </div>
                    <div>
                        <div>Представитель тендера</div>
                        <div className={`p-1 border border-pult/20 rounded-md bg-pult/5 my-1.5`}>
                            <div>Представитель: {tender.representative}</div>
                            <div>Тел.: {tender.phone}</div>
                            <div>E-mail: {tender.email}</div>
                        </div>
                        <Btn
                            method={`edit`}
                            color={`button`}
                            onClick={() => editRepresentativeHandler(tender.representative, tender.phone, tender.email, tender.id)}
                        >
                            Редактировать представителя
                        </Btn>
                    </div>
                </Grids>

                <Btn
                    method={!criteriaIsShow ? 'view' : 'back'}
                    color={`button`}
                    onClick={() => setCriteriaIsShow(!criteriaIsShow)}
                >
                    {!criteriaIsShow ? "Критерии выбора подрядчика" : "Назад"}
                </Btn>
                {criteriaIsShow && <React.Fragment>
                    <div className={`my-2 flex`}>
                        {criteriaAllWeight === 100 ?
                            (criteriaIsEdit) &&
                            <Btn color={`button`} onClick={() => putCriteriaHandler(criteria)}>Сохранить</Btn>
                            :
                            <div className={`flex flex-col gap-1`}>
                                <Alert severity="warning">Сумма весов критериев должна быть 100</Alert>
                                {(criteria && criteria.length === 2) &&
                                    <Btn color={`button`} onClick={templateCriteriaHandler}>Добавить критерий</Btn>}
                            </div>
                        }
                    </div>
                    {criteria &&
                        <Box className='px-4 h-fit'>
                            <div className={tables.container}>
                                <table className={tables.table}>
                                    <TheadSort
                                        names={headerNames}
                                        array={criteria}
                                        setArray={setCriteria}
                                    />
                                    <tbody>
                                    {criteria.map(c => {
                                        return (<tr key={c.id}>
                                            {headerNames.map((name, index) => {

                                                const namePrint = !c.is_required ? <input
                                                    type={`text`}
                                                    defaultValue={c.name}
                                                    className={`${inputs.input} w-full`}
                                                    onChange={(e) => changeCriteriaHandler(c.id, 'name', e.target.value)}
                                                /> : c.name

                                                const weight = (
                                                    <input
                                                        type={`number`}
                                                        className={`${criteriaAllWeight !== 100 ? `${inputs['invalid-input']} font-bold` : ''} ${inputs.input} w-20 text-center mx-auto block`}
                                                        defaultValue={c.weight}
                                                        min={0}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "-") {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            let value = e.target.value.replace("-", "");
                                                            changeCriteriaHandler(c.id, 'weight', value ? +value : 0);
                                                        }}
                                                    />
                                                )
                                                const value = (<select
                                                    className={`${inputs.input} w-20 text-center mx-auto block`}
                                                    defaultValue={c.value === 1 ? '1' : '2'}
                                                    onChange={(e) => changeCriteriaHandler(c.id, 'value', +e.target.value)}
                                                >
                                                    <option value={'1'}>Мин</option>
                                                    <option value={'2'}>Макс</option>
                                                </select>);
                                                const edit = !c.is_required && (
                                                    <Box className='flex'>
                                                        <ButtonGroup>
                                                            <Button
                                                                onClick={() => cloneCriteriaHandler(c.id)}
                                                            >
                                                                Копировать
                                                            </Button>
                                                            <Button
                                                                color="error"
                                                                onClick={() => deleteCriteriaHandler(c.id)}
                                                            >
                                                                Удалить
                                                            </Button>
                                                        </ButtonGroup>
                                                    </Box>
                                                );

                                                return (<td
                                                    key={index}
                                                    className={(name.tag !== "name") ? `text-center` : ""}
                                                >
                                                    {(name.tag === "name") ?
                                                        namePrint :
                                                        (name.tag === "weight") ?
                                                            weight :
                                                            (name.tag === "value") ?
                                                                value :
                                                                (name.tag === "edit") ?
                                                                    edit :
                                                                    c[name.tag]}
                                                </td>);
                                            })}
                                        </tr>)
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </Box>
                    }
                </React.Fragment>}
                <Btn method={`view`}>
                    <Link to={tender?.estimate_template ? `${userSettings.fileServer}${tender?.estimate_template}` : `/uploads/docs/contractors_estimate.xlsx`} target={`_blank`}>
                        Шаблон сметы подрядчика
                    </Link>
                </Btn>

                {!editDocs && <React.Fragment>
                    <div>Документы к тендеру</div>
                    <div className={`flex`}>
                        <div className={`flex flex-wrap flex-1 gap-2 items-center border border-pult/20 rounded-md`}>
                            {tender.attachments?.map((doc, id) =>
                                <Btn key={id} link>
                                    <Link target={`_blank`}
                                          to={`${userSettings.fileServer}${doc.file}`}>{doc.name}</Link>
                                </Btn>
                            )}
                        </div>
                    </div>
                    <Btn method={`edit`} color={`button`} onClick={editDocsHandler}>Редактировать документы</Btn>
                </React.Fragment>}
            </React.Fragment>}
            {editDocs && <FormProvider {...methods} >
                <form onSubmit={methods.handleSubmit(putDocumentsHandler)}>
                    <div className={`flex flex-wrap gap-2`}>
                        {editDocs && editDocs.map((doc) => {
                            return (<div key={doc.id}>
                                <RegistrationInput
                                    name={`names-${doc.id}`}
                                    label={`Наименование документа`}
                                    type={'text'}
                                    defaultValue={doc.name}
                                />
                                <RegistrationInput
                                    name={`files-${doc.id}`}
                                    label={`Загрузите документ`}
                                    type={`file`}
                                />
                                <Btn
                                    method={`remove`}
                                    center
                                    onClick={() => removeDocHandler(doc.id)}
                                >
                                    Убрать документ
                                </Btn>
                            </div>);
                        })}
                    </div>
                    <ButtonGroup>
                        <Button variant="contained" color="error" onClick={() => setEditDocs(null)}>Отменить
                            изменения</Button>
                        <Button onClick={addDoc}>Добавить документ</Button>
                        <Button variant="contained" color="success" type="submit">Принять все изменения по
                            документам</Button>
                    </ButtonGroup>
                </form>
            </FormProvider>}
            {lots && <TenderResults
                lots={lots}
                tender={tender}
                userSettings={userSettings}
                tenderConfirm={tenderConfirm}
            />}
        </UserSection>
    );
}