import React, {useContext, useEffect, useState} from "react";
import UserSection from "../../UI/Sections/UserSection";
import {useNavigate} from "react-router-dom";
import {getEstimateList} from "../../SendRequests/workJournalData";
import UserContext from "../../../store/user/user-context";
import {Box, Button} from "@mui/material";
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import tables from "../../UI/Tables/table.module.css";
import TheadSort from "../../UI/Tables/TheadSort";
import {Price} from "../../UI/Convertors/Price";
import * as XLSX from "xlsx";

export default function JobLog() {

    const navigate = useNavigate();
    if (window.localStorage.getItem('role') !== '4') navigate('/work/');
    const userSettings = useContext(UserContext);
    const company_id = window.localStorage.getItem('company');
    const project_id = window.localStorage.getItem('project');

    const [workJournals, setWorkJournals] = useState(null);
    const [message, setMessage] = useState(null);

    useEffect(() => {

        const getData = async () => {
            const getEstimates = await getEstimateList(userSettings, company_id, project_id);

            if (getEstimates && !getEstimates?.success) setMessage(getEstimates.message);
            else {

                const estimateWorkJournalList = getEstimates?.data
                    .flat()
                    .filter(j => j.work_log?.length > 0)
                    .flatMap(e => e.work_log.map(w => ({
                        ...w,
                        value: +w.value,
                        estimate: e,
                        name_works: e.name_works,
                        number: e.number,
                        ssr: e.ssr,
                        units: e.units,
                        subcompany: e.subcompany.info.name,
                    })))
                    .sort((a, b) => new Date(a.date) - new Date(b.date))
                ;
                setWorkJournals(estimateWorkJournalList);
            }
        };

        getData();
    }, [company_id, project_id, userSettings]);

    const columns = [
        {
            tag: "subcompany",
            name: "Подрядчик",
        },
        {
            tag: "date",
            name: "Дата",
        },
        {
            tag: "number",
            name: "№",
        },
        {
            tag: "ssr",
            name: "ССР",
        },
        {
            tag: "name_works",
            name: "Наименование работ",
        },
        {
            tag: "units",
            name: "Ед. изм.",
        },
        {
            tag: "value",
            name: "Объем",
        },
        {
            tag: "comment",
            name: "Комментарий",
        },
    ];
    const exportXlsx = () => {

        const headers = columns.map(c => c.name);

        const workLog = [headers];

        workJournals.forEach(w => {

            const row = columns.map(c => w[c.tag]);

            workLog.push(row);
        })

        const sheets = [
            {
                name: 'Журнал работ',
                data: workLog,
            },
        ];
        const wb = XLSX.utils.book_new();
        sheets.forEach(sheet => {
            const ws = XLSX.utils.aoa_to_sheet(sheet.data);
            XLSX.utils.book_append_sheet(wb, ws, sheet.name);
        });

        const filename = 'Журнал работ.xlsx';
        XLSX.writeFile(wb, filename);
    }
    return (
        <UserSection>
            <h1>Журнал работ</h1>
            {message && Array.isArray(message) && <Box>{message?.map(m => m)}</Box>}
            {workJournals &&
                <Box className={`h-full relative flex flex-col gap-2`}>
                    <Box className={`w-full`}>
                        <Button
                            variant="contained"
                            startIcon={<SimCardDownloadOutlinedIcon/>}
                            onClick={exportXlsx}
                        >
                            Скачать в XLSX
                        </Button>
                    </Box>
                    <Box className={`${tables.container} grow`}>
                        <table className={tables.table}>
                            <TheadSort
                                array={workJournals}
                                names={columns}
                                setArray={setWorkJournals}
                            />
                            <tbody>
                            {workJournals.map((w, num) => {

                                return (
                                    <tr key={num}>
                                        {columns.map((c, col) => {

                                            const date = c.tag === 'date' && new Date(w[c.tag]).toLocaleDateString();
                                            const value = c.tag === 'value' && Price(w[c.tag]);

                                            return (
                                                <td
                                                    key={col}
                                                    className={`
                                                        ${c.tag === 'subcompany' ? "whitespace-nowrap text-left" : ""}
                                                        ${c.tag === 'name_works' ? "min-w-[250px] text-left" : ""}
                                                        ${(c.tag !== 'subcompany' && c.tag !== 'name_works' && c.tag !== 'comment') ? "text-center" : ""}
                                                    `}
                                                >
                                                    {
                                                        date ? date :
                                                            value ? value :
                                                                w[c.tag]
                                                    }
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </Box>
                </Box>
            }
        </UserSection>
    )
}