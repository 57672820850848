import styles from './Select.module.css';

const Select = ({
                    name,
                    handleOnChange,
                    options,
                    shownList,
                    type,
                    placeholder = 'Выберите ответственного',
                    emptyMessage = 'Список пуст',
                }) => {
    return (
        <div className={styles.container}>
            <div className={styles.collapsed}>
                <select
                    name={name}
                    onChange={handleOnChange}
                    className={`${styles.dropdown}
                      ${shownList === type
                        ? styles['dropdown-show']
                        : ''}
                    `}
                >
                    <option style={{display: 'none'}} value={''}>{placeholder}</option>
                    {options.length ?
                        options.map((item) => (
                            <option
                                key={item.id}
                                data-id={item.id}
                                value={item.id}
                            >
                                {item.name || item.full_name}
                            </option>
                        ))
                        :
                        <option
                            value={''}
                            disabled
                        >
                            {emptyMessage}
                        </option>
                    }
                </select>
            </div>
        </div>
    )
        ;
}

export default Select;