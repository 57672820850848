import React, {useContext, useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import UserContext from "../../../store/user/user-context";
import {getCompany} from "../../UI/GetInfo/getCompany";
import axios from "axios";
import UserSection from "../../UI/Sections/UserSection";
import Warnings from "../../UI/Sections/Warnings";
import Btn from "../../UI/Buttons/Btn";
import inputsStyles from "../../UI/Inputs/inputs.module.css";
import {PrintPhone} from "../../UI/Convertors/PrintPhone";
import {sendSequentialRequests} from "../../SendRequests/sendSequentialRequests";
import {ErrorOccurred} from "../../UI/Sections/ErrorOccurred";
import {Alert, Box, Button, Typography} from "@mui/material";
import {FormProvider, useForm} from "react-hook-form";

export default function InfoAccept() {

    const navigate = useNavigate();
    if (window.localStorage.getItem('role') !== '2') navigate('/work/');

    const methods = useForm();
    const {handleSubmit} = methods;

    const userSettings = useContext(UserContext);
    const company_id = localStorage.getItem("company");
    const project_id = localStorage.getItem("project");
    const subcompanyData = new URLSearchParams(useLocation().search);
    const subcompany_id = +subcompanyData.get('subcompany');
    const tender_id = +subcompanyData.get('tender');

    const [answers, setAnswers] = useState(null);
    const [subcompanyInfo, setSubcompanyInfo] = useState(null);
    const [subCompanyState, setSubCompanyState] = useState(null);
    const [isApproved, setIsApproved] = useState({});

    const [contractDocuments, setContractDocuments] = useState(null);
    const [contractActs, setContractActs] = useState(null);
    const [reasons, setReasons] = useState({});

    const infoKeys = [
        {
            name: "name",
            label: "Наименование компании",
            disabled: true,
        },
        {
            name: "legal_address",
            label: "Юридический адрес",
            disabled: subcompanyInfo?.type === 2,
        },
        {
            name: "postal_address",
            label: "Почтовый адрес",
        },
        {
            name: "kpp",
            label: "КПП",
            disabled: true,
        },
        {
            name: "inn",
            label: "ИНН",
            disabled: true,
        },
        {
            name: "ogrn",
            label: "ОГРН",
            disabled: true,
        },
        {
            name: "okpo",
            label: "ОКПО",
            disabled: true,
        },
        {
            name: "bank",
            label: "Наименование банка",
            disabled: true,
        },
        {
            name: "settlement_account",
            label: "Р/С",
        },
        {
            name: "correspondent_account",
            label: "К/С",
            disabled: true,
        },
        {
            name: "bik",
            label: "БИК",
            // disabled: true,
        },
        {
            name: "director",
            label: "Ген директор (Ф.И.О.)",
        },
        {
            name: "basis",
            label: "Основание полномочий",
        },
        {
            name: "email",
            label: "Адрес официальной эл. почты",
            disabled: true,
        },
        {
            name: "phone",
            label: "Контактный телефон",
        },
        {
            name: "type",
            label: "Право собственности",
            disabled: true,
        },
    ];
    const docsKeys = [
        {
            name: "number",
            label: "Номер документа",
        },
        {
            name: "date",
            label: "Дата документа",
        },
        {
            name: "organization",
            label: "Наименование организации",
        },
    ];
    const ownershipNames = {
        1: "Частное лицо",
        2: "Юридическое лицо",
        3: "Индивидуальный предприниматель",
    };

    const reasonHandler = (name, comment, value) => {
        const currentReason = {...reasons};
        value = value.trim();

        if (value.length) {
            if (!currentReason[comment]) currentReason[comment] = {};
            currentReason[comment][name] = value;
        } else {
            if (currentReason[comment]) delete currentReason[comment][name];
        }

        setReasons(currentReason);
    }

    const acceptSubcompanyInfo = async () => {

        try {

            const acceptInfo = await axios.post(
                `${userSettings.api}/subcompanies/${subcompany_id}/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/accept-info`,
                '',
                {headers: userSettings.headers.post}
            );
            setAnswers(<Warnings correct>{acceptInfo.data.detail}</Warnings>)
            window.location.reload();
        } catch (error) {

            const responseError = ErrorOccurred(
                error.response,
                "Ошибка согласования документов при регистрации",
                "acceptSubcompanyDocs"
            );
            setAnswers(responseError.jsx);
        }
    }
    const rejectSubcompanyInfo = async () => {
        try {
            const requests = Object.keys(reasons['info']).map((key) => {

                const formData = new FormData();
                formData.append("reason", reasons['info'][key]);
                formData.append("name", key);

                return ({
                    method: 'POST',
                    url: `${userSettings.api}/subcompanies/${subcompany_id}/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/company-verification/`,
                    data: formData,
                    headers: userSettings.headers.postForm
                });
            });

            const results = await sendSequentialRequests(requests);

            if (results.length === Object.keys(reasons['info']).length) window.location.reload();
        } catch (error) {
        }
    }

    const acceptSubcompanyDocs = async () => {

        try {

            const signDocs = await axios.post(
                `${userSettings.api}/subcompanies/${subcompany_id}/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/sign-docs`,
                '',
                {headers: userSettings.headers.post}
            );

            setIsApproved(prev => ({...prev, 2: true}))
            // setAnswers(<Warnings correct>{acceptInfo.data.detail}</Warnings>)
            // window.location.reload();
        } catch (error) {

            const responseError = ErrorOccurred(
                error.response,
                "Ошибка согласования документов при регистрации",
                "acceptSubcompanyDocs"
            );
            setAnswers(responseError.jsx);
        }
    }
    const rejectSubcompanyDocById = async (doc_id) => {

        try {
            const comment = reasons['docs'][doc_id];

            const formData = new FormData();
            formData.append("reason", comment);
            formData.append("is_declined", true);

            const declinedDocument = await axios.post(
                `${userSettings.api}/subcompanies/${subcompany_id}/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/declined-documents/${doc_id}`,
                formData,
                {headers: userSettings.headers.postForm}
            )

            const response = declinedDocument.data
            setContractDocuments(prev => prev.map(document => {
                if (document.id === doc_id) return ({
                    ...document,
                    is_declined: response.is_declined,
                    reason: response.reason
                })
                else return document;
            }))
        } catch (error) {
            console.error(error)
        }
    }
    const acceptSubcompanyActs = async () => {

        try {

            const signDocs = await axios.post(
                `${userSettings.api}/subcompanies/${subcompany_id}/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/sign-acts`,
                '',
                {headers: userSettings.headers.post}
            );

            setIsApproved(prev => ({...prev, 3: true}))
            // setAnswers(<Warnings correct>{acceptInfo.data.detail}</Warnings>)
            // window.location.reload();
        } catch (error) {

            const responseError = ErrorOccurred(
                error.response,
                "Ошибка согласования актов при регистрации",
                "acceptSubcompanyActs"
            );
            setAnswers(responseError.jsx);
        }
    }
    const rejectSubcompanyActById = async (act_id) => {

        try {
            const comment = reasons['acts'][act_id];

            const formData = new FormData();
            formData.append("reason", comment);
            formData.append("is_declined", true);

            const declinedAct = await axios.post(
                `${userSettings.api}/subcompanies/${subcompany_id}/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/declined-acts/${act_id}`,
                formData,
                {headers: userSettings.headers.postForm}
            )

            const response = declinedAct.data
            setContractActs(prev => prev.map(act => {
                if (act.id === act_id) return ({
                    ...act,
                    is_declined: response.is_declined,
                    reason: response.reason
                })
                else return act;
            }))
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {

        const getSubcompanyInfo = async () => {

            try {
                getCompany([subcompany_id], userSettings)
                    .then(results => {
                        setSubcompanyInfo(results[0]);
                    })
                    .catch(error => {
                        console.error("Произошла ошибка при получении компаний:", error)
                    });

                const subcompanyState = await axios(
                    `${userSettings.api}/company/${company_id}/subcompanies`,
                    userSettings.headers.get
                )
                const currentSubcompanyState = subcompanyState.data.data.find(s => s.tender === tender_id);
                setSubCompanyState(currentSubcompanyState);
                setIsApproved({
                    1: currentSubcompanyState.contract,
                    2: currentSubcompanyState.is_documents_concerted,
                    3: currentSubcompanyState.is_documents_concerted
                })

                const registerDocs = await axios.get(
                    `${userSettings.api}/subcompanies/${subcompany_id}/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/register-docs/`,
                    userSettings.headers.get
                );
                setContractDocuments(registerDocs.data);

                const registerActs = await axios.get(
                    `${userSettings.api}/subcompanies/${subcompany_id}/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/register-acts/`,
                    userSettings.headers.get
                );
                setContractActs(registerActs.data);

                setAnswers(null);

            } catch (error) {
                setAnswers(<Warnings>
                    Ошибка получения данных - {error.response.data.errors ?
                    error.response.data.errors.map((e, id) => <p key={id}>{e.detail}</p>)
                    :
                    error.response.status
                }
                </Warnings>)
            }
        }

        getSubcompanyInfo();

    }, [subcompany_id, userSettings, company_id, project_id, tender_id]);

    const agreementTypes = [
        {
            id: 1,
            type: 'is_info_accepted',
            label: 'Принять информацию по подрядчику',
            complete_message: "Информация о компании подрядчика принята",
            keys: infoKeys,
            acceptHandler: acceptSubcompanyInfo,
            rejectHandler: rejectSubcompanyInfo,
            reason: null,
            comment: null,
            data: subcompanyInfo,
        },
        {
            id: 2,
            type: 'is_documents_accepted',
            label: 'Принять документы подрядчика',
            complete_message: "Документы подрядчика приняты",
            keys: docsKeys,
            acceptHandler: acceptSubcompanyDocs,
            reason: "Подрядчик не загрузил документы",
            comment: "docs",
            data: contractDocuments,
        },
        {
            id: 3,
            type: 'is_acts_accepted',
            label: 'Акты подрядчика при регистрации',
            complete_message: "Акты подрядчика приняты",
            keys: docsKeys,
            acceptHandler: acceptSubcompanyActs,
            rejectHandler: null,
            reason: "Подрядчик не загрузил акты",
            comment: null,
            data: contractActs,
        },
    ];

    const renderInfo = (property, data, ownershipNames, type) => {
        const companyProperty = (type.id === 1 && property.name === 'type') && ownershipNames[data[property.name]];
        const propertyResult = property.name === "phone" ? PrintPhone(data[property.name]) : (data[property.name]);

        return (
            <div key={property.name} className={`border border-pult/20 rounded-md px-1 py-0.5`}>
                <div className={`text-center`}>{property.label}</div>
                {data[property.name] &&
                    <div className={`px-1 py-0.5 border border-pult/30 rounded-md bg-pult-hover/10 my-1`}>
                        {companyProperty ?
                            companyProperty :
                            propertyResult
                        }
                    </div>}
            </div>
        );
    };
    const renderDocs = (data, type) => {
        return (<div className={`px-1 py-0.5 border border-pult/20 rounded-md`} key={data.id}>
            <Btn method={`download`} color={`button`} center>
                <Link to={`${userSettings.fileServer}${data.file}`} target={`_blank`}>{data.name}</Link>
            </Btn>
            {type.id === 3 &&
                <div>
                    {(data.is_declined) ?
                        <div className={`max-w-[270px] mt-2`}>
                            <Alert severity="warning">
                                <div className={`text-sm`}>Комментарий к отклонению: {data.reason}</div>
                            </Alert>
                        </div> :
                        <div className="flex flex-col">
                        <textarea
                            name={data.id}
                            onChange={(e) => reasonHandler(data.id, "acts", e.target.value)}
                            className={`${inputsStyles.input} mt-1`}
                            placeholder={`Укажите причину отклонения`}
                        />
                            {reasons.acts && reasons.acts[data.id] &&
                                <Button onClick={() => rejectSubcompanyActById(data.id)} variant="contained"
                                        color="error">Отклонить
                                    акт</Button>}
                        </div>
                    }
                </div>
            }
            <div className={type.id !== 3 ? `my-3` : ''}>
                {docsKeys.map(property => {

                    const propertyResult = property.name === 'date' ? new Date(data[property.name]).toLocaleDateString() : data[property.name];

                    if (data[property.name]) return (
                        <div key={property.name}>
                            <div className={`text-mm text-center`}>{property.label}:</div>
                            <div
                                className={`px-1 py-0.5 border border-pult/30 rounded-md bg-pult-hover/10`}>{propertyResult}</div>
                        </div>
                    );
                    else return null;
                })}
            </div>
        </div>);
    };
    const renderFields = (name, render_elements, comment, label, is_declined, reason) => {

        return (
            <div key={name}>
                {render_elements}
                {(comment) && <div>
                    {(is_declined) ?
                        <div className={`max-w-[270px] mt-2`}>
                            <Alert severity="warning">
                                <div className={`text-sm`}>Комментарий к отклонению: {reason}</div>
                            </Alert>
                        </div> :
                        <div className="flex flex-col">
                        <textarea
                            name={name}
                            onChange={(e) => reasonHandler(name, comment, e.target.value)}
                            className={`${inputsStyles.input} mt-1`}
                            placeholder={`Укажите причину отклонения ${label}`}
                        />
                            {reasons.docs && reasons.docs[name] &&
                                <Button onClick={() => rejectSubcompanyDocById(name)} variant="contained" color="error">Отклонить
                                    документ</Button>}
                        </div>
                    }
                </div>}
            </div>
        );
    }

    const addAgreementDocument = async (data) => {
        try {
            const addAgreementDoc = await axios.post(`${userSettings.api}/subcompanies/${subCompanyState.subcompany}/companies/${subCompanyState.main}/projects/${project_id}/tenders/${subCompanyState.tender}/concert-agreement/`, {}, {headers: userSettings.headers.postForm});

            navigate('/work/')
        } catch (e) {
        }
    };

    return (
        <UserSection>
            <FormProvider {...methods}>
                <form>
                    <h1>Принять информацию по подрядчику <b className={`text-pult`}>{subcompanyInfo?.name}</b></h1>
                    <Box>
                        Договор № <span className={`underline`}>{subCompanyState?.id}</span>
                    </Box>
                    {answers && answers}
                    {agreementTypes.map(type => {

                        const checkComplete = subCompanyState && subCompanyState[type.type];
                        const updateType = type.id !== 1 && type.type.replace('accepted', 'uploaded');
                        const checkInput = (!checkComplete && subCompanyState) && subCompanyState[updateType];

                        const isInfoAcceptedType = type.id === 1;
                        const isTypeIncludesDeclined = type.id !== 1 ? type?.data?.filter(entity => entity.is_declined).length > 0 : false;

                        return (
                            <Box key={type.id}>
                                {!isInfoAcceptedType && <h2 className={`text-xl`}>{type.label}</h2>}
                                {(checkComplete || (checkInput && !type.data)) ?
                                    <Warnings correct>{type.complete_message}</Warnings> :
                                    (type.id !== 1 && !checkInput) ?  // (!checkInput || !type.data)
                                        <Warnings>{type.reason}</Warnings> :
                                        <Box className="flex flex-col gap-4">
                                            {/* не изменяемые */}
                                            <Box className={`flex gap-3 flex-wrap`}>
                                                {(type.id === 1 && type.data) &&
                                                    type.keys.filter(property => property.disabled).map(propName => {

                                                        return (renderInfo(propName, type.data, ownershipNames, type));
                                                    })
                                                }
                                            </Box>
                                            {/* управление */}
                                            <Box className={`flex flex-wrap gap-3`}>
                                            {!isInfoAcceptedType && (
                                              <>
                                                {isTypeIncludesDeclined &&
                                                    <Alert severity="warning">Чтобы принять всё, не должно быть ничего
                                                        отклонённого</Alert>
                                                }
                                                {isApproved[type.id] ?
                                                    <Alert severity="success">Согласовано</Alert>
                                                    :
                                                    <Button
                                                      disabled={isTypeIncludesDeclined}
                                                      color="success"
                                                      variant="contained" method={`apply`}
                                                      onClick={type.acceptHandler}
                                                    >
                                                      Принять все</Button>
                                                }
                                              </>
                                            )}
                                            </Box>
                                            {/* изменяемые */}
                                            <Box className={`flex gap-3 flex-wrap`}>
                                                {type.data &&
                                                (type.id === 1) ?
                                                    type.keys
                                                        .filter(key => !key.disabled)
                                                        .map(key =>
                                                            renderFields(
                                                                key.name,
                                                                renderInfo(key, type.data, ownershipNames, type),
                                                                type.comment,
                                                                key.label
                                                            )
                                                        ) :
                                                    (type.id === 2 || type.id === 3) &&
                                                    type.data.map(doc => {

                                                        return (
                                                            renderFields(
                                                                doc.id,
                                                                renderDocs(doc, type),
                                                                type.comment,
                                                                doc?.reason,
                                                                doc?.is_declined,
                                                                doc?.reason,
                                                            )
                                                        )
                                                    })
                                                }
                                            </Box>
                                        </Box>
                                }
                            </Box>
                        );
                    })}

                    {Object.values(isApproved).reduce((acc, cur) => acc && cur, true) && subCompanyState?.agreement_document &&
                    <Box className="mt-10">
                        <Typography>Принять договор</Typography>
                        <Button onClick={handleSubmit(addAgreementDocument)} color="success"
                                variant={'contained'}>Принять</Button>
                    </Box>
                    }
                </form>
            </FormProvider>
        </UserSection>
    );
}
