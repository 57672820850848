import {Alert, Box, Button} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import React from "react";

export default function PaymentCompany() {
    const data = new URLSearchParams(useLocation().search);
    const company_id = +data.get('company');
    const amount = +data.get('amount');
    const status = data.get('status');

    return (
        <Box className="flex items-center h-full">
            <Box className="w-max mx-auto">
                {status === "success" ?
                    <Box className={`flex gap-2 items-center flex-col`}>
                        <Alert severity="success">Оплата {amount}р. прошла</Alert>
                        <Button color={"success"} component={Link} to={'/work/'} variant="outlined">На главную</Button>
                    </Box> :
                    status === "error" ?
                        <Box className={`flex gap-2 items-center flex-col`}>
                            <Alert severity="error">Оплата не прошла</Alert>
                            <Button component={Link} to={`/work/payment-card/?company=${company_id}&amount=${amount}`} variant="outlined">Попробовать еще раз</Button>
                        </Box> :
                        status === "rejected" &&
                        <Box className={`flex gap-2 items-center flex-col`}>
                            <Alert severity="warning">Отказ от оплаты</Alert>
                            <Button component={Link} to={`/work/payment-card/?company=${company_id}&amount=${amount}`} variant="outlined">Попробовать еще раз</Button>
                            <Button color={"success"} component={Link} to={'/work/'} variant="outlined">На главную</Button>
                        </Box>
                }
            </Box>
        </Box>
    );
}
