import {useContext, useEffect, useState} from "react";
import {getTender} from "../../../../UI/GetInfo/getSubcompanies";
import {getCompanyProjectPersons, getObjectName} from "../../../../UI/GetInfo/getCompany";
import UserContext from "../../../../../store/user/user-context";
import {getQualityDocs} from "../../../../UI/GetInfo/getTendersInfo";
import {validateWorkDatesAndNames} from "../../Akt-osvidetelstvovaniya/helpers/validateWorkDatesAndNames";
import {getWorkLogValueAndCost} from "../helpers/getWorkLogValueAndCost";
import {getFirstDateWithCurrentYear} from "../helpers/getFirstDateWithCurrentYear";
import {getEstimateElements} from "../../../../UI/GetInfo/getEstimations";
import {getTotalCosts} from "../helpers/getTotalCosts";

const useFetchedInfo = (subcompany_id, tender_id) => {
    const userSettings = useContext(UserContext);
    const [fetchedInfo, setFetchedInfo] = useState(null);
    const [errorInfo, setErrorInfo] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function getInfo() {
            const allTenders = await getTender(userSettings, subcompany_id);
            const currentTender = allTenders.find(tender => tender.tender.id === tender_id);

            const signatories = {
                company: currentTender?.contract?.company_signatory?.signatory,
                subcompany: currentTender?.contract?.subcompany_signatory?.signatory,
            }

            const company = currentTender.tender.project.company;

            const project_id = currentTender.tender.project.id;
            const object_name = await getObjectName(userSettings, company.id, project_id);

            const subcompany = userSettings.companies.find(s => s.id === subcompany_id);

            const personsList = await getCompanyProjectPersons(userSettings, currentTender.contract.main, currentTender.contract.project_id);

            const estimateElements = await getEstimateElements(userSettings, currentTender.contract.main, currentTender.contract.project_id, tender_id, currentTender.contract.subcompany);
            const qualityDocs = await getQualityDocs(userSettings, currentTender.contract.main, currentTender.contract.project_id, tender_id, currentTender.contract.subcompany)
            const filteredQualityDocs = qualityDocs.filter(doc => !doc.is_approved);

            const {
                workDates,
                workNames,
                works,
                allDates
            } = validateWorkDatesAndNames(filteredQualityDocs, estimateElements, true)

            const logsForTable = works.map(work => {
                const {startTotalCost} = getTotalCosts(work);
                const startYearTotalCost = getFirstDateWithCurrentYear(work)
                return ({
                        id: work.id,
                        name_works: work.name_works,
                        start: startTotalCost,
                        yearStart: startYearTotalCost,
                        totalCost: getWorkLogValueAndCost(work)
                    }
                )
            })

            const summary = {
                start: (logsForTable.reduce((acc, cur) => acc + Number(cur.start), 0)),
                yearStart: (logsForTable.reduce((acc, cur) => acc + Number(cur.yearStart), 0)),
                period: (logsForTable.reduce((acc, cur) => acc + Number(cur.totalCost), 0))
            }

            return {
                allTenders,
                currentTender,
                company,
                project_id,
                object_name,
                subcompany,
                personsList,
                workDates,
                workNames,
                logsForTable,
                summary,
                signatories
            }
        }

        getInfo()
            .then(setFetchedInfo)
            .catch(setErrorInfo)
            .finally(() => setIsLoading(false));
    }, [userSettings, subcompany_id, tender_id]);
    return {fetchedInfo, errorInfo, isLoading};
};

export default useFetchedInfo;