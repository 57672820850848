import React, {useContext, useEffect, useState} from "react";
import UserSection from "../../UI/Sections/UserSection";
import {Link, useNavigate} from "react-router-dom";
import UserContext from "../../../store/user/user-context";
import axios from "axios";
import Btn from "../../UI/Buttons/Btn";
import Popup from "../../UI/Popup/Popup";
import styles from "../dash.module.css";
import Warnings from "../../UI/Sections/Warnings";
import {ErrorOccurred} from "../../UI/Sections/ErrorOccurred";
import {Box, Button, Tooltip} from "@mui/material";

const PlanConfigurator = () => {

    const navigate = useNavigate();
    if (window.localStorage.getItem('role') !== '2') navigate('/work/');

    const userSettings = useContext(UserContext);
    const company_id = window.localStorage.getItem('company');
    const project_id = window.localStorage.getItem('project');
    const [popupShow, setPopupShow] = useState(null);
    const [answers, setAnswers] = useState(null);

    const [buildingList, setBuildingList] = useState(null);
    const building_id = +window.location.pathname.split("/").pop() || null;
    const [sectionsList, setSectionsList] = useState(null);
    const building = buildingList && buildingList.filter(b => b.id === building_id)[0];
    const addSectionHandler = () => {

        const addSection = (data) => {

            axios.post(
                `${userSettings.api}/plans-configurator/companies/${company_id}/projects/${project_id}/building/${building_id}/sections/`,
                {name: data.other_name ? data.other_name : data.name},
                {headers: userSettings.headers.postForm}
            )
                .then(response => {
                    setSectionsList(prevSections => [...prevSections, {
                        id: response.data.data.id,
                        name: response.data.data.name,
                        ranges: []
                    }]);
                    setPopupShow(false);
                    setAnswers(<Warnings correct>Секция добавлена</Warnings>);
                })
                .catch(error => {
                    console.error(`\n error`, (error.code === 'ERR_BAD_REQUEST') ? 'ERR_BAD_REQUEST' : error.data);
                    if (error.response.data) setAnswers(<Warnings reject>
                        <div>Ошибка добавления секции:</div>
                        <ul>
                            {error.response.data.errors ?
                                error.response.data.errors.map((e, id) => <li key={id}>{e.detail} {e.attr}</li>)
                                :
                                error.response.status
                            }
                        </ul>
                    </Warnings>);
                });
        }

        const sectionsName = ["СПОЗУ", "АР", "КР", "ЭС", "ЭМ", "НВК", "ВК", "ТС", "ОВ", "СС", "ГС", "ТХ", "ПОС", "ПОД", "ООС", "ПБ", "ОДИ", "ЭЭ", "Иная"];
        const existingNames = sectionsList && new Set(sectionsList.map(section => section.name));
        const sectionsNamesList = existingNames ? sectionsName.filter(name => !existingNames.has(name)) : sectionsName;

        setPopupShow(<Popup
            title={`Добавить раздел к корпусу`}
            addSection={sectionsNamesList}
            onSubmit={addSection}
            onCloseModal={() => setPopupShow(false)}
        />);
    }
    const deleteSectionHandler = (id) => {

        axios.delete(
            `${userSettings.api}/plans-configurator/companies/${company_id}/projects/${project_id}/building/${building_id}/sections/${id}`,
            {headers: {Authorization: userSettings.token}}
        )
            .then(response => {
                const updatedList = sectionsList.filter(section => section.id !== id)
                setSectionsList(updatedList)
                setSectionsRangeLists(prev => {
                    const updatedRanges = {...prev};
                    delete updatedRanges[id];

                    return updatedRanges;
                })
                // window.location.reload();
                setAnswers(<Warnings correct>Секция удалена</Warnings>);
            })
            .catch(error => {
                if (error.response.data) setAnswers(<Warnings reject>
                    <div>Ошибка удаления секции:</div>
                    <ul>
                        {error.response.data.errors ?
                            error.response.data.errors.map((e, id) => <li key={id}>{e.detail} {e.attr}</li>)
                            :
                            error.response.data.message ?
                                error.response.data.message
                                :
                                error.response.status
                        }
                    </ul>
                </Warnings>);

            });
    }
    const [sectionsRangeLists, setSectionsRangeLists] = useState();
    const addRangePost = (id, formData) => {
        axios.post(
            `${userSettings.api}/plans-configurator/companies/${company_id}/projects/${project_id}/building/${building_id}/sections/${id}/range/`,
            formData,
            {headers: userSettings.headers.postForm}
        )
            .then((response) => {
                setSectionsRangeLists((prevRanges) => {
                    return {
                        ...prevRanges,
                        [id]: [...(prevRanges[id] || []), response.data.data],
                    }
                });

                // const updatedList = sectionsList.map(section => {
                //     if (section.id === Number(id)) {
                //         return ({
                //             ...section,
                //             ranges: [...section.ranges, response.data.data]
                //         })
                //     } else return section;
                // })

                setSectionsList(prev => prev.map(section => {
                    if (section.id === Number(id)) {
                        return ({
                            ...section,
                            ranges: [...section.ranges, response.data.data]
                        })
                    } else return section;
                }))
                setPopupShow(false);
                setAnswers(<Warnings correct>Диапазон добавлен</Warnings>);
            })
            .catch(error => {
                const responseError = ErrorOccurred(
                    error.response,
                    "Ошибка записи в раздел",
                    "addRangePost"
                );
                setAnswers(responseError.jsx);
            });
    }
    const addRangeHandler = (id) => {

        const addRange = (e) => {

            e.preventDefault();

            const formData = new FormData(e.target);
            addRangePost(id, formData);
        }

        const section = sectionsList.filter(s => s.id === id)[0];

        setPopupShow(<Popup
            title={`Добавить диапазон в раздел ${section.name} корпуса ${building.name}`}
            addRange={{
                min: -building.underground_floors,
                max: building.floors,
                exist: section.ranges.map(r => ([r.min, r.max]))
            }}
            onSubmit={addRange}
            onCloseModal={() => setPopupShow(false)}
        />);
    }
    const addRangePdfHandler = (id) => {

        const addRangePdf = async (data) => {
            try {
                setPopupShow(false);
                data.forEach(d => {
                    const formData = new FormData();
                    formData.append('min', d.min);
                    formData.append('max', d.max);

                    const base64Image = d.plan.substring(d.plan.indexOf(',') + 1);
                    const byteCharacters = atob(base64Image);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], {type: 'image/jpeg'});
                    const tempFile = new File([blob], 'temp.jpg', {type: 'image/jpeg'});
                    formData.append('plan', tempFile);
                    addRangePost(id, formData);
                });

            } catch (error) {

                const responseError = ErrorOccurred(
                    error.response,
                    "Ошибка создания диапазонов из pdf",
                    "addRangePdfHandler"
                );
                setAnswers(responseError.jsx);
            }
        };

        const section = sectionsList.filter(s => s.id === id)[0];

        setPopupShow(
            <Popup
                title={`Добавить диапазоны из pdf в раздел ${section.name} корпуса ${building.name}`}
                addPdfRange={{
                    min: -building.underground_floors,
                    max: building.floors,
                    exist: section.ranges.map(r => ([r.min, r.max])),
                    building_id: building_id,
                    company_id: company_id,
                    project_id: project_id,
                    section_id: id,
                    token: userSettings.token,
                    api: userSettings.api,
                    fileServer: userSettings.fileServer,
                }}
                onSubmit={addRangePdf}
                onCloseModal={() => setPopupShow(false)}
            />
        );
    };
    const editRangeHandler = (section_id, range_id) => {

        const editRange = (e) => {

            e.preventDefault();

            const range = sectionsRangeLists[section_id].filter(r => r.id === range_id)[0];

            const formData = new FormData();
            formData.append('plan', e.target.plan.files[0]);
            formData.append('min', range.min);
            formData.append('max', range.max);

            setPopupShow(false);

            axios.put(
                `${userSettings.api}/plans-configurator/companies/${company_id}/projects/${project_id}/building/${building_id}/sections/${section_id}/range/${range_id}/`,
                formData,
                {headers: userSettings.headers.postForm}
            )
                .then((response) => {
                    setSectionsList(prevSections => prevSections.map(section => {
                        if (section.id === section_id) {
                            return {
                                ...section,
                                ranges: section.ranges.map(range => {
                                    if (range.id === range_id) {
                                        return {
                                            ...range,
                                            min: response.data.data.min,
                                            max: response.data.data.max,
                                            plan: response.data.data.plan,
                                            floors: response.data.data.floors,
                                        };
                                    }
                                    return range;
                                }),
                            };
                        }
                        return section;
                    }));
                    setAnswers(<Warnings correct>Диапазон изменен</Warnings>);
                })
                .catch(error => {
                    console.error('\n error', error);
                    if (error.response.data) setAnswers(<Warnings reject>
                        <div>Ошибка изменения диапазона:</div>
                        <ul>
                            {error.response.data.errors ?
                                error.response.data.errors.map((e, id) => <li key={id}>{e.detail} {e.attr}</li>)
                                :
                                error.response.status
                            }
                        </ul>
                    </Warnings>);
                });
        }

        setPopupShow(<Popup
            title={`Изменить изображение в диапазоне в разделе ${sectionsList.filter(s => s.id === section_id)[0].name} корпуса ${building.name}`}
            editRangeImage={true}
            onSubmit={editRange}
            onCloseModal={() => setPopupShow(false)}
        />);
    }
    const cloneSchemeHandler = (section_id) => {

        const section = sectionsList.filter(s => s.id === section_id)[0];
        const sectionsListEmptyRanges = sectionsList.filter(s => s.ranges).filter(s => s.ranges.length === 0);

        const cloneScheme = (data) => {

            Object.keys(data).forEach(d => {
                if (!data[d]) return;

                section.ranges.forEach(r => {

                    const formData = new FormData();
                    formData.append('min', r.min);
                    formData.append('max', r.max);
                    formData.append('plan', '');

                    addRangePost(d, formData);
                });
            })
        };

        if (!sectionsListEmptyRanges.length > 0) return;

        setPopupShow(<Popup
            title={`Выберите раздел корпуса ${building.name} для копирования схемы из раздела ${section.name}`}
            form={{
                type: "checkbox",
                fields: sectionsListEmptyRanges.map(s => {
                    return ({
                        "type": 'checkbox',
                        "name": s.id,
                        "label": s.name,
                        "checked": 'false',
                    })
                }),
                "submit": "Скопировать схему"
            }}
            onSubmit={cloneScheme}
            onCloseModal={() => setPopupShow(false)}
        />);
    }
    const deleteRangeHandler = (section_id, range_id) => {

        const options = {
            headers: {
                Authorization: userSettings.token,
                'accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        };

        axios.delete(
            `${userSettings.api}/plans-configurator/companies/${company_id}/projects/${project_id}/building/${building_id}/sections/${section_id}/range/${range_id}`,
            options
        )
            .then((response) => {
                const updatedList = sectionsList.map(section => {
                    if (section.id === section_id) {
                        return ({
                            ...section, ranges: section.ranges.filter(range => range.id !== range_id)
                        })
                    } else return section;
                })

                setSectionsList(updatedList)

                // window.location.reload();
                setAnswers(<Warnings correct>Диапазон удален</Warnings>);
            })
            .catch(error => {
                console.error('\n error', error.response);
                if (error.response.data) setAnswers(<Warnings reject>
                    <div>Ошибка удаления диапазона:</div>
                    <ul>
                        {error.response.data.errors ?
                            error.response.data.errors.map((e, id) => <li key={id}>{e.detail} {e.attr}</li>)
                            :
                            error.response.data.message ?
                                error.response.data.message
                                :
                                error.response.status
                        }
                    </ul>
                </Warnings>);
            });

    }
    const areRangesCoveringAllValues = (sectionsList, min, max) => {

        if (sectionsList && sectionsList.length > 0) {
            const sectionsMin = Math.min(...sectionsList.map(s => s.min));
            const sectionsMax = Math.max(...sectionsList.map(s => s.max));

            return !(sectionsMin === -min && sectionsMax === max);
        } else return true;
    };

    useEffect(() => {

        axios.get(
            `${userSettings.api}/tep-building/companies/${company_id}/projects/${project_id}/buildings/`,
            {headers: {Authorization: userSettings.token}}
        )
            .then(response => setBuildingList(response.data.data))
            .catch(error => console.error('\n error', error));

    }, [company_id, project_id, userSettings]);
    useEffect(() => {

        if (building_id) {

            axios.get(
                `${userSettings.api}/plans-configurator/companies/${company_id}/projects/${project_id}/building/${building_id}/sections/`,
                userSettings.headers.get
            )
                .then(response => {
                    if (response.data) setSectionsList(response.data);
                })
                .catch(error => console.error('\n error', error));
        }
    }, [building_id, company_id, project_id, userSettings]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const requests = sectionsList.map(async s => {
                    const response = await axios.get(`${userSettings.api}/plans-configurator/companies/${company_id}/projects/${project_id}/building/${building_id}/sections/${s.id}`, {
                        headers: {
                            Authorization: userSettings.token
                        }
                    });
                    return {sectionId: s.id, data: response.data.data};
                });

                const results = await Promise.all(requests);

                const updatedRangeLists = {};
                results.forEach(result => {
                    updatedRangeLists[result.sectionId] = result.data;
                });

                setSectionsRangeLists(prevState => ({
                    ...prevState,
                    ...updatedRangeLists
                }));
            } catch (error) {
                console.error('\n error', error)
            }
        };

        if (building_id && sectionsList) fetchData();
    }, [building_id, company_id, project_id, sectionsList, userSettings]);

    const allFloors = building?.floors + building?.underground_floors
    return (
        <UserSection>
            {popupShow}
            <h1>Конфигуратор планов</h1>
            {answers && answers}
            {buildingList ?
                <React.Fragment>
                    <div>Список корпусов</div>
                    <div className={styles.list}>
                        {buildingList.map(building => (
                            <Btn key={building.id} className={styles.btn} color="button">
                                <Link
                                    to={`/work/coordinator/plan-configurator/building/${building.id}`}>{building.name}</Link>
                            </Btn>
                        ))}
                    </div>
                </React.Fragment>
                :
                <div>
                    <div>В проекте нет корпусов</div>
                    <Button><Link to={'/work/coordinator/object-information'}>Добавить корпус</Link></Button>
                </div>
            }
            {building_id && <React.Fragment>
                <h2 className={`text-xl`}>Информация по корпусу<span
                    className={`text-3xl`}>"{building && building.name}"</span></h2>
                <Btn color="button" method="add" onClick={addSectionHandler}>Добавить раздел</Btn>
                {(sectionsList?.length > 0) && <React.Fragment>
                    <div>Список разделов</div>
                    <div className={styles.list}>
                        {sectionsList.map((s, index) => {
                            const currentFloors = s.ranges.reduce((acc, range) => {
                                return acc + range.floors.length
                            }, 0);
                            const isDisabledAdd = (currentFloors === allFloors)
                            return (
                                <div key={`${s.id}_${index}`} className={styles.card}>
                                    <div className={styles.label}>{s.name}</div>
                                    {(sectionsRangeLists && sectionsRangeLists[s.id] && sectionsRangeLists[s.id].length > 0) &&
                                        <div className={`mx-2 grow`}>
                                            {sectionsRangeLists[s.id].sort((a, b) => a.min - b.min).map(r =>
                                                <React.Fragment
                                                    key={r.id}>
                                                    <div className={`flex`}>
                                                        {r.plan ?
                                                            <Btn className={`grow`}
                                                                 icon={<svg viewBox="0 0 24 24" fill="pult">
                                                                     <path fill="none" d="M0 0h24v24H0z"/>
                                                                     <path
                                                                         d="M5 11.1l2-2 5.5 5.5 3.5-3.5 3 3V5H5v6.1zM4 3h16a1 1 0 011 1v16a1 1 0 01-1 1H4a1 1 0 01-1-1V4a1 1 0 011-1zm11.5 7a1.5 1.5 0 110-3 1.5 1.5 0 010 3z"/>
                                                                 </svg>}><Link target={`_blank`}
                                                                               to={`${userSettings.fileServer}${r.plan}`}>{r.min > building.floors ? "Кровля" : `с ${r.min} по ${r.max}`}</Link></Btn>
                                                            : <div
                                                                className={`w-full my-auto`}>{r.min > building.floors ? "Кровля" : `с ${r.min} по ${r.max}`}</div>}
                                                        <Btn method={`edit`} color={`button`} icononly={true}
                                                             className={`mx-2`}
                                                             onClick={() => editRangeHandler(s.id, r.id)}/>
                                                        <Btn method="remove" icononly={true}
                                                             onClick={() => deleteRangeHandler(s.id, r.id)}/>
                                                    </div>
                                                </React.Fragment>)}
                                        </div>
                                    }
                                    <div className={`mx-2 grow`}>
                                        {building && <React.Fragment>
                                            {isDisabledAdd
                                                ? <Tooltip
                                                    placement={"top"}
                                                    title={"Все этажи в разделе выбраны"}
                                                >
                                                    <Box>
                                                        <Button
                                                            disabled
                                                            variant="outlined"
                                                        >
                                                            Добавить диапазон
                                                        </Button>
                                                    </Box>
                                                </Tooltip>
                                                : <Button
                                                    onClick={() => addRangeHandler(s.id)}
                                                    variant="outlined"
                                                >
                                                    Добавить диапазон
                                                </Button>
                                            }

                                            <Btn icon={<svg className={`scale-90`} viewBox="0 0 384 512" fill="pult">
                                                <path
                                                    d="M320 464c8.8 0 16-7.2 16-16v-32h48v32c0 35.3-28.7 64-64 64H64c-35.35 0-64-28.7-64-64v-32h48v32c0 8.8 7.16 16 16 16h256zm-64-304c-17.7 0-32-14.3-32-32V48H64c-8.84 0-16 7.16-16 16v128H0V64C0 28.65 28.65 0 64 0h165.5c17 0 33.2 6.743 45.2 18.75l90.6 90.55c12 12 18.7 28.2 18.7 45.2V192h-48v-32h-80zM88 224c30.9 0 56 25.1 56 56s-25.1 56-56 56h-8v32c0 8.8-7.16 16-16 16s-16-7.2-16-16V240c0-8.8 7.16-16 16-16h24zm24 56c0-13.3-10.7-24-24-24h-8v48h8c13.3 0 24-10.7 24-24zm48-40c0-8.8 7.2-16 16-16h24c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48h-24c-8.8 0-16-7.2-16-16V240zm32 112h8c8.8 0 16-7.2 16-16v-64c0-8.8-7.2-16-16-16h-8v96zm144-128c8.8 0 16 7.2 16 16s-7.2 16-16 16h-32v32h32c8.8 0 16 7.2 16 16s-7.2 16-16 16h-32v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V240c0-8.8 7.2-16 16-16h48z"/>
                                            </svg>} className={`mx-auto`} onClick={() => addRangePdfHandler(s.id)}>Загрузить
                                                из PDF</Btn>
                                        </React.Fragment>}
                                    </div>
                                    {(sectionsRangeLists && sectionsRangeLists[s.id] && sectionsRangeLists[s.id].length > 0) &&
                                        <Btn icon={<svg fill="none" className={`scale-90`} viewBox="0 0 24 24">
                                            <path fill="currentColor" d="M13 7H7V5h6v2zM13 11H7V9h6v2zM7 15h6v-2H7v2z"/>
                                            <path fill="currentColor" fillRule="evenodd"
                                                  d="M3 19V1h14v4h4v18H7v-4H3zm12-2V3H5v14h10zm2-10v12H9v2h10V7h-2z"
                                                  clipRule="evenodd"/>
                                        </svg>} color="button" className={`mx-auto`}
                                             onClick={() => cloneSchemeHandler(s.id)}>Копировать схему</Btn>}
                                    <Btn method="remove" className={`mx-auto`}
                                         onClick={() => deleteSectionHandler(s.id)}>Удалить
                                        раздел</Btn>
                                </div>
                            )
                        })}
                    </div>
                </React.Fragment>}
            </React.Fragment>}
        </UserSection>
    );
}

export default PlanConfigurator;