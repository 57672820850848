import axios from 'axios';

export const GetUserInfo = async (userSettings, company_id, setCompanyUsers, setCompanyProjects) => {

    try {
        const updateCompanyUsers = await axios.get(
            `${userSettings.api}/company/${company_id}/users`,
            userSettings.headers.get
        );
        const updateCompanyProjects = await axios.get(
            `${userSettings.api}/company/${company_id}/projects/projects/`,
            userSettings.headers.get
        );

        if (setCompanyUsers) setCompanyUsers(updateCompanyUsers.data.users.sort((a, b) => a.id - b.id));
        if (setCompanyProjects) setCompanyProjects(updateCompanyProjects.data.data.sort((a, b) => a.id - b.id));
    } catch (error) {
        console.error('\n company-users', error.response.data || error.response);
    }
};