import React from "react";
import { Route, Routes } from "react-router-dom";
import { UserContextProvider } from "../../store/user/UserContextProvider";
import ControlPanel from "./ControlPanel/ControlPanel";
import { useCookies } from "react-cookie";

import WorkSection from "../UI/Sections/WorkSection";
import Hello from "./Hello";
import UsersManagement from "./Administrator/UsersManagement";
import ProjectsManagement from "./Administrator/ProjectsManagement";
import ContractorsManagement from "./Administrator/ContractorsManagement";
import FinancialSchedule from "./Coordinator/FinancialSchedule";
import ObjectInformation from "./Coordinator/ObjectInformation";
import CompanyRepresentatives from "./Coordinator/CompanyRepresentatives";
import ConstructionSupervision from "./Coordinator/ConstructionSupervision";
import PlanConfigurator from "./Coordinator/PlanConfigurator";
import ProductionCalendar from "./Coordinator/ProductionCalendar";
import Tenders from "./Coordinator/Tenders";
import ConsolidatedEstimateCalculation from "./SectionManager/ConsolidatedEstimateCalculation";
import DefineSSR from "./SectionManager/DefineSSR";
import QualityControl from "./ConstructionControl/QualityControl";
import QualityControlBuilding from "./ConstructionControl/QualityControlBuilding";
import SubcompanyWelcome from "./Subcompany/SubcompanyWelcome";
import JobLog from "./SectionManager/JobLog";
import GeodeticCenterBase from "./SectionManager/GeodeticCenterBase";
import AcceptanceTransferSite from "./SectionManager/AcceptanceTransferSite";
import AddJobLog from "./Subcompany/AddJobLog";
import InfoAccept from "./Coordinator/InfoAccept";
import SubmitWorkPayment from "./Subcompany/SubmitWorkPayment";
import ViewOrder from "./Subcompany/ViewOrder";
import SectionManagerTasks from "./Gip/SectionManagerTasks";
import ViewGipOrder from "./Gip/ViewGipOrder";
import TenderInfo from "./Coordinator/TenderInfo";
import TenderAdd from "./Coordinator/TenderAdd";
import UploadRegistrationDocs from "./Subcompany/UploadRegistrationDocs";
import UnapprovedWorkLog from "./SectionManager/UnapprovedWorkLog";
import UnapprovedWorkLogDocuments from "./ConstructionControl/UnapprovedWorkLogDocuments";
import UploadRegistrationActs from "./Subcompany/UploadRegistrationActs";
import FinancialControlPlanFact from "./Depositor/FinancialControlPlanFact";
import QualityControlWorks from "./Depositor/QualityControlWorks";
import SubcompanyDocuments from "./Coordinator/SubcompanyDocuments";
import GenerateAktOsvidetelstvovaniyaPDF from "./Subcompany/Akt-osvidetelstvovaniya/GenerateAkt-OsvidetelstvovaniyaPDF";
import GenerateKS2PDF from "./Subcompany/KS-2/GenerateKS-2PDF";
import GenerateKS3PDF from "./Subcompany/KS-3/GenerateKS-3PDF";
import Rates from "./Depositor/Rates";
import CardPay from "../Public/Payment/CardPay";
import PaymentEvent from "../Public/Payment/PaymentEvent";
import PaymentCompany from "../Public/Payment/PaymentCompany";
import AdministratorTariffRequests from "./Administrator/AdministratorTariffRequests";
import DepositorTariffRequests from "./Depositor/DepositorTariffRequests";
import ReportKS6A from "./Subcompany/ReportKS-6A";
import ContractExecutionStatus from "./Subcompany/ContractExecutionStatus";
import CreateActs from "./Subcompany/CreateActs";
import Representatives from "./Subcompany/Representatives";
import SubcompanyInfo from "./Subcompany/SubcompanyInfo";

const Work = (props) => {
  const [cookies] = useCookies(["access"]);
  const token = `Bearer ${cookies.access}`;

  const company_id = +window.localStorage.getItem("company");
  const subcompany_id = +window.localStorage.getItem("subcompany");

  return (
    <UserContextProvider
      token={token}
      erpApiUrl={props.erpApiUrl}
      fileServer={props.fileServer}
      tender_directions={props.tender_directions}
    >
      <div className="flex h-screen overflow-none">
        {company_id || subcompany_id ? <ControlPanel /> : ""}
        <WorkSection>
          <Routes>
            {/* Маршрут по умолчанию для /work/ */}
            <Route path="/" element={<Hello />} />

            {/*Administrator*/}
            <Route
              path="administrator/users-management"
              element={<UsersManagement />}
            />
            <Route
              path="administrator/projects-management"
              element={<ProjectsManagement />}
            />
            <Route
              path="administrator/contractors-management"
              element={<ContractorsManagement />}
            />
            <Route
              path="administrator/tariff-requests"
              element={<AdministratorTariffRequests />}
            />

            {/*Coordinator*/}
            <Route
              path="coordinator/financial-schedule"
              element={<FinancialSchedule />}
            />
            <Route
              path="coordinator/object-information"
              element={<ObjectInformation />}
            />
            <Route
              path="coordinator/company-representatives"
              element={<CompanyRepresentatives />}
            />
            <Route
              path="coordinator/construction-supervision"
              element={<ConstructionSupervision />}
            />
            <Route
              path="coordinator/plan-configurator/*"
              element={<PlanConfigurator />}
            />
            <Route
              path="coordinator/production-calendar"
              element={<ProductionCalendar />}
            />
            <Route path="coordinator/tenders" element={<Tenders />} />
            <Route
              path="coordinator/contract/info-accept/*"
              element={<InfoAccept />}
            />
            <Route path="coordinator/tender-info/*" element={<TenderInfo />} />
            <Route path="coordinator/tender-add" element={<TenderAdd />} />
            <Route
              path="coordinator/documents"
              element={<SubcompanyDocuments />}
            />

            {/*SectionManager*/}
            <Route
              path="section-manager/object-information"
              element={<ObjectInformation />}
            />
            <Route
              path="section-manager/consolidated-estimate-calculation"
              element={<ConsolidatedEstimateCalculation />}
            />
            <Route
              path="section-manager/define-ssr/*"
              element={<DefineSSR />}
            />
            <Route path="section-manager/job-log/" element={<JobLog />} />
            <Route
              path="section-manager/geodetic-center-base/"
              element={<GeodeticCenterBase />}
            />
            <Route
              path="section-manager/acceptance-transfer-site/"
              element={<AcceptanceTransferSite />}
            />
            <Route
              path="section-manager/task-engineer/"
              element={<QualityControl />}
            />
            <Route
              path="section-manager/task-engineer/building/*"
              element={<QualityControlBuilding />}
            />
            <Route
              path="section-manager/agree-volume/*"
              element={<UnapprovedWorkLog />}
            />
            <Route
              path="section-manager/financial-control"
              element={<FinancialControlPlanFact />}
            />

            {/*ConstructionControl*/}
            <Route
              path="technical-control/quality-control"
              element={<QualityControl />}
            />
            <Route
              path="/technical-control/quality-control/building/*"
              element={<QualityControlBuilding />}
            />
            <Route
              path="/technical-control/agree-quality-documents/*"
              element={<UnapprovedWorkLogDocuments />}
            />

            {/*Subcompany*/}
            <Route
              path="subcompany/upload-registration-docs/*"
              element={<UploadRegistrationDocs />}
            />
            <Route
              path="subcompany/upload-registration-acts/*"
              element={<UploadRegistrationActs />}
            />
            <Route path="subcompany/welcome/" element={<SubcompanyWelcome />} />
            <Route path="subcompany/info/" element={<SubcompanyInfo />} />
            <Route path="subcompany/job-log/" element={<AddJobLog />} />
            <Route
              path="subcompany/submit-work-payment/"
              element={<SubmitWorkPayment />}
            />
            <Route path="subcompany/view-order/*" element={<ViewOrder />} />
            <Route path="subcompany/report-ks6a" element={<ReportKS6A />} />
            <Route
              path="subcompany/contract-execution-status"
              element={<ContractExecutionStatus />}
            />
            <Route
              path="subcompany/representatives"
              element={<Representatives />}
            />

            {/*11.9.5*/}
            <Route path="subcompany/acts" element={<CreateActs />} />
            <Route
              path="subcompany/akt-osvidetelstvovaniya"
              element={<GenerateAktOsvidetelstvovaniyaPDF />}
            />
            <Route path="subcompany/ks-2" element={<GenerateKS2PDF />} />
            <Route path="subcompany/ks-3" element={<GenerateKS3PDF />} />

            {/*GIP*/}
            <Route
              path="engineer/section-manager-tasks"
              element={<SectionManagerTasks />}
            />
            <Route
              path="engineer/section-manager-tasks/view-order/*"
              element={<ViewGipOrder />}
            />

            {/*Depositor*/}
            <Route
              path="depositor/financial-control"
              element={<FinancialControlPlanFact />}
            />
            <Route
              path="depositor/quality-control-works"
              element={<QualityControlWorks />}
            />
            <Route path="depositor/rates" element={<Rates />} />
            <Route
              path="depositor/tariff-requests"
              element={<DepositorTariffRequests />}
            />

            {/*Payment*/}
            <Route path="payment-card/*" element={<CardPay />} />
            <Route path="payment-event/*" element={<PaymentEvent />} />
            <Route path="payment-company/*" element={<PaymentCompany />} />
          </Routes>
        </WorkSection>
      </div>
    </UserContextProvider>
  );
};

export default Work;
