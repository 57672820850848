import React from "react";
import {Document, Font, Page, StyleSheet, Text, View} from "@react-pdf/renderer";
import {getCurrentDate} from "../../Work/Subcompany/Akt-osvidetelstvovaniya/helpers/getCurrentDate";
import {getSurnameAndInitials} from "../../Work/Subcompany/Akt-osvidetelstvovaniya/helpers/getSurnameAndInitials";
import {validateWorkNamesList} from "../../Work/Subcompany/Akt-osvidetelstvovaniya/helpers/validateWorkNamesList";

Font.register({
    family: "Roboto", src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"
});

const styles = StyleSheet.create({
    body: {
        fontFamily: "Roboto",
        fontSize: 10,
        padding: 20,
    },
    page: {
        display: "flex",
        flexDirection: "column",
        padding: 10,
    },
    block: {
        margin: "10px 0",
        display: "flex",
        flexDirection: "column",
    },
    row: {
        display: "flex",
        flexDirection: "row",
    },
    spaceBetween: {
        justifyContent: "space-between"
    },
    end: {
        alignItems: "flex-end",
    },
    horizontalBreak: {
        width: "100%",
        borderBottom: "1px solid black"
    },
    underline: {
        textDecoration: "underline"
    },
    textCenter: {
        textAlign: "center",
        margin: "10px 0",
    }
});

const AktOsvidetelstvovaniya = (props) => {
    const {
        object_name,
        workDates,
        workNames,
    } = props.fetchedInfo;

    const {
        customer,
        customerRepresentative,
        builder,
        builderRepresentative,
        documentation,
        documentationRepresentative,
    } = props.mixedState;

    const {
        builderControl,
        otherInspection,
        otherInspection2
    } = props.representativesState;

    const {
        first,
        second,
        fourth,
        sixth,
        seventh,
        additional_info,
        copies,
        attachments,
    } = props.formData;

    const representativePerson = props.representativePerson

    const currentDate = getCurrentDate()?.split(" ");
    const workStart = workDates.minDate ? workDates.minDate.split(" ") : "---";
    const workEnd = workDates.maxDate ? workDates.maxDate.split(" ") : "---";
    return (
        <Document title='Akt-osvidetelstvovaniya-otvetstvennyh-konstrukciy'>
            <Page style={styles.body}>
                <View style={styles.block}>
                    <Text>Объект капитального строительства:</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {object_name.object_name}
                        </Text>
                        <Text>(наименование проектной документации, почтовый или строительный адрес объекта капитального
                            строительства)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>Застройщик (технический заказчик, эксплуатирующая организация или региональный
                        оператор)</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {(customer.ogrn && customer.inn)
                                ? (customer.name + ", " + customer.ogrn + ", " + customer.inn + ", " + customer.legal_address)
                                : " "
                            }
                        </Text>
                        <Text>
                            (фамилия, имя, отчество, адрес места жительства, ОРГНИП, ИНН индивидуального
                            предпринимателя,
                            наименование, ОГРН, ИНН, место нахождения юридического лица, телефон/факс,
                            наименование, ОГРН, ИНН саморегулируемой организации, членом которой является – для
                            индивидуальных предпринимателей и юридических лиц;
                            фамилия, имя, отчество, паспортные данные, адрес места жительства, телефон/факс – для
                            физических лиц, не являющихся индивидуальными
                            предпринимателями)
                        </Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>Лицо, осуществляющее строительство</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {(builder.ogrn && builder.inn)
                                ? (builder.name + ", " + builder.ogrn + ", " + builder.inn + ", " + builder.legal_address)
                                : " "
                            }
                        </Text>
                        <Text>
                            (фамилия, имя, отчество, адрес места жительства, ОРГНИП, ИНН индивидуального
                            предпринимателя,
                            наименование, ОГРН, ИНН, место нахождения юридического лица, телефон/факс,
                            наименование, ОГРН, ИНН саморегулируемой организации, членом которой является)
                        </Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>Лицо, осуществляющее подготовку проектной документации</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {(documentation.ogrn && documentation.inn)
                                ? (documentation.name + ", " + documentation.ogrn + ", " + documentation.inn + ", " + documentation.legal_address)
                                : " "
                            }
                        </Text>
                        <Text>
                            (фамилия, имя, отчество, адрес места жительства, ОРГНИП, ИНН индивидуального
                            предпринимателя,
                            наименование, ОГРН, ИНН, место нахождения юридического лица, телефон/факс,
                            наименование, ОГРН, ИНН саморегулируемой организации, членом которой является)
                        </Text>
                    </View>
                </View>

                <View style={[styles.block, styles.textCenter]}>
                    <Text>АКТ</Text>
                    <Text>освидетельствования скрытых работ</Text>
                </View>

                <View style={[styles.row, styles.spaceBetween]}>
                    <View style={styles.row}>
                        <Text>N</Text>
                        <Text style={[styles.underline, {marginLeft: 5}]}>{21838123213}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text>"</Text>
                        <Text style={[styles.underline, {marginHorizontal: 2}]}>{currentDate[0]}</Text>
                        <Text>"</Text>
                        <Text style={[styles.underline, {marginHorizontal: 3}]}>{`${currentDate[1]}`}</Text>
                        <Text>{`${currentDate[2] + " " + currentDate[3]}`}</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>
                        Представитель застройщика (технического заказчика, эксплуатирующей организации или регионального
                        оператора) по вопросам строительного контроля
                    </Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {`${customerRepresentative.position + ", " + getSurnameAndInitials(customerRepresentative.name).initials}`}
                        </Text>
                        <Text>
                            (должность, фамилия, инициалы, идентификационный номер в национальном реестре специалистов в
                            области строительства, реквизиты распорядительного
                            документа, подтверждающего полномочия, с указанием наименования, ОГРН, ИНН, места нахождения
                            юридического лица, фамилии, имени, отчества, адреса
                            места жительства, ОРГНИП, ИНН индивидуального предпринимателя)
                        </Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>Представитель лица, осуществляющего строительство:</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {`${builderRepresentative.position + ", " + getSurnameAndInitials(builderRepresentative.name).initials}`}
                        </Text>
                        <Text>(должность, фамилия, инициалы, реквизиты распорядительного документа, подтверждающего
                            полномочия)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>
                        Представитель лица, осуществляющего строительство, по вопросам строительного контроля
                        (специалист
                        по организации строительства)
                    </Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {`${builderControl.position + ", " + getSurnameAndInitials(builderControl.name).initials}`}
                        </Text>
                        <Text>
                            (должность, фамилия, инициалы, идентификационный номер в национальном реестре специалистов в
                            области строительства, реквизиты распорядительного
                            документа, подтверждающего полномочия)
                        </Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>Представитель лица, осуществляющего подготовку проектной документации</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {`${documentationRepresentative.position + ", " + getSurnameAndInitials(documentationRepresentative.name).initials}`}
                        </Text>
                        <Text>
                            (должность, фамилия, инициалы, реквизиты распорядительного документа, подтверждающего
                            полномочия, с указанием наименования, ОГРН, ИНН, места
                            нахождения юридического лица, фамилии, имени, отчества, адреса места жительства, ОРГНИП, ИНН
                            индивидуального предпринимателя, наименования,
                            ОГРН, ИНН саморегулируемой организации, членом которой является указанное юридическое лицо,
                            индивидуальный предприниматель)
                        </Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>Представитель лица, выполнившего работы, подлежащие освидетельствованию</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {`${representativePerson.full_name}`}
                        </Text>
                        <Text>
                            (должность, фамилия, инициалы, реквизиты распорядительного документа, подтверждающего
                            полномочия, с указанием наименования, ОГРН, ИНН, места
                            нахождения юридического лица, фамилии, имени, отчества, адреса места жительства, ОРГНИП, ИНН
                            индивидуального предпринимателя)
                        </Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>а также иные представители лиц, участвующих в освидетельствовании:</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {otherInspection
                                ? `${otherInspection.position + ", " + getSurnameAndInitials(otherInspection.name).initials}`
                                : " "}
                        </Text>
                        <Text>(должность с указанием наименования организации, фамилия, инициалы, реквизиты
                            распорядительного документа, подтверждающего полномочия)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>произвели осмотр работ, выполненных</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {otherInspection
                                ? `${otherInspection.position + ", " + getSurnameAndInitials(otherInspection.name).initials}`
                                : " "}
                        </Text>
                        <Text>(наименование лица, выполнившего работы, подлежащие освидетельствованию)</Text>
                    </View>
                </View>

                <Text>и составили настоящий акт о нижеследующем:</Text>
                <View style={styles.block}>
                    <Text>1. К освидетельствованию предъявлены следующие работы:</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>{first}</Text>
                        <Text>(наименование скрытых работ)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>2. Работы выполнены по проектной документации</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>{second}</Text>
                        <Text>(номер, другие реквизиты чертежа, наименование проектной и/или рабочей документации,
                            сведения о лицах, осуществляющих подготовку раздела проектной
                            и/или рабочей документации)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>3. При выполнении работ применены</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>{validateWorkNamesList(workNames)}</Text>
                        <Text>
                            (наименование строительных материалов (изделий), реквизиты сертификатов и/или других
                            документов, подтверждающих их качество и безопасность)
                        </Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>
                        4. Предъявлены документы, подтверждающие соответствие работ предъявляемым требованиям:
                    </Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>{fourth}</Text>
                        <Text>(исполнительные схемы и чертежи, результаты экспертиз, обследований, лабораторных и иных
                            испытаний выполненных работ, проведенных в процессе</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <View>
                        <View style={styles.row}>
                            <Text>5. Даты: </Text>
                            <Text>начала работ</Text>
                            <Text style={{marginLeft: 2}}>«</Text>
                            <Text style={[styles.underline, {marginHorizontal: 2}]}>{workStart[0]}</Text>
                            <Text>»</Text>
                            <Text style={[styles.underline, {marginHorizontal: 3}]}>{workStart[1]}</Text>
                            <Text>{`${workStart[2] + " " + workStart[3]}`}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{marginLeft: 39}}>окончания работ</Text>
                            <Text style={{marginLeft: 2}}>«</Text>
                            <Text style={[styles.underline, {marginHorizontal: 2}]}>{workEnd[0]}</Text>
                            <Text>»</Text>
                            <Text style={[styles.underline, {marginHorizontal: 3}]}>{workEnd[1]}</Text>
                            <Text>{`${workEnd[2] + " " + workEnd[3]}`}</Text>
                        </View>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>6. Работы выполнены в соответствии с</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {sixth}
                        </Text>
                        <Text>(наименования и структурные единицы технических регламентов, иных нормативных правовых
                            актов, разделы проектной и/или рабочей документации)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>7. Разрешается производство последующих работ</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>{seventh}</Text>
                        <Text>(наименование работ, конструкций, участков сетей инженерно-технического
                            обеспечения)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <View style={styles.row}>
                        <Text>
                            Дополнительные сведения
                        </Text>
                        <Text style={[styles.underline, {marginHorizontal: 10}]}>
                            {additional_info}
                        </Text>
                    </View>
                    <View style={styles.row}>
                        <Text>Акт составлен в</Text>
                        <Text style={[styles.underline, {marginHorizontal: 10}]}>
                            {`${copies}`}
                        </Text>
                        <Text>экземплярах.</Text>
                    </View>
                    <Text>Приложения: {attachments}</Text>
                </View>

                <View style={styles.block}>
                    <Text>Представитель застройщика (технического заказчика, эксплуатирующей организации или
                        регионального
                        оператора) по вопросам строительного контроля
                    </Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {`${getSurnameAndInitials(customerRepresentative.name).initials}`}
                        </Text>
                        <Text>(фамилия, инициалы, подпись)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>Представитель лица, осуществляющего строительство</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {`${getSurnameAndInitials(builderRepresentative.name).initials}`}
                        </Text>
                        <Text>(фамилия, инициалы, подпись)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>Представитель лица, осуществляющего строительство, по вопросам строительного контроля
                        (специалист
                        по организации строительства)</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {`${getSurnameAndInitials(builderControl.name).initials}`}
                        </Text>
                        <Text>(фамилия, инициалы, подпись)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>Представитель лица, осуществляющего подготовку проектной документации</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {`${getSurnameAndInitials(documentationRepresentative.name).initials}`}
                        </Text>
                        <Text>(должность, фамилия, инициалы, подпись)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>Представитель лица, выполнившего работы, подлежащие освидетельствованию</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {`${representativePerson.short_name}`}
                        </Text>
                        <Text>(фамилия, инициалы, подпись)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>Представители иных лиц</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {otherInspection
                                ? `${getSurnameAndInitials(otherInspection.name).initials}`
                                : " "
                            }
                        </Text>
                        <Text>(фамилия, инициалы, подпись)</Text>
                    </View>

                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {otherInspection2
                                ? `${getSurnameAndInitials(otherInspection2.name).initials}`
                                : " "
                            }
                        </Text>
                        <Text>(фамилия, инициалы, подпись)</Text>
                    </View>
                </View>
            </Page>
        </Document>)
};

export default AktOsvidetelstvovaniya;