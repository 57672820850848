import React, {Suspense} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Welcome from "./components/Public/Welcome";
import Authorization from "./components/Public/Registration/Authorization";
import ForgotPassword from "./components/Public/Registration/ForgotPassword";
import PasswordReset from "./components/Public/Registration/PasswordReset";
import TenderList from "./components/Public/Tenders/TenderList";
import RegisterCompany from "./components/Public/Registration/RegisterCompany";
import RegistractionSuccess from "./components/Public/Registration/RegistractionSuccess";
import Payment from "./components/Public/Registration/Payment";
import PayCards from "./components/Public/Registration/PayCards";
import PaySuccess from "./components/Public/Registration/PaySuccess";
import PayNotSuccess from "./components/Public/Registration/PayNotSuccess";
import WaitBillPay from "./components/Public/Registration/WaitBillPay";
import CreatePassword from "./components/Public/Registration/CreatePassword";
import Work from "./components/Work/Work";
import Invite from "./components/Public/Registration/Invite";
import Reset from "./components/Public/Registration/Reset";
import ParticipateTender from "./components/Public/Tenders/ParticipateTender";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

const queryClient = new QueryClient()

function App() {

    const fileServer = process.env.REACT_APP_ERP_FILE_URL;
    const erpApiUrl = process.env.REACT_APP_ERP_API_URL;
    const tender_directions = {
        1: "Строительство",
        2: "Реклама",
        3: "Аренда и перевозки",
        4: "Материалы"
    };

    return (
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <Suspense>
                    <Routes>
                        <Route path="/" element={<Welcome erpApiUrl={erpApiUrl}/>}/>
                        <Route path="/authorization" element={<Authorization erpApiUrl={erpApiUrl}/>}/>
                        <Route path="/forgot-password" element={<ForgotPassword erpApiUrl={erpApiUrl}/>}/>
                        <Route path="/password-reset/*" element={<PasswordReset erpApiUrl={erpApiUrl}/>}/>
                        <Route path="/tenders" element={<TenderList fileServer={fileServer} api={erpApiUrl}
                                                                    tender_directions={tender_directions}/>}/>
                        <Route path="/tenders/participate/*"
                               element={<ParticipateTender api={erpApiUrl} tender_directions={tender_directions}
                                                           fileServer={fileServer}/>}/>
                        <Route path="/registration-company" element={<RegisterCompany api={erpApiUrl}/>}/>
                        <Route path="/registration-success" element={<RegistractionSuccess/>}/>
                        <Route path="/payment" element={<Payment erpApiUrl={erpApiUrl}/>}/>
                        <Route path="/pay-cards" element={<PayCards/>}/>
                        <Route path="/pay-success" element={<PaySuccess/>}/>
                        <Route path="/pay-not-success" element={<PayNotSuccess/>}/>
                        <Route path="/wait-bill-pay" element={<WaitBillPay/>}/>
                        <Route path="/create-password/*" element={<CreatePassword erpApiUrl={erpApiUrl}/>}/>
                        <Route path="/work/*" element={<Work erpApiUrl={erpApiUrl} fileServer={fileServer}
                                                             tender_directions={tender_directions}/>}/>
                        <Route path="/invite/*" element={<Invite erpApiUrl={erpApiUrl}/>}/>
                        <Route path="/reset/*" element={<Reset erpApiUrl={erpApiUrl}/>}/>
                    </Routes>
                </Suspense>
            </BrowserRouter>
        </QueryClientProvider>
    );
}

export default App;