import React, {useContext, useState} from "react";
import Btn from "../../UI/Buttons/Btn";
import {Link} from "react-router-dom";
import {Alert, Box, Button} from "@mui/material";
import UserContext from "../../../store/user/user-context";
import InputFile from "../../UI/Inputs/InputFile";
import {FormProvider, useForm} from "react-hook-form";
import axios from "axios";

export default function TendersDetail(
    {
        project,
        contractInfo,
        getAccessCheck,
        coordinatorAccepted,
        contractDocumentsUploaded,
        contractDocumentsAccepted,
        contractActsUploaded,
        contractActsAccepted,
        subcompany_id,
        contract,
        tender,
    }
) {
    const userSettings = useContext(UserContext);

    const methods = useForm();
    const {handleSubmit} = methods;


    const [isDetailed, setIsDetailed] = useState(false);
    const [agreement, setAgreement] = useState(contract?.agreement_document);

    const contractSelect = () => {

        window.localStorage.setItem('company', contract.subcompany);
        window.localStorage.setItem('tender', contract.tender);
        window.localStorage.setItem('role', 6);

        window.location.replace('/work/subcompany/welcome/')
    }

    const addAgreementDocument = async (data) => {
        const formData = new FormData();
        formData.append('agreement_document', data.agreement_document[0]);

        try {
            const addAgreementDoc = await axios.post(`${userSettings.api}/subcompanies/${contract.subcompany}/companies/${contract.main}/projects/${contract.project_id}/tenders/${contract.tender}/send-agreement/`, formData, {headers: userSettings.headers.postForm});
            setAgreement(addAgreementDoc.data.agreement_document)
        } catch (e) {
            console.error("error: ", e)
        }
    };

    return (
        <React.Fragment>
            <h2 className={`text-center  border-b border-pult/10`}>Заказчик {project.company.name}</h2>
            <Box className="flex justify-center mt-2">
                <Button
                    variant="contained"
                    onClick={() => setIsDetailed(!isDetailed)}>
                    {isDetailed ? 'Скрыть детали' : "Договор"}
                </Button>
            </Box>
            {isDetailed &&
                <Box className="flex flex-col space-y-2">
                    <Alert severity="info">
                        {contractInfo.map((c, id) =>
                            <div key={id}>
                                {c.label && <div>{c.label}</div>}
                                <div className={c.class ? c.class : `text-mm mb-1`}>
                                    {c.value}
                                </div>
                            </div>
                        )}
                    </Alert>

                    <Box className="flex flex-col space-y-2">
                        {agreement && !contract.is_agreement_concerted &&
                            <Alert severity="warning">Вы загрузили договор, но координатор его пока не одобрил
                                <Button variant="contained" className={`w-full`}>
                                    <Link
                                        to={`${userSettings.fileServer + agreement}`}
                                        target="_blank"
                                    >
                                        Показать договор
                                    </Link>
                                </Button>
                            </Alert>
                        }
                        {getAccessCheck &&
                            <Alert severity="success">Данные согласованы, договор по контракту прикреплён
                                <Button variant="contained" className={`w-full`}>
                                    <Link
                                        to={`${userSettings.fileServer + agreement}`}
                                        target="_blank"
                                    >
                                        Показать договор
                                    </Link>
                                </Button>
                            </Alert>
                        }
                        {contractDocumentsAccepted
                            ? <Alert severity="success">
                                Документы согласованы
                                <Button variant="contained" className={`w-full`}>
                                    <Link
                                        to={`subcompany/upload-registration-docs/?subcompany=${subcompany_id}&tender=${contract.tender}`}>
                                        Показать документы
                                    </Link>
                                </Button>
                            </Alert>
                            : <Alert severity="warning">
                                <Box>
                                    <div>Документы на согласовании</div>
                                    <Button variant="contained" className={`w-full`}>
                                        <Link
                                            to={`subcompany/upload-registration-docs/?subcompany=${subcompany_id}&tender=${contract.tender}`}>
                                            {!contractDocumentsUploaded ? 'Загрузить' : 'Показать'} документы
                                        </Link>
                                    </Button>
                                </Box>
                            </Alert>
                        }
                        {contractActsAccepted
                            ? <Alert severity="success">
                                Акты согласованы
                                <Btn className={`w-full`} color={!contractActsUploaded ? 'warning' : 'button'}>
                                    <Link
                                        to={`subcompany/upload-registration-acts/?subcompany=${subcompany_id}&tender=${contract.tender}`}>
                                        Показать акты
                                    </Link>
                                </Btn>
                            </Alert>
                            : <Alert severity="warning">
                                <div>Акты на согласовании</div>
                                <Btn className={`w-full`} color={!contractActsUploaded ? 'warning' : 'button'}>
                                    <Link
                                        to={`subcompany/upload-registration-acts/?subcompany=${subcompany_id}&tender=${contract.tender}`}>
                                        {!contractActsUploaded ? 'Загрузить' : 'Показать'} акты
                                    </Link>
                                </Btn>
                            </Alert>
                        }
                    </Box>

                </Box>}
            {contract && !agreement &&
                <FormProvider {...methods}>
                    <form>
                        <Box className='flex flex-col justify-center my-2 gap-2'>
                            <InputFile required={true} name="agreement_document" label='Договор'/>
                            <Button
                                onClick={handleSubmit(addAgreementDocument)}
                                variant="contained" color="warning" type="submit"
                            >
                                Отправить договор координатору
                            </Button>
                        </Box>
                    </form>
                </FormProvider>
            }
            {getAccessCheck &&
                <Box className='flex justify-center my-2'>
                    <Button
                        onClick={contractSelect}
                        variant="contained" color="success"
                    >
                        {tender?.tender.name}
                    </Button>
                </Box>
            }
        </React.Fragment>
    );
}