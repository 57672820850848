import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DiagramFinancialSchedule = (props) => {

    const template = {
        plan: {
            label: 'Финансовый план',
            color: '#555f78'
        },
        fact: {
            label: 'Выполненные работы',
            color: '#5f7855'
        }
    }

    // Собираем уникальные метки из планов и фактических данных
    const allTimestamps = [...new Set([
        ...Object.keys(props.data.plan || {}),
        ...Object.keys(props.data.fact || {})
    ])].sort((a, b) => a - b);

    const labels = allTimestamps.map(date => {
        const label = new Date(date * 1000).toLocaleDateString('ru-RU',{year: 'numeric', month: 'long'});
        return label[0].toUpperCase() + label.slice(1);
    });

    const data = {
        labels,
        datasets: [
            {
                label: template.plan.label,
                // Заполняем данные плана или null для отсутствующих дат
                data: allTimestamps.map(ts => props.data.plan[ts] || null),
                borderColor: template.plan.color,
                backgroundColor: template.plan.color,
                pointRadius: 3,
                pointHoverRadius: 7,
            },
            {
                label: template.fact.label,
                // Заполняем данные факта или null для отсутствующих дат
                data: allTimestamps.map(ts => props?.data?.fact?.[ts] || null),
                borderColor: template.fact.color,
                backgroundColor: template.fact.color,
                pointRadius: 3,
                pointHoverRadius: 7,
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        scales: {
            x: {
                beginAtZero: true,
            },
            y: {
                beginAtZero: true,
            },
        },
        plugins: {
            legend: false
        }
    };

    return (
        <div className={`h-[250px] mb-3`}>
            <Line options={options} data={data} height={1300} />
        </div>
    );
}

export default DiagramFinancialSchedule;