import React from "react";
import Btn from "../../UI/Buttons/Btn";
import statusConfig from "../ConstructionControl/statuses_config.json";
import fakeRepresentative from "./user.json";
import * as XLSX from "xlsx";

export default function EvidenceBase(
    {
        orders,
        userSettings,
    }
) {

    const subcompanies = [...new Set(orders?.map(order => order.contract))]
        .map(contract => {

            const contractOrders = orders.filter(order => order.contract === contract);
            const subcompany = contractOrders[0].subcompany;

            return ({
                orders: contractOrders,
                subcompany,
            })
        })
    ;

    const exportXls = (subcompany_orders) => {

        const redOrders = subcompany_orders || orders;

        const rows = [
            [
                "№",
                "Дата",
                "Действие",
                "Ответственный",
                "Описание",
                "Норматив",
                "Фото",
                "Подрядчик",
                "Категория",
                "Срок устранения"
            ]
        ];
        redOrders.forEach(order => {

            rows.push([
                order.id,
                new Date(order.history[0].date_time).toLocaleDateString(),
                statusConfig[order.history[0].status].label,
                order.history[0].user_id || fakeRepresentative.full_name,
                order.description,
                order.standard,
                order.photos.map(photo => `${userSettings.fileServer}${photo.file}`).join(", "),
                `${order.subcompany.info.name} Тендер: ${order.subcompany.tender.name}`,
                order.category,
                new Date(order.date_completion).toLocaleDateString()
            ])

            order.history.forEach((history, index) => {

                if (index > 0) rows.push([
                    "",
                    new Date(history.date_time).toLocaleDateString(),
                    statusConfig[history.status].label,
                    (history.status === 3) ? order.subcompany.info.director : history.user_id || fakeRepresentative.full_name,
                    "",
                    "",
                    "",
                    "",
                    "",
                    ""
                ]);
            })
        });

        const sheets = [
            {
                name: `${new Date().toLocaleDateString()}`,
                data: rows
            }
        ];
        const wb = XLSX.utils.book_new();
        sheets.forEach(sheet => {
            const ws = XLSX.utils.aoa_to_sheet(sheet.data);
            XLSX.utils.book_append_sheet(wb, ws, sheet.name);
        });

        const filename = `Доказательная база по подрядчикам ${new Date().toLocaleDateString()}.xlsx`;
        XLSX.writeFile(wb, filename);
    }

    return (subcompanies?.length > 0 &&
        <React.Fragment>
            <h2>Выгрузить доказательную базу</h2>
            <div className={`md:flex md:flex-wrap md:gap-2`}>
                {subcompanies?.map(s => {


                    return (
                        <Btn key={s.subcompany.id} method={`xls`} icon color={`button`} onClick={() => exportXls(s.orders)}>
                            {s.subcompany.tender.name} - {s.subcompany.info.name}
                        </Btn>
                    )
                })}
            </div>
            <Btn method={`xls`} color={`button`} onClick={() => exportXls(orders)}>Выгрузить по всем подрядчикам</Btn>
        </React.Fragment>
    );
}