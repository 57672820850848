import {Button} from "@mui/material";
import {PDFDownloadLink, PDFViewer} from "@react-pdf/renderer";
import useFetchedInfo from "./hooks/useFetchedInfo";
import AdmittanceAct from "../../../../UI/PdfDocuments/AdmittanceAct";

const GenerateAdmittanceActPDF = () => {
    const subcompany_id = +window.localStorage.getItem('company');
    const tender_id = +window.localStorage.getItem('tender');

    const {fetchedInfo, isLoading} = useFetchedInfo(subcompany_id, tender_id);

    return (
        <div>
            {!isLoading && fetchedInfo &&

                <div className="flex flex-col items-center">
                    <PDFDownloadLink
                        document={
                            <AdmittanceAct/>
                        }
                        fileName={'АКТ-ДОПУСК'}
                    >
                        <Button variant="contained">Скачать для подписи</Button>
                    </PDFDownloadLink>

                    {/*<PDFViewer>*/}
                    {/*    <AdmittanceAct/>*/}
                    {/*</PDFViewer>*/}
                </div>
            }
        </div>
    )
};

export default GenerateAdmittanceActPDF;
