import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import UserContext from "../../../store/user/user-context";
import UserSection from "../../UI/Sections/UserSection";
import axios from "axios";
import styles from "../dash.module.css";
import Btn from "../../UI/Buttons/Btn";
import Grids from "../../UI/Grids/Grids";
import Warnings from "../../UI/Sections/Warnings";

const QualityControl = () => {

    const navigate = useNavigate();
    const role = +window.localStorage.getItem('role');
    if (role !== 3 && role !== 4) navigate('/work/');

    const userSettings = useContext(UserContext);
    const company_id = window.localStorage.getItem('company');
    const project_id = window.localStorage.getItem('project');

    const [answers, setAnswers] = useState(null)
    const [buildingList, setBuildingList] = useState([]);
    useEffect(() => {
        axios.get(
            `${userSettings.api}/tep-building/companies/${company_id}/projects/${project_id}/buildings/`,
            userSettings.headers.get
        )
            .then(response => {
                if (response.data.data.length > 0) setBuildingList(response.data.data)
                else setAnswers(<Warnings>Отсутствуют ТЭП-объекты</Warnings>)
            })
            .catch(error => console.error('\n error', error));

    }, [company_id, project_id, userSettings]);

    return (
        <UserSection>
            <h1>Контроль качества работ</h1>

            {answers &&
                <div className='w-fit'>
                    {answers}
                </div>
            }

            {buildingList.length > 0 &&
                <React.Fragment>
                    <Grids cols={4}>
                        {buildingList.map(b => {

                            return (<div className={styles.card} key={b.id}>
                                <div className={styles.label}>{b.name}</div>
                                <div className={styles.area}>
                                    <div>Адрес: {b.address}</div>
                                    <div>Кадастровый номер: {b.cadastral_number}</div>
                                    <div>Этажи:
                                        с {b.underground_floors ? `-${b.underground_floors}` : 1} до {b.floors}</div>
                                </div>
                                <Link
                                    to={`/work/${role === 3 ? "technical-control/quality-control" : role === 4 && "section-manager/task-engineer"}/building/?building=${b.id}`}
                                    className={`mx-auto`}>
                                    <Btn color={`button`}>Перейти в корпус</Btn>
                                </Link>
                            </div>)
                        })}
                    </Grids>
                </React.Fragment>
            }
        </UserSection>
    );
}
export default QualityControl;