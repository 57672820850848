import {Alert} from "@mui/material";
import axios from "axios";
import {getCompanyInfo, getSubcomanyList} from "./workJournalData";
import {sendSequentialRequests} from "./sendSequentialRequests";

export const getQualityDocsList = async (userSettings, company_id, project_id, subcompany_id, tender_id) => {

    try {

        const response = await axios.get(
            `${userSettings.api}/work_payment/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/subcompanies/${subcompany_id}/quality-docs`,
            userSettings.headers.get
        );

        const company = await getCompanyInfo(userSettings, subcompany_id)

        if (response?.data?.length > 0) {
            return ({
                documents: response.data,
                company_id,
                project_id,
                tender_id,
                subcompany_id,
                company,
            });
        }
    } catch (error) {
        console.error('\n getQualityDocsList', error.response);

        if (error.response.data) {

            return ({
                success: false,
                message: error?.response?.data.errors.map((e, i) => <Alert severity="error"
                                                                           key={i}>attr: {e.attr} detail: {e.detail} code: {e.code} </Alert>)
            })
        }
    }
}

export const getQualityDocs = async (userSettings, company_id, project_id, currentRole) => {
    const subcompanies = await getSubcomanyList(
        userSettings,
        company_id,
        project_id,
    );

    if (subcompanies?.data?.length > 0) {

        const getQualityDocs = subcompanies?.data?.map(async (s) => {
            const response = await getQualityDocsList(
                userSettings,
                company_id,
                project_id,
                s.subcompany,
                s.tender,
            );

            return response;
        });
        const prepareQualityDocs = await Promise.all(getQualityDocs);
        const updateQualityDocs = prepareQualityDocs
            .filter((d) => d && d.documents)
            .filter((d) => {
                const roleChecks = {
                    4: (doc) => doc.is_manager_approved === false,
                    3: (doc) => doc.is_build_control_approved === false,
                };

                return roleChecks[currentRole]
                    ? d.documents.some(roleChecks[currentRole])
                    : false;
            });
        return updateQualityDocs;
    }
};

export const getTendersList = async (userSettings, company_id, project_id) => {
    try {
        const response = await axios.get(
            `${userSettings.api}/tenders/companies/${company_id}/`,
            userSettings.headers.get
        );

        if (response?.data) {
            return ({
                success: true,
                data: response?.data?.filter(t => t.project.id === project_id),
            })
        }
    } catch (error) {
        console.error('\n getTendersList', error.response);

        if (error.response.data) {

            return ({
                success: false,
                message: error?.response?.data.errors.map((e, i) => <Alert severity="error"
                                                                           key={i}>attr: {e.attr} detail: {e.detail} code: {e.code} </Alert>)
            })
        }
    }
}

export const rejectVolumesQualityDocuments = async (userSettings, company_id, project_id, tender_id, subcompany_id, comments) => {

    try {
        for (const [quality_id, manager_comments] of Object.entries(comments)) {
            axios.post(`${userSettings.api}/work_payment/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/subcompanies/${subcompany_id}/quality_documents/${quality_id}/reject-volumes`,
                JSON.stringify({manager_comments: manager_comments}),
                {headers: userSettings.headers.post}
            )
        }

    } catch (error) {
        console.error('\n rejectVolumesQualityDocuments', error.response);

        if (error.response) {

            return ({
                success: false,
                message: error?.response?.data.errors.map((e, i) => <Alert severity="error"
                                                                           key={i}>attr: {e.attr} detail: {e.detail} code: {e.code} </Alert>)
            })
        }
    }
}

export const rejectQualityDocuments = async (userSettings, company_id, project_id, tender_id, subcompany_id, comments) => {

    try {

        const requests = Object.keys(comments)?.map(quality_document_id => {

            const data = {
                "build_control_comments": comments[quality_document_id]
            };

            return (
                {
                    method: 'POST',
                    url: `${userSettings.api}/work_payment/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/subcompanies/${subcompany_id}/quality_documents/${quality_document_id}/reject-docs`,
                    data,
                    headers: userSettings.headers.post
                }
            )
        })
        const results = await sendSequentialRequests(requests);
        return results;
    } catch (error) {
        console.error('\n rejectQualityDocuments', error.response);

        if (error.response.data) {

            return ({
                success: false,
                message: error?.response?.data.errors.map((e, i) => <Alert severity="error"
                                                                           key={i}>attr: {e.attr} detail: {e.detail} code: {e.code} </Alert>)
            })
        }
    }
}

export const approveQualityDocuments = async (userSettings, company_id, project_id, tender_id, subcompany_id, documents) => {

    try {

        const requests = documents.map(quality_document_id => {

            return (
                {
                    method: 'POST',
                    url: `${userSettings.api}/work_payment/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/subcompanies/${subcompany_id}/quality_documents/${quality_document_id}/approve-docs`,
                    headers: userSettings.headers.post
                }
            )
        })

        const results = await sendSequentialRequests(requests);

        return results;
    } catch (error) {

        if (error.response.data) {

            return ({
                success: false,
                message: error?.response?.data.errors.map((e, i) => <Alert severity="error"
                                                                           key={i}>attr: {e.attr} detail: {e.detail} code: {e.code} </Alert>)
            })
        }
    }
}

export const approveVolumeQualityDocuments = async (userSettings, company_id, project_id, tender_id, subcompany_id, documents) => {

    try {
        for (let i = 0; i < documents.length; i++) {
            const response = await axios.post(
                `${userSettings.api}/work_payment/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/subcompanies/${subcompany_id}/quality_documents/${documents[i]}/approve-volumes`,
                {},
                {headers: userSettings.headers.post}
            )

        }
    } catch (error) {
        console.error('\n approveQualityDocuments', error.response);

        if (error.response.data) {

            return ({
                success: false,
                message: error?.response?.data.errors.map((e, i) => <Alert severity="error"
                                                                           key={i}>attr: {e.attr} detail: {e.detail} code: {e.code} </Alert>)
            })
        }
    }
}